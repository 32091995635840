import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../services';
import { DialogService } from 'src/app/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  pageLeave(): Observable<boolean> {
    return new Observable(ob => {
      // console.log('正在 离开');

      // ob.next(false)//
      ob.next(true);

      // if (!this.isSaven) {
      //   this.modal.warning({
      //     nzTitle: '正在离开，是否需要保存改动的数据？',
      //     nzOnOk: () => {
      //       // 保存数据
      //       ob.next(false);
      //       alert('is saving');
      //       this.isSaven = true;
      //     },
      //     nzCancelText: '取消',
      //     nzOnCancel: () => {
      //       ob.next(true);
      //     }
      //   });

      // } else {
      //   ob.next(true);
      // }
    });
  }


  // 1 3 4 5 6 7 8
  // 4 6 8  重复多次

  // 1
  // 构造函数永远首先被调用
  constructor(
    private dialogService: DialogService,
    private router: Router) {
    console.log(' - - constructor');
  }

  // 2
  // 输入属性变化时被调用
  ngOnChanges() {
    console.log(' - - ngOnChanges - ');
  }

  // 3
  // 组件初始化时被调用
  ngOnInit() {
    console.log(' - - ngOnInit - ');
  }

  // 4 2
  // 脏值检测时调用 (会调用多次)
   ngDoCheck() {
    console.log(' - - ngDoCheck - ');
  }

  // 5
  // 当内容投影ng-content完成时调用
  ngAfterContentInit(): void {
    console.log(' - - ngAfterContentInit - ');
  }

  // 6 2
  // Angular检测投影内容时被调用（多次）
  ngAfterContentChecked(): void {
    console.log(' - - ngAfterContentChecked - ');
  }

  // 7
  // 当组件视图（子视图）初始化完成时
  ngAfterViewInit(): void {
    console.log(' - - ngAfterViewInit - ');
  }

  // 8 2
  // 当检测视图变化时（多次）
  ngAfterViewChecked(): void {
    console.log(' - - ngAfterViewChecked - ');
  }

  // 9
  // 当组件销毁时
  ngOnDestroy(): void {
    console.log(' - - ngOnDestroy - ');
  }


  removeDialog() {
    this.dialogService.close();
  }

  toView() {
    this.router.navigate(['poi/1']);
  }
}
