export const PAGESUBTITLE = ''; //  - 上海教博会2021

export const MAPDIST: any = {
  // 2024 // 科学育人地图
  science_shsx: {
    _iid: 1,
    rid: 1,
    api: 'assets/tmp/json/science/shsx.json',
    title: '上海市学生（青少年）科创教育基地地图',
    ptitle: '上海市学生（青少年）科创教育基地地图' + PAGESUBTITLE,
    url: '/science/science_shsx'
  },
  science_shkp: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/shkp.json',
    title: '上海科普教育体验基地站点地图',
    ptitle: '上海科普教育体验基地站点地图' + PAGESUBTITLE,
    url: '/science/science_shkp'
  },
  science_qgzx: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/qgzx.json',
    title: '全国中小学科学教育实验区、实验校（上海地区）地图',
    ptitle: '全国中小学科学教育实验区、实验校（上海地区）地图' + PAGESUBTITLE,
    url: '/science/science_qgzx'
  },
  science_shsk: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/shsk.json',
    title: '上海市科技教育特色示范学校地图',
    ptitle: '上海市科技教育特色示范学校地图' + PAGESUBTITLE,
    url: '/science/science_shsk'
  },
  science_gjzx: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/gjzx.json',
    title: '国家中小学人工智能教育基地（上海地区）地图',
    ptitle: '国家中小学人工智能教育基地（上海地区）地图' + PAGESUBTITLE,
    url: '/science/science_gjzx'
  },
  science_gdjy: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/gdjy.json',
    title: '高等教育对接三大重点产业领域高校地图',
    ptitle: '高等教育对接三大重点产业领域高校地图' + PAGESUBTITLE,
    url: '/science/science_gdjy' // url: '/life/ty'
  },
  science_zyjy: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/zyjy.json',
    title: '职业教育对接上海“五大中心”建设的重点专业地图',
    ptitle: '职业教育对接上海“五大中心”建设的重点专业地图' + PAGESUBTITLE,
    url: '/science/science_zyjy'
  },
  science_kxjj: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/kxjj.json',
    title: '科学家精神教育基地地图',
    ptitle: '科学家精神教育基地地图' + PAGESUBTITLE,
    url: '/science/science_kxjj'
  },
  science_shsq: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/science/shsq.json',
    title: '上海市青少年科学实践工作站地图',
    ptitle: '上海市青少年科学实践工作站地图' + PAGESUBTITLE,
    url: '/science/science_shsq'
  },
  // --------------
  // // 2024 // 教育数字化转型地图
  // edu_shsj: {
  //   _iid: 4,
  //   rid: 4,
  //   api: 'assets/tmp/json/edu/shsj.json',
  //   title: '上海市教育信息化应用标杆培育校地图',
  //   ptitle: '上海市教育信息化应用标杆培育校地图' + PAGESUBTITLE,
  //   url: '/edu/edu_shsj'
  // },
  // edu_gdjy: {
  //   _iid: 4,
  //   rid: 4,
  //   api: 'assets/tmp/json/edu/gdjy.json',
  //   title: '高等教育对接“人工智能”重大产业领域高校地图',
  //   ptitle: '高等教育对接“人工智能”重大产业领域高校地图' + PAGESUBTITLE,
  //   url: '/edu/edu_gdjy'
  // },
  // edu_shgx: {
  //   _iid: 4,
  //   rid: 4,
  //   api: 'assets/tmp/json/edu/shgx.json',
  //   title: '上海高校“人工智能”专业人才培养地图',
  //   ptitle: '上海高校“人工智能”专业人才培养地图' + PAGESUBTITLE,
  //   url: '/edu/edu_shgx'
  // },
  // edu_shzy: {
  //   _iid: 4,
  //   rid: 4,
  //   api: 'assets/tmp/json/edu/shzy.json',
  //   title: '上海职业教育虚拟仿真实训基地地图',
  //   ptitle: '上海职业教育虚拟仿真实训基地地图' + PAGESUBTITLE,
  //   url: '/edu/edu_shzy'
  // },

  // 2022 new theme map
  newshsszjyyzkcdt: {
    _iid: 201,
    rid: 37,
    api: 'assets/tmp/json/new/201.json',
    title: '上海市素质教育优质课程资源地图',
    ptitle: '上海市素质教育优质课程资源地图' + PAGESUBTITLE,
    url: '/zlsjjy/201'
  },
  newshsxxtycgkfdt: {
    _iid: 202,
    rid: 39,
    api: 'assets/tmp/json/new/202.json',
    title: '上海市学校体育场馆开放地图',
    ptitle: '上海市学校体育场馆开放地图' + PAGESUBTITLE,
    url: '/zlsjjy/202'
  },
  newshsxsztyxsjlxdt: {
    _iid: 203,
    rid: 40,
    api: 'assets/tmp/json/new/203.json',
    title: '上海市学生主题研学实践路线地图',
    ptitle: '上海市学生主题研学实践路线地图' + PAGESUBTITLE,
    url: '/zlsjjy/203'
  },
  newkxjjsjyjddt: {
    _iid: 204,
    rid: 41,
    api: 'assets/tmp/json/new/204.json',
    title: '科学家精神教育基地地图',
    ptitle: '科学家精神教育基地地图' + PAGESUBTITLE,
    url: '/zlsjjy/204'
  },
  newshsjyxxhyybgpyxdt: {
    _iid: 205,
    rid: 44,
    api: 'assets/tmp/json/new/205.json',
    title: '上海市教育信息化应用标杆培育校地图',
    ptitle: '上海市教育信息化应用标杆培育校地图' + PAGESUBTITLE,
    url: '/jyszhzx/205'
  },
  newshgdjydjrgznzdcylygxdt: {
    _iid: 206,
    rid: 45,
    api: 'assets/tmp/json/new/206.json',
    title: '高等教育对接“人工智能”重大产业领域高校地图',
    ptitle: '高等教育对接“人工智能”重大产业领域高校地图' + PAGESUBTITLE,
    url: '/jyszhzx/206'
  },
  newshgxrgznzyrcpydt: {
    _iid: 207,
    rid: 46,
    labs: [],
    icon: false,
    api: 'assets/tmp/json/new/207.json',
    title: '上海高校“人工智能”专业人才培养地图',
    ptitle: '上海高校“人工智能”专业人才培养地图' + PAGESUBTITLE,
    url: '/jyszhzx/207'
  },

  newshjyxnfzsxjddt: {
    _iid: 208,
    rid: 47,
    api: 'assets/tmp/json/new/208.json',
    title: '上海职业教育虚拟仿真实训基地地图',
    ptitle: '上海职业教育虚拟仿真实训基地地图' + PAGESUBTITLE,
    url: '/jyszhzx/208'
  }, // TODO jyszhzx

  newsjswfzjysjqdt: {
    _iid: 210,
    rid: 36,
    api: 'assets/tmp/json/new/210.json',
    title: '“双减”15分钟教育实践圈地图',
    ptitle: '”双减“15分钟教育实践圈地图' + PAGESUBTITLE,
    url: '/zlsjjy/210'
  },
  newshszxxxrxcxdt: {
    _iid: 211,
    rid: 38,
    api: 'assets/tmp/json/new/211.json',
    title: '上海市中小学校入学查询地图',
    ptitle: '上海市中小学校入学查询地图' + PAGESUBTITLE,
    url: '/zlsjjy/211'
  },
  newcsjagzyjyjddt: {
    _iid: 212,
    rid: 42,
    api: 'assets/tmp/json/new/212.json',
    title: '长三角爱国主义教育基地地图',
    ptitle: '长三角爱国主义教育基地地图' + PAGESUBTITLE,
    url: '/zlsjjy/212'
  },
  newcsjndjyjddt: {
    _iid: 213,
    rid: 43,
    api: 'assets/tmp/json/new/213.json',
    title: '长三角劳动教育基地地图',
    ptitle: '长三角劳动教育基地地图' + PAGESUBTITLE,
    url: '/zlsjjy/213'
  },

  redzgxlszkc: { // 1
    _iid: 101,
    rid: 27,
    api: 'assets/tmp/json/red/1_zgxlszkc.json',
    title: '上海高校“中国系列”思政课选修课地图',
    ptitle: '上海高校“中国系列”思政课选修课地图' + PAGESUBTITLE,
    url: '/red/1'
  },
  redshzxyzgxldt: { // 2
    _iid: 102,
    rid: 30,
    api: 'assets/tmp/json/red/2_shzxyzgxldt.json',
    title: '上海中小幼“中国系列”课程地图',
    ptitle: '上海中小幼“中国系列”课程地图' + PAGESUBTITLE,
    url: '/red/2'
  },
  redzxxhsxyzj: { // 3
    _iid: 103,
    rid: 31,
    api: 'assets/tmp/json/red/3_xyhszj.json',
    title: '校园红色足迹地图',
    ptitle: '校园红色足迹地图' + PAGESUBTITLE,
    url: '/red/3',
    icon: false,
    labs: [
      {
        val: '中小学',
        name: '中小学'
      },
      {
        val: '高校',
        name: '高校'
      },
    ]
  },
  redshdzxhsjz: { // 4
    _iid: 104,
    rid: 32,
    api: 'assets/tmp/json/red/4_xyhsjz.json',
    title: '校园红色建筑地图',
    ptitle: '校园红色建筑地图' + PAGESUBTITLE,
    url: '/red/4',
    icon: false,
    labs: [
      {
        val: '校园红色建筑',
        name: '校园红色建筑'
      },
      {
        val: '校园红色建筑印迹',
        name: '校园红色建筑印迹'
      },
    ]
  },
  redxysssjyr: { // 5
    _iid: 105,
    rid: 33,
    api: 'assets/tmp/json/red/5_sssjyr.json',
    title: '“四史”实践育人地图',
    ptitle: '“四史”实践育人地图' + PAGESUBTITLE,
    url: '/red/5',
    icon: false,
    labs: [
      {
        val: '高中阶段',
        name: '高中阶段'
      },
      {
        val: '高校',
        name: '高校'
      },
    ]
  },
  redagzyjy: { // 6
    _iid: 106,
    rid: 34,
    api: 'assets/tmp/json/red/6_shagzyxlshsj.json',
    title: '上海爱国主义系列社会实践地图',
    ptitle: '上海爱国主义系列社会实践地图' + PAGESUBTITLE,
    url: '/red/6'
  },
  redcityculture: { // 7
    _iid: 107,
    rid: 35,
    api: 'assets/tmp/json/red/7_shhswh.json',
    title: '上海红色文化地图',
    ptitle: '上海红色文化地图' + PAGESUBTITLE,
    url: '/red/7',
    icon: false,
    labs: [
      {
        val: '革命旧址',
        name: '革命旧址'
      },
      {
        val: '纪念设施',
        name: '纪念设施'
      },
      {
        val: '革命遗址',
        name: '革命遗址'
      },
    ]
  },
  redshszxxzgxldsxxjyhdkc: { // 8
    _iid: 108,
    rid: 49,
    api: 'assets/tmp/json/red/8_shszxxzgxldsxxjyhdkc.json',
    title: '上海市中小学“中国系列”党史学习教育活动课程',
    ptitle: '上海市中小学“中国系列”党史学习教育活动课程' + PAGESUBTITLE,
    url: '/red/8'
  },

  gdjydjgjzdzlzdzy: {
    _iid: 1,
    rid: 1,
    title: '高等教育对接三大重点产业领域高校地图',
    ptitle: '高等教育对接三大重点产业领域高校地图' + PAGESUBTITLE,
    api: 'assets/tmp/json/city/gj.json',
    url: '/city/gdjydjgjzdzlzdzy',
  },
  zyjydjwdzx: {
    _iid: 2,
    rid: 2,
    api: 'assets/tmp/json/city/2_zyjydjwdzx.json',
    title: '职业教育对接上海“五个中心”建设的重点专业地图',
    ptitle: '职业教育对接上海“五个中心”建设的重点专业地图' + PAGESUBTITLE,
    url: '/city/zyjydjwdzx'
  },
  jyyjtpgjdt: {
    _iid: 3,
    rid: 3,
    title: '上海教育助力脱贫攻坚、服务乡村振兴地图',
    ptitle: '上海教育助力脱贫攻坚、服务乡村振兴地图' + PAGESUBTITLE,
    api: 'assets/tmp/json/city/3_jyyjtpgjdt.json',
    url: '/city/jyyjtpgjdt'
  },
  ty: {
    _iid: 4,
    rid: 4,
    api: 'assets/tmp/json/life/ty.json',
    title: '最柔软群体的托育机构地图',
    ptitle: '最柔软群体的托育机构地图' + PAGESUBTITLE,
    url: '/life/ty'
  },
  jmkhxx: {
    _iid: 5,
    rid: 5,
    api: 'assets/tmp/json/life/jmkhxx.json',
    title: '上海市新优质学校集群发展项目学校地图',
    ptitle: '上海市新优质学校集群发展项目学校地图' + PAGESUBTITLE,
    url: '/life/jmkhxx'
  },
  jqczjsgcxx: {
    _iid: 6,
    rid: 6,
    api: 'assets/tmp/json/life/jqczjsgcxx.json',
    title: '上海市公办初中强校工程实验校地图',
    ptitle: '上海市公办初中强校工程实验校地图' + PAGESUBTITLE,
    url: '/life/jqczjsgcxx'
  },
  xqhjth: {
    _iid: 7,
    rid: 7,
    title: '上海市学区化集团化办学地图',
    ptitle: '上海市学区化集团化办学地图 ' + PAGESUBTITLE,
    api: 'assets/tmp/json/life/xqhjth/shanghai.json',
    subapi: 'assets/tmp/json/life/xqhjth/',
    url: '/life/xqhjth'
  },
  tsgz: {
    _iid: 8,
    rid: 8,
    api: 'assets/tmp/json/life/tsgz.json',
    title: '上海市特色普通高中地图',
    ptitle: '上海市特色普通高中地图 ' + PAGESUBTITLE,
    url: '/life/tsgz'
  },
  wdxsyslm: {
    _iid: 9,
    rid: 9,
    title: '上海五大学生艺术联盟地图',
    ptitle: '上海五大学生艺术联盟地图 ' + PAGESUBTITLE,
    url: '/life/wdxsyslm',
    icon: true,
    icondir: '/assets/imgs/wdlm',
    api: 'assets/tmp/json/life/9_wdxsyslm.json',
    labs: [
      {
        id: 1,
        val: '合唱联盟',
        allname: '合唱联盟',
        name: '合'
      },
      {
        id: 2,
        val: '戏剧联盟',
        allname: '戏剧联盟',
        name: '戏'
      },
      {
        id: 3,
        val: '交响乐联盟',
        allname: '交响乐联盟',
        name: '交'
      },
      {
        id: 4,
        val: '民乐联盟',
        allname: '民乐联盟',
        name: '民'
      },
      {
        id: 5,
        val: '舞蹈联盟',
        allname: '舞蹈联盟',
        name: '舞'
      }
    ]
  },
  shstsjyxx: {
    _iid: 11,
    rid: 11,
    api: 'assets/tmp/json/life/11_shstsjyxx.json',
    title: '上海特殊教育学校地图',
    ptitle: '上海特殊教育学校地图 ' + PAGESUBTITLE,
    url: '/life/shstsjyxx'
  },
  tjzdzx: {
    _iid: 30,
    rid: 30,
    api: 'assets/tmp/json/life/tjzdzx.json',
    title: '特教指导中心地图',
    ptitle: '特教指导中心地图' + PAGESUBTITLE,
    url: '/life/tjzdzx'
  },
  zgzgtzbgt: {
    _iid: 12,
    rid: 12,
    title: '职业教育贯通培养地图',
    ptitle: '职业教育贯通培养地图 ' + PAGESUBTITLE,
    api: 'assets/tmp/json/life/12_zgzgtzbgt.json',
    url: '/life/zgzgtzbgt'
  },
  zjwpzy: {
    _iid: 13,
    rid: 13,
    title: '职教王牌专业地图',
    ptitle: '职教王牌专业地图 ' + PAGESUBTITLE,
    url: '/life/zjwpzy',
    icon: false,
    api: 'assets/tmp/json/life/13_zjwpzy.json',
    labs: [
      {
        val: '高职',
        name: '高职'
      },
      {
        val: '中职',
        name: '中职'
      },
    ]
  },
  sjjndsbzxx: {
    _iid: 14,
    rid: 14,
    api: 'assets/tmp/json/life/14_sjjndsbzxx.json',
    title: '世界技能大赛备战学校地图',
    ptitle: '世界技能大赛备战学校地图 ' + PAGESUBTITLE,
    url: '/life/sjjndsbzxx'
  },
  gjjsylzy: {
    _iid: 15,
    rid: 15,
    title: '一流大学和一流学科建设高校地图',
    ptitle: '一流大学和一流学科建设高校地图 ' + PAGESUBTITLE,
    url: '/life/gjjsylzy',
    icon: true,
    icondir: '/assets/imgs/city',
    api: 'assets/tmp/json/life/15_gjjsylzy.json',
    labs: [
      {
        id: 4,
        val: '一流大学',
        name: '一流大学'
      },
      {
        id: 11,
        val: '一流学科',
        name: '一流学科'
      },
    ]
  },
  gfgyxk: {
    _iid: 16,
    rid: 16,
    title: '上海高校高峰学科地图',
    ptitle: '上海高校高峰学科地图 ' + PAGESUBTITLE,
    url: '/life/gfgyxk',
    icon: false,
    api: 'assets/tmp/json/life/gfgyxk.json',
    labs: [
      {
        val: '',
        name: '高校'
      },
      {
        val: '高峰',
        name: '高峰学科'
      },
      // {
      //   val: '高原',
      //   name: '高原学科'
      // }
    ]
  },
  lnjyxx: {
    _iid: 18,
    rid: 18,
    title: '上海市老年教育学校地图',
    ptitle: '上海市老年教育学校地图 ' + PAGESUBTITLE,
    url: '/life/lnjyxx',
    icon: false,
    api: 'assets/tmp/json/life/18_lnjyxx.json',
    labs: [
      {
        val: '1',
        name: '市级老年大学'
      },
      {
        val: '2',
        name: '高校老年大学'
      },
      {
        val: '3',
        name: '区级老年大学'
      },
      {
        val: '4',
        name: '街镇老年大学'
      }
    ]
  },
  qsnkxsjgzz: {
    _iid: 20,
    rid: 20,
    api: 'assets/tmp/json/future/qsnkxsjgzz.json',
    title: '上海市青少年科学实践工作站地图',
    ptitle: '上海市青少年科学实践工作站地图 ' + PAGESUBTITLE,
    url: '/future/qsnkxsjgzz'
  },
  sedshsjjd: {
    _iid: 21,
    rid: 21,
    title: '上海十四大系列社会实践基地地图',
    ptitle: '上海十四大系列社会实践基地地图 ' + PAGESUBTITLE,
    url: '/future/sedshsjjd',
    icon: false,
    api: 'assets/tmp/json/future/21_sedshsjjd.json',
    labs: [
      {
        val: '1',
        name: '爱国主义教育系列'
      },
      {
        val: '2',
        name: '民族、民俗、民间文化系列'
      },
      {
        val: '3',
        name: '公民教育系列'
      },
      {
        val: '4',
        name: '健康与生存教育系列'
      },
      {
        val: '5',
        name: '科普教育系列'
      },
      {
        val: '6',
        name: '行业企业体验系列'
      },
      {
        val: '7',
        name: '工业和信息化系列'
      },
      {
        val: '8',
        name: '职业教育考察系列'
      },
      {
        val: '9',
        name: '农业生态考察系列'
      },
      {
        val: '10',
        name: '绿化环境系列'
      },
      {
        val: '11',
        name: '艺术教育系列'
      },
      {
        val: '12',
        name: '爱心服务系列'
      },
      {
        val: '13',
        name: '未成年人校外实践场所'
      },
      {
        val: '14',
        name: '上海市学生社区实践指导站'
      }
    ]
  },
  qgjtjycxsjjd: {
    _iid: 22,
    rid: 22,
    api: 'assets/tmp/json/future/22_qgjtjycxsjjd.json',
    title: '全国家庭教育创新实践基地地图',
    ptitle: '全国家庭教育创新实践基地地图 ' + PAGESUBTITLE,
    url: '/future/qgjtjycxsjjd'
  },
  qjzxxsxljksfzx: {
    _iid: 23,
    rid: 23,
    api: 'assets/tmp/json/future/qjzxxsxljksfzx.json',
    title: '上海市区级中小学生心理健康示范中心地图',
    ptitle: '上海市区级中小学生心理健康示范中心地图 ' + PAGESUBTITLE,
    url: '/future/qjzxxsxljksfzx'
  },
  smzsxxjdxx: {
    _iid: 24,
    rid: 24,
    title: '上海市民终身教育学习体验基地学习地图',
    ptitle: '上海市民终身教育学习体验基地学习地图 ' + PAGESUBTITLE,
    url: '/life/smzsxxjdxx',
    icon: false,
    api: 'assets/tmp/json/life/24_smzsxxjdxx.json',
    labs: [
      {
        val: '红色文化体验基地',
        name: '红色文化体验基地'
      },
      {
        val: '科普教育体验基地',
        name: '科普教育体验基地'
      },
      {
        val: '文化艺术体验基地',
        name: '文化艺术体验基地'
      },
      {
        val: '海派文化体验基地',
        name: '海派文化体验基地'
      },
      {
        val: '智慧生活体验基地',
        name: '智慧生活体验基地'
      },
      {
        val: '服饰文化体验基地',
        name: '服饰文化体验基地'
      },
      {
        val: '创意手工体验基地',
        name: '创意手工体验基地'
      },
      {
        val: '乒乓文化体验基地',
        name: '乒乓文化体验基地'
      },
      {
        val: '南湖革命传统教育体验基地',
        name: '南湖革命传统教育体验基地'
      },
      {
        val: '巧克力文化体验基地',
        name: '巧克力文化体验基地'
      },
      {
        val: '非遗传承体验基地',
        name: '非遗传承体验基地'
      },
      {
        val: '长三角市民终身学习体验站点',
        name: '长三角市民终身学习体验站点'
      }
    ]
  },
  zyjykfsxzx: {
    _iid: 25,
    rid: 25,
    api: 'assets/tmp/json/life/25_zyjykfsxzx.json',
    title: '上海市职业教育开放实训中心地图',
    ptitle: '上海市职业教育开放实训中心地图 ' + PAGESUBTITLE,
    url: '/life/zyjykfsxzx'
  },
  gqjyzyfb: {
    _iid: 26,
    rid: 26,
    title: '上海市各区中小学教育资源供给状况热力图',
    ptitle: '上海市各区中小学教育资源供给状况热力图 ' + PAGESUBTITLE,
    url: '/life/gqjyzyfb'
  },
  jcjyzyfb: {
    _iid: 27,
    rid: 17,
    title: '上海市基础教育资源分布图',
    ptitle: '上海市基础教育资源分布图',
    url: '/life/jcjyzyfb'
  },
  jmkyey: {
    _iid: 28,
    rid: 28,
    api: 'assets/tmp/json/life/28_jmkyey.json',
    title: '上海幼儿园分布图',
    ptitle: '上海幼儿园分布图 ' + PAGESUBTITLE,
    url: '/life/jmkyey'
  },
  shjysswjbjscgdt: {
    _iid: 29,
    rid: 29,
    title: '上海教育“十三五”基本建设成果地图',
    ptitle: '上海教育“十三五”基本建设成果地图',
    url: '/city/shjysswjbjscgdt',
    api: '/assets/tmp/json/city/29_shjysswjbjscgdt.json',
    icon: false,
    labs: [
      {
        val: '基教成果（案例）',
        name: '基教成果（案例）'
      },
      {
        val: '高教成果（案例）',
        name: '高教成果（案例）'
      },
    ]
  }
};
// 全部地图
export const ROUTES: any = [
  {
    id: 'edu',
    label: '教育主题地图',
    url: '/main',
    data:
      [
        {
          id: 'city',
          name: '教育让城市更美好',
          subs: [
            {
              label: '创新引领发展',
              subs: [
                {
                  id: 1,
                  batch: 1,
                  name: MAPDIST.gdjydjgjzdzlzdzy.title,
                  url: MAPDIST.gdjydjgjzdzlzdzy.url
                },
                {
                  id: 2,
                  batch: 2,
                  name: MAPDIST.zyjydjwdzx.title,
                  url: MAPDIST.zyjydjwdzx.url
                },
              ]
            },
            {
              label: '教育服务国家',
              subs: [
                {
                  id: 3,
                  batch: 3,
                  name: MAPDIST.jyyjtpgjdt.title,
                  url: MAPDIST.jyyjtpgjdt.url
                },
              ]
            },
            {
              label: '基建筑基逐梦',
              subs: [
                {
                  id: 29,
                  batch: 4,
                  name: MAPDIST.shjysswjbjscgdt.title,
                  url: MAPDIST.shjysswjbjscgdt.url
                },
              ]
            },
          ]
        },
        {
          id: 'life',
          name: '教育让人生更出彩',
          subs: [
            {
              label: '幼有善育',
              subs: [
                {
                  id: 4,
                  batch: 1,
                  name: MAPDIST.ty.title,
                  url: MAPDIST.ty.url
                },
                {
                  id: 28,
                  batch: 4,
                  name: MAPDIST.jmkyey.title,
                  url: MAPDIST.jmkyey.url
                }
              ]
            },
            {
              label: '优质均衡',
              subs: [
                {
                  id: 5,
                  batch: 1,
                  name: MAPDIST.jmkhxx.title,
                  url: MAPDIST.jmkhxx.url
                },
                {
                  id: 6,
                  batch: 1,
                  name: MAPDIST.jqczjsgcxx.title,
                  url: MAPDIST.jqczjsgcxx.url
                },
                {
                  id: 7,
                  batch: 1,
                  name: MAPDIST.xqhjth.title,
                  url: MAPDIST.xqhjth.url
                },
                {
                  id: 8,
                  batch: 1,
                  name: MAPDIST.tsgz.title,
                  url: MAPDIST.tsgz.url
                },
                {
                  id: 9,
                  batch: 2,
                  name: MAPDIST.wdxsyslm.title,
                  url: MAPDIST.wdxsyslm.url
                },
                {
                  id: 11,
                  batch: 2,
                  name: MAPDIST.shstsjyxx.title,
                  url: MAPDIST.shstsjyxx.url,
                },
                {
                  id: 30,
                  batch: 5,
                  name: MAPDIST.tjzdzx.title,
                  url: MAPDIST.tjzdzx.url,
                },
                {
                  id: 26,
                  batch: 4,
                  name: MAPDIST.gqjyzyfb.title,
                  url: MAPDIST.gqjyzyfb.url
                },
                {
                  id: 27,
                  batch: 4,
                  name: MAPDIST.jcjyzyfb.title,
                  url: MAPDIST.jcjyzyfb.url,
                },
              ]
            },
            {
              label: '贯通融合',
              subs: [
                {
                  id: 12,
                  batch: 3,
                  name: MAPDIST.zgzgtzbgt.title,
                  url: MAPDIST.zgzgtzbgt.url
                },
                {
                  id: 13,
                  batch: 2,
                  name: MAPDIST.zjwpzy.title,
                  url: MAPDIST.zjwpzy.url,
                },
                {
                  id: 14,
                  batch: 2,
                  name: MAPDIST.sjjndsbzxx.title,
                  url: MAPDIST.sjjndsbzxx.url
                },
                {
                  id: 25,
                  batch: 3,
                  name: MAPDIST.zyjykfsxzx.title,
                  url: MAPDIST.zyjykfsxzx.url
                },
              ]
            },
            {
              label: '特色一流',
              subs: [
                {
                  id: 15,
                  batch: 2,
                  name: MAPDIST.gjjsylzy.title,
                  url: MAPDIST.gjjsylzy.url,
                },
                {
                  id: 16,
                  batch: 1,
                  name: MAPDIST.gfgyxk.title,
                  url: MAPDIST.gfgyxk.url,
                },
              ]
            },
            {
              label: '泛在可选',
              subs: [
                {
                  id: 18,
                  batch: 2,
                  name: MAPDIST.lnjyxx.title,
                  url: MAPDIST.lnjyxx.url
                },
                {
                  id: 24,
                  batch: 3,
                  name: MAPDIST.smzsxxjdxx.title,
                  url: MAPDIST.smzsxxjdxx.url
                },
              ]
            },
          ]
        },
        {
          id: 'future',
          name: '教育让未来更可期',
          subs: [
            {
              label: '',
              subs: [
                {
                  id: 20,
                  batch: 1,
                  name: MAPDIST.qsnkxsjgzz.title,
                  url: MAPDIST.qsnkxsjgzz.url
                },
                {
                  id: 21,
                  batch: 3,
                  name: MAPDIST.sedshsjjd.title,
                  url: MAPDIST.sedshsjjd.url,
                },
                {
                  id: 22,
                  batch: 2,
                  name: MAPDIST.qgjtjycxsjjd.title,
                  url: MAPDIST.qgjtjycxsjjd.url
                },
                {
                  id: 23,
                  batch: 1,
                  name: MAPDIST.qjzxxsxljksfzx.title,
                  url: MAPDIST.qjzxxsxljksfzx.url
                },
              ]
            },
          ]
        },
      ],
  },
  {
    id: 'red',
    label: '红色育人地图',
    url: '/red',
    data: [
      {
        id: 'in',
        name: '内圈课程育人',
        subs: [
          {
            label: '',
            subs: [
              {
                id: 1,
                batch: 1,
                name: MAPDIST.redzgxlszkc.title,
                url: MAPDIST.redzgxlszkc.url,
              },
              {
                id: 2,
                batch: 1,
                name: MAPDIST.redshzxyzgxldt.title,
                url: MAPDIST.redshzxyzgxldt.url,
              },
              {
                id: 8,
                batch: 2,
                name: MAPDIST.redshszxxzgxldsxxjyhdkc.title,
                url: MAPDIST.redshszxxzgxldsxxjyhdkc.url,
              },
            ]
          },
        ]
      },
      {
        id: 'center',
        name: '中圈文化育人',
        subs: [
          {
            label: '',
            subs: [
              {
                id: 3,
                batch: 1,
                name: MAPDIST.redzxxhsxyzj.title,
                url: MAPDIST.redzxxhsxyzj.url,
              },
              {
                id: 4,
                batch: 1,
                name: MAPDIST.redshdzxhsjz.title,
                url: MAPDIST.redshdzxhsjz.url,
              },
              {
                id: 5,
                batch: 1,
                name: MAPDIST.redxysssjyr.title,
                url: MAPDIST.redxysssjyr.url,
              },
            ]
          },
        ]
      },
      {
        id: 'out',
        name: '外圈合力育人',
        subs: [
          {
            label: '',
            subs: [
              {
                id: 6,
                batch: 1,
                name: MAPDIST.redagzyjy.title,
                url: MAPDIST.redagzyjy.url
              },
              {
                id: 7,
                batch: 1,
                name: MAPDIST.redcityculture.title,
                url: MAPDIST.redcityculture.url
              },
            ]
          },
        ]
      },

    ]
  },
  {
    id: 'zlsjjy',
    label: '助力“双减”教育地图',
    url: '/zlsjjy',
    data: [
      {
        id: '',
        name: '',
        subs: [
          {
            label: '',
            subs: [
              {
                id: 210,
                batch: 1,
                name: MAPDIST.newsjswfzjysjqdt.title,
                url: MAPDIST.newsjswfzjysjqdt.url,
              },
              {
                id: 201,
                batch: 1,
                name: MAPDIST.newshsszjyyzkcdt.title,
                url: MAPDIST.newshsszjyyzkcdt.url,
              },
              {
                id: 211,
                batch: 1,
                name: MAPDIST.newshszxxxrxcxdt.title,
                url: MAPDIST.newshszxxxrxcxdt.url,
              },
              {
                id: 202,
                batch: 1,
                name: MAPDIST.newshsxxtycgkfdt.title,
                url: MAPDIST.newshsxxtycgkfdt.url,
              },
              {
                id: 203,
                batch: 1,
                name: MAPDIST.newshsxsztyxsjlxdt.title,
                url: MAPDIST.newshsxsztyxsjlxdt.url,
              },
              {
                id: 204,
                batch: 1,
                name: MAPDIST.newkxjjsjyjddt.title,
                url: MAPDIST.newkxjjsjyjddt.url,
              },
              {
                id: 212,
                batch: 2,
                name: MAPDIST.newcsjagzyjyjddt.title,
                url: MAPDIST.newcsjagzyjyjddt.url,
              },
              {
                id: 213,
                batch: 2,
                name: MAPDIST.newcsjndjyjddt.title,
                url: MAPDIST.newcsjndjyjddt.url,
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'jyszhzx',
    label: '教育数字化转型地图',
    url: '/jyszhzx',
    data: [
      {
        id: '',
        name: '',
        subs: [
          {
            label: '',
            subs: [
              {
                id: 205,
                batch: 1,
                name: MAPDIST.newshsjyxxhyybgpyxdt.title,
                url: MAPDIST.newshsjyxxhyybgpyxdt.url,
              },
              {
                id: 206,
                batch: 1,
                name: MAPDIST.newshgdjydjrgznzdcylygxdt.title,
                url: MAPDIST.newshgdjydjrgznzdcylygxdt.url,
              },
              {
                id: 207,
                batch: 1,
                name: MAPDIST.newshgxrgznzyrcpydt.title,
                url: MAPDIST.newshgxrgznzyrcpydt.url,
              },
              {
                id: 208,
                batch: 1,
                name: MAPDIST.newshjyxnfzsxjddt.title,
                url: MAPDIST.newshjyxnfzsxjddt.url,
              },
            ]
          }
        ]
      }
    ]
  }
];

