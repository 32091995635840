import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/shared/config';
import { RunService } from 'src/app/shared/services';

@Component({
  selector: 'app-in',
  templateUrl: './in.component.html',
  styleUrls: ['./in.component.styl']
})
export class RedInComponent implements OnInit {
  deviceMode = null;

  data: {label: string, subs: any[]}[] = [];

  constructor(
    private router: Router,
    private runService: RunService
  ) { }

  ngOnInit() {
    this.data = ROUTES[1].data[0].subs;
  }

  toTuoYu(url: string) {
    this.runService.reBackUrl = '/red/in';
    this.router.navigateByUrl(url);
  }
}
