import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/dialog';

@Component({
  selector: 'app-guide-share',
  templateUrl: './guide-share.component.html',
  styleUrls: ['./guide-share.component.styl']
})
export class GuideShareComponent implements OnInit {

  constructor(private dialogServce: DialogService) { }

  ngOnInit() {

  }

  close() {
    this.dialogServce.close();
  }
}
