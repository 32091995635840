import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { MAPDIST } from 'src/app/shared/config';

declare var wx: any;
// @ts-ignore
@Component({
  selector: 'app-kechuang-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceKeChuangListComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  resrows: any[] = [];
  constructor(
    private router: Router,
    private http: HttpClient,
    private ngZone: NgZone,
    private utilsService: UtilsService,
    private cd: ChangeDetectorRef ) {}

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;
  ngOnInit() {
    this.ttitle = MAPDIST.science_shsx.title;
    this.http.get(MAPDIST.science_shsx.api).toPromise().then((rows: any) => {
      // console.log('上海市学生（青少年）科创教育基地地图:', rows);
      this.rows = rows;
      this.clmpref.refreshMapPanel();

      // const te = [];
      // const test1 = this.utilsService.encryptData(JSON.stringify(te));
      // const test2 = this.utilsService.daeacarayapata(test1);
      // console.log('test1', test1);
      // console.log('test2', test2, JSON.stringify(test2) === JSON.stringify(te));
    });
  }
  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode) {
        title = row.district;
        totalRow.push(row);
      }
    }

    // // TOOD 临时修改， 概览模式两个 金山区青少年活动中心 ，先删除掉一个
    // console.log('totalRow', totalRow)
    // const idx = totalRow.findIndex( v => v.name === '金山区青少年活动中心');
    // if ( idx >= 0 ) {
    //   totalRow.splice( idx, 1);
    // }

    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
    this.cd.detectChanges();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.company) || p.test(row.name) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = '检索';
  }
  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }
  toView(i: number = -1) {
    // this.ngZone.run(() => this.router.navigateByUrl('/pmap/ty/' + i));
    this.ngZone.run(() => this.router.navigateByUrl('/science/pmap/science_shsx/' + i)); // /science/science_shsx
  }
}
