export * from './app-start/app-start.component';
export * from './main/main.component';

export * from './city';
export * from './future';
export * from './science';
// export * from './edu';
export * from './life';

export * from './builder';

export * from './pmap';
export * from './subpmap';

export * from './detail';

export * from './tmp';

export * from './routes.module';
