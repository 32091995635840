<app-rback rurl="/life" *ngIf="!showexpl"></app-rback>
<app-xtheme>
  <app-xtheme-hh> 
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">
          {{ttitle}}
          <app-expl [aei]='4' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group">
      <div class="map-zoom">
        <app-hzoom>
          <div>
            <img src="/assets/imgs/gif/tsgz.gif"/>
            <div class="map-svg-container" #mapSvgContainerRef ></div> 
          </div>
        </app-hzoom>
      </div> 
    </div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'tsgz'"></app-liked-shared>
      <div class="list-group life-theme-color">
        <div class="lwtitle"> 学校 </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let area of rows" >
            <div class="leftll" (click)="toDistrict(area.dcode)"><div class="txt">{{area.name}}</div></div>
            <div class="rightll">
              <div class="rllitem" *ngFor="let row of area.data" (click)="toView(row.id)">{{row.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copytime">数据更新时间：2023年5月</div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt life-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/tsgz.gif"/>
            <div class="map-svg-dom" #mapSvgContainerRefV ></div> 
          </app-zoomer>
        </div>

      </div>
    </div>
    <div class="right-lw"> 
      <div class="header-wrapper">
        <div class="search"> 
          <div class="logoname2 life-theme-color">
            {{ttitle}}
            <app-expl [aei]='4' (bindExplEvent)="showexpl = $event"></app-expl>
          </div> 
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle life-theme-color"> 学校 </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let area of rows" >
              <div class="leftll" (click)="toDistrict(area.dcode)"><div class="txt">{{area.name}}</div></div>
              <div class="rightll">
                <div class="rllitem" *ngFor="let row of area.data" (click)="toView(row.id)">{{row.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <div class="copytime">数据更新时间：2023年5月</div>
    </div>
   
  </app-xtheme-vv>
</app-xtheme>
  