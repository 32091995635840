<!--
<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">{{ttitle}}</div>
        <div class="input">
          <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
          <div class="clear-img" (click)="closeKeyboard(1)">
            <img class="clear" src="/assets/imgs/search.png"/>
          </div>
        </div>
       &lt;!&ndash; <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div>&ndash;&gt;
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" >
      <div class="map-zoom">
        <app-clmap #clmpref (bindSelected)="switchDist($event)"></app-clmap>
      </div>
    </div>
    <div class="llitem " *ngFor="let item of resrows" style="height: 0.01px;overflow: hidden;position: absolute;">{{item.company}}</div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'ty'"></app-liked-shared>
      <div class="list-group">
        <div class="lwtitle bblitem">
          <div class="title">{{title}}</div>
        </div>
        <div class="lwtitle life-theme-color bblitem" *ngIf="kw.length===0">
          <div class="pre iicon" (click)="preProf()">
            <img class="ipre" src="/assets/imgs/city/top2.png"/>
          </div>
          <div class="title">{{types[typei].name}}</div>
          <div class="next iicon" (click)="nextProf()">
            <img class="inext" src="/assets/imgs/city/top2.png"/>
          </div>
        </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.company}}</div>
          <div class="llitem" *ngIf="resrows.length===0">无数据</div>
        </div>&lt;!&ndash;active&ndash;&gt;
        <div class="state-group">数据更新时间：2024年5月</div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-lw">
     &lt;!&ndash; <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt life-theme-color">地图模式</div>
      </div>&ndash;&gt;
      <img class="compass" src="/assets/imgs/icon/compass.png" />
      <div class="map-group" >
        <div class="map-zoom">
          <app-vvclmap (bindSelected)="switchDist($event)"></app-vvclmap>
        </div>
      </div>
    </div>
    <div class="right-lw">
      <div class="header-wrapper">
        <div class="search">
          <div class="logoname2 life-theme-color">{{ttitle}}</div>
          <div class="input-group">
            <div class="input">
              <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2"  (keyup)="changeKw()"/>
              <div class="clear-img" (click)="closeKeyboard(2)">
                <img class="clear" src="/assets/imgs/search.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle bblitem">{{title}}</div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.company}}</div>
            <div class="llitem" *ngIf="resrows.length===0">无数据</div>
          </div>&lt;!&ndash;active&ndash;&gt;
          <div class="state-group">数据更新时间：2024年5月</div>
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
-->


<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">{{ttitle}}</div>
        <div class="input">
          <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
          <div class="clear-img" (click)="closeKeyboard(1)">
            <img class="clear" src="/assets/imgs/search.png" />
          </div>
        </div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
          <div class="txt life-theme-color">地图模式</div>
        </div>
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" >
      <div class="map-zoom">
        <app-clmap #clmpref (bindSelected)="switchDist($event)" [selBgColor]="'#E46C0A'"></app-clmap>
      </div>
    </div>
    <div class="llitem " *ngFor="let item of resrows" style="height: 0.01px;overflow: hidden;position: absolute;">{{item.name}}</div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'zyjykfsxzx'"></app-liked-shared>
      <div class="list-group">
        <div class="lwtitle"><div class="title">{{title}}</div></div>
        <div class="lwtitle life-theme-color bblitem" *ngIf="kw.length===0">
          <div class="pre iicon" (click)="preProf()">
            <img class="ipre" src="/assets/imgs/city/top2.png"/>
          </div>
          <div class="title">{{types[typei].name}}</div>
          <div class="next iicon" (click)="nextProf()">
            <img class="inext" src="/assets/imgs/city/top2.png"/>
          </div>
        </div>
        <div class="lwtlist {{kw.length===0?'':'issearch'}}" >
<!--          <div class="llitem" *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.company}}</div>-->
          <div class="llitem" *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)"> {{item.name}}</div>
          <div class="llitem" *ngIf="resrows.length===0">无数据</div>

         <!-- <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">
            <div class="scname">
              <div>{{i + 1}}. {{item.name}}</div>
            </div>
            <div class="scprojects">
              <div class="scpitem" *ngFor="let proj of item.majors">
                <div>{{proj}}</div>
              </div>
            </div>
          </div>
          <div class="llitem" *ngIf="resrows.length===0">无数据</div>-->
        </div><!--active-->
      </div>
      <div class="copytime">数据更新时间：2024年5月</div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-lw">
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
        <div class="txt life-theme-color">地图模式</div>
      </div>
      <img class="compass" src="/assets/imgs/icon/compass.png" />
      <div class="map-group" >
        <div class="map-zoom">
          <app-vvclmap (bindSelected)="switchDist($event)" [selBgColor]="'#E46C0A'"></app-vvclmap>
        </div>
      </div>
    </div>
    <div class="right-lw">
      <div class="header-wrapper">
        <div class="search">
          <div class="logoname2 life-theme-color">{{ttitle}}</div>
          <div class="input-group">
            <div class="input">
              <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2"  (keyup)="changeKw()"/>
              <div class="clear-img" (click)="closeKeyboard(2)">
                <img class="clear" src="/assets/imgs/search.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle"><div class="title">{{title}}</div></div>
          <div class="lwtitle life-theme-color bblitem" *ngIf="kw.length===0">
            <div class="pre iicon" (click)="preProf()">
              <img class="ipre" src="/assets/imgs/city/top2.png"/>
            </div>
            <div class="title">{{types[typei].name}}</div>
            <div class="next iicon" (click)="nextProf()">
              <img class="inext" src="/assets/imgs/city/top2.png"/>
            </div>
          </div>
          <div class="lwtlist {{kw.length===0?'':'issearch'}}">
<!--            <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">{{item.company}}</div>-->
            <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">{{item.name}}</div>
            <div class="llitem" *ngIf="resrows.length===0">无数据</div>


      <!--      <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">
              <div class="scname">
                <div>{{i + 1}}. {{item.name}}</div>
              </div>
              <div class="scprojects">
                <div class="scpitem" *ngFor="let proj of item.majors">
                  <div>{{proj}}</div>
                </div>
              </div>
            </div>
            <div class="llitem" *ngIf="resrows.length===0">无数据</div>-->
          </div><!--active-->
        </div>
        <div class="copytime">数据更新时间：2024年5月</div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
