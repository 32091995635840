<app-rback rurl="/main"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <img class="map" src="/assets/imgs/main/main-hh-map.png" /> 
    <div class="title">
      <img src="/assets/imgs/main/future-title.png" />
    </div>
    <div class="section-wrapper">
      <div class="sw-item" *ngFor="let item of data" >
        <!-- <div class="label">{{item.label}}</div> -->
        <div class="intro">
          <p *ngFor="let sub of item.subs" (click)="toTuoYu(sub.url)">{{sub.name}}</p>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <img src="/assets/imgs/main/main-vv-map.png"/>
    </div>
    <div class="right-wrapper">
      
      <div class="title">
        <img src="/assets/imgs/main/future-title.png" />
      </div>

      <div class="section-wrapper">
        <div class="sw-item" *ngFor="let item of data" >
          <!-- <div class="label">{{item.label}}</div> -->
          <div class="intro">
            <p *ngFor="let sub of item.subs" (click)="toTuoYu(sub.url)">{{sub.name}}</p>
          </div>
        </div>
      </div>
     
    </div>
  </app-xtheme-vv>
</app-xtheme>
  