import { Component, OnInit, ElementRef, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { XMapService } from 'src/app/shared/services';
import { mapOpenCloseAnimation } from 'src/app/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ROUTES } from 'src/app/shared/config';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl'],
  animations: [ mapOpenCloseAnimation ]
})
export class MainComponent implements OnInit {
  listVRoomShow = false;
  listVRooms = [
    {
      id: 0,
      name: '市级馆',
      color: 'city',
      childs: []
    },
    {
      id: 1,
      color: 'life',
      name: '长三角教育展示区',
      childs: []
    },
    {
      id: 2,
      color: 'future',
      name: '高等教育展示区',
      childs: []
    },
    {
      id: 3,
      color: 'city',
      name: '基础教育展示区',
      childs: []
    },
    {
      id: 4,
      color: 'life',
      name: '职业教育展示区',
      childs: []
    },
    {
      id: 5,
      disable: true,
      color: 'future',
      name: '教育企业展示区',
      childs: []
    },
  ];
  vdists = {};

  listModeShow = false;
  listRoutes = [];

  deviceMode = null;
  data: any;

  navi = 0;
  navs = [
    {
      name: '',
      items: []
    }
  ];

  popup = {
    id: 0,
    show: false,
    hh: {x: '', y: ''},
    vv: {x: '', y: ''}
  };

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRefV', {static: true}) mapSvgContainerRefVl: ElementRef;

  constructor(
    private rd2: Renderer2,
    private router: Router,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private xmapService: XMapService
  ) { }

  ngOnInit() {
    this.listRoutes = ROUTES[0].data;
    window.closeMask = () => {
      this.popup = {
        id: 0,
        show: false,
        hh: {x: '', y: ''},
        vv: {x: '', y: ''}
      };
      this.delMoBan();
      this.cd.detectChanges();
    };
    window.mapEvent = (i: number, ii: number) => {
      const dom = this.data[i];
      const popup = dom.popup;

      let hhx = popup.x / 610 * 100;
      let vvy = popup.y / 700 * 100;
      if (hhx > 36) { hhx = 36; }
      if (vvy > 78) { vvy = 78; }
      // if (vvy > 58) vvy = 58

      this.popup = {
        id: dom.id,
        show: true,
        hh: {x: hhx + 'vw', y: popup.y / 610 * 100 + 'vw'},
        vv: {x: popup.x / 700 * 100 + 'vh', y: vvy + 'vh'},
      };

      this.navi = 0;
      this.navs = this.vdists[dom.id];

      this.addMoBan(dom.data);
      this.cd.detectChanges();
    };
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();

    const vdists = {};
    // this.http.get('assets/tmp/json/main.json')
    // .toPromise()
    // .then((resp: any) => {
    //   for (let i = 0; i < resp.length; i++) {
    //     const row = resp[i]
    //     if (row.type<0) continue
    //     if (!vdists[row.dcode]) vdists[row.dcode] = {}
    //     if (!vdists[row.dcode][row.type]) vdists[row.dcode][row.type] = []
    //     vdists[row.dcode][row.type].push(row)

    //     this.listVRooms[row.type].childs.push(row)
    //   }
    //   const newvdists = {}
    //   Object.keys(vdists).forEach(dcode => {
    //     const area  = vdists[dcode]
    //     const vdata = []
    //     Object.keys(area).forEach(dtype => {
    //       const ditem = area[dtype]
    //       vdata.push({
    //         type: dtype,
    //         name: this.listVRooms[dtype].name,
    //         items: ditem
    //       })
    //     })
    //     newvdists[dcode] = vdata
    //   })
    //   this.vdists = newvdists
    // })
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #314f6a;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + i + ',' + ii + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path onclick="mapEvent(' + i + ',' + ii + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + item.bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon onclick="mapEvent(' + i + ',' + ii + ')" points="' + ele.points + '" fill="' + item.bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          default:

        }
      }
    }
    innerHTML += '<path class="mask-0 mask-dom" onclick="closeMask()" data-i="0" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-1 mask-dom" onclick="closeMask()" data-i="1" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-2 mask-dom" onclick="closeMask()" data-i="2" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-3 mask-dom" onclick="closeMask()" data-i="3" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRefVl.nativeElement, 'innerHTML', innerHTML);
  }

  addMoBan(data: any) {
    this.delMoBan();
    const paths = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].type != 'path') { break; }
      paths.push(data[i].val);
    }

    const doms = document.getElementsByClassName('mask-dom');
    for (let i = 0; i < doms.length; i++) {
      const dom = doms[i];
      const di  = parseInt(dom.getAttribute('data-i'));
      if (!paths[di]) { continue; }

      dom.setAttribute('d', paths[di]);
      dom.setAttribute('fill-opacity', '0.4');
    }
  }
  delMoBan() {
    const doms = document.getElementsByClassName('mask-dom');

    for (let i = 0; i < doms.length; i++) {
      const dom = doms[i];
      const di  = parseInt(dom.getAttribute('data-i'));
      dom.setAttribute('fill-opacity', '0');
      dom.setAttribute('d', 'M10000 10000');
    }
  }

  toNext(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  ccNavi(i: number) {
    this.navi = i;
    this.cd.detectChanges();
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }

  gotoVv(vcode: string) {
    window.location.href = environment.vvhost + '/' + vcode + '/?redirect_uri=' + encodeURIComponent(environment.vmap + '/main?r=miniprogram');
  }

  showlistVRoomShow() {
    this.listVRoomShow = true;
  }

}
