<app-rback rurl="/zlsjjy" ></app-rback>

<app-xtheme [theme]="'new0'">
    <app-xtheme-hh>
        <div class="new-map-wrapper">
            <div class="bg-1-group">
                <img src="/assets/image/0801/bg.jpg"/>
            </div>
            <div class="bg-2-group">
                <img src="/assets/image/0801/bg-ml.gif" />
            </div>

            <div class="middle-qiu">
                <img src="/assets/image/0801/m-qiu.png"/>
            </div>
            <div class="middle-sj">
                <img src="/assets/image/0801/m-sj.png"/>
            </div>

            <div class="qrcode-wrapper">
                <div class="logo2">
                    <img src="/assets/image/0801/h-logo1.png"/>
                </div>
                <div class="title-txt">{{ttitle}}</div>
                <div class="qwimg-group">
                    <div class="qwimg-img">
                        <img src="/assets/image/0918/qrcode.png" />
                        <img class="qrcode-qrcode" src="/assets/image/0919/qrcode-rx.png" />
                    </div>
                </div>
                <div class="qwmsg-group">
                    <img src="/assets/image/0918/qrcode-msg.png" />
                </div>
            </div>

        </div>
    </app-xtheme-hh>
    <app-xtheme-vv>
        <div class="new-map-wrapper bg-vv">
            <div class="bg-1-group">
                <img src="/assets/image/0801/vv-bg.png"/>
            </div>
            <div class="bg-2-group">
                <!-- <img src="/assets/image/0801/vv-bg-gif.png" onLoad="mapImgLoad()"/> -->
            </div>

            <div class="container-wrapper">
                <div class="left-wrapper">
                    <div class="middle-sj">
                        <img src="/assets/image/0801/m-sj.png"/>
                    </div>
                </div>
                <div class="right-wrapper">

                    <div class="qrcode-wrapper">
                        <div class="logo2">
                            <img src="/assets/image/0801/h-logo1.png"/>
                        </div>
                        <div class="title-txt">{{ttitle}}</div>
                        <div class="qwimg-group">
                            <div class="qwimg-img">
                                <img src="/assets/image/0918/qrcode.png" />
                                <img class="qrcode-qrcode" src="/assets/image/230906/l_rxcx.png" />
                            </div>
                        </div>
                        <div class="qwmsg-group">
                            <img src="/assets/image/0918/qrcode-msg.png" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </app-xtheme-vv>
</app-xtheme>
