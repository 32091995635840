import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-xyhsjz',
  templateUrl: './xyhsjz.component.html',
  styleUrls: ['./xyhsjz.component.styl']
})
export class RedXyhsjzComponent implements OnInit {


  ttitle = '';

  data: any[] = [];

  profs: any[] = [];
  seli = 0;

  rows: any[]  = [];

  titles2: any = [ '现在使用单位', '现在用途' ];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.profs  = MAPDIST.redshdzxhsjz.labs;
    this.ttitle = MAPDIST.redshdzxhsjz.title;
    this.http.get(MAPDIST.redshdzxhsjz.api).toPromise().then((data: any) => {
      for (let i = 0; i < data.length; i++) {
        data[i].jznames = data[i].jzname.split('/');
      }

      this.data = data;
      this.selied();
    });
  }

  changeIType(i: number) {
    this.seli  = i;
    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.profs[this.seli].val != item.type2) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/red/subpmap/redshdzxhsjz/' + this.seli + '/' + i);
  }

}
