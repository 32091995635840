import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { MAPDIST } from 'src/app/shared/config';

declare var wx: any;
@Component({
  selector: 'app-zhongxiaoxue-list', // app-tuoyu-list
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceZhongXiaoXueListComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  isTest = false; // 是否是实验区
  resrows: any[] = [];
  hasDataArea: any[] = [];
  constructor(
    private router: Router,
    private http: HttpClient,
    private ngZone: NgZone,
    private utilsService: UtilsService,
    private cd: ChangeDetectorRef ) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;
  ngOnInit() {
    this.ttitle = MAPDIST.science_qgzx.title;
    this.http.get(MAPDIST.science_qgzx.api).toPromise().then((rows: any) => {
      // console.log('全国中小学科学教育实验区、实验校（上海地区）地图：', JSON.stringify(rows[0]));
      // console.log('全国中小学科学教育实验区、实验校（上海地区）地图：', rows);
      // const hasDataArea = [
      //   {id: 310115, name : '浦东新区'},
      //   {id: 310113, name : '宝山区'},
      // ];
      // const test = [
      //   {dcode: 310115, company: '1上海宝山保霖托育有限公司', location: '31.404842,121.482368'},
      //   {dcode: 310113, company: '2上海宝山保霖托育有限公司', location: '31.404842,121.482368'},
      //   {dcode: 310113, company: '3上海宝山保霖托育有限公司', location: '31.404842,121.482368'},
      //   {dcode: 310113, company: '4上海宝山保霖托育有限公司', location: '31.404842,121.482368'},
      // ];
      // const hasDataArea = Array.from(new Set(test.map( v => v.dcode))).map( v => ( {id: v} ) );
      // console.log('hasDataArea', hasDataArea);
      // this.hasDataArea = hasDataArea;
      // this.rows = [];
      // const hasDataArea = Array.from(new Set(rows.map( v => v.dcode))).map( v => ( {id: v} ) );

      const hasDataArea = [
        {id: 310115, name : '浦东新区'},
        {id: 310107, name : '普陀区'},
        {id: 310109, name : '虹口区'},
        {id: 310113, name : '宝山区'},
        {id: 310104, name : '徐汇区'},
      ];
      this.hasDataArea = hasDataArea;
      this.rows = rows;
      this.clmpref.refreshMapPanel( undefined, this.hasDataArea );
    });
  }
  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode) {
        title = row.district;
        totalRow.push(row);
      }
    }
    // console.log('resrows', JSON.stringify(totalRow), this.dcode);
    const objs = this.hasDataArea.find(v => v.id === this.dcode);
    if ( totalRow.length === 0 && objs) {
      totalRow.push( {
        id: 7,
        dcode: this.dcode,
        company: `${objs.name}(全国中小学科学教育实验区)`,
        area: obj.name,
        categories: '',
        address: ''
      } );
    }


    this.resrows = totalRow;
    this.title = this.dcodename;
    this.isTest = this.hasDataArea.findIndex(v => v.id === this.dcode) >= 0;
    if (this.kw.length > 0) { this.kw = ''; }
    this.cd.detectChanges();

    // this.clmpref.refreshMapPanel( undefined, this.hasDataArea );
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }
    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.company) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    // return;
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/science_qgzx/' + i));
  }
}
