import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { XMapService, RunService } from 'src/app/shared/services';
import { EventManager, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-red-pmap',
  templateUrl: './pmap.component.html',
  styleUrls: ['./pmap.component.styl']
})
export class RedPmapComponent implements OnInit {
  seldiv = 0;
  rurl = '';
  model = '';

  deviceMode = null;

  rows = [];
  tmp  = {};

  selMarker = null;

  map       = null;
  infoWin   = null;
  markers   = [];
  markerClusterer = null;

  selid = -1;

  dcode = 0;
  latlngs: any  = {310115: '31.258582,121.601201', 310106: '31.232084,121.442082', 310104: '31.192365,121.435903', 310105: '31.205880,121.428550', 310107: '31.244153,121.432104', 310110: '31.285590,121.538554', 310114: '31.295508,121.310888', 310116: '30.891012,121.023380'};

  constructor(
    private title: Title,
    private ngZone: NgZone,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager,
    private runService: RunService
  ) { }

  ngOnInit() {
    const model = this.route.snapshot.paramMap.get('model');
    const selid = +this.route.snapshot.paramMap.get('selid');
    if (selid > -1) { this.selid = selid; }

    const pObjt = MAPDIST[model];


    // console.log('red pmap ngOnInit:', model, selid, pObjt);
    if (!pObjt) {
      this.location.back();
      return;
    }

    this.model = model;

    this.title.setTitle('地图模式 - ' + pObjt.ptitle);
    this.rurl = pObjt.url;
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      switch (model) {
        case 'redzgxlszkc':
          for (let i = 0; i < resp.length; i++) {
            const item = resp[i];
            item.majors = [];
            for (let j = 0; j < resp.length; j++) {
              const jtem = resp[j];
              if (jtem.name === item.name) { item.majors.push(jtem.major); }
            }
            resp[i] = item;
          }
          break;
        default:

      }
      this.rows = resp;
      this.xmapservice.loadQqMapJs(() => {
        this.loadFullMap();
      });
    });

    window.gotoView = (model: string, id: any) => {
      this.ngZone.run(() => {
        this.runService.isMapDetail = true;
        this.router.navigateByUrl('/red/d/' + model + '/' + id);
      });
    };
  }

  loadFullMap() {
    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 11,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new window.qq.maps.LatLngBounds(ne, sw)
    }));

    window.qq.maps.event.addListener(this.map, 'click', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });

    const MyOverlay = this.xmapservice.initMyRedOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => {
        this.loadFullMapMarkers();
      }
    });
  }

  loadFullMapMarkers() {
    this.infoWin.close(() => {this.closeMarker(); });

    const size = new window.qq.maps.Size(50, 57);
    const bicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, size);
    const biconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, size);

    let markers;
    if (environment.markerClusterer) {
      if (this.markerClusterer !== null) {
        this.markerClusterer.clearMarkers();
      }
      markers = new window.qq.maps.MVCArray();
    } else {
      markers = [];
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }

    for (let i = 0; i < this.rows.length; i++) {
      const obj = this.rows[i];

      let objLatLng;
      let myLatLng;
      const objlocation =  obj.location.split('；')[0];
      objLatLng = objlocation.split(',');
      if (objLatLng.length < 2) { objLatLng = ['31.240692', '121.501751']; }

      myLatLng = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);

      const marker = new window.qq.maps.Marker({
        position: myLatLng,
        icon: bicon,
        map: this.map,

        bicon, biconed
      });
      window.qq.maps.event.addListener(marker, 'click', () => {
        this.map.panTo(myLatLng);
        this.infoWin.xsetPosition(myLatLng);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        this.infoWin.open(() => {this.openMarker(marker); });
      });

      markers.push(marker);

      if (obj.id == this.selid) {
        this.map.setZoom(14);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        setTimeout(() => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
        }, 50);
        this.infoWin.open(() => {this.openMarker(marker); });
      }

    }

    if (this.dcode > 0) {
      const objLatLng = this.latlngs[this.dcode].split(',');
      const myLatLng  = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);
      // this.map.setZoom(14)
      this.map.panTo(myLatLng);
    }
    this.markers = markers;

    if (environment.markerClusterer) {
      this.markerClusterer = new window.qq.maps.MarkerCluster({
        maxZoom: 18,
        gridSize: 40,
        map: this.map,
        markers,
        zoomOnClick: true,
        minimumClusterSize: 3,
      });
    }
  }

  getInfoWinHtml(obj: any) {
    let html = '';
    switch (this.model) {
      case 'redzgxlszkc': // 中国思政

        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #c95b51">课程：</span>' + obj.majors.join('、') + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';

        html += '</div>';

        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'redzgxlszkc\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/new/icon/red-detail.png"/> </div> ';

        break;
      case 'redshzxyzgxldt':
          html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
          html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">课程：</span>' + obj.major + '</div>';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
          html += '</div>';
          break;
      case 'redshszxxzgxldsxxjyhdkc':
          html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
          html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">课程：</span>' + obj.subject + '</div>';
          html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
          html += '</div>';
          break;
      case 'redagzyjy': // 爱国主义教育
        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
        if (obj.tel) { html += '<div style="font-size: 12px; word-break:break-all;"><span style="color: #c95b51">电话：</span>' + obj.tel + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px; word-break:break-all;"><span style="color: #c95b51">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #c95b51">简介：</span>' + obj.intro + '</div>';
        html += '</div>';
        break;
      default:
    }

    return html;
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

}
