import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { XMapService } from '../../services/xmap.service';

@Component({
  selector: 'app-clmap',
  templateUrl: './clmap.component.html',
  styleUrls: ['./clmap.component.styl']
})
export class ClmapComponent implements OnInit {
  @Input() selBgColor = '#dd52e9';
  @Input() bgColor = '#4958e4';
  @Input() hasDataColor = '#4958e4';
  @Input() hasDataArea: any[] = [];
  kvs = {310101: '黄浦区', 310104: '徐汇区', 310105: '长宁区', 310106: '静安区', 310107: '普陀区', 310109: '虹口区', 310110: '杨浦区', 310112: '闵行区', 310113: '宝山区', 310114: '嘉定区', 310115: '浦东新区', 310116: '金山区', 310117: '松江区', 310118: '青浦区', 310120: '奉贤区', 310151: '崇明区'};

  data: any = [];

  @Output() bindSelected = new EventEmitter<any>(); // 当前变化的位置 index

  constructor(private rd2: Renderer2, private xmapService: XMapService) {}

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;

  ngOnInit() {
    // 此处会被pc 版组件覆盖
    window.mapEvent = (dcode: number) => {
      this.refreshMapPanel(dcode, this.hasDataArea);
    };
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();
    // this.refreshMapPanel()
  }

  refreshMapPanel(dcode: number = 310115, hasDataArea = []) {
    // 给这些添加有数据的颜色
    this.hasDataArea = hasDataArea;
    if ( hasDataArea && hasDataArea.length > 0 ) {
      hasDataArea.forEach( ( v) => {
        const index = this.data.findIndex( item => item.id === v.id);
        if ( index >= 0 ) {
          this.data.splice( index, 1, { ...this.data[index], hasData: true } );
          const doms1 = document.getElementsByClassName('dpath-' + v.id);
          for (let i = 0; i < doms1.length; i++)  {
            const dom = doms1[i];
            dom.setAttribute('fill', this.hasDataColor);
            dom.classList.add('has-data-panel');
          }
        }
      } );
    }
    this.removeSelectMapPanel();
    const doms = document.getElementsByClassName('dpath-' + dcode);
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill', this.selBgColor);
      dom.classList.add('selected-map-panel');
    }
    this.bindSelected.emit({id: dcode, name: this.kvs[dcode]});
  }
  removeSelectMapPanel() {
    const doms = document.getElementsByClassName('selected-map-panel');
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      const has = dom.classList.contains('has-data-panel');
      const color = has ? this.hasDataColor : this.bgColor;
      dom.setAttribute('fill', color);
      // dom.setAttribute('fill', this.bgColor);
      dom.classList.remove('selected-map-panel');
    }
    const ndoms = document.getElementsByClassName('selected-map-panel');
    if (ndoms.length > 0) { this.removeSelectMapPanel(); }
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + id + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path class="dpath-' + id + '" onclick="mapEvent(' + id + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon class="dpath-' + id + '" onclick="mapEvent(' + id + ')" points="' + ele.points + '" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
        }
      }
    }
    innerHTML += '</svg>';
    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
  }
}
