<app-rback [type]='2' [rurl]="'/city/gdjydjgjzdzlzdzy'"></app-rback>   
<div class="full-map-wrapper">
    <div class="qqmap-wrapper" id="qq-full-map-wrapper"></div>
  
    <div class="btns-group" [ngClass]="{'vv-btns': deviceMode !== 0}">
  
      <div class="bbitem" *ngFor="let icon of icons; let i = index" (click)="loadFullMapMarkers(i)">
        <div class="icon">
          <img src="/assets/imgs/city/i{{icon.id + (iconSeled===i ? 'ed' : '') }}.png" /> 
        </div> 
      </div>
    
    </div>
  
</div>