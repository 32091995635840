import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-qjzxxsxljksfzx',
  templateUrl: './qjzxxsxljksfzx.component.html',
  styleUrls: ['./qjzxxsxljksfzx.component.styl'],
})
export class FutureQjzxxsxljksfzxComponent implements OnInit {
  ttitle = '';

  rows: any = [];

  constructor(
    private http: HttpClient,
    private router: Router ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.qjzxxsxljksfzx.title;
    this.http.get(MAPDIST.qjzxxsxljksfzx.api).toPromise().then((rows: any) => {
      // console.log('1 rows', rows[0]);
      // console.log('2 rows', rows);
      // console.log('3 rows', JSON.stringify(rows));
      this.rows = rows;
    } );
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/pmap/qjzxxsxljksfzx/' + i);
  }

}
