import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SharedHammerConfig extends HammerGestureConfig {
  overrides = {
      pan: { threshold: 0.1 },
      pinch: { threshold: 0.4 },
      rotate: { enable: false }
  } as any;
}
