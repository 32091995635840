import { trigger, transition, style, query, animateChild, group, animate, state } from '@angular/animations';

const nextState = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ left: '100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('300ms ease-out', style({ left: '-100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ left: '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
]
const preState = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ left: '-100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('300ms ease-out', style({ left: '100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ left: '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
]

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('ZeroPage => OnePage', nextState),
    transition('OnePage => ZeroPage', preState),
 
    transition('ZeroPage => ThreePage', nextState),
    transition('ThreePage => ZeroPage', preState),

    transition('OnePage => TwoPage', nextState),
    transition('TwoPage => OnePage', preState),

    transition('OnePage => ThreePage', nextState),
    transition('ThreePage => OnePage', preState),

    transition('TwoPage   => ThreePage', nextState),
    transition('ThreePage => TwoPage',   preState),

    transition('ThreePage => FourPage', nextState),
    transition('FourPage  => ThreePage',preState),

    transition('FourPage => FivePage', nextState),
    transition('FivePage => FourPage',preState),
 
    transition('TwoPage => FivePage', nextState),
    transition('FivePage => TwoPage',preState),

    transition('ThreePage => FivePage', nextState),
    transition('FivePage => ThreePage',preState),

    transition('FivePage => SixPage', nextState),
    transition('SixPage  => FivePage',preState),

    transition('ThreePage => SixPage', nextState),
    transition('SixPage => ThreePage', preState),
  ])
 
export const mapOpenCloseAnimation = trigger('openClose', [
  state('open', style({
   left: '0',
   opacity: 1
  })),
  state('closed', style({
    left: '-100vw',
    opacity: 0
  })),
  transition('open => closed', [
    animate('0.1s')
  ]),
  transition('closed => open', [
    animate('0.2s')
  ]),
])
export const mapUpDownAnimation = trigger('upDown', [
  state('up', style({
   bottom: '0'
  })),
  state('down', style({
    bottom: '-100vh'
  })),
  transition('up => down', [
    animate('0.1s')
  ]),
  transition('down => up', [
    animate('0.2s')
  ]),
])