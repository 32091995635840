import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ClmapComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-10_tyytl',
  templateUrl: './10_tyytl.component.html',
  styleUrls: ['./10_tyytl.component.styl']
})
export class LifeTyytlComponent implements OnInit {
  ttitle = '';

  typei = 0;
  types = [{id: 1, name: '小学体育兴趣化'}, {id: 2, name: '初中体育多样化'}, {id: 3, name: '高中体育专项化'}];

  rows: any = [];

  dcode = 0;
  dcodename = '';

  kw = '';
  title = '';
  resrows: any[] = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private cd: ChangeDetectorRef, ) { }

    @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;

  ngOnInit() {
    this.ttitle = MAPDIST.tyytl.title;
    this.http.get(MAPDIST.tyytl.api).toPromise().then((rows: any) => {
      this.rows = rows;
      this.clmpref.refreshMapPanel();
    });

  }

  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;

    this.upDateList();
    if (this.kw.length > 0) { this.kw = ''; }

    this.cd.detectChanges();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.name) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/tyytl/' + i));
  }

  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }

    this.upDateList();
  }

  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }

    this.upDateList();
  }

  upDateList() {
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode && row.type === this.types[this.typei].id) {
        title = row.district;
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = this.dcodename;
  }
}
