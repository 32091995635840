<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname life-theme-color"> {{ttitle}} </div>
    <div class="map-group">
        <app-liked-shared [mapkey]="'gqjyzyfb'"></app-liked-shared>
        
        <app-hzoom> 
            <div class="map-svg-container" #mapSvgContainerRef ></div> 
        </app-hzoom>

        <div class="map-popup-list" *ngIf="popup.show" [ngStyle]="{'top': popup.hh.y, 'left': popup.hh.x}">
            <div class="mpl-bg">
                <div class="left-group">
                    <div class="lgitem">可提供学位数</div>
                    <div class="lgitem">实际在校人数</div> 
                    <div class="lgitem">资源供应值</div>
                </div>
                <div class="right-group">
                    <div class="lgitem">{{popup.obj.jhval}}</div>
                    <div class="lgitem">{{popup.obj.zxval}}</div>
                    <div class="lgitem">{{popup.obj.prop}}</div>
                </div>
            </div>
        </div> 
    </div>
    <div class="tuli-group">
        <img src="/assets/imgs/popupbg/tuli.png"/>
    </div>
 
    <div class="table-group">
        
        <div class="th-group">
            <div class="th"><div>区</div></div>
            <div class="th"><div>本区可提供学位数</div></div>
            <div class="th"><div>本区实际在校学生数</div></div>
            <div class="th"><div>资源供应值</div></div>
        </div>
        <div class="td-group" *ngFor="let item of bgkvs">
            <div class="td">{{item.name}}</div>
            <div class="td">{{item.jhval}}</div>
            <div class="td">{{item.zxval}}</div>
            <div class="td">{{item.prop}}</div>
        </div>
    </div>

    <div class="desc-group">
        <div class="p">资源供应值 = 本区可提供学位数 / 本区实际在校学生数</div>
        <div class="p">资源供应值越大，说明教育资源供应越充足。（具体数据点击地图）</div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv> 
    <div class="left-wrapper"> 
        <div class="map-svg-container">
            <app-zoomer>
                <div class="map-svg-dom" #mapSvgContainerRefV ></div> 
            </app-zoomer>
            <div class="map-popup-list" *ngIf="popup.show" [ngStyle]="{'top': popup.vv.y, 'left': popup.vv.x}">
                <div class="mpl-bg">
                    <div class="left-group">
                        <div class="lgitem">可提供学位数</div>
                        <div class="lgitem">实际在校人数</div> 
                        <div class="lgitem">资源供应值</div>
                    </div>
                    <div class="right-group">
                        <div class="lgitem">{{popup.obj.jhval}}</div>
                        <div class="lgitem">{{popup.obj.zxval}}</div> 
                        <div class="lgitem">{{popup.obj.prop}}</div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <div class="right-wrapper"> 
        <div class="logoname life-theme-color"> {{ttitle}} </div>
        <div class="tuli-group">
            <img src="/assets/imgs/popupbg/tuli.png"/>
        </div>
     
        <div class="table-group">
            <div class="th-group">
                <div class="th"><div>区</div></div>
                <div class="th"><div>本区可提供学位数</div></div>
                <div class="th"><div>本区实际在校学生数</div></div>
                <div class="th"><div>资源供应值</div></div>
            </div>
            <div class="td-group" *ngFor="let item of bgkvs">
                <div class="td">{{item.name}}</div>
                <div class="td">{{item.jhval}}</div>
                <div class="td">{{item.zxval}}</div>
                <div class="td">{{item.prop}}</div>
            </div>
        </div>
    
        <div class="desc-group">
            <div class="p">资源供应值 = 本区可提供学位数 / 本区实际在校学生数</div>
            <div class="p">资源供应值越大，说明教育资源供应越充足。（具体数据点击地图）</div>
        </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
 