import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-22_qgjtjycxsjjd',
  templateUrl: './22_qgjtjycxsjjd.component.html',
  styleUrls: ['./22_qgjtjycxsjjd.component.styl']
})
export class FutureQgjtjycxsjjdComponent implements OnInit {
  ttitle = '';

  data: any = [];

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.qgjtjycxsjjd.title;
    this.http.get(MAPDIST.qgjtjycxsjjd.api).toPromise().then((resp: any) => this.data = resp );
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/pmap/qgjtjycxsjjd/' + i);
  }

}
