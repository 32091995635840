import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-12_zgzgtzbgt',
  templateUrl: './12_zgzgtzbgt.component.html',
  styleUrls: ['./12_zgzgtzbgt.component.styl']
})
export class LifeZgzgtzbgtComponent implements OnInit {
  ttitle = '';
  kw = '';
  showexpl = false;

  data = [];

  rows = [];

  slabi = 0;
  slabs = [
    {
      id: 1,
      name: '中高职贯通'
    },
    {
      id: 2,
      name: '中本贯通'
    },
    {
      id: 3,
      name: '五年一贯制'
    }
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.zgzgtzbgt.title;
    this.http.get(MAPDIST.zgzgtzbgt.api).toPromise().then((data: any) => {
      this.data = data;
      this.grows();
    });
  }

  changeIType(i: number) {
    this.slabi = i;
    this.kw = '';
    this.grows();
  }

  grows() {
    const rows = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      if (row.type === this.slabs[this.slabi].name && (this.kw.length === 0 || (p.test(row.prename) || p.test(row.prearea) || p.test(row.major) || p.test(row.nexname)))) {
        rows.push(row);
      }
    }
    this.rows = rows;
  }
  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.grows();
  }

  toView(act: number = 0, id: number = 0) {
    this.router.navigateByUrl('/life/zgzgtzbgt/' + this.slabi + '/' + act + '/' + id);
  }

}
