import { Component, OnInit, NgZone, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { XMapService, RunService, UtilsService } from 'src/app/shared/services';
import { EventManager, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import {MAPDIST, PAGESUBTITLE} from 'src/app/shared/config';

@Component({
  selector: 'app-pmap',
  templateUrl: './pmap.component.html',
  styleUrls: ['./pmap.component.styl']
})
export class PmapComponent implements OnInit, AfterViewChecked {
  seldiv = 0;
  rurl = '';
  model = '';

  deviceMode = null;

  rows = [];
  tmp  = {};

  selMarker = null;

  map       = null;
  infoWin   = null;
  markers   = [];
  markerClusterer = null;

  selid = -1;
  pageTitle = '';
  dcode = 0;
  latlngs: any  = {310115: '31.258582,121.601201', 310106: '31.232084,121.442082', 310104: '31.192365,121.435903', 310105: '31.205880,121.428550', 310107: '31.244153,121.432104', 310110: '31.285590,121.538554', 310114: '31.295508,121.310888', 310116: '30.891012,121.023380'};

  constructor(
    private title: Title,
    private ngZone: NgZone,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager,
    private runService: RunService,
    private util: UtilsService,
  ) { }
  ngAfterViewChecked(): void {
    this.title.setTitle(this.pageTitle);
  }
  ngOnInit() {
    const model = this.route.snapshot.paramMap.get('model'); // jmkyey
    const selid = +this.route.snapshot.paramMap.get('selid'); // 1
    if (selid > -1) { this.selid = selid; }

    const pObjt = MAPDIST[model];
    // const pObjt = {
    //   _iid: 28,
    //   rid: 28,
    //   api: 'assets/tmp/json/life/28_jmkyey.json',
    //   title: '上海幼儿园分布图',
    //   ptitle: '上海幼儿园分布图 ' + PAGESUBTITLE,
    //   url: '/life/jmkyey'
    // }

    // console.log('pmap ngOnInit:', {model, selid, pObjt});

    if (!pObjt) {
      this.location.back();
      return;
    }

    this.model = model;
    this.pageTitle = '地图模式 - ' + pObjt.ptitle;
    this.rurl = pObjt.url;
    if (model === 'tsgz') {
      const dcode = this.runService.get('tsgz-district');
      if (dcode !== undefined) {
        this.runService.delete('tsgz-district');
        this.rurl += '/' + dcode;
        this.dcode = dcode;
      }
    }
    if (model === 'zyjydjwdzx') {
      const cxdcode = this.runService.get('zyjydjwdzx-district');
      if (cxdcode !== undefined) {
        this.runService.delete('zyjydjwdzx-district');
        this.rurl += '/' + cxdcode;
      }
    }
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      // console.log( '1 resp', JSON.stringify(resp));
      // console.log( 'resp', resp);

      // const te = {
      //   id: 1,
      //   area: '黄浦区',
      //   name: '上海市黄浦区中华路第三小学',
      //   address: '黄浦区乔家路7号',
      //   website: ' http://zsx.hpe.cn/xykfr/',
      //   remarks: '上海市黄浦区中华路第三小学',
      //   dcode: 310101,
      //   location: '31.21681,121.49821'
      // };

      // const te1 = {
      //   id: 1,
      //   area: '浦东新区',
      //   name: '上海集成电路科创教育基地',
      //   address: '上海市浦东新区丹桂路999弄C5栋',
      //   website: '',
      //   remarks: '',
      //   dcode: 310115,
      //   // location: '',
      //   location: '31.222449,121.628535',
      //   lng_lat: '31.222449,121.628535',
      //   subject: '集成电路',
      //   brief: '基地主要面向',
      //   company: '上海集成电路科创教育基地',
      // };

      // TODO 兼容处理， 返回的字段缺少经纬度

      // const defaultLocation = '31.222449,121.628535'
      const defaultLocation = '';
      this.rows = resp.map(v => ( {
        ...v,
        location: v.location || v.lng_lat || defaultLocation, // TODO 兼容处理， 地图1，2，3缺该字段
        name: v.name || v.company || '', // TODO 兼容处理， 返回的字段缺少 name
        area: v.area || v.district || '', // 地图4 缺少area
        subject: v.subject || '',
        brief: v.brief || '',
        website: v.website || '',
        remarks: v.remarks || '',
      } ));
      // this.rows = [te1];
      if (this.model === 'zyjydjwdzx') {
        const tmp = {};
        for (let i = 0; i < this.rows.length; i++) {
          const row = this.rows[i];
          if (!tmp[row.name]) { tmp[row.name] = []; }
          tmp[row.name].push(row);
        }
        this.tmp = tmp;
      }
      this.xmapservice.loadQqMapJs(() => {
        this.loadFullMap();
      });
    });

    window.gotoView = (model: string, id: any, poiid: any = '') => {
      this.ngZone.run(() => {
        this.runService.isMapDetail = true;
        switch (model) {
          case 'tsgz': // 特色高中
          case 'jqczjsgcxx': // 上海市公办初中强校工程实验校地图
          if (this.util.isWxMiniprogram()){
              const xurl = '/pages/poi/poi?id=' + poiid;
              window.parent.postMessage(`navigate:${xurl}`, '*');
              window.wx.miniProgram.navigateTo({
                url: xurl,
              });
            }
          break;
          default:
            this.router.navigateByUrl('/detail/' + model + '/' + id);
        }
      });
    };
  }

  loadFullMap() {
    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 11,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new window.qq.maps.LatLngBounds(ne, sw)
    }));

    window.qq.maps.event.addListener(this.map, 'click', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });

    const MyOverlay = this.xmapservice.initMyOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => {
        this.loadFullMapMarkers();
      }
    });
  }

  loadFullMapMarkers() {
    this.infoWin.close(() => {this.closeMarker(); });

    const size = new window.qq.maps.Size(50, 57);
    const bicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, size);
    const biconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, size);

    let markers;
    if (environment.markerClusterer) {
      if (this.markerClusterer !== null) {
        this.markerClusterer.clearMarkers();
      }
      markers = new window.qq.maps.MVCArray();
    } else {
      markers = [];
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }

    for (let i = 0; i < this.rows.length; i++) {
      const obj = this.rows[i];

      let objLatLng;
      let myLatLng;
      if (this.model === 'shjysswjbjscgdt') {
        objLatLng = obj.latlng;
      } else {
        const objlocation =  obj.location.split('；')[0];
        objLatLng = objlocation.split(',');
        if (objLatLng.length < 2) { objLatLng = ['31.240692', '121.501751']; }
      }

      myLatLng = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);

      const marker = new window.qq.maps.Marker({
        position: myLatLng,
        icon: bicon,
        map: this.map,
        bicon, biconed
      });
      window.qq.maps.event.addListener(marker, 'click', () => {
        this.map.panTo(myLatLng);
        this.infoWin.xsetPosition(myLatLng);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        this.infoWin.open(() => {this.openMarker(marker); });
      });

      markers.push(marker);

      // console.log('obj', {
      //   obj,
      //   id: obj.id,
      //   selid: this.selid,
      // });
      if (obj.id == this.selid) {
        this.map.setZoom(14);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        setTimeout(() => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
        }, 50);
        this.infoWin.open(() => {this.openMarker(marker); });
      }

    }
    if (this.dcode > 0) {
      const objLatLng = this.latlngs[this.dcode].split(',');
      const myLatLng  = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);
      // this.map.setZoom(14)
      this.map.panTo(myLatLng);
    }
    this.markers = markers;

    if (environment.markerClusterer) {
      this.markerClusterer = new window.qq.maps.MarkerCluster({
        maxZoom: 18,
        gridSize: 40,
        map: this.map,
        markers,
        zoomOnClick: true,
        minimumClusterSize: 3,
      });
    }
  }

  getInfoWinHtml(obj: any) {
    // console.log('getInfoWinHtml', obj);
    let html = '';
    switch (this.model) {
      case 'science_shsx': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;
      case 'science_shkp': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;
      case 'science_qgzx': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;
      case 'science_shsk': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;
      case 'science_gjzx': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;

      case 'ty': // 托育地图
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.company + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">机构代码：</span>' + obj.id + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">机构类型：</span>' + obj.type + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">服务形式：</span>' + obj.model + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区托育服务管理机构：</span>' + obj.organization + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">机构负责人：</span>' + obj.leader + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">机构联系电话：</span>' + obj.number + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">举办者：</span>' + obj.organizers + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';
        // html += '<div style="font-size: 12px"><span style="color: #9b7b7c">供餐情况：</span>' + obj.status + '</div>'
        // html += '<div style="font-size: 12px"><span style="color: #9b7b7c">收费情况（元/月）：</span>' + obj.pay + '</div>'
        html += '</div>';
        break;
      case 'tyytl':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        html += '</div>';

        html += '</div>';
        break;
      case 'jmkyey': // 家门口幼儿园
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #9b7b7c">所属机构名称：</span>' + obj.aname + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        html += '</div>';

        html += '</div>';
        break;
      case 'jmkhxx': // 家门口 好学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.remarks) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">微信公众号：</span>' + obj.remarks + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;word-break:break-all;"><span style="color: #9b7b7c">官网：</span>' + obj.website + '</div>'; }
        html += '<div style="font-size: 12px;margin-bottom:10px; white-space: nowrap;"></div>';
        html += '</div>';
        if (obj.website && false) { html += '<div><div style="font-size:0;line-height:0;display:flex;justify-content: center;"> <a href="' + obj.website + '"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail2.png"/> </a> </div></div>'; }

        html += '</div>';
        break;
      case 'jqczjsgcxx': // 加强建设
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';
        html += '</div>';
        if (obj.poiid){
          if (this.util.isWxMiniprogram()){
            html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'jqczjsgcxx\',' + obj.id + ',' + obj.poiid + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail.png"/> </div> ';
          } else {
            html += '<div style="padding:0 10px 10px 10px;font-size: 12px;display: flex;"><span style="color: #63a497;flex: 0 0 30px">详情扫一扫：</span><div id="jqczjsgcxx-qrcode" style="flex:1;padding:4px;background-color:#fff;border-radius: 4px;"></div></div>';
          }
          setTimeout(() => {
            new window.QRCode('jqczjsgcxx-qrcode', {
              text: environment.scanUrl + `?url=${encodeURIComponent(`/pages/poi/poi?id=${obj.poiid}`)}`,
              width: 128,
              height: 128,
              colorDark : '#000000',
              colorLight : '#ffffff',
              correctLevel : window.QRCode.CorrectLevel.H
          });
          }, 300);
        }
        break;
      case 'tsgz': // 特殊高中
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #63a497">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';

        // if (obj.introduction) html += '<div style="font-size: 12px"><span style="color: #9b7b7c">简介：</span>' + obj.introduction + '</div>'
        if (obj.website) { html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>'; }

        html += '</div>';
        if (obj.poiid){
          if (this.util.isWxMiniprogram()){
            html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'tsgz\',' + obj.id + ',' + obj.poiid + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail.png"/> </div> ';
          } else {
            html += '<div style="padding:0 10px 10px 10px;font-size: 12px;display: flex;"><span style="color: #63a497;flex: 0 0 30px">详情扫一扫：</span><div id="tsgz-qrcode" style="flex:1;padding:4px;background-color:#fff;border-radius: 4px;"></div></div>';
          }
          console.log(obj);

          setTimeout(() => {
            new window.QRCode('tsgz-qrcode', {
              text: environment.scanUrl + `?url=${encodeURIComponent(`/pages/poi/poi?id=${obj.poiid}`)}`,
              width: 128,
              height: 128,
              colorDark : '#000000',
              colorLight : '#ffffff',
              correctLevel : window.QRCode.CorrectLevel.H
          });
          }, 300);
        }
        break;
      case 'shstsjyxx': // 特殊教育
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        if (obj.introduction) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">简介：</span>' + obj.introduction + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>'; }

        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'tjzdzx': // 特教指导中心
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">电话：</span>' + obj.tel + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">简介：</span>' + obj.intro + '</div>';
        if (obj.webstie_wechat) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">网址/微信公众号：</span>' + obj.webstie_wechat + '</div>'; }
        html += '</div>';
        if (obj.poiid){
          if (this.util.isWxMiniprogram()){
            html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'tsgz\',' + obj.id + ',' + obj.poiid + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail.png"/> </div> ';
          } else {
            html += '<div style="padding:0 10px 10px 10px;font-size: 12px;display: flex;"><span style="color: #63a497;flex: 0 0 30px">详情扫一扫：</span><div id="tsgz-qrcode" style="flex:1;padding:4px;background-color:#fff;border-radius: 4px;"></div></div>';
          }
          console.log(obj);

          setTimeout(() => {
            new window.QRCode('tsgz-qrcode', {
              text: environment.scanUrl + `?url=${encodeURIComponent(`/pages/poi/poi?id=${obj.poiid}`)}`,
              width: 128,
              height: 128,
              colorDark : '#000000',
              colorLight : '#ffffff',
              correctLevel : window.QRCode.CorrectLevel.H
          });
          }, 300);
        }
        break;
      case 'qsnkxsjgzz':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #63a497">学科：</span>' + obj.subject + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">简介：</span>' + obj.Introduction + '</div>';
        if (obj.website) { html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>'; }
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'qjzxxsxljksfzx':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #63a497">区域：</span>' + obj.area + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';

        if (obj.introduction) { html += '<div style="font-size: 12px"><span style="color: #63a497">简介：</span>' + obj.introduction + '</div>'; }
        if (obj.website) { html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>'; }
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'gjzdsss':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #63a497">依托单位：</span>' + obj.unit + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>';
        if (obj.introduction) { html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #63a497">简介：</span>' + obj.introduction + '</div>'; }
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'qgjtjycxsjjd':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #63a497">行政区：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;

      case 'zyjydjwdzx': // 职业教育 对接5大中心
        const tmp = this.tmp[obj.name];
        let major = obj.major;
        let types = obj.type;
        if (tmp.length > 1) {
          const xtypes = [];
          const xmajor = [];
          for (let i = 0; i < tmp.length; i++) {
            const row = tmp[i];
            xtypes.push(row.type);
            xmajor.push(row.major);
          }
          types = xtypes.join();
          major = xmajor.join();
        }
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #63a497">行政区：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">产业：</span>' + types + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">专业：</span>' + major + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'shsxxhbgx': // 信息化 标杆校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">类型：</span>' + obj.type + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #9b7b7c">所属区/上级主管：</span>' + obj.superior + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px;"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';

        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      case 'sjjndsbzxx': // 世界技能大赛 备战学校
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';

        // for (let i = 0; i < obj.projects.length; i++) {
        //   html += '<div style="font-size: 12px"><span style="color: #9b7b7c">'+obj.projects[i]+'：</span>' + obj.introductions[i] + '</div>'
        // }
        html += '<div style="font-size: 12px;margin-bottom:10px;"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';


        html += '</div>';
        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'sjjndsbzxx\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail.png"/> </div> ';
        break;
      case 'zyjykfsxzx': // 上海市职业教育开放实训中心地图
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;margin-bottom:10px;"><span style="color: #9b7b7c">实训中心：</span>' + obj.majors.join('、') + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px;"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';
        html += '</div>';
        break;
      case 'shjysswjbjscgdt':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;margin-bottom:10px;"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>';
        html += '</div>';
        break;
      case 'gjjsylzy':
        html += '<div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;margin-bottom:0;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #63a497">类型：</span>' + obj.type + '</div>';
        // html += '<div style="font-size: 12px"><span style="color: #63a497">电话：</span>' + obj.tel + '</div>'
        html += '<div style="font-size: 12px"><span style="color: #63a497">学科：</span>' + obj.subjects + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #63a497">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #63a497">官网：</span>' + obj.website + '</div>';
        html += '</div>';

        html += '</div>';
        html += '</div>';
        break;
      default:
    }

    return html;
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }
}
