import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-13_zjwpzy',
  templateUrl: './13_zjwpzy.component.html',
  styleUrls: ['./13_zjwpzy.component.styl']
})
export class LifeZjwpzyComponent implements OnInit {
  ttitle = '';
  rows = [];
  data = [];

  iconSeled = 0;
  iconSeled2 = 0;
  icons = [
    {
      id: 1,
      name: '高职',
      childs: ['高水平高职', '高水平专业群']
    },
    {
      id: 2,
      name: '中职',
      childs: []
    },
  ];
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.zjwpzy.title;
    this.http.get(MAPDIST.zjwpzy.api).toPromise().then((resp: any) => {
      this.rows = resp;

      this.iconSeledEvent(0);
    });
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/subpmap/zjwpzy/' + this.iconSeled + '/' + i);
  }
  iconSeledEvent(i: number = 0) {
    this.iconSeled = i;
    if (i > 0) {
      this.iconSeled2 = undefined;
    } else {
      this.iconSeled2 = 0;
    }
    this.generateData();
  }
  iconSeled2Event(i: number = 0) {
    this.iconSeled2 = i;
    this.generateData();
  }
  generateData() {
    const data = [];
    const type2 = this.icons[this.iconSeled].childs[this.iconSeled2];
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (this.icons[this.iconSeled].name != row.type) { continue; }
      if (this.iconSeled2 != undefined && !row.type2.includes(this.icons[this.iconSeled].childs[this.iconSeled2])) { continue; }

      data.push(row);
    }
    this.data = data;
  }

}
