<app-guide [pici]="1"></app-guide>
<app-rback rurl="/city" *ngIf="!showexpl"></app-rback>

<app-xtheme>
  <app-xtheme-hh id="gjgj-wrapper">
    <div class="logoname city-theme-color">
      {{title}}
      <app-expl [aei]='0' (bindExplEvent)="showexpl = $event"></app-expl>
    </div>
    <div class="input-group">
      <div class="input" [ngClass]="{'active': kw.length > 0}">
        <input id="xinput-1" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
        <div class="img-clear" (click)="changeKw()">
          <img class="clear" src="/assets/imgs/search.png"/>
        </div>

        <div class="rlist-wrapper">
          <ng-container *ngFor="let item of rlist">
            <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="showFullMapEvent(3, $event, item.id)"> </div>
          </ng-container>
          <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
        </div>

      </div>

      <div class="map-model-group" (click)="showFullMapEvent(iconSeled, $event)">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt">地图模式</div>
      </div>

    </div>

    <div class="map-group">
      <div class="mg-container">
        <app-hzoom>
          <img class="oimg" *ngIf="iconSeled===-1" src="/assets/imgs/main/main-map.png" />
          <img class="iled" *ngIf="iconSeled>-1" src="/assets/imgs/gif/gj-{{iconSeled}}.gif" />
        </app-hzoom>
      </div>
    </div>

    <div class="btns-wrapper">
      <app-liked-shared [mapkey]="'gdjydjgjzdzlzdzy'"></app-liked-shared>

      <div class="btns-group">
        
        <div class="bbitem" *ngFor="let item of icons; let i = index">
          <div class="icon">
            <div class="img-icon" (click)="iconSeledEvent(i)" >
              <img src="/assets/imgs/city/i{{item.id + (iconSeled === i ? 'ed' : '')}}.png" />
            </div>

            <div class="list-group" *ngIf="topSeled === i">
              <ng-container *ngFor="let idtem of data">
                <div class="lgitem" *ngIf="idtem.industry.includes(item.id) || topSeled === 3" (click)="showFullMapEvent(i, $event, idtem.id)" >{{idtem.name}}</div>
              </ng-container>
            </div>
          </div>
          <div class="intro" (click)="topSeledEvent(i, $event)">
            <div>{{item.txt}}</div>
            <img src="/assets/imgs/city/top{{item.id}}.png"/>
          </div>
        </div>
        <img class="gapr" src="/assets/imgs/city/gapr.png" />
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv id="gjgj-wrapper2">
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent(iconSeled, $event)">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt">地图模式</div>
      </div>

      <div class="map-group">
        <div class="mg-container">
          <app-zoomer>
            <img class="oimg" *ngIf="iconSeled===-1" src="/assets/imgs/main/main-map.png" />
            <img class="iled" *ngIf="iconSeled>-1" src="/assets/imgs/gif/gj-{{iconSeled}}.gif" />
          </app-zoomer>
        </div>
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname city-theme-color">
        {{title}}
        <app-expl [aei]='0' (bindExplEvent)="showexpl = $event"></app-expl>
      </div>
      <div class="input-group">
        <div class="input" [ngClass]="{'active': kw.length > 0}">
          <input id="xinput-2" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
          <div class="img-clear" (click)="changeKw()">
            <img class="clear" src="/assets/imgs/search.png"/>
          </div>

          <div class="rlist-wrapper">
            <ng-container *ngFor="let item of rlist">
              <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="showFullMapEvent(3, $event, item.id)"> </div>
            </ng-container>
            <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
          </div>

        </div>
      </div>
      <div class="btns-wrapper">
        <div class="btns-group">
          <div class="bbitem" *ngFor="let item of icons; let i = index">
            <div class="icon">
              <div class="img-icon" (click)="iconSeledEvent(i)">
                <img src="/assets/imgs/city/i{{item.id + (iconSeled === i ? 'ed' : '')}}.png" />
              </div>

              <div class="list-group" *ngIf="topSeled === i">
                <ng-container *ngFor="let idtem of data">
                  <div class="lgitem" *ngIf="idtem.industry.includes(item.id) || topSeled === 3" (click)="showFullMapEvent(i, $event, idtem.id)" >{{idtem.name}}</div>
                </ng-container>
              </div>
            </div>
            <div class="intro" (click)="topSeledEvent(i, $event)">
              <div>{{item.txt}}</div>
              <img src="/assets/imgs/city/top{{item.id}}.png"/>
            </div>

            <img *ngIf="i===3" class="gapr" src="/assets/imgs/city/gapr.png" />
          </div>
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
