import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { mapOpenCloseAnimation } from 'src/app/animations';
import { MAPDIST } from '../../config';

@Component({
  selector: 'app-expl',
  templateUrl: './expl.component.html',
  styleUrls: ['./expl.component.styl'],
  animations: [ mapOpenCloseAnimation ]
})
export class ExplComponent implements OnInit {

  @Input() aei = 0;
  @Output() bindExplEvent = new EventEmitter<boolean>();

  show = false;
  deviceMode: any = 0;
  themes: string[] = ['city', 'city', 'city', 'life', 'life', 'life', 'life', 'future', 'city', 'jyszhzx', 'jyszhzx'];
  datas: any[] = [
    {
      title: '高等教育对接三大重点产业领域高校地图',
      intro: ['上海打造集成电路、人工智能、生物医药高地，解决核心技术“卡脖子”瓶颈。', '高校智慧助力“中国芯”“智能造”“创新药”的发展，对接国家战略和服务城市发展。']
    },
    {
      title: '职业教育对接上海“五个中心”<br/>建设的重点专业地图',
      intro: ['上海正在着力建设“国际金融中心、经济中心、贸易中心、航运中心和科技创新中心”五个中心。', '职业教育紧密对接产业需要，城市发展，培育更多新时代的知识型、技能型、创新型人才。']
    },
    {
      title: '上海教育助力脱贫攻坚、服务乡村振兴地图',
      intro: ['在中国全面建成小康社会，向第二个百年奋斗目标进军的征程中，教育始终发挥着重要作用。', '上海教育充分发挥自身优势特色，积极助力脱贫攻坚、服务乡村振兴，在援藏、援青、援疆、援滇等工作中播撒大爱、收获满意、增进团结，在促进当地教育优质均衡发展，建设高质量乡村教育体系，筑牢中华民族共同体意识等方面持续贡献上海的智慧与力量。']
    },
    {
      title: '上海市学区化集团化办学地图',
      intro: ['为了每一个孩子的健康成长，办好每一所家门口的学校。', '237个学区集团覆盖75％义务教育学校，呈现出一幅上海义务教育优质均衡发展的生动画卷。']
    },
    {
      title: '上海市特色普通高中地图',
      intro: ['自2017年起，上海已连续四批命名了12所特色普通高中。', '特色普通高中的创建突破了人才培养模式单一以及“千校一面”同质化发展的瓶颈，形成普通高中分类发展、百花齐放的新局面。']
    },
    {
      title: '职业教育贯通培养地图',
      intro: ['上海搭建职业教育“立交桥”，让成才之路越来越宽。', '目前已初步形成中职—高职—应用本科—专业学位研究生纵向完整的培养体系。', '从专业布局到培养模式，上海职业教育坚持探索创新，培养更多高级技术技能型人才，成为上海产业能级提升的底蕴之一。']
    },
    {
      title: '世界技能大赛备战学校地图',
      intro: ['上海是中国近代工业的重要发源地之一，也是近现代中国职业教育的发源地之一。', '2021年第46届世界技能大赛将在中国上海举办。匠心筑梦，技能报国。上海职业教育将实现新的跨越。']
    },
    {
      title: '上海市青少年科学实践工作站地图',
      intro: ['打破围墙，合力育人。', '依托29个工作站、116个实践点，青少年科学实践工作站通过制定科学研究小课题，让每一位参与学生从事1项科学研究课题任务。', '提高青少年科学创新精神与实践能力，深入推进高中生综合素质评价工作的开展。']
    },
    {
      title: MAPDIST.shjysswjbjscgdt.title,
      intro: [
        '筑基谋发展  奋进新征程',
        '为庆祝中国共产党成立 100 周年，深入学习贯彻习近平总书记新时代中国特色社会主义思想，学思践悟“人民城市人民建，人民城市为人民”重要理念，全面展示教育基本建设服务、支撑教育事业发展的成效，在中共上海市教卫工作委员会、上海市教育委员会指导下，上海市教育基建管理中心、上海教育报刊总社在展会上设立“筑基谋发展 奋进新征程”上海教育基本建设成果展专栏的同时制作本地图，展示 “十三五”上海基础教育、高等教育基本建设成果。',
        '“十三五”期间，在上海市委、市政府领导下，市、区相关政府部门及有关单位通力支持、协同推进，保障落实本市教育基本建设规划项目稳步实施，助力上海教育事业高质量发展。上海基础教育基本建设旨在优化布局、保障办学条件。全市16区共计开工实施各级各类学校建设项目518个（其中竣工409个），新增校舍约475万平方米。高等教育基建工作聚焦并立足于服务国家战略、提升上海高校服务能力；对接学科需求、加快文教产教融合发展；秉承和谐理念、实施空间布局战略调整；完善硬件设施、提升高校内涵建设；创建美丽校园、改善高校育人环境等五方面。',
        '本次活动得到相关高校、区教育局、委属学校（单位）等的大力支持和积极配合，特此鸣谢！'
      ]
    },
    {
      title: '上海市青少年科学实践工作站地图',
      intro: ['打破围墙，合力育人。', '依托29个工作站、116个实践点，青少年科学实践工作站通过制定科学研究小课题，让每一位参与学生从事1项科学研究课题任务。', '提高青少年科学创新精神与实践能力，深入推进高中生综合素质评价工作的开展。']
    },
    {
      title: '上海高校人工智能人才培养概况',
      intro: [
        '研究生培养阶段，现有学科目录中与人工智能领域较紧密的学科主要包括：数学、系统科学、统计学、信息与通信工程、控制科学与工程、计算机科学与技术、电子与通信工程、软件工程、智能科学与技术等。',
        '目前，上海高校中开设相关学科、专业的高校共38所，相关学科专业104个，2020年招生数12000余人，2020年在校生数3.6万余人。复旦、交大、同济、华理工、东华、上理工、海事、应用、上师大、上大、工程大、上师大天华等12所高校设置有人工智能专业。',
        '上海现有上海交通大学、同济大学等12所高校成立人工智能研究院，开展相关学科本科和研究生人才培养。'
      ],
      isnew207: true
    },

  ];

  constructor(
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });
  }

  explEvent(show: boolean) {
    this.show = show;
    this.bindExplEvent.emit(show);
  }
}
