import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/shared/config';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class NewJyszhzxIndexComponent implements OnInit {
  deviceMode = null;

  data: any[] = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.data = ROUTES[3].data[0].subs[0].subs;
  }

  toTuoYu(url: string) {
    this.router.navigateByUrl(url);
  }

}
