
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface BroadCast {
  name: string;
  body: Subject<any>;
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private subjects: BroadCast[] = [];

  constructor() { }

  emit<T>(name: string, body: any) {
    let subject = null;
    let isNew   = true;
    for (const key in this.subjects) {
      if (this.subjects.hasOwnProperty(key)) {
        const element = this.subjects[key];
        if (name === element.name) {
          subject = element.body;
          isNew   = false;
        }
      }
    }
    if (isNew) {
      subject = new Subject<T>();
    }
    subject.next(body);
    if (isNew) {
      this.subjects.push({
        name,
        body: subject
      });
    }
  }

  sub(name: string): Observable<any> {
    for (const key in this.subjects) {
      if (this.subjects.hasOwnProperty(key)) {
        const element = this.subjects[key];
        if (name === element.name) {
          return element.body.asObservable();
        }
      }
    }
    return new Observable;
  }

  getSubs() {
    console.log('broadcast service  getSubs');
    return this.subjects;
  }
}
