
<app-rback rurl="/city" *ngIf="!showexpl"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">  
        <div class="logo2name city-theme-color">
          {{ttitle}}
          <app-expl [aei]='2' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>
        <div class="full-map-group" (click)="toView($event)">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
          <div class="txt city-theme-color">地图模式</div>
        </div>  
      </div>
    </div>
    <div class="map-group">
      <app-liked-shared [mapkey]="'jyyjtpgjdt'"></app-liked-shared>
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/gif/3_jyyjtp.gif?id=123124f"/>
        </app-hzoom>
      </div> 
      <div class="plist-group">
        <div class="pgitem" 
          [ngStyle]="{top:swlabs[i].hhtop, height: swlabs[i].hhheight}"
          *ngFor="let item of swlabs;let i=index" 
          (click)="toView($event, i)"></div>
        <div class="pgitem ish" (click)="toView($event)"></div>
      </div>
      <div class="copydtime">
        数据更新时间：2022年底 
      </div>
    </div> 
    
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="xvv-wrapper">
      <div class="full-map-group" (click)="toView($event)">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt city-theme-color">地图模式</div>
      </div>   
      <div class="logo2name city-theme-color">
        {{ttitle}}
        <app-expl [aei]='2' (bindExplEvent)="showexpl = $event"></app-expl>
      </div>
      <div class="mpwrapper">
        
        <div class="map-group">
          <div class="map-zoom">
            <app-hzoom>
              <img src="/assets/imgs/gif/3_jyyjtp.gif?id=123124f"/>
            </app-hzoom>
          </div> 
          <div class="plist-group">
            <div class="pgitem" 
              [ngStyle]="{top:swlabs[i].vvtop, height: swlabs[i].vvheight}"
              *ngFor="let item of swlabs;let i=index" 
              (click)="toView($event, i)"></div>
            <div class="pgitem ish" (click)="toView($event)"></div>
          </div>
          <div class="copydtime">数据更新时间：2022年底 </div>
        </div>
       
      </div> 
    </div> 
  </app-xtheme-vv>
</app-xtheme>
  