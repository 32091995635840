<app-rback [type]='22' [rurl]='rurl' theme='i-new'></app-rback> 

<div class="full-map-wrapper">

    <div class="qqmap-wrapper" id="qq-full-map-wrapper"></div>
 
    <div class="filters-group" [ngClass]="{'vv-group': deviceMode !== 0}" >
      <div class="img-btn imb-left" (click)="swLabPre()">
        <img src="/assets/imgs/city/top2.png"/>
      </div>
      <div class="txt life-theme-color">
        <div class="tt">{{swlabs[swlab].name}}</div>
      </div>
      <div class="img-btn imb-right" (click)="swLabNex()">
        <img src="/assets/imgs/city/top2.png"/>
      </div>
    </div>
    
  </div>