import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RunService {

  reBackUrl = '/';

  uid: string;
  iShowScreen = false;

  isMapDetail = false;

  isRedX = false;

  data: any = {};

  constructor() { }

  set(key: string, data: any): void {
    this.data[key] = data;
  }

  get(key: string): any {
    return this.data[key];
  }

  delete(key: string): void {
    delete this.data[key];
  }

  analysisUrl(url: string) {
    const urlObj = new URL(environment.apihost + url);
    const uid    = urlObj.searchParams.get('uid');
    const is     = urlObj.searchParams.get('is');
    const isRedX = urlObj.searchParams.get('isr');

    if (uid) {
      this.uid = uid;
      localStorage.setItem('uid', uid);
    } else if (!this.uid) {
      this.uid = localStorage.getItem('uid');
    }
    if (is) {
      this.iShowScreen = true;
      localStorage.setItem('iShowScreen1', 'iShowScreen');
    } else if (!this.iShowScreen) {
      const iShowScreen = localStorage.getItem('iShowScreen1');
      if (iShowScreen) {
        this.iShowScreen = true;
      }
    }

    if (isRedX) {
      this.isRedX = true;
      localStorage.setItem('isRedX', 'isRedX');
    } else if (!this.isRedX) {
      const isRedX = localStorage.getItem('isRedX');
      if (isRedX) {
        this.isRedX = true;
      }
    }

  }
}
