<app-rback [reback]="true"></app-rback>

<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="xhh">
      建设中
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="xhh">
      建设中
    </div>
  </app-xtheme-vv>
</app-xtheme>
  