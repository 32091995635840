import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { XResService } from 'src/app/shared/services/xres.service';
import { CacheService, UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-tmp',
  templateUrl: './tmp.component.html',
  styleUrls: ['./tmp.component.styl']
})
export class TmpComponent implements OnInit {
  dists = [{id: 310101, name: '黄浦区'}, {id: 310104, name: '徐汇区'}, {id: 310105, name: '长宁区'}, {id: 310106, name: '静安区'}, {id: 310107, name: '普陀区'}, {id: 310109, name: '虹口区'}, {id: 310110, name: '杨浦区'}, {id: 310112, name: '闵行区'}, {id: 310113, name: '宝山区'}, {id: 310114, name: '嘉定区'}, {id: 310115, name: '浦东新区'}, {id: 310116, name: '金山区'}, {id: 310117, name: '松江区'}, {id: 310118, name: '青浦区'}, {id: 310120, name: '奉贤区'}, {id: 310151, name: '崇明区'}];

  kvs   = {310101: '黄浦区', 310104: '徐汇区', 310105: '长宁区', 310106: '静安区', 310107: '普陀区', 310109: '虹口区', 310110: '杨浦区', 310112: '闵行区', 310113: '宝山区', 310114: '嘉定区', 310115: '浦东新区', 310116: '金山区', 310117: '松江区', 310118: '青浦区', 310120: '奉贤区', 310151: '崇明区'};
  vks   = {黄浦区: 310101, 徐汇区: 310104, 长宁区: 310105, 静安区: 310106, 普陀区: 310107, 虹口区: 310109, 杨浦区: 310110, 闵行区: 310112, 宝山区: 310113, 嘉定区: 310114, 浦东新区: 310115, 金山区: 310116, 松江区: 310117, 青浦区: 310118, 奉贤区: 310120, 崇明区: 310151};

  // 百度坐标系转腾讯
  // let result = gcoord.transform(
  //   [116,31],
  //   gcoord.BD09,
  //   gcoord.GCJ02
  // );

  constructor(
    private http: HttpClient,
    private xresService: XResService,
    private cacheService: CacheService,
    private utilService: UtilsService,
  ) { }

  dbsort(data: any) {
    const max = data.length - 1;
    for (let j = 0; j < max; j++) {
      let done = true;
      for (let i = 0; i < max - j; i++) {
        if (data[i].length < data[i + 1].length) {
          const temp = data[i];
          data[i] = data[i + 1];
          data[i + 1] = temp;
          done = false;
        }
      }
      if (done) { break; }
    }
    return data;
  }

  // excel window 2010: 全局替换 Alt+Enter => 空白：：去除换行符

  ngOnInit() {
    this.aes();
    return;
    this.http.get('assets/tmp/json/tmp.json').toPromise().then((data: any) => {

      // for (let i=0; i<data.length;i++) {
      //   data[i].id = i+1;
      //   let cxlocation = data[i].bdlocation.split(',');
      //   if (cxlocation.length<2) {
      //     data[i].bdlocation = '';
      //     data[i].location = '';
      //     continue;}
      //   let result = gcoord.transform(
      //     cxlocation,
      //     gcoord.BD09,
      //     gcoord.GCJ02
      //   );
      //   data[i].location = result[1]+','+result[0]
      // }
      // console.log(JSON.stringify(data))
      // return;
      let toi = 0;
      for (let i = 0; i < data.length; i++) {
        const citem   = data[i];
        data[i].id = i + 1;

        // data[i].dcode = this.vks[data[i].area]
        // console.log(citem)

        if (data[i].location) { continue; }
        // console.log(citem); continue;

        const addr    = '上海市' + citem.address;

        if (data[i].local) { delete data[i].local; }
        setTimeout(() => {
          this.http.get('http://jtest.jsoma.com.cn/QpYDeNPDA77wGbup?txt=' + addr).toPromise().then((res: any) => {
            if (res && res.status === 0) {
              data[i].location = res.result.location.lat + ',' + res.result.location.lng;

              console.log(JSON.stringify(data));
            } else {
              // console.log(addr)
            }
            if (data[i].id == 3300) {
              console.log(JSON.stringify(data));
            }
          }, err => {
            console.log('err', addr);
          });
        }, toi * 280);
        ++toi;
      }
      // console.log(JSON.stringify(data))

    });

  }

  aes() {
    const newData = {};
    const oldData = {};
    this.http.get('assets/tmp/json/tmp.json').toPromise().then((ndata: any) => {
      // for (let i=0;i<ndata.length;i++) {
      //   if (['上海市上海中学','复旦大学附属中学','上海市实验学校','上海市青少年校外活动营地——东方绿舟'].includes(ndata[i].name)) {
      //     ndata[i].type2 = '委属学校（单位）'
      //   } else {
      //     ndata[i].type2 = ndata[i].district
      //   }
      // }
      // console.log(JSON.stringify(ndata))
      // this.http.get('assets/tmp/json/main.json').toPromise().then((odata: any) => {
      //   for (let i=0;i<ndata.length;i++) {
      //     const item = ndata[i]
      //     newData[item.name.trim()] = item.vcode.trim()
      //   }
      //   for (let i=0;i<odata.length;i++) {
      //     const item = odata[i]
      //     oldData[item.name.trim()] = item.vcode.trim()
      //   }
      //   Object.keys(oldData).forEach(key => {
      //     const oldCode = oldData[key]
      //     const newCode = newData[key]
      //     if (oldCode != newCode) {
      //       console.log(key, oldCode+' => '+ newCode)
      //     }
      //     // console.log(key, oldCode, newCode)
      //   })
        // console.log(this.utilService.eanacarayapata(JSON.stringify(data)))
      // })
        // console.log(this.utilService.daeacarayapata(data.data))
      // console.log(ndata)
      console.log(JSON.stringify({rdata: this.utilService.eanacarayapata(JSON.stringify(ndata))}) );

      // console.log(this.utilService.daeacarayapata(data.data))
    });
    // let text = this.utilService.eanacarayapata(JSON.stringify(this.kvs))
    // console.log(text)
    // console.log(this.utilService.daeacarayapata(text))
  }

  exportExcels() {

    // const schools = []
    // const iids = [7] // [5, 6, 8, 9,10,11,19 ] // 7
    // for (let i=0; i < this.datas.length; i++) {
    //   const item = this.datas[i]
    //   if (!iids.includes(item.id)) continue

    //   for (let j=0;j<item.data.length; j++) {
    //     const name = item.data[j]
    //     let ext_name = name.substr(name.length-2)
    //     if (['大学','学院'].includes(ext_name) || schools.includes(name)) continue

    //     schools.push(name)
    //   }
    //   // console.log(item.id, item.data.length)
    // }

    // const cts = {}
    // for (let i=0; i < schools.length; i++) {
    //   const sname = schools[i]
    //   for (let j=0; j < this.datas.length; j++) {
    //     const cxmap = this.datas[j]
    //     for (let k=0;k<cxmap.data.length;k++) {
    //       const dname = cxmap.data[k]
    //       if (dname.indexOf(sname) > -1) {
    //         if (!cts[sname]) cts[sname] = []
    //         cts[sname].push(cxmap.id + ' - ' + cxmap.name)
    //         break
    //       }
    //     }
    //   }
    // }

    // let ncts = []
    // Object.keys(cts).forEach(schoolname => {
    //   const items = cts[schoolname]
    //   const length= items.length
    //   ncts.push({
    //     schoolname,
    //     items,
    //     length
    //   })
    //   // for (let i=0;i<length;i++) {
    //   //   const mapname = items[i]
    //   //   ncts.push({
    //   //     schoolname,
    //   //     mapname,
    //   //     length
    //   //   })
    //   // }
    // })

    // const newncts = this.dbsort(ncts)

    // const ddncts = []
    // for (let i=0;i<newncts.length;i++) {
    //   const schoolitem = newncts[i]
    //   for (let j=0;j<schoolitem.items.length;j++) {
    //     const mapname = schoolitem.items[j]
    //     ddncts.push({
    //       schoolname:schoolitem.schoolname,
    //       mapname,
    //       length: schoolitem.length
    //     })
    //   }
    // }
    // console.log(JSON.stringify(ddncts))
    // return
    // // 高教 / 职教
    // const cts = {}
    // for (let i=0; i < this.schools.length; i++) {
    //   const item = this.schools[i]
    //   if (item.type !== 4) continue
    //   const sname = item.name
    //   for (let j=0; j < this.datas.length; j++) {
    //     const cxmap = this.datas[j]
    //     for (let k=0;k<cxmap.data.length;k++) {
    //       const dname = cxmap.data[k]
    //       if (dname.indexOf(sname) > -1) {
    //         if (!cts[sname]) cts[sname] = []
    //         cts[sname].push(cxmap.id + ' - ' + cxmap.name)
    //         break
    //       }
    //     }
    //   }
    // }
    // let ncts = []
    // Object.keys(cts).forEach(schoolname => {
    //   const items = cts[schoolname]
    //   const length= items.length
    //   ncts.push({
    //     schoolname,
    //     items,
    //     length
    //   })
    //   // for (let i=0;i<length;i++) {
    //   //   const mapname = items[i]
    //   //   ncts.push({
    //   //     schoolname,
    //   //     mapname,
    //   //     length
    //   //   })
    //   // }
    // })
    // const newncts = this.dbsort(ncts)
    // const ddncts = []
    // for (let i=0;i<newncts.length;i++) {
    //   const schoolitem = newncts[i]
    //   for (let j=0;j<schoolitem.items.length;j++) {
    //     const mapname = schoolitem.items[j]
    //     ddncts.push({
    //       schoolname:schoolitem.schoolname,
    //       mapname,
    //       length: schoolitem.length
    //     })
    //   }
    // }
    // console.log(JSON.stringify(ddncts))
    // return
    // const afs = {}
    // this.http.get('assets/tmp/json/tmp.json').toPromise().then((data: any) => {
    //   for (let i=0;i<data.length;i++) {
    //     const item = data[i]
    //     const name = item.name.trim()
    //     if (!afs[name]) afs[name] = {dcode: this.vks[item.district], district: item.district, name: item.name, majors:[], address: []}

    //     if (!afs[name].address.includes(item.address)) afs[name].address.push(item.address)

    //     afs[name].majors.push(item.major)
    //   }

    //   let i = 58
    //   const resfs = []
    //   Object.keys(afs).forEach(key => {
    //     i += 1
    //     const citem   = afs[key]
    //     const addr    = citem.address[0]
    //     citem.id      = i
    //     citem.address = citem.address.join('、')
    //     setTimeout(() => {
    //       this.http.get('http://api.ired.tcg.xdebug.linktour.vip/worker/run?txt=' + addr).toPromise().then((res: any) => {
    //         if (res.status === 0) {
    //           citem.location = res.result.location.lat + ',' + res.result.location.lng
    //           resfs.push(citem)
    //         } else {
    //           console.log(addr)
    //         }
    //         if (resfs.length === 13) {
    //           console.log(JSON.stringify(resfs))
    //         }
    //       }, err => {
    //         console.log('err', addr)
    //       })
    //     }, i*280)
    //   })
    // })

    // return
    // this.http.get('assets/tmp/json/tmp.json').toPromise().then((data: any) => {
    //     // const xxs = {}
    //     // for (let i = 0; i < data.length; i++) {
    //     //   const row = data[i]
    //     //   xxs[row.id] = row
    //     // }
    //     this.http.get('assets/tmp/json/life/12_zgzgtzbgt.json').toPromise().then((rows: any) => {
    //       for (let i = 0; i < rows.length; i++) {
    //         const row = rows[i]
    //         if (row.prelocation === row.nexlocation) console.log(row.id, row.prelocation, row.nexlocation)

    //       }
    //       // console.log(JSON.stringify(rows))
    //     })
    //     // const address = row.address.split('、')[0]
    //     // setTimeout(() => {
    //     //   this.http.get('http://api.ired.tcg.xdebug.linktour.vip/worker/run?txt=' + address).toPromise().then((res: any) => {
    //     //     if (res.status === 0) {
    //     //       rows[i].location = res.result.location.lat + ',' + res.result.location.lng
    //     //     } else {
    //     //       console.log(address)
    //     //     }
    //     //     if (i===rows.length-1) {
    //     //       console.log(JSON.stringify(rows))
    //     //     }
    //     //   }, err => {
    //     //     console.log('err', address)
    //     //   })
    //     // }, i*280)
    // })
  }
}
