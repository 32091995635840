import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SwiperComponent } from 'src/app/shared/components';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EventManager, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { RunService, UtilsService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class NewDetailComponent implements OnInit {
  vsrc = '';
  vplay = false;

  iid_f = {1: '人工智能', 2: '集成电路', 3: '生物医药'};

  deviceMode = null;

  row: any = {};
  url = '';
  model = '';
  selid = 0;
  si = 1;
  filei = [1, 1];

  // 0 视频 720p  1 照片  16:9
  files: any = [
    [], // 视频
    []  // 图片
  ];

  isd: boolean = this.utilsService.isSmartDevice();

  @ViewChild('swipercomponent1', { static: false }) public swipercomponent1f: SwiperComponent;
  @ViewChild('swipercomponent2', { static: false }) public swipercomponent2f: SwiperComponent;

  constructor(
    private title: Title,
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private eventManager: EventManager,
    private runService: RunService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    // alert(document.documentElement.clientWidth + " : " + document.documentElement.clientHeight)

    this.model  = this.route.snapshot.paramMap.get('model');
    this.selid = +this.route.snapshot.paramMap.get('selid');

    if (this.selid < 1) { this.location.back(); }

    const pObjt = MAPDIST[this.model];
    if (!pObjt) {
      this.location.back();
      return;
    }
    this.url = pObjt.url;
    this.title.setTitle('详情 - ' + pObjt.ptitle);

    this.deviceMode = window.orientation;

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      // console.log('1 new detail get:', JSON.stringify(resp[0]));
      // console.log('2 new detail get:', resp[0]);

      let isrow = true;
      for (let i = 0; i < resp.length; i++) {
        if (resp[i].id == this.selid) {
          this.row = resp[i];
          isrow = false;
          break;
        }
      }
      // console.log('4 detail get:', isrow, this.row);
      if (isrow) { this.location.back(); }

      if (this.row.files && this.row.files.length > 0) {
        const videos = [];
        const images = [];
        for (let i = 0; i < this.row.files.length; i++) {
          const item = this.row.files[i];
          if (item.type === 'video') { videos.push(item); }
          if (item.type === 'image') { images.push(item); }
        }
        this.files = [videos, images];
        if (videos.length > 0) { this.si = 0; }
      }
    });
  }

  // 后退问题
  // console.log(this.runService.isMapDetail)
  bindOutputEvent() {
    if (this.runService.isMapDetail) {
      this.runService.isMapDetail = false;
      this.location.back();
    } else {
      this.router.navigate([ this.url ], { replaceUrl: true });
    }
  }
  bindchange(i: number, si: number) {
    this.filei[si] = i + 1;
    this.cd.detectChanges();
  }

  swtichLunbo(i: number) {
    this.si = i;
  }

  refreshSwp() {
    // this.swipercomponent1f.outRefresh()
    // this.swipercomponent2f.outRefresh()
  }

  goBack() {
    this.location.back();
  }

  toPlay(url: string) {
    this.vplay = true;
    this.vsrc  = url;
  }
  pause() {
    this.vplay = false;
  }

  gotoVv(vcode: string) {
    window.location.href = environment.vvhost + '/' + vcode + '/?redirect_uri=' + encodeURIComponent(environment.vmap + '/detail/' + this.model + '/' + this.selid + '?r=miniprogram');
  }
}
