import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-rengongzhineng',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceRenGongZhiNengListComponent implements OnInit {
  ttitle = '';

  rows: any = [];

  constructor(
    private http: HttpClient,
    private router: Router ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.science_gjzx.title;
    this.http.get(MAPDIST.science_gjzx.api).toPromise().then((rows: any) => {
      // console.log('国家中小学人工智能教育基地（上海地区）地图:', JSON.stringify(rows))
      this.rows = rows;
    } );
  }

  toView(i: number = -1) {
    // return;
    this.router.navigateByUrl('/pmap/science_gjzx/' + i);
  }

}
