<app-rback [type]="1" [rsback]='true'></app-rback>
<app-xtheme [theme]="'red0'">
    <app-xtheme-hh class="xthh-wrapper">
        <div class="version1-wrapper" *ngIf="false">
            <div class="logo-wrapper">
                <img src="/assets/imgs/new/i-h-logo.png?i=0928"/>
            </div>
            <div class="btns-wrapper">
                <img src="/assets/imgs/new/theme/main-v-red-btns1.png"/>
                <div class="btns-group">
                    <div class="btn" *ngFor="let item of subs.vv"
                        [ngStyle]="{ 'left': item.left+'vw' }" (click)="gotoThemeMap(item.id)"></div>
                </div>
            </div>
        </div>
        <div class="version2-wrapper">
            <div class="bg-gif">
                <div class="logo-group"><img src="/assets/imgs/new/red2/i-red-theme-logo.png?i=0928" /></div>
                <div class="title-group"><img src="/assets/imgs/new/red2/i-red-theme.png" /></div>
                <div class="btns-group">
                    <div class="btn-img" (click)="gotoThemeMap('in')"><img src="/assets/imgs/new/red2/i-red-theme-in.png" /></div>
                    <div class="btn-img" (click)="gotoThemeMap('center')"><img src="/assets/imgs/new/red2/i-red-theme-center.png" /></div>
                    <div class="btn-img" (click)="gotoThemeMap('out')"><img src="/assets/imgs/new/red2/i-red-theme-out.png" /></div>
                </div>
                <img class='img-gif' src="http://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com/app/edu-expo/red.gif?id=12" />
                <img class='img-bg' src="/assets/imgs/new/red-h-bg.png" />
            </div>
        </div>
    </app-xtheme-hh>
    <app-xtheme-vv class="xtvv-wrapper">
        <div class="logo-wrapper">
            <img src="/assets/imgs/new/i-v-logo.png?i=0928"/>
        </div>
        <div class="btns-wrapper">
            <img [src]="'/assets/imgs/new/theme/main-h-red-btns-'+(iShowScreen?1:0)+'.png'"/>
            <div class="btns-group">
                <ng-container *ngFor="let item of subs.hh">
                    <div class="btn" *ngIf="item.id < 3 && !iShowScreen"
                    [ngStyle]="{ 'top': item.top+'vh' }" (click)="gotoThemeMap(item.id)"></div>
                    <div class="btn" *ngIf="item.id > 2"
                    [ngStyle]="{ 'top': item.top+'vh' }" (click)="gotoThemeMap(item.id)"></div>
                </ng-container>
            </div>
        </div>
    </app-xtheme-vv>
</app-xtheme>
