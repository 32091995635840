import { Component, Input, OnInit } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'app-xtheme',
  templateUrl: './xtheme.component.html',
  styleUrls: ['./xtheme.component.styl']
})
export class XthemeComponent implements OnInit {
  deviceMode = null;
  @Input() theme = '';

  constructor(private eventManager: EventManager) { }

  ngOnInit() {
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });
  }

}
