import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { mapOpenCloseAnimation } from 'src/app/animations';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-shjysswjbjscgdt',
  templateUrl: './shjysswjbjscgdt.component.html',
  styleUrls: ['./shjysswjbjscgdt.component.styl'],
  animations: [ mapOpenCloseAnimation ]
})
export class CityShjysswjbjscgdtComponent implements OnInit {
  showexpl = false;
  title = '';

  kw = '';
  rlist: any = [];

  tagi = 0;
  tags = [];

  districti = 0;
  districts = ['委属学校（单位）', '浦东新区', '黄浦区', '徐汇区', '长宁区', '普陀区', '静安区', '虹口区', '杨浦区', '闵行区', '宝山区', '嘉定区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'];

  data: any[] = [];
  rows: any[] = [];

  topi = 0;
  tops = [
    {
      name: '',
      memo: '',
      top10: {basicschool: [], highschool: []},
    },
    {
      name: '人气TOP10',
      memo: '提示：5月22日-7月1日，系统将自动记录每个基教、高教建设成果项目的页面浏览量。根据人气值，分别生成基教、高教前Top10排行榜。',
      top10: {basicschool: [], highschool: []},
      data: [
        {
          name: '基教TOP10',
          rows: [
            { name: '浦东新区', qty: '130.55K' },
            { name: '黄浦区', qty: '128.55K' },
            { name: '委属学校（单位）', qty: '126.55K' },
            { name: '徐汇区', qty: '125.55K' },
            { name: '静安区', qty: '124.55K' },
            { name: '闵行区', qty: '123.55K' },
            { name: '杨浦区', qty: '120.55K' },
            { name: '宝山区', qty: '116.55K' },
            { name: '松江区', qty: '115.55K' },
            { name: '普陀区', qty: '18.55K' },

          ]
        },
        {
          name: '高教TOP10',
          rows: [
            { name: '上海交通大学', qty: '130.55K' },
            { name: '上海大学', qty: '128.55K' },
            { name: '复旦大学）', qty: '126.55K' },
            { name: '华东理工大学', qty: '125.55K' },
            { name: '华东师范大学', qty: '124.55K' },
            { name: '上海理工大学', qty: '123.55K' },
            { name: '上海商学院', qty: '120.55K' },
            { name: '上海戏剧学院', qty: '116.55K' },
            { name: '东华大学', qty: '115.55K' },
            { name: '上海第二工业大学', qty: '18.55K' },

          ]
        }
      ]
    },
    {
      name: '点赞TOP10',
      memo: '提示：5月22日-7月1日，用户可对地图中展示的基教、高教建设成果项目进行点赞。系统根据点赞数，分别生成基教、高教前Top10排行榜。',
      top10: {basicschool: [], highschool: []},
      data: [
        {
          name: '基教TOP10',
          rows: [
            { name: '浦东新区', qty: '30.55K' },
            { name: '黄浦区', qty: '28.55K' },
            { name: '委属学校（单位）', qty: '26.55K' },
            { name: '徐汇区', qty: '25.55K' },
            { name: '静安区', qty: '24.55K' },
            { name: '闵行区', qty: '23.55K' },
            { name: '杨浦区', qty: '20.55K' },
            { name: '宝山区', qty: '16.55K' },
            { name: '松江区', qty: '15.55K' },
            { name: '普陀区', qty: '8.55K' },

          ]
        },
        {
          name: '高教TOP10',
          rows: [
            { name: '上海交通大学', qty: '30.55K' },
            { name: '上海大学', qty: '28.55K' },
            { name: '复旦大学）', qty: '26.55K' },
            { name: '华东理工大学', qty: '25.55K' },
            { name: '华东师范大学', qty: '24.55K' },
            { name: '上海理工大学', qty: '23.55K' },
            { name: '上海商学院', qty: '20.55K' },
            { name: '上海戏剧学院', qty: '16.55K' },
            { name: '东华大学', qty: '15.55K' },
            { name: '上海第二工业大学', qty: '8.55K' },

          ]
        }
      ]
    },

  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.title = MAPDIST.shjysswjbjscgdt.title;
    this.tags  = MAPDIST.shjysswjbjscgdt.labs;
    this.http.get(MAPDIST.shjysswjbjscgdt.api).toPromise().then((resp: any) => {
      this.data = resp;

      this.calc();
    });

    // TODO...
    // this.http.get('/v1/eduexpo/map/29/pv/top10').toPromise().then((rows: any) => this.tops[1].top10 = rows )
    // this.http.get('/v1/eduexpo/map/29/praise/top10').toPromise().then((rows: any) => this.tops[2].top10 = rows )
  }

  gotoView(id: number = 0) {
    this.router.navigateByUrl('/city/shjysswjbjscgdt/' + id);
  }
  showFullMapEvent(id: number = 0) {
    this.router.navigateByUrl('/subpmap/shjysswjbjscgdt/' + this.tagi + '/' + id);
  }
  selied(i: number) {
    this.tagi = i;
    this.calc();
  }
  preProf() {
    this.districti -= 1;
    if (this.districti === -1) { this.districti = this.districts.length - 1; }
    this.calc();
  }
  nextProf() {
    this.districti += 1;
    if (this.districti === this.districts.length) { this.districti = 0; }
    this.calc();
  }
  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.rlist = [];
      return;
    }

    const p = new RegExp(this.kw, 'i');
    const rlist = [];
    const limit = 5;
    let ij = 0;
    for (let i = 0; i < this.data.length; i++) {
      if (ij === limit) { break; }
      const obj = this.data[i];
      if (p.test(obj.name)) {
        const id   = obj.id;
        const name = obj.name.replace(this.kw, '<span>' + this.kw + '</span>');

        rlist.push({id, name: name + ' - ' + obj.pjname});
        ij++;
      }
    }

    this.rlist = rlist;
  }

  calc() {

    const rows = [];
    const tmps = {};
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.tagi === 0 && item.type2 === this.districts[this.districti] && item.type.includes(this.tags[this.tagi].val)) {
        // rows.push(item)

        if (!tmps[item.name]) { tmps[item.name] = {name: item.name, subs: []}; }
        tmps[item.name].subs.push(item);
      }
      if (this.tagi === 1 && item.type.includes(this.tags[this.tagi].val)) {
        if (!tmps[item.name]) { tmps[item.name] = {name: item.name, subs: []}; }
        tmps[item.name].subs.push(item);
      }
    }
    Object.keys(tmps).forEach(kname => {
      rows.push(tmps[kname]);
    });

    this.rows = rows;
  }
}
