<app-rback [type]="22" [rurl]="'/life/zgzgtzbgt'"></app-rback>
<div class="content-wrapper">
  <div class="map-wrapper">
    <div class="qqfmap" id="qq-full-map-wrapper"></div> 
  </div>

  <div class="filters-group" [ngClass]="{'vv-group': deviceMode !== 0}" *ngIf="true">
    <div class="img-btn imb-left" (click)="swLabPre()">
      <img src="/assets/imgs/city/top2.png"/>
    </div>
    <div class="txt life-theme-color">
      <div class="tt">{{slabs[slabi].name}}</div>
    </div>
    <div class="img-btn imb-right" (click)="swLabNex()">
      <img src="/assets/imgs/city/top2.png"/>
    </div>
  </div>
</div>
