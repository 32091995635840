/*
import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { MAPDIST } from 'src/app/shared/config';

declare var wx: any;
@Component({
  selector: 'app-shifan-list', //
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceShiFanListComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  resrows: any[] = [];
  typei = 0;
  types = [{id: 1, name: '中职'}, {id: 2, name: '高职'}];
  constructor(
    private router: Router,
    private http: HttpClient,
    private ngZone: NgZone,
    private utilsService: UtilsService,
    private cd: ChangeDetectorRef ) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;
  ngOnInit() {
    this.ttitle = MAPDIST.science_shsk.title;
    this.http.get(MAPDIST.science_shsk.api).toPromise().then((rows: any) => {
      console.log('上海市科技教育特色示范学校：', JSON.stringify(rows[0]));

      // this.rows = rows;
      this.rows = [];
      this.clmpref.refreshMapPanel();
    });
  }
  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
    this.cd.detectChanges();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.company) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    return
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/ty/' + i));
  }

  //  ---------
  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }
    this.getRows();
  }
  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }
    this.getRows();
  }
  getRows() {
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode && row.type == this.types[this.typei].name) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
  }
}
*/


import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ClmapComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-shifan-list', //
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceShiFanListComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  resrows: any[] = [];
  typei = 0;
  types = [
    // 子项新增type,用来和这个数据比对
    {id: 1, name: '小学'},
    {id: 2, name: '初中'},
    {id: 3, name: '高中'},
    // {id: 4, name: '九年一贯制'},
    // {id: 5, name: '十五年一贯制'},
    {id: 6, name: '一贯制'},
    {id: 7, name: '完中'}
    // {id: 8, name: '完全中学'}
  ];
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private cd: ChangeDetectorRef) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;

  ngOnInit() {
    this.ttitle = MAPDIST.science_shsk.title;
    this.http.get(MAPDIST.science_shsk.api).toPromise().then((rows: any) => {
      // console.log('上海市职业教育开放实训中心地图：', JSON.stringify(rows[0]));
      // console.log('上海市职业教育开放实训中心地图：', JSON.stringify(rows));
      // console.log('上海市职业教育开放实训中心地图：', rows);
      this.rows = rows;
      this.clmpref.refreshMapPanel();
    });
  }

  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    this.typei = 0;
    this.getRows();
    this.cd.detectChanges();
  }

  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }
    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      let pjonoff = false;
      for (let ik = 0; ik < row.majors.length; ik++) {
        const pjname = row.majors[ik];
        if (p.test(pjname)) {
          pjonoff = true;
          continue;
        }
      }
      if (pjonoff || p.test(row.address) || p.test(row.name) || p.test(row.district)) {
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = '检索';
  }
  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }
    this.getRows();
  }
  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }
    this.getRows();
  }
  getRows() {
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode && row.type == this.types[this.typei].name) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    // return
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/science_shsk/' + i));
  }
}
