import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { RunService, UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-qsmzqjydt',
  templateUrl: './qsmzqjydt.component.html',
  styleUrls: ['./qsmzqjydt.component.styl']
})
export class QsmzqjydtComponent implements OnInit {


  listRoutes = [
      [
        {
          label: '幼有善育',
          data: [
            {
              name: MAPDIST.ty.title,
              url: MAPDIST.ty.url
            },
            {
              name: MAPDIST.jmkyey.title,
              url: MAPDIST.jmkyey.url
            },
          ],
        },
        {
          label: '优质均衡',
          data: [
            {
              name: MAPDIST.jmkhxx.title,
              url: MAPDIST.jmkhxx.url
            },
            {
              name: MAPDIST.jqczjsgcxx.title,
              url: MAPDIST.jqczjsgcxx.url
            },
            {
              name: MAPDIST.xqhjth.title,
              url: MAPDIST.xqhjth.url
            },
            {
              name: MAPDIST.tsgz.title,
              url: MAPDIST.tsgz.url
            },
            {
              name: MAPDIST.wdxsyslm.title,
              url: MAPDIST.wdxsyslm.url
            },
            {
              name: MAPDIST.tjzdzx.title,
              url: MAPDIST.tjzdzx.url
            },
            {
              name: MAPDIST.shstsjyxx.title,
              url: MAPDIST.shstsjyxx.url
            },
            {
              name: MAPDIST.jcjyzyfb.title,
              url: MAPDIST.jcjyzyfb.url
            },
          ],
        },
      ],
      [
        {
          label: '贯通融合',
          data: [
            {
              name: MAPDIST.zgzgtzbgt.title,
              url: MAPDIST.zgzgtzbgt.url
            },
            {
              name: MAPDIST.sjjndsbzxx.title,
              url: MAPDIST.sjjndsbzxx.url
            },
            {
              name: MAPDIST.zyjykfsxzx.title,
              url: MAPDIST.zyjykfsxzx.url
            },
          ],
        },
        {
          label: '特色一流',
          data: [
            {
              name: MAPDIST.gjjsylzy.title,
              url: MAPDIST.gjjsylzy.url
            },
            {
              name: MAPDIST.gfgyxk.title,
              url: MAPDIST.gfgyxk.url
            },
          ],
        },
        {
          label: '泛在可选',
          data: [
            {
              name: MAPDIST.lnjyxx.title,
              url: MAPDIST.lnjyxx.url
            },
            {
              name: MAPDIST.smzsxxjdxx.title,
              url: MAPDIST.smzsxxjdxx.url
            },
          ],
        },
      ]
      ];


  constructor(
    private router: Router,
    private util: UtilsService,
    private runService: RunService,
  ) { }

  ngOnInit() {
  }

  goto(url: string) {
    this.runService.reBackUrl = '/qsmzqjydt';
    this.router.navigateByUrl(url);
  }
}
