import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-videoplay',
  templateUrl: './videoplay.component.html',
  styleUrls: ['./videoplay.component.styl']
})
export class VideoplayComponent implements OnInit {
  _show = false;

  @Input() src    = '';
  @Input() poster = '';

  // @Input() show   = false

  @Output() onclose = new EventEmitter<any>();
  @Output() onplay  = new EventEmitter<any>();
  @Output() onpause = new EventEmitter<any>();

  @Input()
  set show(show: boolean) {
    this._show = show;
    if (show) { this.play(); }

    if (!show) { this.close(); }
  }
  get show(): boolean { return this._show; }

  constructor() { }

  ngOnInit() {
    // document.addEventListener("WeixinJSBridgeReady", () => (<HTMLVideoElement>document.getElementById('fullscreen-video')).play(), false)
  }

  play() {
    const video = document.getElementById('fullscreen-video') as HTMLVideoElement;
    video.src = this.src;
    video.onplay  = () => this.onplay.emit();
    video.onpause = () => setTimeout(() => {
      this.onpause.emit();
    }, 200);

    video.play();
  }

  close() {
    const video = document.getElementById('fullscreen-video') as HTMLVideoElement;
    video.pause();

    this.onclose.emit(true);
  }
}
