import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-19_shsxxhbgx',
  templateUrl: './19_shsxxhbgx.component.html',
  styleUrls: ['./19_shsxxhbgx.component.styl']
})
export class FutureShsxxhbgxComponent implements OnInit {
  ttitle = '';

  data: any = [];
  rows: any = [];

  typei = 1;
  types: string[] = ['', ''];

  constructor(
    private http: HttpClient,
    private router: Router  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.shsxxhbgx.title;
    this.http.get(MAPDIST.shsxxhbgx.api).toPromise().then((rows: any) => {
      this.data = rows;
      const types = [];
      for (const row of rows) {
        if (types.includes(row.type)) { continue; }

        types.push(row.type);
      }
      this.types = types;
      this.getRows();
    });
  }

  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }

    this.getRows();
  }

  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }

    this.getRows();
  }
  getRows() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      if (this.types[this.typei] === row.type) { rows.push(row); }
    }
    this.rows = rows;
  }
  toView(i: number = -1) {
    this.router.navigateByUrl('/pmap/shsxxhbgx/' + i);
  }
}
