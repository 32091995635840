<div class="error-wrapper" (click)="goBack()" *ngIf="code === 0">
  <div>
    <div class="number">404</div>
    <div class="message">Not Found Here!</div>
    <div class="btnsub">返回上一页</div>
  </div>
</div>

<div class="unauthorized-wrapper" *ngIf="code === 4040">
  <div class="unagroup">
    <div class="icon"><img src="/assets/imgs/icon/info.png"/></div>
    <div class="unatxt">请在微信客户端打开链接</div>
  </div>
</div>