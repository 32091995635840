import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';
declare var wx: any;
@Component({
  selector: 'app-tuoyu-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})

export class LifeTjzdzxListComponent implements OnInit {
  ttitle = '';

  rows: any = [];

  dcode = 0;
  dcodename = '';

  kw = '';
  title = '';
  resrows: any[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef ) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;

  ngOnInit() {
    this.ttitle = MAPDIST.tjzdzx.title;
    this.http.get(MAPDIST.tjzdzx.api + '?v=08211322').toPromise().then((rows: any) => {
        this.rows = rows;
        this.clmpref.refreshMapPanel();
    });
  }

  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (parseInt(row.dcode) === this.dcode) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }

    this.cd.detectChanges();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.name) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    // if (this.utilsService.iswechat()) {
    //   wx.miniProgram.getEnv((res) => {
    //     if (res.miniprogram) {
    //       wx.miniProgram.navigateTo({url: '/pages/school/school'})
    //     } else {//在微信中
    //       this.ngZone.run(() => this.router.navigateByUrl('/pmap/tjzdzx/' + i))
    //     }
    //   });
    // }else{
      this.ngZone.run(() => this.router.navigateByUrl('/pmap/tjzdzx/' + i));
    // }

    // if (wx) {
    //   console.log('sd');

    //   wx.miniProgram.getEnv((res)=>{
    //     console.log(res);
    //     console.log('=============');

    //     if (res.miniprogram) {
    //         alert("在小程序里");
    //     } else {
    //         alert("不在小程序里");
    //     }
    //  })

    // }else{

    // }
  }

}
