import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/services';
import { Location } from '@angular/common';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-211_newshszxxxrxcxdt',
  templateUrl: './211_newshszxxxrxcxdt.component.html',
  styleUrls: ['./211_newshszxxxrxcxdt.component.styl']
})
export class NewShszxxxrxcxdtComponent implements OnInit {
  ttitle = '';
  constructor(
    private util: UtilsService,
    private location: Location
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshszxxxrxcxdt.title;
    if (this.util.isWxMiniprogram()){
      // window.parent.postMessage('navigate:/pages/index/search/map/map?subject=0', '*');//后面的*号就是处理跨域问题的
      const xurl = '/pages/home/map/map?type=5';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      setTimeout(() => {
        this.location.back();
      }, 800);
    }
  }

}
