<app-rback rurl="/city/gdjydjgjzdzlzdzy/map" top="cas"></app-rback>
<div class="hh-wrapper"  [ngStyle]="{'z-index': deviceMode === 0 ? 22: -1}">
  <div class="body"> 
    <div class="lunbo-group">
      <div class="lgbg"></div>
      <div class="swiper">
        <app-swiper  
          *ngIf="si === 0"
          #swipercomponent1
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          (bindchange)="bindchange($event, 0)">
            <app-swiper-item *ngFor="let item of files[0]; let i=index">
              <div class="video-group">
                <video 
                  (click)="toPlay('vv-video', i)"
                  src="{{item.file}}" 
                  [id]="'vv-video' + i"
                  poster="{{item.cover}}?x-oss-process=image/resize,m_fill,w_300,h_168,limit_0"> </video>
                <div class="vplay" (click)="toPlay('vv-video', i)" *ngIf="!item.play">
                  <img src="/assets/imgs/icon/vplay.png"/>
                </div>  
              </div>
            </app-swiper-item>
        </app-swiper>
        <app-swiper  
          *ngIf="si === 1"
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          (bindchange)="bindchange($event, 1)">
            <app-swiper-item *ngFor="let item of files[1]">

                <img [src]="item.file" *ngIf="item.type === 'pic'"/> 

            </app-swiper-item>
        </app-swiper>
      </div>

      <div class="docs-wrapper">
        <div class="docs-group">
          <div class="doc-item" [ngClass]="{'active': si === 0}" (click)="swtichLunbo(0)">视频 {{filei[0]}}/{{files[0].length}}</div>
          <div class="doc-item" [ngClass]="{'active': si === 1}" (click)="swtichLunbo(1)">图片 {{filei[1]}}/{{files[1].length}}</div>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="title-tags-group">
        <div class="title">{{row.name}}</div>
        
        <div class="tags-group">
          <div class="tag tt{{iid}}" *ngFor="let iid of row.industry">{{iid_f[iid]}}</div>
        </div>
      </div>
        
      <!-- <div class="desc-group">
        上海唯一一所培养中级上海唯一一所培养中级上海唯一一所培养中级。
      </div> -->
   
      <div class="map-phone-wrapper">
        <div class="map-wrapper">
          <div class="map-group" id="address-map-group"></div>
          <div class="imap-wrapper" (click)="goBack()">
            <div class="imapw">
              <img src="/assets/imgs/icon/gj-imap.png" />
              <div class="address">
                {{row.district}} {{row.address ? ' - ' + row.address:''}}
              </div>
            </div>
          </div> 
        </div>
        <div class="phone-wrapper">
          <div class="pwgroup">
            <img src="/assets/imgs/icon/gj-phone.png" />
            <div class="phone">021 - 98477640</div>
          </div>
        </div>
      </div>
      <div class="gapr1"></div>
      <div class="btn-group">
        <div class="bgitem bg1">展厅</div>
        <div class="bgitem bg2">展厅</div>
        <div class="bgitem bg3">展厅</div>
      </div>
      <div class="gapr2"></div>
      <div class="intro-group">
        <div class="subject-group" *ngIf="row.introduction">
          <div class="label">人工智能</div>
          <div class="txt"> {{row.introduction}} </div>
        </div>
        <div class="subject-group" *ngIf="row.subject2">
          <div class="label">集成电路</div>
          <div class="txt"> {{row.subject2}} </div>
        </div>
        <div class="subject-group" *ngIf="row.subject3">
          <div class="label">生物医药</div>
          <div class="txt"> {{row.subject3}} </div>
        </div>
      </div>

    </div>

  </div>
</div>
  
<div class="vv-wrapper" [ngStyle]="{'z-index': deviceMode !== 0 ? 22: -1}">
  <div class="left-wrapper">

    <div class="lunbo-group">
      <div class="lgbg"></div>
      <div class="swiper">
        <app-swiper  
          *ngIf="si === 0"
          #swipercomponent2
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          (bindchange)="bindchange($event, 0)">
            <app-swiper-item *ngFor="let item of files[0];let i=index">

              <div class="video-group">
                <video 
                  (click)="toPlay('hh-video', i)"
                  src="{{item.file}}" 
                  [id]="'hh-video' + i"
                  poster="{{item.cover}}?x-oss-process=image/resize,m_fill,w_300,h_168,limit_0"> </video>
                <div class="vplay" (click)="toPlay('hh-video', i)" *ngIf="!item.play">
                  <img src="/assets/imgs/icon/vplay.png"/>
                </div>  
              </div>

            </app-swiper-item>
        </app-swiper> 

        <app-swiper  
          *ngIf="si === 1"
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          (bindchange)="bindchange($event, 1)">
            <app-swiper-item *ngFor="let item of files[1]">

              <img [src]="item.file" *ngIf="item.type === 'pic'"/>
                
            </app-swiper-item>
        </app-swiper> 
      </div>

      <div class="docs-wrapper">
        <div class="docs-group">
          <div class="doc-item" [ngClass]="{'active': si === 0}" (click)="swtichLunbo(0)">视频 {{filei[0]}}/{{files[0].length}}</div>
          <div class="doc-item" [ngClass]="{'active': si === 1}" (click)="swtichLunbo(1)">图片 {{filei[1]}}/{{files[1].length}}</div>
        </div>  
      </div>
 
    </div>
    <div class="container">
      <div class="title-tags-group">
        <div class="title">{{row.name}}</div>
        
        <div class="tags-group">
          <div class="tag tt{{iid}}" *ngFor="let iid of row.industry">{{iid_f[iid]}}</div>
        </div>
      </div>
        
      <!-- <div class="desc-group">
        上海唯一一所培养中级上海唯一一所培养中级上海唯一一所培养中级。
      </div> -->
   
      <div class="map-phone-wrapper">
        <div class="map-wrapper">
          <div class="map-group" id="address-map-group2"></div>
          <div class="popup-wrapper">
            <div class="popup-body">
              <div class="pb-item pwaddress" (click)="goBack()">
                <img src="/assets/imgs/icon/gj-imap.png" />
                <div class="address">
                  {{row.district}} {{row.address ? ' - ' + row.address:''}}
                </div>
              </div>
              <div class="pb-item pwphone">
                <img src="/assets/imgs/icon/gj-phone.png" />
                <div class="phone">021 - 98477640</div>
              </div>
            </div>
          </div> 
        </div> 
      </div>
    </div>
  </div>
  <div class="right-wrapper">
  
    <div class="btn-group">
      <div class="bgitem bg1">展厅</div>
      <div class="bgitem bg2">展厅</div>
      <div class="bgitem bg3">展厅</div>
    </div>
    <div class="gapr2"></div>
    <div class="intro-group">
      <div class="subject-group" *ngIf="row.introduction">
        <div class="label">人工智能</div>
        <div class="txt"> {{row.introduction}} </div>
      </div>
      <div class="subject-group" *ngIf="row.subject2">
        <div class="label">集成电路</div>
        <div class="txt"> {{row.subject2}} </div>
      </div>
      <div class="subject-group" *ngIf="row.subject3">
        <div class="label">生物医药</div>
        <div class="txt"> {{row.subject3}} </div>
      </div>
    </div>
  </div>
    
</div>