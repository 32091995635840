import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadding',
  templateUrl: './loadding.component.html',
  styleUrls: ['./loadding.component.styl']
})
export class LoaddingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
