<div class="img-btn" (click)="explEvent(true)">
  <img src="/assets/imgs/sm/{{themes[aei]}}.png"/>
</div>
 
<div class="expl-popup-wrapper" [ngClass]="{'alignitems':(deviceMode!==0 || !datas[aei].isnew207)}" [@openClose]="show ? 'open' : 'closed'">
  <div class="close-img-btn" (click)="explEvent(false)">
    <img src="/assets/imgs/main/close.png"/>
  </div>
  <div class="epw-body" [ngClass]="{'hhvvw':deviceMode===0}">
    <div class="title {{themes[aei]}}-theme-color" [innerHTML]="datas[aei].title"></div>

    <div class="intro-group">
      <p *ngFor="let item of datas[aei].intro">{{item}}</p>
    </div>

    <div class="tables-group isnew-207" *ngIf="datas[aei].isnew207">
      <table>
        <thead>
          <tr>
            <th>培养层次</th>
            <th>学科（专业）数</th>
            <th>主要学科（专业）名称</th>
            <th>学科（专业）开设高校数</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>研究生</td>
            <td>66</td>
            <td>
              计算机科学与技术、
              控制科学与工程、
              信息与通信工程、
              电子与通信工程、软件工程……
            </td>
            <td>20</td>
          </tr>
          <tr>
            <td>本科</td>
            <td>25</td>
            <td>
              计算机科学与技术、
              智能科学与技术、
              数据科学与大数据技术、
              自动化、电子信息工程……
            </td>
            <td>17</td>
          </tr>
          <tr>
            <td>高职高专</td>
            <td>13</td>
            <td>
              计算机应用技术、
              大数据技术及应用、
              物联网应用技术、
              机电一体化技术、
              工业机器人技术……
            </td>
            <td>13</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>104</td>
            <td>-</td>
            <td>50</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>