<app-rback rurl="/future"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="hh-wrapper">
      <p>请横屏使用</p>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw">

      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
        <div class="txt red-theme-color">地图模式</div>
      </div>
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/21_sedshsjjd-{{seli}}.gif"/>
          </app-zoomer>
        </div>
      </div>
    </div>
    <div class="right-lw">

      <div class="header-wrapper">

        <div class="logo1">
          <img src="/assets/imgs/new/old/logo1.png"/>
        </div>
        <div class="title">
          <div class="logoname2 red-theme-color">{{ttitle}}</div>
        </div> 

      </div>

      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle red-theme-color">
            <div class="pre iicon" (click)="preProf()">
              <img class="ipre" src="/assets/imgs/new/i-arrow-y.png"/>
            </div>
            <div class="title red-theme-color">{{profs[seli]}}</div>
            <div class="next iicon" (click)="nextProf()">
              <img class="inext" src="/assets/imgs/new/i-arrow-y.png"/>
            </div>
          </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.place}}</div>
            <div class="llitem" *ngIf="rows.length===0">无数据</div>
          </div>
        </div>
      </div>

    </div>

  </app-xtheme-vv>
</app-xtheme>
