<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname life-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt life-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container">
        <app-hzoom>
          <img class="iled" src="/assets/imgs/gif/18_lnjyxx_{{seli}}.gif" [ngStyle]="{'left': seli===3?'-100%':'0'}" />
          <div class="map-svg-dom" #mapSvgContainerRef [ngStyle]="{'left': seli===3?'0':'-100%'}" ></div> 
        </app-hzoom>
      </div> 
    </div> 
 
    <div class="list-group">
      <app-liked-shared [mapkey]="'lnjyxx'"></app-liked-shared>

      <div class="lwtitle life-theme-color">
        <div class="pre icon" (click)="preProf()">
          <img src="/assets/imgs/city/top2.png" />
        </div>
        <div class="title">{{data[seli].name}}</div>
        <div class="next icon" (click)="nextProf()">
          <img src="/assets/imgs/city/top2.png" />
        </div>
      </div>
      <div class="lwtitle subfilter life-theme-color" *ngIf="seli === 3">
        <div class="pre icon" (click)="preProf(true)">
          <img src="/assets/imgs/city/top2.png" />
        </div>
        <div class="title">{{subtitle}}</div>
        <div class="next icon" (click)="nextProf(true)">
          <img src="/assets/imgs/city/top2.png" />
        </div>
      </div>
      <div class="lg-group" [ngStyle]="{'height':seli===3?'222px':'264px'}">
        <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="showFullMapEvent(item.id)">
          <div *ngIf="seli===0&&i===2" style="width: 20px;display: inline-block;"></div>
          <ng-container *ngIf="!(seli===0&&i===2)"> {{(seli===0?(i>1?i-1:i):i)+1}}、 </ng-container>
          {{item.name}}
        </div>
      </div>
    </div>
    <div class="copytime">数据更新时间：2021年12月</div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt life-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container">
          <!-- <app-hzoom> -->
            <div class="zoomer-wrapper">
              <img class="iled" src="/assets/imgs/gif/18_lnjyxx_{{seli}}.gif" [ngStyle]="{'left': seli===3?'-100%':'0'}" />
              <div class="map-svg-dom" #mapSvgContainerRef1 [ngStyle]="{'left': seli===3?'0':'-100%' }" ></div> 
            </div>
          <!-- </app-hzoom> -->
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname life-theme-color">{{ttitle}}</div>
 
      <div class="list-group">
        <div class="lwtitle life-theme-color">
          <div class="pre icon" (click)="preProf()">
            <img src="/assets/imgs/city/top2.png" />
          </div>
          <div class="title">{{data[seli].name}}</div>
          <div class="next icon" (click)="nextProf()">
            <img src="/assets/imgs/city/top2.png" />
          </div>
        </div>
        <div class="lwtitle subfilter life-theme-color" *ngIf="seli === 3">
          <div class="pre icon" (click)="preProf(true)">
            <img src="/assets/imgs/city/top2.png" />
          </div>
          <div class="title">{{subtitle}}</div>
          <div class="next icon" (click)="nextProf(true)">
            <img src="/assets/imgs/city/top2.png" />
          </div>
        </div>
        <div class="lg-group" [ngStyle]="{'height':seli===3?'calc(100vh - 177px)':'calc(100vh - 137px)'}">
          <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="showFullMapEvent(item.id)">
            <div *ngIf="seli===0&&i===2" style="width: 20px;display: inline-block;"></div>
            <ng-container *ngIf="!(seli===0&&i===2)"> {{(seli===0?(i>1?i-1:i):i)+1}}、 </ng-container>
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="copytime">数据更新时间：2021年12月</div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   