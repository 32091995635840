import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-202_newshsxxtycgkfdt',
  templateUrl: './202_newshsxxtycgkfdt.component.html',
  styleUrls: ['./202_newshsxxtycgkfdt.component.styl']
})
export class NewShsxxtycgkfdtComponent implements OnInit {

  ttitle = '';

  rows: any = [];

  dcode = 0;
  dcodename = '';

  kw = '';
  title = '';
  resrows: any[] = [];

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshsxxtycgkfdt.title;
    this.http.get(MAPDIST.newshsxxtycgkfdt.api).toPromise().then((rows: any) => {
      this.rows = rows;
      this.clmpref.refreshMapPanel();
    });
  }

  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.district === this.dcodename) {
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }

    this.cd.detectChanges();
  }


  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.name) || p.test(row.district) || p.test(row.address) || p.test(row.places)) {
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    this.ngZone.run(() => this.router.navigateByUrl('/new/pmap/newshsxxtycgkfdt/' + i));
  }
}
