<app-rback [type]="2" [rurl]="'/city/jyyjtpgjdt'"></app-rback>
<div class="content-wrapper">
  <div class="map-wrapper">
    <div class="qqfmap" id="qq-full-map-wrapper"></div> 
  </div> 
</div>

<div class="popup-info-wrapper" [@openClose]="infos.length>0 ? 'open' : 'closed'">
  <div class="piw-close-group" (click)="closePopup()">
    <img src="/assets/imgs/main/close.png"/>
  </div>
  <div class="piw-body">
    <div class="piwitem postprop" *ngIf="isStartMarker">
      在中国全面建成小康社会，向第二个百年奋斗目标进军的征程中，教育始终发挥着重要作用。上海教育充分发挥自身优势特色，积极助力脱贫攻坚、服务乡村振兴，在援藏、援青、援疆、援滇等工作中播撒大爱、收获满意、增进团结，在促进当地教育优质均衡发展，建设高质量乡村教育体系，筑牢中华民族共同体意识等方面持续贡献上海的智慧与力量。
    </div>
    <div class="piwitem" *ngFor="let item of infos">
      <div class="wtitle">{{item.area}}</div>
      <div class="wtable">
        <div class="wtr" *ngFor="let topic of item.data">
          <div class="wth"><div class="wtxt">{{topic.topic}}</div></div>
          <div class="wtd">
            <div class="wtdd" *ngFor="let note of topic.note">
              <strong *ngIf="note.title">{{note.title}}：</strong>{{note.intro}}
            </div>
            <div class="report"*ngIf="topic.report">[相关报道：{{topic.report}}]</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
