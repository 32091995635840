<app-rback rurl="/zlsjjy"></app-rback>

<app-xtheme theme="new0">
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name zlsjjy-theme-color">{{ttitle}}</div>
        <div class="input">
          <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
          <div class="clear-img">
            <img class="clear" src="/assets/imgs/search.png" (click)="closeKeyboard(1)" />
          </div>
        </div>
        
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt zlsjjy-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" >  
      <div class="map-zoom">
        <app-clmap #clmpref (bindSelected)="switchDist($event)"></app-clmap>
      </div>
    </div>

    <div class="llitem " *ngFor="let item of resrows" style="height: 0.01px;overflow: hidden;position: absolute;">{{item.name}}</div>

    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'newshsszjyyzkcdt'"></app-liked-shared>
      <div class="list-group">
        <div class="lwtitle bblitem zlsjjy-theme-color">{{title}}</div>
        <div class="lwtlist">
          
          <div class="lggitem courses zlsjjy-theme-color"  >  
            <div class="lgleft" style="justify-content: center;">
              <div class="txt" > 机构名称 </div>
            </div>
            <div class="lgright">
              <div class="lgritem" style="justify-content: center;">
                <div class="txt"> 优质课程 </div>
              </div> 
            </div>
          </div>  
          <div class="lggitem courses" *ngFor="let item of resrows;let i = index" (click)="toView(item.id)">  
            <div class="lgleft">
              <div class="txt">
                {{i+1}}、{{item.name}}
              </div>
            </div>
            <div class="lgright">
              <div class="lgritem" *ngFor="let course of item.courses" >
                <div class="txt">
                  {{course.coursename}}
                </div>
              </div> 
            </div>
          </div>  
          <ng-container *ngIf="resrows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
        </div><!--active-->
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt zlsjjy-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" >  
        <div class="map-zoom">
          <app-vvclmap (bindSelected)="switchDist($event)"></app-vvclmap>
        </div>
      </div>
    </div>
    <div class="right-lw">
  
      <div class="header-wrapper"> 
       
        <div class="search"> 
          <div class="logoname2 zlsjjy-theme-color">{{ttitle}}</div>
          <div class="input-group">
            <div class="input">
              <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2"  (keyup)="changeKw()"/>
              <div class="clear-img">
                <img class="clear" src="/assets/imgs/search.png" (click)="closeKeyboard(2)"/>
              </div>
            </div> 
          </div>
          
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle bblitem zlsjjy-theme-color">{{title}}</div>
          <div class="lwtlist">

            <div class="lggitem courses zlsjjy-theme-color"  >  
              <div class="lgleft" style="justify-content: center;">
                <div class="txt" > 机构名称 </div>
              </div>
              <div class="lgright">
                <div class="lgritem" style="justify-content: center;">
                  <div class="txt"> 优质课程 </div>
                </div> 
              </div>
            </div>   
              <div class="lggitem courses" *ngFor="let item of resrows;let i = index" (click)="toView(item.id)">  
                <div class="lgleft">
                  <div class="txt">
                    {{i+1}}、{{item.name}}
                  </div>
                </div>
                <div class="lgright">
                  <div class="lgritem" *ngFor="let course of item.courses" >
                    <div class="txt">
                      {{course.coursename}}
                    </div>
                  </div> 
                </div>
              </div>  
              <ng-container *ngIf="resrows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
          </div>
        </div>
      </div>
       
    </div>
  
    
  </app-xtheme-vv>
</app-xtheme>
  
