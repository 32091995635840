import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-203_newshsxsztyxsjlxdt',
  templateUrl: './203_newshsxsztyxsjlxdt.component.html',
  styleUrls: ['./203_newshsxsztyxsjlxdt.component.styl']
})
export class NewShsxsztyxsjlxdtComponent implements OnInit {

  ttitle = '';

  data: any[] = [];

  profs: string[] = [];
  seli = 0;
  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshsxsztyxsjlxdt.title;
    this.http.get(MAPDIST.newshsxsztyxsjlxdt.api).toPromise().then((data: any) => {
      const topics = [];
      for (const ditem of data) {
        if (!topics.includes(ditem.topic)) {
          topics.push(ditem.topic);
        }
      }
      this.profs = topics;
      this.data = data;
      this.selied();
    });
  }

  preProf() {
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.profs.length - 1; }

    this.selied();
  }

  nextProf() {
    this.seli += 1;
    if (this.seli === this.profs.length) { this.seli = 0; }

    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.profs[this.seli] != item.topic) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    // this.router.navigateByUrl('/new/subpmap/newshsxsztyxsjlxdt/'+this.seli+'/' + i)

    this.router.navigateByUrl('/zlsjjy/203/map/' + i);
  }

}
