<app-rback rurl="/life"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">{{ttitle}}</div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" (click)="toView()">
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/gif/11_shstsjyxx.gif"/>
        </app-hzoom>
      </div> 
    </div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'shstsjyxx'"></app-liked-shared>

      <div class="list-group life-theme-color">
        <div class="lwtitle life-theme-color bblitem"> 学校 </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{ i + 1 }}. {{item.name}}</div>
        </div>
      </div>
      <div class="copytime">数据更新时间：2023年7月</div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt life-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/11_shstsjyxx.gif"/>
          </app-zoomer>
        </div>

      </div>
    </div>
    <div class="right-lw">
  
      <div class="header-wrapper"> 
       
        <div class="search"> 
          <div class="logoname2 life-theme-color">{{ttitle}}</div> 
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle life-theme-color bblitem"> 学校 </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{ i + 1}}. {{item.name}}</div>
          </div>
        </div>
        <div class="copytime">数据更新时间：2023年7月</div>
      </div>
       
    </div>
   
  </app-xtheme-vv>
</app-xtheme>
  