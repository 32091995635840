import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { EventManager, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';
import { EchoService, RunService, UtilsService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class CityShjysswjbjscgdtDetailComponent implements OnInit {

  row: any = {show: false};
  url = '';

  id = 0;

  filei = 1;

  UROOT = '';

  uid: any;

  isd: boolean = this.utilsService.isSmartDevice();

  @ViewChild('swipercomponent1', { static: false }) public swipercomponent1f: SwiperComponent;
  @ViewChild('swipercomponent2', { static: false }) public swipercomponent2f: SwiperComponent;

  constructor(
    private title: Title,
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private eventManager: EventManager,
    private runService: RunService,
    private utilsService: UtilsService,
    private echoService: EchoService,
  ) { }

  lbw = 2880;
  lbh = 1152;
  ngOnInit() {
    if (this.isd) {
      this.lbw = 600;
      this.lbh = 450;
    }

    this.UROOT = environment.U_ROOT;
    this.id  = parseInt(this.route.snapshot.paramMap.get('id'));

    if (isNaN(this.id)) { this.location.back(); }

    const pObjt = MAPDIST.shjysswjbjscgdt;
    if (!pObjt) {
      this.location.back();
      return;
    }
    this.url = pObjt.url;
    this.title.setTitle('详情 - ' + pObjt.ptitle);

    this.uid = this.runService.uid;
    if (!this.uid) { this.uid = '0'; }

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      let isrow = true;
      for (let i = 0; i < resp.length; i++) {
        if (resp[i].id == this.id) {
          const row = resp[i];
          for (let j = 0; j < resp.length; j++) {
            const srow = resp[j];
            if (row.name != srow.name) { continue; }

            if (!row.pjnames) {
              row.files = [];
              row.pjnames = [];
            }

            if (row.id != srow.id) { continue; }

            row.files = row.files.concat(srow.file);
            srow.isLiked = false;

            if (this.uid > 0 && false) {
                this.http.get('/v1/eduexpo/map/29/praise?child=' + srow.id + '&uid=' + this.uid + '&type=MAP_SSW').toPromise().then((resp: any) => {

                  if (resp.self) { srow.isLiked = true; }

                }).finally(() => row.pjnames.push(srow));
            } else {
              row.pjnames.push(srow);
            }

          }
          row.show = true;

          this.row = row;

          if (this.uid > 0 && false) { this.http.put('/v1/eduexpo/map/29/pv', {
            mapid: 29,
            district: row.district,
            school: row.name,
            project: row.pjname,
            uid: this.uid,
            type: 'MAP_SSW',
            stype: row.type,
            child: row.id
          }).toPromise().then();
          }

          isrow = false;

          break;
        }
      }
      if (isrow) { this.location.back(); }

    });
  }

  liked(ri: number, id: any) {
    if (this.uid > 0 && false) {
      const row = this.row.pjnames[ri];
      this.http.put('/v1/eduexpo/map/29/praise',
        {
          mapid: 29,
          district: row.district,
          school: row.name,
          project: row.pjname,
          uid: this.uid,
          type: 'MAP_SSW',
          stype: row.type,
          child: row.id
        }).toPromise().then(resp => {
        this.row.pjnames[ri].isLiked = true;
      });
    }
  }

  bindchange(pi: number) {
    this.filei = pi + 1;
    this.cd.detectChanges();
  }
  // 后退问题
  // console.log(this.runService.isMapDetail)
  bindOutputEvent() {
    if (this.runService.isMapDetail) {
      this.runService.isMapDetail = false;
      this.location.back();
    } else {
      this.router.navigate([ this.url ], { replaceUrl: true });
    }
  }

  refreshSwp() {
    // this.swipercomponent1f.outRefresh()
    // this.swipercomponent2f.outRefresh()
  }

  goBack() {
    this.location.back();
  }

  toFullView() {
    if (this.isd) {
      this.echoService.fullscreenslider(this.row.files, this.filei - 1);
    }
  }
}
