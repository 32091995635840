<app-rback rurl="/life" *ngIf="!showexpl"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper"> 
      <div class="search"> 
        <div class="logo2name life-theme-color">
          {{ttitle}}
          <app-expl [aei]='6' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>
        <div class="input">
          <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
          <div class="clear-img" (click)="closeKeyboard(1)">
            <img class="clear" src="/assets/imgs/search.png" />
          </div>
        </div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" > 
      <div class="map-zoom">
        <app-clmap #clmpref (bindSelected)="switchDist($event)"></app-clmap>
      </div>
    </div>
   
    <div class="llitem " *ngFor="let item of resrows" style="height: 0.01px;overflow: hidden;position: absolute;">{{item.name}}</div>

    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'sjjndsbzxx'"></app-liked-shared>

      <div class="list-group">
        <div class="lwtitle">{{title}}</div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">
            <div class="scname">
              <div>{{i + 1}}. {{item.name}}</div>
            </div>
            <div class="scprojects">
              <div class="scpitem" *ngFor="let proj of item.projects">
                <div>{{proj}}</div>
              </div>
            </div>
          </div>
          <div class="llitem" *ngIf="resrows.length===0">无数据</div>
        </div><!--active-->
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw">   
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
        <div class="txt life-theme-color">地图模式</div>
      </div> 
      
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" >  
        <div class="map-zoom">
          <app-vvclmap (bindSelected)="switchDist($event)"></app-vvclmap>
        </div> 
      </div>
    </div>
    <div class="right-lw"> 
      <div class="header-wrapper">  
        <div class="search"> 
          <div class="logoname2 life-theme-color">
            {{ttitle}}
            <app-expl [aei]='6' (bindExplEvent)="showexpl = $event"></app-expl>
          </div>
          <div class="input-group">
            <div class="input">
              <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2"  (keyup)="changeKw()"/>
              <div class="clear-img" (click)="closeKeyboard(2)">
                <img class="clear" src="/assets/imgs/search.png"/>
              </div>
            </div> 
          </div>
          
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle">{{title}}</div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of resrows; let i = index;" (click)="toView(item.id)">
              <div class="scname">
                <div>{{i + 1}}. {{item.name}}</div>
              </div>
              <div class="scprojects">
                <div class="scpitem" *ngFor="let proj of item.projects">
                  <div>{{proj}}</div>
                </div>
              </div>
            </div>
            <div class="llitem" *ngIf="resrows.length===0">无数据</div>
          </div><!--active-->
        </div>
      </div> 
    </div> 
  </app-xtheme-vv>
</app-xtheme>
 