<app-rback [rsback]='true'></app-rback>
<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="logoname red-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/new/imap.png"/> 
      <div class="txt red-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container">
        <app-hzoom> 
          <div class="map-svg-dom" #mapSvgContainerRef ></div> 
        </app-hzoom>
      </div> 
    </div> 
 
    <div class="list-group red-list-bg">
      <app-liked-shared [mapkey]="'redzxxhsxyzj'"></app-liked-shared>

      <div class="lwtitle red-list-border-bottom"> 
          <ng-container *ngFor="let tname of titlei;let ti=index">
            <div class="title red-list-border-left" [ngClass]="{'red-theme-color': ti === seli}" (click)="selied(ti)"> {{tname}} </div> 
          </ng-container> 
      </div>
      <div class="lwtitle subfilter red-theme-color red-list-border-bottom">
        <div class="pre icon" (click)="preProf()">
          <img src="/assets/imgs/new/i-arrow-y.png" />
        </div>
        <div class="title"> {{titleii[selii]}} </div>
        <div class="next icon" (click)="nextProf()">
          <img src="/assets/imgs/new/i-arrow-y.png" />
        </div>
      </div>
      <div class="lg-group" [ngStyle]="{'height':true?'231px':''}">
        <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="showFullMapEvent(item.id)">  
          {{i+1}}、{{item.name}}
        </div>
        <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/new/imap.png"/> 
        <div class="txt red-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container">
          <!-- <app-hzoom> -->
            <div class="zoomer-wrapper"> 
              <div class="map-svg-dom" #mapSvgContainerRef1 ></div> 
            </div>
          <!-- </app-hzoom> -->
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname red-theme-color">{{ttitle}}</div>
 
      <div class="list-group red-list-bg">
        <div class="lwtitle red-list-border-bottom"> 
            <ng-container *ngFor="let tname of titlei;let ti=index">
                <div class="title red-list-border-left" [ngClass]="{'red-theme-color': ti === seli}" (click)="selied(ti)"> {{tname}} </div> 
              </ng-container> 
        </div>
        <div class="lwtitle subfilter red-theme-color red-list-border-bottom">
          <div class="pre icon" (click)="preProf()">
            <img src="/assets/imgs/new/i-arrow-y.png" />
          </div>
          <div class="title"> {{titleii[selii]}} </div>
          <div class="next icon" (click)="nextProf()">
            <img src="/assets/imgs/new/i-arrow-y.png" />
          </div>
        </div>
        <div class="lg-group" [ngStyle]="{'height':true?'calc(100vh - 160px)':''}">
          <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="showFullMapEvent(item.id)">
            {{i+1}}、{{item.name}}
          </div>
          <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   