<app-swiper (click)="close()"
  height="100%" 
  [showPosition]="false" 
  [interval]="10000"
  [duration]="1000"
  [isShowPager]='false'
  [autoplay]='false'
  [current] = 'current'
  (bindchange)="bindchange($event)">
    <app-swiper-item *ngFor="let item of files">  
      <div class="img">
        <img [src]="UROOT + item "/>   
      </div> 
    </app-swiper-item>
</app-swiper> 

<div class="doc-wrapper"> 
  <div class="doc-group" > {{fi+1}}/{{files.length}}</div> 
</div>