<app-guide [pici]="4"></app-guide>
<app-rback rurl="/life" *ngIf="!showexpl"></app-rback>

<app-xtheme>
    <app-xtheme-hh id='xqhjth-wrapper'>
      <div class="container">
       
        <div class="logoname life-theme-color">
          {{ttitle}} 
          <app-expl [aei]='3' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>

        <div class="input-group">
          <div class="input" [ngClass]="{'active': kw.length > 0}">
            <input placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
            <div class="search-icon" (click)="changeKw()">
              <img src="/assets/imgs/icon/xqhjth-search.png"/>
            </div>

            <div class="rlist-wrapper">
              <ng-container *ngFor="let item of rlist">
                <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="gotoArea(item.area, item.xqhjth, $event)"> </div>
              </ng-container> 
              <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
            </div>
          </div>
        </div>
   
        <div class="map-container">

          <div class="map-mode life-theme-color" *ngIf="false">
            <div class="mm mm1" *ngIf="mode === 1" (click)="mode=2">资源<br/>热力图</div>
            <div class="mm mm2" *ngIf="mode === 2" (click)="mode=1">资源<br/>分布图</div>
          </div>
          <div class="svg-group" [ngClass]="{'fbtu': mode===1}"> 
            <app-hzoom>
              <div class="map-zoom">
                <div class="svg-container" [ngClass]="{'active': mode===2}" #svgContainerRef></div>
                <div class="svg-container fbtus" [ngClass]="{'active': mode===1}" #svgContainerRef21></div>
              </div>
            </app-hzoom> 
 
          </div>
  
          <div class="map-legend" *ngIf="mode===1">

            <app-liked-shared [mapkey]="'xqhjth'"></app-liked-shared>
          
            <div class="mlgroup">
              <div class="mlitem">
                <div class="blockr1"></div>
                <div class="desc"> 集团化 <span>193</span> 个，共覆盖 <span>1152</span> 所学校 </div>
              </div>
              <div class="mlitem" style="margin-top: 10px;">
                <div class="blockr2"></div>
                <div class="desc"> 学区化 <span>82</span> 个，共覆盖 <span>637</span> 所学校 </div>
              </div>
              <div class="mlitem" style="margin-top: 20px;">
                <div class="desc" style="font-size: 12px;"> 数据更新时间：2022年9月 </div>
              </div>
            </div>
          </div>

        </div>

        <div class="area-group"> 
           
          <div class="bottom-btn" >
            <div class="list-group" *ngIf="showArea&&false">
              <div class="item" *ngFor="let item of areas" (click)="toArea(item.id, $event)">{{item.txt}}</div>
            </div> 
            <div class="dlist-group" *ngIf="showArea">
              <div class="ditem-group" *ngFor="let items of lareas">
                <div class="ditem" *ngFor="let item of items" (click)="toArea(item.id, $event)">{{item.txt}}</div> 
              </div>
            </div> 
            <div class="title-toto" >
              <img class="icon" src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}"/>
              <div class="label-txt">区</div> 
            </div>
            <div class="agbtn" (click)="showList($event)">
              <img class="label" src="/assets/imgs/icon/xqhjth-sub-area-label.png"/>
            </div>
          </div>
        </div>
        
      </div> 
    </app-xtheme-hh>
    <app-xtheme-vv id='xqhjth-wrapper2'>
      <div class="left-lw">
        <div class="map-mode life-theme-color" *ngIf="false">
          <div class="mm mm1" *ngIf="mode === 1" (click)="mode=2">资源<br/>热力图</div>
          <div class="mm mm2" *ngIf="mode === 2" (click)="mode=1">资源<br/>分布图</div>
        </div>
        <div class="map-zoom" [ngClass]="{'fbtu': mode===1}">
          <app-zoomer>
            <div class="svg-container" [ngClass]="{'active': mode===2}" #svgContainerRef2></div>
            <div class="svg-container fbtus" [ngClass]="{'active': mode===1}" #svgContainerRef22></div>
          </app-zoomer>
        </div>
      </div>
      <div class="right-lw">
        <div class="logoname life-theme-color">
          {{ttitle}} 
          <app-expl [aei]='3' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>
        
        <div class="input-group">
          <div class="input" [ngClass]="{'active': kw.length > 0}">
            <input placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2" (keyup)="changeKw()"/>
            <div class="search-icon" (click)="changeKw()">
              <img src="/assets/imgs/icon/xqhjth-search.png"/>
            </div>

            <div class="rlist-wrapper">
              <ng-container *ngFor="let item of rlist">
                <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="gotoArea(item.area, item.xqhjth, $event)"> </div>
              </ng-container> 
              <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
            </div>
          </div>
        </div>
   
        <div class="map-legend" *ngIf="mode===1">
          <div class="mlgroup">
            <div class="mlitem">
              <div class="blockr1"></div>
              <div class="desc"> <p>集团化 <span>193</span> 个</p> <p>共覆盖 <span>1152</span> 所学校 </p> </div>
            </div>
            <div class="mlitem" style="margin-top: 10px;">
              <div class="blockr2"></div>
              <div class="desc"> 学区化 <span>82</span> 个，共覆盖 <span>637</span> 所学校 </div>
            </div>
            <div class="mlitem" style="margin-top: 20px;">
              <div class="desc" style="font-size: 12px;"> 数据更新时间：2022年9月 </div>
            </div>
          </div>
        </div>

        <div class="area-group"> 
          <div class="bottom-btn">
            <div class="list-group" *ngIf="showArea&&false">
              <div class="item" *ngFor="let item of areas" (click)="toArea(item.id, $event)">{{item.txt}}</div>
            </div> 
            <div class="dlist-group" *ngIf="showArea">
              <div class="ditem-group" *ngFor="let items of lareas">
                <div class="ditem" *ngFor="let item of items" (click)="toArea(item.id, $event)">{{item.txt}}</div> 
              </div>
            </div> 
            <div class="title-toto" (click)="showList($event)">
              <img class="icon" src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}"/>
              <div class="label-txt">区</div> 
            </div> 
            
            <div class="agbtn" (click)="showList($event)">
              <img class="label" src="/assets/imgs/icon/xqhjth-sub-area-label.png"/>
            </div>
          </div>
        </div>
        
      </div>
    </app-xtheme-vv>
  </app-xtheme>