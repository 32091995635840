import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';
import { ErrorComponent } from './shared/components';
import {
  AppStartComponent,
  MainComponent,
  CityIndexComponent,
  CityGjgjzlzdzyComponent,
  CityGjGjDetailComponent,

  ScienceIndexComponent,
  ScienceKeChuangListComponent,
  ScienceGaoDengListComponent,
  ScienceZhiYeJiaoYuListComponent,
  ScienceKeXueShiJianListComponent,
  ScienceKePuJiaoYuListComponent,
  ScienceZhongXiaoXueListComponent,
  ScienceShiFanListComponent,
  ScienceRenGongZhiNengListComponent,
  ScienceKeXueJiaListComponent,

  // EduIndexComponent,
  // EduXinXiHuaListComponent,
  // EduGaoDengJiaoYuListComponent,
  // EduShangHaiGaoXiaoListComponent,
  // EduXuNiFangZhenListComponent,

  LifeIndexComponent,
  LifeTuoyuListComponent,
  LifeXqhjthComponent,
  LifeXqhjthAreaComponent,
  LifeJmkhxxComponent,
  LifeJqczjsgcxxComponent,

  FutureIndexComponent,
  FutureQsnkxsjgzzComponent,
  LifeTsgzComponent,
  LifeGfgyxkComponent,
  FutureQjzxxsxljksfzxComponent,
  BuilderComponent,
  CityGjmapComponent,
  PmapComponent,
  LifeGjjsylzyComponent,
  LifeLnjyxxComponent,
  FutureGjzdsssComponent,
  FutureQgjtjycxsjjdComponent,
  CityZyjydjwdzxComponent,
  CityZyjydjwdzxAreaComponent,
  TmpComponent,
  LifeZjwpzyComponent,
  LifeShstsjyxxComponent,
  LifeSjjndsbzxxComponent,
  LifeWdxsyslmComponent,
  SubpmapComponent,
  DetailComponent,
  FutureSedshsjjdComponent,
  LifeZgzgtzbgtComponent,
  LifeZbmapComponent,
  CityJyyjtpgjdtComponent,
  CityJyyjtpgjdtMapComponent,
  LifeSmzsxxjdxxComponent,
  LifeZyjykfsxzxComponent,
  LifeGqjyzyfbComponent,
  LifeJcjyzyfbComponent,
  LifeJmkyeyComponent,
  LifeTsgzDistrictComponent,
  CityShjysswjbjscgdtComponent,
  CityShjysswjbjscgdtDetailComponent,

  SciencePmapComponent,
  ScienceDetailComponent,
} from './routes';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RedSzjhComponent, RedDetailComponent, RedMainComponent, RedShagzyxlshsjComponent, RedShhswhComponent, RedShzxyzgxldtComponent, RedSssjyrComponent, RedXyhsjzComponent, RedXyhszjComponent, RedZgxlszkcComponent, RedPmapComponent, RedSubpmapComponent, RedInComponent, RedOutComponent, RedCenterComponent } from './routes/red';
import { MAPDIST, PAGESUBTITLE } from './shared/config';
import { FutureSedshsjjdBigComponent } from './routes/future/21_sedshsjjd_big';
import { NewDetailComponent, NewJyszhzxIndexComponent, NewKxjjsjyjddtComponent, NewPmapComponent, NewShgdjydjrgznzdcylygxdtComponent, NewShgxrgznzyrcpydtComponent, NewShjyxnfzsxjddtComponent, NewShsjyxxhyybgpyxdtComponent, NewShsszjyyzkcdtComponent, NewShsxsztyxsjlxdtComponent, NewShsxsztyxsjlxdtMapComponent, NewShsxsztyxsjlxdtMapGlComponent, NewShsxxtycgkfdtComponent, NewShszxxxrxcxdtComponent, NewSjswfzjysjqdtComponent, NewSubpmapComponent, NewZlsjjyIndexComponent } from './routes/new';
import { NewCsjagzyjyjddtComponent } from './routes/new/212_newcsjagzyjyjddt/212_newcsjagzyjyjddt.component';
import { NewCsjndjyjddtComponent } from './routes/new/213_newcsjndjyjddt/213_newcsjndjyjddt.component';
import { RedShszxxzgxldsxxjyhdkcComponent } from './routes/red/8_shszxxzgxldsxxjyhdkc';
import { NewShsxsztyxsjlxdtSubpmapComponent } from './routes/new/203_newshsxsztyxsjlxdt/subpmap';
import { LifeTjzdzxListComponent } from './routes/life/30_tjzdzx/list.component';
import { HsyrztdtComponent } from './routes/2023/hsyrztdt/hsyrztdt.component';
import { QsmzqjydtComponent } from './routes/2023/qsmzqjydt/qsmzqjydt.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch:  'full', },
  {
    path: 'start',
    canActivate: [AppGuard],
    component: AppStartComponent,
    pathMatch: 'full',
    data: { animation: 'ZeroPage', title: PAGESUBTITLE, titleI18n: '统一启动处理', keep: true }
  },
  {
    path: 'hsyrztdt',
    canActivate: [AppGuard],
    component: HsyrztdtComponent,
    pathMatch: 'full',
    data: { animation: 'ZeroPage', title: '红色育人主题地图', titleI18n: '红色育人主题地图', }
  },
  {
    path: 'qsmzqjydt',
    canActivate: [AppGuard],
    component: QsmzqjydtComponent,
    pathMatch: 'full',
    data: { animation: 'ZeroPage', title: '全生命周期教育地图', titleI18n: '全生命周期教育地图', }
  },
  {
    path: 'zlsjjy',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard],
        component: NewZlsjjyIndexComponent,
        pathMatch:  'full',
        data: { animation: 'OnePage', title: '助力“双减”教育地图' + PAGESUBTITLE }
      },
      {
        path: '210',
        canActivate: [AppGuard],
        component: NewSjswfzjysjqdtComponent,
        data: {
          iid: MAPDIST.newsjswfzjysjqdt._iid,
          rid: MAPDIST.newsjswfzjysjqdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newsjswfzjysjqdt.ptitle,
          keep: true
        }
      },
      {
        path: '201',
        canActivate: [AppGuard],
        component: NewShsszjyyzkcdtComponent,
        data: {
          iid: MAPDIST.newshsszjyyzkcdt._iid,
          rid: MAPDIST.newshsszjyyzkcdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshsszjyyzkcdt.ptitle,
          keep: true
        }
      },
      {
        path: '211',
        canActivate: [AppGuard],
        component: NewShszxxxrxcxdtComponent,
        data: {
          iid: MAPDIST.newshszxxxrxcxdt._iid,
          rid: MAPDIST.newshszxxxrxcxdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshszxxxrxcxdt.ptitle,
          keep: true
        }
      },
      {
        path: '202',
        canActivate: [AppGuard],
        component: NewShsxxtycgkfdtComponent,
        data: {
          iid: MAPDIST.newshsxxtycgkfdt._iid,
          rid: MAPDIST.newshsxxtycgkfdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshsxxtycgkfdt.ptitle,
          keep: true
        }
      },
      {
        path: '203',
        canActivate: [AppGuard],
        component: NewShsxsztyxsjlxdtComponent,
        data: {
          iid: MAPDIST.newshsxsztyxsjlxdt._iid,
          rid: MAPDIST.newshsxsztyxsjlxdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshsxsztyxsjlxdt.ptitle,
          keep: true
        }
      },
      {
        path: '203/map/:selid',
        canActivate: [AppGuard],
        component: NewShsxsztyxsjlxdtMapComponent,
        data: {
          iid: MAPDIST.newshsxsztyxsjlxdt._iid,
          rid: MAPDIST.newshsxsztyxsjlxdt.rid,
          animation: 'ThreePage',
          title: MAPDIST.newshsxsztyxsjlxdt.ptitle,
          keep: true
        }
      },
      {
        path: '203/submap/:selid',
        canActivate: [AppGuard],
        component: NewShsxsztyxsjlxdtSubpmapComponent,
        data: {
          iid: MAPDIST.newshsxsztyxsjlxdt._iid,
          rid: MAPDIST.newshsxsztyxsjlxdt.rid,
          animation: 'ThreePage',
          title: MAPDIST.newshsxsztyxsjlxdt.ptitle,
          keep: true
        }
      },
      {
        path: '203/mapgl/:selid',
        canActivate: [AppGuard],
        component: NewShsxsztyxsjlxdtMapGlComponent,
        data: {
          iid: MAPDIST.newshsxsztyxsjlxdt._iid,
          rid: MAPDIST.newshsxsztyxsjlxdt.rid,
          animation: 'ThreePage',
          title: MAPDIST.newshsxsztyxsjlxdt.ptitle,
          keep: true
        }
      },
      {
        path: '204',
        canActivate: [AppGuard],
        component: NewKxjjsjyjddtComponent,
        data: {
          iid: MAPDIST.newkxjjsjyjddt._iid,
          rid: MAPDIST.newkxjjsjyjddt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newkxjjsjyjddt.ptitle,
          keep: true
        }
      },
      {
        path: '212',
        canActivate: [AppGuard],
        component: NewCsjagzyjyjddtComponent,
        data: {
          iid: MAPDIST.newcsjagzyjyjddt._iid,
          rid: MAPDIST.newcsjagzyjyjddt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newcsjagzyjyjddt.ptitle,
          keep: true
        }
      },
      {
        path: '213',
        canActivate: [AppGuard],
        component: NewCsjndjyjddtComponent,
        data: {
          iid: MAPDIST.newcsjndjyjddt._iid,
          rid: MAPDIST.newcsjndjyjddt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newcsjndjyjddt.ptitle,
          keep: true
        }
      },
    ]
  },
  // 14
  {
    path: 'jyszhzx',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard],
        component: NewJyszhzxIndexComponent,
        pathMatch:  'full',
        data: { animation: 'OnePage', title: '教育数字化转型地图' + PAGESUBTITLE }
      },
      {
        path: '205',
        canActivate: [AppGuard],
        component: NewShsjyxxhyybgpyxdtComponent,
        data: {
          iid: MAPDIST.newshsjyxxhyybgpyxdt._iid,
          rid: MAPDIST.newshsjyxxhyybgpyxdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshsjyxxhyybgpyxdt.ptitle,
          keep: true
        }
      },
      {
        path: '206',
        canActivate: [AppGuard],
        component: NewShgdjydjrgznzdcylygxdtComponent,
        data: {
          iid: MAPDIST.newshgdjydjrgznzdcylygxdt._iid,
          rid: MAPDIST.newshgdjydjrgznzdcylygxdt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshgdjydjrgznzdcylygxdt.ptitle,
          keep: true
        }
      },
      {
        path: '207',
        canActivate: [AppGuard],
        component: NewShgxrgznzyrcpydtComponent,
        data: {
          iid: MAPDIST.newshgxrgznzyrcpydt._iid,
          rid: MAPDIST.newshgxrgznzyrcpydt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshgxrgznzyrcpydt.ptitle,
          keep: true
        }
        },
      {
        path: '208',
        canActivate: [AppGuard],
        component: NewShjyxnfzsxjddtComponent,
        data: {
          iid: MAPDIST.newshjyxnfzsxjddt._iid,
          rid: MAPDIST.newshjyxnfzsxjddt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newshjyxnfzsxjddt.ptitle,
          keep: true
        }
        },
    ]
  },
  {
    path: 'new/detail/:model/:selid',
    canActivate: [AppGuard],
    component: NewDetailComponent,
    pathMatch: 'full',
    data: { animation: 'FivePage', title: '' + PAGESUBTITLE, titleI18n: '统一详情模版' }
  },
  {
    path: 'new/pmap/:model/:selid',
    canActivate: [AppGuard],
    component: NewPmapComponent, data: { animation: 'ThreePage', title: '地图模式' + PAGESUBTITLE }
  },
  {
    path: 'new/subpmap/:model/:swlab/:selid',
    canActivate: [AppGuard],
    component: NewSubpmapComponent, data: { animation: 'ThreePage', title: '地图模式' + PAGESUBTITLE }
  },
 // 21
  { // 15
    path: 'red',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard],
        component: RedMainComponent,
        pathMatch:  'full',
        data: { animation: 'OnePage', title: '校内外育人红色版图' + PAGESUBTITLE } },
      {
        path: 'in',
        canActivate: [AppGuard],
        component: RedInComponent,
        data: { animation: 'TwoPage',
          title: '校内外育人红色版图' + PAGESUBTITLE } },
      {
        path: '1',
        canActivate: [AppGuard],
        component: RedZgxlszkcComponent,
        data: { iid: MAPDIST.redzgxlszkc._iid, rid: MAPDIST.redzgxlszkc.rid, animation: 'ThreePage', title: MAPDIST.redzgxlszkc.ptitle, keep: true } },
      {
        path: '2',
        canActivate: [AppGuard],
        component: RedShzxyzgxldtComponent,
        data: { iid: MAPDIST.redshzxyzgxldt._iid, rid: MAPDIST.redshzxyzgxldt.rid, animation: 'ThreePage', title: MAPDIST.redshzxyzgxldt.ptitle, keep: true } },
      {
        path: 'center',
        canActivate: [AppGuard],
        component: RedCenterComponent,
        data: { animation: 'TwoPage', title: '校内外育人红色版图' + PAGESUBTITLE } },
      {
        path: '3',
        canActivate: [AppGuard],
        component: RedXyhszjComponent,
        data: { iid: MAPDIST.redzxxhsxyzj._iid, rid: MAPDIST.redzxxhsxyzj.rid, animation: 'ThreePage', title: MAPDIST.redzxxhsxyzj.ptitle, keep: true } },
      {
        path: '4',
        canActivate: [AppGuard],
        component: RedXyhsjzComponent,
        data: { iid: MAPDIST.redshdzxhsjz._iid, rid: MAPDIST.redshdzxhsjz.rid, animation: 'ThreePage', title: MAPDIST.redshdzxhsjz.ptitle, keep: true } },
      {
        path: '5',
        canActivate: [AppGuard],
        component: RedSssjyrComponent,
        data: { iid: MAPDIST.redxysssjyr._iid, rid: MAPDIST.redxysssjyr.rid, animation: 'ThreePage', title: MAPDIST.redxysssjyr.ptitle, keep: true } },
      {
        path: 'out',
        canActivate: [AppGuard],
        component: RedOutComponent,
        data: { animation: 'TwoPage', title: '校内外育人红色版图' + PAGESUBTITLE } },
      {
        path: '6',
        canActivate: [AppGuard],
        component: RedShagzyxlshsjComponent, data: { iid: MAPDIST.redagzyjy._iid, rid: MAPDIST.redagzyjy.rid, animation: 'ThreePage', title: MAPDIST.redagzyjy.ptitle, keep: true } },
      {
        path: '7',
        canActivate: [AppGuard],
        component: RedShhswhComponent, data: { iid: MAPDIST.redcityculture._iid, rid: MAPDIST.redcityculture.rid, animation: 'ThreePage', title: MAPDIST.redcityculture.ptitle, keep: true } },
      {
        path: '8',
        canActivate: [AppGuard],
        component: RedShszxxzgxldsxxjyhdkcComponent,
        data: { iid: MAPDIST.redshszxxzgxldsxxjyhdkc._iid, rid: MAPDIST.redshszxxzgxldsxxjyhdkc.rid, animation: 'ThreePage', title: MAPDIST.redshszxxzgxldsxxjyhdkc.ptitle, keep: true } },
      {
        path: '00',
        canActivate: [AppGuard],
        component: RedSzjhComponent,
        data: { iid: 100, rid: 100, animation: 'TwoPage', title: '思政聚合' + PAGESUBTITLE, keep: true } },
      {
        path: 'pmap/:model/:selid',
        canActivate: [AppGuard],
        component: RedPmapComponent,
        data: { animation: 'FivePage', title: '地图模式' + PAGESUBTITLE } },
      {
        path: 'subpmap/:model/:swlab/:selid',
        canActivate: [AppGuard],
        component: RedSubpmapComponent,
        data: { animation: 'FivePage', title: '地图模式' + PAGESUBTITLE } },
      {
        path: 'd/:model/:selid',
        canActivate: [AppGuard],
        component: RedDetailComponent,
        data: { animation: 'SixPage', title: '详情图文介绍' + PAGESUBTITLE } },
    ]
  },
  //  36
  { path: 'main', canActivate: [AppGuard], component: MainComponent, data: { animation: 'OnePage', title: PAGESUBTITLE } },
  {
    path: 'city',
    canActivate: [AppGuard],
    children: [
      { path: '', canActivate: [AppGuard], component: CityIndexComponent, pathMatch:  'full', data: { animation: 'TwoPage', title: '教育让城市更美好' + PAGESUBTITLE } },
      {
        path: 'gdjydjgjzdzlzdzy',
        canActivate: [AppGuard],
        component: CityGjgjzlzdzyComponent,
        data: { iid: MAPDIST.gdjydjgjzdzlzdzy._iid, rid: MAPDIST.gdjydjgjzdzlzdzy.rid, animation: 'ThreePage', title: MAPDIST.gdjydjgjzdzlzdzy.ptitle, keep: true } },
      {
        path: 'gdjydjgjzdzlzdzy/map/:iconseled/:id',
        canActivate: [AppGuard],
        component: CityGjmapComponent,
        data: { iid: MAPDIST.gdjydjgjzdzlzdzy._iid, rid: MAPDIST.gdjydjgjzdzlzdzy.rid, animation: 'FourPage', title: '地图模式 - ' + MAPDIST.gdjydjgjzdzlzdzy.ptitle, keep: true  } },
      {
        path: 'gdjydjgjzdzlzdzy/map/:gj',
        canActivate: [AppGuard],
        component: CityGjGjDetailComponent,
        data: { iid: MAPDIST.gdjydjgjzdzlzdzy._iid, rid: MAPDIST.gdjydjgjzdzlzdzy.rid, animation: 'FivePage', title: '高教详情 - ' + MAPDIST.gdjydjgjzdzlzdzy.ptitle }},
      {
        path: 'zyjydjwdzx',
        canActivate: [AppGuard],
        component: CityZyjydjwdzxComponent,
        data: { iid: MAPDIST.zyjydjwdzx._iid, rid: MAPDIST.zyjydjwdzx.rid, animation: 'ThreePage', title: MAPDIST.zyjydjwdzx.ptitle, keep: true } },
      {
        path: 'zyjydjwdzx/:dcode',
        canActivate: [AppGuard],
        component: CityZyjydjwdzxAreaComponent,
        data: { iid: MAPDIST.zyjydjwdzx._iid, rid: MAPDIST.zyjydjwdzx.rid, animation: 'FourPage', title: '区图 - ' + MAPDIST.zyjydjwdzx.ptitle, keep: true  } },
      {
        path: 'jyyjtpgjdt',
        canActivate: [AppGuard],
        component: CityJyyjtpgjdtComponent,
        data: { iid: MAPDIST.jyyjtpgjdt._iid, rid: MAPDIST.jyyjtpgjdt.rid, animation: 'ThreePage', title: MAPDIST.jyyjtpgjdt.ptitle, keep: true } },
      {
        path: 'jyyjtpgjdt/:index',
        canActivate: [AppGuard],
        component: CityJyyjtpgjdtMapComponent,
        data: { iid: MAPDIST.jyyjtpgjdt._iid, rid: MAPDIST.jyyjtpgjdt.rid, animation: 'FourPage', title: '地图模式 - ' + MAPDIST.jyyjtpgjdt.ptitle, keep: true } },
      {
        path: 'shjysswjbjscgdt',
        canActivate: [AppGuard],
        component: CityShjysswjbjscgdtComponent,
        data: { iid: MAPDIST.shjysswjbjscgdt._iid, rid: MAPDIST.shjysswjbjscgdt.rid, animation: 'ThreePage', title: MAPDIST.shjysswjbjscgdt.ptitle, keep: true } },
      {
        path: 'shjysswjbjscgdt/:id',
        canActivate: [AppGuard],
        component: CityShjysswjbjscgdtDetailComponent,
        data: { iid: MAPDIST.shjysswjbjscgdt._iid, rid: MAPDIST.shjysswjbjscgdt.rid, animation: 'SixPage', title: '详情' + MAPDIST.shjysswjbjscgdt.ptitle } },
    ]
  },
  // 46
  {
    path: 'life',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard], component: LifeIndexComponent, pathMatch:  'full', data: { animation: 'TwoPage', title: '教育让人生更出彩' + PAGESUBTITLE } },
      {
        path: 'ty',
        canActivate: [AppGuard],
        component: LifeTuoyuListComponent,
        data: {
          iid: MAPDIST.ty._iid,
          rid: MAPDIST.ty.rid,
          animation: 'ThreePage',
          title: MAPDIST.ty.ptitle,
          keep: true
        }
      },
      { path: 'jmkhxx', canActivate: [AppGuard], component: LifeJmkhxxComponent, data: { iid: MAPDIST.jmkhxx._iid, rid: MAPDIST.jmkhxx.rid, animation: 'ThreePage', title: MAPDIST.jmkhxx.ptitle, keep: true } },
      { path: 'jqczjsgcxx', canActivate: [AppGuard], component: LifeJqczjsgcxxComponent, data: { iid: MAPDIST.jqczjsgcxx._iid, rid: MAPDIST.jqczjsgcxx.rid, animation: 'ThreePage', title: MAPDIST.jqczjsgcxx.ptitle, keep: true } },
      { path: 'tsgz', canActivate: [AppGuard], component: LifeTsgzComponent, data: { iid: MAPDIST.tsgz._iid, rid: MAPDIST.tsgz.rid, animation: 'ThreePage', title: MAPDIST.tsgz.ptitle } },
      { path: 'tsgz/:dcode', canActivate: [AppGuard], component: LifeTsgzDistrictComponent, data: { animation: 'FourPage', title: '区图 - ' + MAPDIST.tsgz.ptitle } },
      { path: 'gfgyxk', canActivate: [AppGuard], component: LifeGfgyxkComponent, data: { iid: MAPDIST.gfgyxk._iid, rid: MAPDIST.gfgyxk.rid,  animation: 'ThreePage', title: MAPDIST.gfgyxk.ptitle, keep: true } },
      { path: 'xqhjth', canActivate: [AppGuard], component: LifeXqhjthComponent, data: { iid: MAPDIST.xqhjth._iid, rid: MAPDIST.xqhjth.rid, animation: 'ThreePage', title: MAPDIST.xqhjth.ptitle, keep: true } },
      { path: 'xqhjth/:area', canActivate: [AppGuard], component: LifeXqhjthAreaComponent, data: { iid: MAPDIST.xqhjth._iid, rid: MAPDIST.xqhjth.rid, animation: 'FourPage', title: '区图 - ' + MAPDIST.xqhjth.ptitle } },
      { path: 'gjjsylzy', canActivate: [AppGuard], component: LifeGjjsylzyComponent, data: { iid: MAPDIST.gjjsylzy._iid, rid: MAPDIST.gjjsylzy.rid, animation: 'ThreePage', title: MAPDIST.gjjsylzy.ptitle, keep: true } },
      { path: 'lnjyxx', canActivate: [AppGuard], component: LifeLnjyxxComponent, data: { iid: MAPDIST.lnjyxx._iid, rid: MAPDIST.lnjyxx.rid, animation: 'ThreePage', title: MAPDIST.lnjyxx.ptitle, keep: true } },
      { path: 'zjwpzy', canActivate: [AppGuard], component: LifeZjwpzyComponent, data: { iid: MAPDIST.zjwpzy._iid, rid: MAPDIST.zjwpzy.rid, animation: 'ThreePage', title: MAPDIST.zjwpzy.ptitle, keep: true } },
      { path: 'shstsjyxx', canActivate: [AppGuard], component: LifeShstsjyxxComponent, data: { iid: MAPDIST.shstsjyxx._iid, rid: MAPDIST.shstsjyxx.rid, animation: 'ThreePage', title: MAPDIST.shstsjyxx.ptitle } },
      { path: 'sjjndsbzxx', canActivate: [AppGuard], component: LifeSjjndsbzxxComponent, data: { iid: MAPDIST.sjjndsbzxx._iid, rid: MAPDIST.sjjndsbzxx.rid, animation: 'ThreePage', title: MAPDIST.sjjndsbzxx.ptitle, keep: true } },
      { path: 'wdxsyslm', canActivate: [AppGuard], component: LifeWdxsyslmComponent, data: { iid: MAPDIST.wdxsyslm._iid, rid: MAPDIST.wdxsyslm.rid, animation: 'ThreePage', title: MAPDIST.wdxsyslm.ptitle, keep: true } },
      { path: 'zgzgtzbgt', canActivate: [AppGuard], component: LifeZgzgtzbgtComponent, data: { iid: MAPDIST.zgzgtzbgt._iid, rid: MAPDIST.zgzgtzbgt.rid, animation: 'ThreePage', title: MAPDIST.zgzgtzbgt.ptitle, keep: true } },
      { path: 'zgzgtzbgt/:type/:act/:id', canActivate: [AppGuard], component: LifeZbmapComponent, data: { iid: MAPDIST.zgzgtzbgt._iid, rid: MAPDIST.zgzgtzbgt.rid, animation: 'FourPage', title: '地图模式 - ' + MAPDIST.zgzgtzbgt.ptitle } },
      { path: 'smzsxxjdxx', canActivate: [AppGuard], component: LifeSmzsxxjdxxComponent, data: { iid: MAPDIST.smzsxxjdxx._iid, rid: MAPDIST.smzsxxjdxx.rid, animation: 'ThreePage', title: MAPDIST.smzsxxjdxx.ptitle, keep: true } },
      { path: 'zyjykfsxzx', canActivate: [AppGuard], component: LifeZyjykfsxzxComponent, data: { iid: MAPDIST.zyjykfsxzx._iid, rid: MAPDIST.zyjykfsxzx.rid, animation: 'ThreePage', title: MAPDIST.zyjykfsxzx.ptitle, keep: true } },
      { path: 'gqjyzyfb', canActivate: [AppGuard], component: LifeGqjyzyfbComponent, data: { iid: MAPDIST.gqjyzyfb._iid, rid: MAPDIST.gqjyzyfb.rid, animation: 'ThreePage', title: MAPDIST.gqjyzyfb.ptitle } },
      { path: 'jcjyzyfb', canActivate: [AppGuard], component: LifeJcjyzyfbComponent, data: { iid: MAPDIST.jcjyzyfb._iid, rid: MAPDIST.jcjyzyfb.rid,  animation: 'ThreePage', title: MAPDIST.jcjyzyfb.ptitle } },
      { path: 'jmkyey', canActivate: [AppGuard], component: LifeJmkyeyComponent, data: { iid: MAPDIST.jmkyey._iid, rid: MAPDIST.jmkyey.rid, animation: 'ThreePage', title: MAPDIST.jmkyey.ptitle, keep: true } },
      { path: 'tjzdzx', canActivate: [AppGuard], component: LifeTjzdzxListComponent, data: { iid: MAPDIST.tjzdzx._iid, rid: MAPDIST.tjzdzx.rid, animation: 'ThreePage', title: MAPDIST.tjzdzx.ptitle, keep: true } },
    ]
  },
  // 69
  {
    path: 'future',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard],
        component: FutureIndexComponent,
        pathMatch:  'full', data: { animation: 'TwoPage', title: '教育让未来更可期' + PAGESUBTITLE }
      },
      {
        path: 'qsnkxsjgzz',
        canActivate: [AppGuard],
        component: FutureQsnkxsjgzzComponent,
        data: { iid: MAPDIST.qsnkxsjgzz._iid,
          rid: MAPDIST.qsnkxsjgzz.rid, animation: 'ThreePage', title: MAPDIST.qsnkxsjgzz.ptitle, keep: true }
      },
      {
        path: 'qjzxxsxljksfzx',
        canActivate: [AppGuard],
        component: FutureQjzxxsxljksfzxComponent,
        data: {
          iid: MAPDIST.qjzxxsxljksfzx._iid,
          rid: MAPDIST.qjzxxsxljksfzx.rid,
          animation: 'ThreePage',
          title: MAPDIST.qjzxxsxljksfzx.ptitle
        }
      },
      {
        path: 'qgjtjycxsjjd',
        canActivate: [AppGuard],
        component: FutureQgjtjycxsjjdComponent,
        data: { iid: MAPDIST.qgjtjycxsjjd._iid,
          rid: MAPDIST.qgjtjycxsjjd.rid, animation: 'ThreePage', title: MAPDIST.qgjtjycxsjjd.ptitle }
      },
      {
        path: 'sedshsjjd',
        canActivate: [AppGuard],
        component: FutureSedshsjjdComponent,
        data: { iid: MAPDIST.sedshsjjd._iid,
          rid: MAPDIST.sedshsjjd.rid, animation: 'ThreePage', title: MAPDIST.sedshsjjd.ptitle, keep: true }
      },
      {
        path: 'sedshsjjdbig',
        canActivate: [AppGuard],
        component: FutureSedshsjjdBigComponent,
        data: { iid: MAPDIST.sedshsjjd._iid, rid: MAPDIST.sedshsjjd.rid, animation: 'ThreePage', title: MAPDIST.sedshsjjd.ptitle, keep: true }
      },
    ]
  },

  // *************2024 start**************
  {
    path: 'science',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [AppGuard],
        component: ScienceIndexComponent,
        pathMatch:  'full',
        data: { animation: 'TwoPage', title: '教育让人生更出彩' + PAGESUBTITLE }
      },
      {
        path: 'science_shsx',
        canActivate: [AppGuard],
        component: ScienceKeChuangListComponent,
        data: {
          iid: MAPDIST.science_shsx._iid,
          rid: MAPDIST.science_shsx.rid,
          animation: 'ThreePage',
          title: MAPDIST.science_shsx.ptitle,
          keep: true
        }
      },
      {
        path: 'science_gdjy', // path: 'gdjydjgjzdzlzdzy',
        // path: 'gdjydjgjzdzlzdzy', // path: 'science_gdjy',
        canActivate: [AppGuard],
        // component: ScienceGaoDengListComponent,
        component: CityGjgjzlzdzyComponent,
        // data: {
        //   iid: MAPDIST.science_gdjy._iid,
        //   rid: MAPDIST.science_gdjy.rid,
        //   animation: 'ThreePage',
        //   title: MAPDIST.science_gdjy.ptitle,
        //   keep: true
        // }
        data: {
          iid: MAPDIST.gdjydjgjzdzlzdzy._iid,
          rid: MAPDIST.gdjydjgjzdzlzdzy.rid,
          animation: 'ThreePage',
          title: MAPDIST.gdjydjgjzdzlzdzy.ptitle,
          keep: true
        }
      },
      {
        path: 'science_zyjy',
        // canActivate: [AppGuard],
        // component: ScienceZhiYeJiaoYuListComponent,
        // data: {
        //   iid: MAPDIST.science_zyjy._iid,
        //   rid: MAPDIST.science_zyjy.rid,
        //   animation: 'ThreePage',
        //   title: MAPDIST.science_zyjy.ptitle,
        //   keep: true
        // }
        // path: 'zyjydjwdzx',
        canActivate: [AppGuard],
        component: CityZyjydjwdzxComponent,
        data: {
          iid: MAPDIST.zyjydjwdzx._iid,
          rid: MAPDIST.zyjydjwdzx.rid,
          animation: 'ThreePage',
          title: MAPDIST.zyjydjwdzx.ptitle,
          keep: true
        }
      },
      {
        path: 'science_kxjj',
        // canActivate: [AppGuard],
        // component: ScienceKeXueJiaListComponent,
        // data: {
        //   iid: MAPDIST.science_kxjj._iid,
        //   rid: MAPDIST.science_kxjj.rid,
        //   animation: 'ThreePage',
        //   title: MAPDIST.science_kxjj.ptitle,
        //   keep: true
        // }

        // path: '204',
        canActivate: [AppGuard],
        component: NewKxjjsjyjddtComponent,
        data: {
          iid: MAPDIST.newkxjjsjyjddt._iid,
          rid: MAPDIST.newkxjjsjyjddt.rid,
          animation: 'TwoPage',
          title: MAPDIST.newkxjjsjyjddt.ptitle,
          keep: true
        }
      },
      {
        path: 'science_shsq',
        // canActivate: [AppGuard],
        // component: ScienceKeXueShiJianListComponent,
        // data: {
        //   iid: MAPDIST.science_shsq._iid,
        //   rid: MAPDIST.science_shsq.rid,
        //   animation: 'ThreePage',
        //   title: MAPDIST.science_shsq.ptitle,
        //   keep: true
        // }
        //
        // path: 'qsnkxsjgzz',
        canActivate: [AppGuard],
        component: FutureQsnkxsjgzzComponent,
        data: { iid: MAPDIST.qsnkxsjgzz._iid,
          rid: MAPDIST.qsnkxsjgzz.rid, animation: 'ThreePage', title: MAPDIST.qsnkxsjgzz.ptitle, keep: true }
      },
      {
        path: 'science_shkp',
        canActivate: [AppGuard],
        component: ScienceKePuJiaoYuListComponent,
        data: {
          iid: MAPDIST.science_shkp._iid,
          rid: MAPDIST.science_shkp.rid,
          animation: 'ThreePage',
          title: MAPDIST.science_shkp.ptitle,
          keep: true
        }
      },
      {
        path: 'science_qgzx',
        canActivate: [AppGuard],
        component: ScienceZhongXiaoXueListComponent,
        data: {
          iid: MAPDIST.science_qgzx._iid,
          rid: MAPDIST.science_qgzx.rid,
          animation: 'ThreePage',
          title: MAPDIST.science_qgzx.ptitle,
          keep: true
        }
      },
      {
        path: 'science_shsk',
        canActivate: [AppGuard],
        component: ScienceShiFanListComponent,
        data: {
          iid: MAPDIST.science_shsk._iid,
          rid: MAPDIST.science_shsk.rid,
          animation: 'ThreePage',
          title: MAPDIST.science_shsk.ptitle,
          keep: true
        }
      },
      {
        path: 'science_gjzx',
        canActivate: [AppGuard],
        component: ScienceRenGongZhiNengListComponent,
        data: {
          iid: MAPDIST.science_gjzx._iid,
          rid: MAPDIST.science_gjzx.rid,
          animation: 'ThreePage',
          title: MAPDIST.science_gjzx.ptitle,
          keep: true
        }
      }
    ]
  },
  {
    path: 'science/detail/:model/:selid',
    canActivate: [AppGuard],
    component: ScienceDetailComponent,
    pathMatch: 'full',
    data: { animation: 'FivePage', title: '' + PAGESUBTITLE, titleI18n: '统一详情模版' }
  },
  {
    path: 'science/pmap/:model/:selid',
    canActivate: [AppGuard],
    component: SciencePmapComponent, data: { animation: 'ThreePage', title: '地图模式' + PAGESUBTITLE }
  },
  // {
  //   path: 'science/subpmap/:model/:swlab/:selid',
  //   canActivate: [AppGuard],
  //   component: NewSubpmapComponent, data: { animation: 'ThreePage', title: '地图模式' + PAGESUBTITLE }
  // },


  // {
  //   path: 'edu',
  //   canActivate: [AppGuard],
  //   children: [
  //     {
  //       path: '',
  //       canActivate: [AppGuard],
  //       component: EduIndexComponent,
  //       pathMatch:  'full',
  //       data: { animation: 'TwoPage', title: '教育让人生更出彩' + PAGESUBTITLE }
  //     },
  //     {
  //       path: 'edu_shsj',
  //       canActivate: [AppGuard],
  //       component: EduXinXiHuaListComponent,
  //       data: {
  //         iid: MAPDIST.edu_shsj._iid,
  //         rid: MAPDIST.edu_shsj.rid,
  //         animation: 'ThreePage',
  //         title: MAPDIST.edu_shsj.ptitle,
  //         keep: true
  //       }
  //     },
  //     {
  //       path: 'edu_gdjy',
  //       canActivate: [AppGuard],
  //       component: EduGaoDengJiaoYuListComponent,
  //       data: {
  //         iid: MAPDIST.edu_gdjy._iid,
  //         rid: MAPDIST.edu_gdjy.rid,
  //         animation: 'ThreePage',
  //         title: MAPDIST.edu_gdjy.ptitle,
  //         keep: true
  //       }
  //     },
  //     {
  //       path: 'edu_shgx',
  //       canActivate: [AppGuard],
  //       component: EduShangHaiGaoXiaoListComponent,
  //       data: {
  //         iid: MAPDIST.edu_shgx._iid,
  //         rid: MAPDIST.edu_shgx.rid,
  //         animation: 'ThreePage',
  //         title: MAPDIST.edu_shgx.ptitle,
  //         keep: true
  //       }
  //     },
  //     {
  //       path: 'edu_shzy',
  //       canActivate: [AppGuard],
  //       component: EduXuNiFangZhenListComponent,
  //       data: {
  //         iid: MAPDIST.edu_shzy._iid,
  //         rid: MAPDIST.edu_shzy.rid,
  //         animation: 'ThreePage',
  //         title: MAPDIST.edu_shzy.ptitle,
  //         keep: true
  //       }
  //     },
  //   ]
  // },
  // *************2024 end*************

  // 74
  { path: 'pmap/:model/:selid', canActivate: [AppGuard], component: PmapComponent, pathMatch: 'full', data: { animation: 'FivePage', title: '' + PAGESUBTITLE, titleI18n: '统一地图模版', keep: true } },
  { path: 'subpmap/:model/:swlab/:selid', canActivate: [AppGuard], component: SubpmapComponent, pathMatch: 'full', data: { animation: 'FivePage', title: '' + PAGESUBTITLE, titleI18n: '2统一地图模版' } },
  { path: 'detail/:model/:selid', canActivate: [AppGuard], component: DetailComponent, pathMatch: 'full', data: { animation: 'SixPage', title: '' + PAGESUBTITLE, titleI18n: '统一详情模版' } },
  { path: 'tmp', component: TmpComponent, pathMatch: 'full', data: {title: 'debug' + PAGESUBTITLE} },
  { path: 'todo', component: BuilderComponent, pathMatch: 'full', data: {title: '系统建设中' + PAGESUBTITLE} },
  { path: 'error', component: ErrorComponent, pathMatch: 'full', data: { title: '页面走丢了' + PAGESUBTITLE, titleI18n: '统一错误处理' } },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];
//  80

@NgModule({
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
