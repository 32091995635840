<app-rback [outputEvent]="true" (bindOutputEvent)="bindOutputEvent()" theme="i-rect"></app-rback>
<div class="hh-wrapper" *ngIf="row.show">
  <div class="body"> 
    <div class="lunbo-group" *ngIf="row.files.length > 0">
      <div class="lgbg" [ngClass]="{'isMobile': isd}"></div>
      <div class="swiper" (click)="toFullView()">
        <app-swiper 
          #swipercomponent1
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          [isShowPager]='!isd'
          (bindchange)="bindchange($event)">
            <app-swiper-item *ngFor="let item of row.files">  
              <img [src]="UROOT + item + '?x-oss-process=image/resize,m_fill,w_' + lbw + ',h_' + lbh + ',limit_0'"/>   
            </app-swiper-item>
        </app-swiper> 
      </div>

      <div class="docs-wrapper">
        <div class="docs-group">
          <div class="doc-item active" >图片 {{filei}}/{{row.files.length}}</div>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="title-tags-group">
        <div class="title">{{row.name}}</div>  
      </div>
         
      <div class="map-phone-btn">
        <div class="left-mp">
          <div class="lmcontent">
            <div class="infoitem" (click)="goBack()">
              <img src="/assets/imgs/idmap.png"/>
              <div class="txt">{{row.district}} {{row.address ? ' - ' + row.address:''}}</div>
            </div>
            <div class="infoitem" *ngIf="false">
              <img src="/assets/imgs/idtel.png"/>
              <div class="txt">021 - 98477640</div>
            </div>
          </div>
        </div>
    
      </div>

      <div class="gapr"></div>
      <div class="intro-group">
       
          <div class="subject-group" *ngFor="let item of row.pjnames; let ri=index">
            <div class="label">
              <div class="ltxt">{{item.pjname}}</div> 
              <div class="like-group" *ngIf="uid > 0" (click)="liked(ri, item.id)">
                <img [src]="'/assets/imgs/common/like' + (item.isLiked?'d':'') + '.png'" />
              </div>
            </div>
            <div class="txt" >{{item.intro}}</div>
            <div class="txt" [innerHtml]="item.richintro">  </div>
          </div> 
        
      </div>

    </div>

  </div>
</div>
    