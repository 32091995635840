import { Component, OnInit, AfterViewChecked, NgZone } from '@angular/core';
import BScroll from '@better-scroll/core';
import Zoom from '@better-scroll/zoom';

BScroll.use(Zoom);

@Component({
  selector: 'app-zoomer',
  templateUrl: './zoomer.component.html',
  styleUrls: ['./zoomer.component.styl']
})
export class ZoomerComponent implements OnInit, AfterViewChecked {

  sliderTimer: any;
  zoomer: any;

  constructor(
    private ngZone: NgZone
  ) { }

  ngOnInit() { }

  ngAfterViewChecked(): void {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.sliderTimer);
      this.sliderTimer = setTimeout(() => {
        this.loadZoomer();
      }, 20);
    });
  }

  loadZoomer() {
    if (this.zoomer) {
      this.zoomer.refresh();
      return;
    }

    this.zoomer = new BScroll('#bs-refl', {
      freeScroll: true,
      scrollX: true,
      scrollY: true,
      useTransition: false, // true 会使右边 闪现
      zoom: {
        start: 1,
        min: 1,
        max: 3
      },
      click: true,

      disableMouse: false,
      disableTouch: false // PC 默认不支持 touch
    });
  }
}
