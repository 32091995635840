import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { XMapService } from 'src/app/shared/services';
import { mapOpenCloseAnimation } from 'src/app/animations';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-city-jyyjtpgjdt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.styl'],
  animations: [mapOpenCloseAnimation]
})
export class CityJyyjtpgjdtMapComponent implements OnInit {
  isStartMarker = false;
  row: any;
  rows  = [];
  infos = [];

  centerlatlng = [31.230355, 121.473710];
  map = null;
  migration = null;
  selMarker = null;

  bicon: any;
  biconed: any;

  index: number;

  xdcenters = [
    [32.953368, 110.522461], // 青海果洛
    [31.877558, 109.160156], // 新疆喀什
    [31.877558, 109.160156], // 西藏日喀则
    [27.566721, 109.995117], // 云南昆明
  ];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.index = +this.route.snapshot.paramMap.get('index');

    this.http.get(MAPDIST.jyyjtpgjdt.api + '?st=134r321').toPromise().then((rows: any) => {
      this.rows = rows;
      if (this.index > -1) {
        for (let i = 0; i < rows.length; i++) {
          if (i === this.index) {
            this.row = rows[i];
            break;
          }
        }
      }

      this.xmapservice.loadQqMapJs(() => {
        setTimeout(() => {
          this.loadFullMap();
        }, 400);
      });
    });

  }

  loadFullMap() {
    const size  = new window.qq.maps.Size(50, 57);
    const pspp  = new window.qq.maps.Point(0, -5);
    this.bicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, pspp, null, size);
    this.biconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, pspp, null, size);

    const sw = new window.qq.maps.LatLng(62.512318, 140.976563); // 东北角坐标
    const ne = new window.qq.maps.LatLng(-0.878872, 66.445313); // 西南角坐标
    let cclatlng = this.xdcenters[this.index];
    if (!cclatlng) { cclatlng = [30.581179, 105.600586]; }
    const center = new window.qq.maps.LatLng(cclatlng[0], cclatlng[1]);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 4,
      center,
      minZoom: 3,
      boundary: new window.qq.maps.LatLngBounds(ne, sw),
    }));

    this.migration = new window.qq.maps.visualization.Migration({
      map: this.map,
      stylePicker: (data) => {
        return {
          color: '#ff8b63', // "rgba(0, 0, 255, .3)",
          width: 2
        };
      }
    });
    this.migrationData();

    // 起点
    const centerlatlng = new window.qq.maps.LatLng(this.centerlatlng[0], this.centerlatlng[1]);
    const marker = new window.qq.maps.Marker({
      position: centerlatlng,
      icon: this.bicon,
      map: this.map,
      bicon: this.bicon,
      biconed: this.biconed
    });
    window.qq.maps.event.addListener(marker, 'click', () => {
      this.map.panTo(centerlatlng);
      if (this.index > -1) {
        this.infos = [this.row];
      }
      if (this.index === -1) {
        this.infos = this.rows;
      }
      this.isStartMarker = true;

      this.openMarker(marker);
      this.cdr.detectChanges();
    });
  }

  migrationData() {
    const nData = [];
    const rows = this.rows;
    for (let i = 0; i < rows.length; i++) {
      if (this.index > -1 && this.index !== i) { continue; }

      const row = rows[i];
      const location = row.location.split(',');
      nData.push({
        from: {lat: this.centerlatlng[0], lng: this.centerlatlng[1] }, // label: 上海
        to:   {lat: location[0], lng: location[1] }  // label:
      });

      // 终点
      const locationlatlng = new window.qq.maps.LatLng(location[0], location[1]);
      const marker = new window.qq.maps.Marker({
        position: locationlatlng,
        icon: this.bicon,
        map: this.map,
        bicon: this.bicon,
        biconed: this.biconed
      });
      window.qq.maps.event.addListener(marker, 'click', () => {
        this.isStartMarker = false;
        this.infos = [row];
        this.openMarker(marker);
        this.cdr.detectChanges();
      });

      if (this.index > -1 && false) {
        this.infos = [row];
        this.cdr.detectChanges();
      }
    }

    this.migration.setData(nData);
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

  closePopup() {
    this.infos = [];
    this.closeMarker();
  }
}
