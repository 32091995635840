import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService } from 'src/app/dialog';

export interface ModalObj {
  title: string;
  content: string;
  showLeft?: boolean;
  leftText?: string;
  leftColor?: string;
  rightText?: string;
  rightColor?: string;
  bgcolor?: string;
  leftFunc?: () => any;
  rightFunc?: () => any;
  completeFunc?: () => any;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit {

  @Input() modalObj: ModalObj;
  @Output()  bindleftEvent = new EventEmitter<any>();
  @Output()  bindrightEvent = new EventEmitter<any>();
  @Output()  bindbgEvent = new EventEmitter<any>();

  constructor(private dialogServce: DialogService) { }

  ngOnInit() {
    this.modalObj.bgcolor = '#000000';
  }

  leftClickEvent()  {
    this.dialogServce.close();
    this.bindleftEvent.emit(true);
    // this.modalObj.leftFunc()
    // this.modalObj.completeFunc()
  }
  rightClickEvent() {
    this.dialogServce.close();
    this.bindrightEvent.emit(true);

    // this.modalObj.rightFunc()
    // this.modalObj.completeFunc()
  }

  bgClickEvent(){
    this.dialogServce.close();
    this.bindbgEvent.emit(true);
  }
}
