<app-rback [type]="1"></app-rback>
<!-- <app-guide [pici]="0"></app-guide> -->
<app-xtheme [theme]="'edu'">
    <app-xtheme-hh>
        <div class="list-map-popup" [@openClose]="listModeShow ? 'open' : 'closed'">
            <div class="lmp-close-group" (click)="listModeShow = false">
                <img src="/assets/imgs/main/close.png"/>
            </div>
            <div class="lmp-body">
                <div class="theme-group bg-{{theme.id}}" *ngFor="let theme of listRoutes">
                    <div class="theme-title" (click)="goto('/' + theme.id)">
                        <div class="ttname">{{theme.name}}</div>
                    </div>
                    <div class="topic-group">
                        <div class="topic-item" *ngFor="let topic of theme.subs">
                            <div class="topic-title" (click)="goto('/' + theme.id)">
                                <div class="tgname">{{topic.label}}</div>
                            </div>
                            <div class="routes-group">
                                <div class="rgitem" *ngFor="let item of topic.subs" (click)="goto(item.url)">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-map-popup vlist-map-popup" [@openClose]="listVRoomShow ? 'open' : 'closed'">
            <div class="lmp-close-group" (click)="listVRoomShow = false">
                <img src="/assets/imgs/main/close.png"/>
            </div>
            <div class="lmp-body">
                <ng-container *ngFor="let topic of listVRooms">
                    <div class="theme-group bg-{{topic.color}}"  *ngIf="!topic.disable">
                        <div class="theme-title">
                            <div class="ttname" [ngStyle]="{'text-indent':topic.id===0?'0':'0.5'}">{{topic.name}}</div>
                        </div>
                        <div class="topic-group">
                            <div class="topic-item">
                                <div class="routes-group">
                                    <div class="rgitem" *ngFor="let item of topic.childs" (click)="gotoVv(item.vcode)">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="hh-body">
            <div class="hh-logo-group">
                <img class="hh-logo" src="/assets/imgs/new/i-h-logo.png?i=0928" /> 

                <div class="popup-list-btn" (click)="listModeShow = true">
                    <img src="/assets/imgs/main/listbtn.png"/>
                </div>

                <!-- <div class="popup-list-btn vlistbtn" (click)="showlistVRoomShow()">
                    <img src="/assets/imgs/main/vlistbtn.png"/>
                </div> -->
            </div>
            <div class="map-group" >
                <div class="map-svg-container">
                    <app-hzoom>
                        <div class="map-svg-dom" #mapSvgContainerRef ></div>
                    </app-hzoom>
                </div>

                <div class="map-popup-group" *ngIf="popup.show&&false" [ngStyle]="{'top': popup.hh.y, 'left': popup.hh.x}">
                    <div class="main-group">
                        <div class="mg-content">
                            <div class="mgitem active" *ngFor="let item of navs; let i = index" [ngClass]="{'active': i === navi }" (click)="ccNavi(i)">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="main-sub-group">
                        <div class="mgitem" *ngFor="let item of navs[navi].items;" (click)="gotoVv(item.vcode)">{{item.name}}</div>
                    </div>
                </div>
            </div>
            <div class="btn-group">
                <div class="img-btn isubtitle"><img src="/assets/imgs/main/edu-title.png"/></div>
                <div class="img-btn width80" (click)="toNext('city')"><img src="/assets/imgs/main/city-title.png"/></div>
                <div class="img-btn width80" (click)="toNext('life')"><img src="/assets/imgs/main/life-title-2.png"/></div>
                <div class="img-btn width80" (click)="toNext('future')"><img src="/assets/imgs/main/future-title.png"/></div>
            </div>
        </div>
    </app-xtheme-hh>
    <app-xtheme-vv>
        <div class="vv-list-map-popup" [@openClose]="listModeShow ? 'open' : 'closed'">
            <div class="lmp-close-group" (click)="listModeShow = false">
                <img src="/assets/imgs/main/close.png"/>
            </div>
            <div class="vvlmp-body">
                <div class="vvlmp left-vvlmp">
                    <div class="vvlmp-content">
                        <ng-container *ngFor="let theme of listRoutes;let i=index">
                            <div class="theme-group bg-{{theme.id}}" *ngIf="i!==1">
                                <div class="theme-title" (click)="goto('/' + theme.id)">
                                    <div class="ttname" >{{theme.name}}</div>
                                </div>
                                <div class="topic-group">
                                    <div class="topic-item" *ngFor="let topic of theme.subs">
                                        <div class="topic-title" (click)="goto('/' + theme.id)">
                                            <div class="tgname">{{topic.label}}</div>
                                        </div>
                                        <div class="routes-group" [ngClass]="{'rgone': topic.subs.length === 1}">
                                            <div class="rgitem" *ngFor="let item of topic.subs" (click)="goto(item.url)">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="vvlmp right-vvlmp">
                    <div class="vvlmp-content">
                        <ng-container *ngFor="let theme of listRoutes;let i=index">
                            <div class="theme-group bg-{{theme.id}}" *ngIf="i===1">
                                <div class="theme-title" (click)="goto('/' + theme.id)">
                                    <div class="ttname">{{theme.name}}</div>
                                </div>
                                <div class="topic-group">
                                    <div class="topic-item" *ngFor="let topic of theme.subs">
                                        <div class="topic-title" (click)="goto('/' + theme.id)">
                                            <div class="tgname">{{topic.label}}</div>
                                        </div>
                                        <div class="routes-group" [ngClass]="{'rgone': topic.subs.length === 1}">
                                            <div class="rgitem" *ngFor="let item of topic.subs" (click)="goto(item.url)">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="vv-list-map-popup vlist-map-popup" [@openClose]="listVRoomShow ? 'open' : 'closed'">
            <div class="lmp-close-group" (click)="listVRoomShow = false">
                <img src="/assets/imgs/main/close.png"/>
            </div>
            <div class="vvlmp-body">
                <div class="vvlmp left-vvlmp">
                    <div class="vvlmp-content">
                        <ng-container *ngFor="let topic of listVRooms;let i=index">
                            <div class="theme-group bg-{{topic.color}}" *ngIf="i<3">
                                <div class="theme-title">
                                    <div class="ttname" [ngStyle]="{'text-indent':topic.id===0?'0':'0.5'}">{{topic.name}}</div>
                                </div>
                                <div class="topic-group">
                                    <div class="topic-item">
                                        <div class="routes-group" [ngClass]="{'rgone': topic.childs.length === 1}">
                                            <div class="rgitem" *ngFor="let item of topic.childs" (click)="gotoVv(item.vcode)">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="vvlmp right-vvlmp">
                    <div class="vvlmp-content">
                        <ng-container *ngFor="let topic of listVRooms;let i=index">
                            <div class="theme-group bg-{{topic.color}}" *ngIf="!topic.disable && i>2">
                                <div class="theme-title">
                                    <div class="ttname">{{topic.name}}</div>
                                </div>
                                <div class="topic-group">
                                    <div class="topic-item">
                                        <div class="routes-group" [ngClass]="{'rgone': topic.childs.length === 1}">
                                            <div class="rgitem" *ngFor="let item of topic.childs" (click)="gotoVv(item.vcode)">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-wrapper map-group">
            <div class="popup-list-btn" (click)="listModeShow = true">
                <img src="/assets/imgs/main/listbtn.png"/>
            </div>
            <!-- <div class="popup-list-btn vlistbtn" (click)="showlistVRoomShow()">
                <img src="/assets/imgs/main/vlistbtn.png"/>
            </div> -->
            <div class="map-svg-container">
                <app-zoomer>
                    <div class="map-svg-dom" #mapSvgContainerRefV ></div>
                </app-zoomer>
            </div>
            <div class="map-popup-group" *ngIf="popup.show" [ngStyle]="{'top': popup.vv.y, 'left': popup.vv.x}">
                <div class="main-group">
                    <div class="mg-content">
                        <div class="mgitem active" *ngFor="let item of navs; let i = index" [ngClass]="{'active': i === navi }" (click)="ccNavi(i)">{{item.name}}</div>
                    </div>
                </div>
                <div class="main-sub-group">
                    <div class="mgitem" *ngFor="let item of navs[navi].items;" (click)="gotoVv(item.vcode)">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="right-wrapper">
            <img class="logo" src="/assets/imgs/new/i-v-logo.png?i=0928" />
            <div class="btn-group">
                <div class="img-btn isubtitle"><img src="/assets/imgs/main/edu-title.png"/></div>
                <div class="img-btn width80" (click)="toNext('city')"><img src="/assets/imgs/main/city-title.png"/></div>
                <div class="img-btn width80" (click)="toNext('life')"><img src="/assets/imgs/main/life-title-2.png"/></div>
                <div class="img-btn width80" (click)="toNext('future')"><img src="/assets/imgs/main/future-title.png"/></div>
            </div>
        </div>
    </app-xtheme-vv>
</app-xtheme>
