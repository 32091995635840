import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-24_smzsxxjdxx',
  templateUrl: './24_smzsxxjdxx.component.html',
  styleUrls: ['./24_smzsxxjdxx.component.styl']
})
export class LifeSmzsxxjdxxComponent implements OnInit {


  ttitle = '';

  data: any[] = [];

  // profs: string[] = ["红色文化体验基地","科普教育体验基地","文化艺术体验基地","海派文化体验基地","智慧生活体验基地","服饰文化体验基地","创意手工体验基地","乒乓文化体验基地","南湖革命传统教育体验基地","巧克力文化体验基地","陶艺创作体验基地"]
  profs: string[] = ['红色文化体验基地', '科普教育体验基地', '文化艺术体验基地', '海派文化体验基地', '智慧生活体验基地', '服饰文化体验基地', '创意手工体验基地', '乒乓文化体验基地', '南湖革命传统教育体验基地', '巧克力文化体验基地', '非遗传承体验基地', '长三角市民终身学习体验站点'];
  seli = 0;
  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.smzsxxjdxx.title;
    this.http.get(MAPDIST.smzsxxjdxx.api).toPromise().then((data: any) => {
      this.data = data;
      // let tps = []
      // for(let i=0;i<data.length;i++) {
      //   if (!tps.includes(data[i].type)) tps.push(data[i].type);
      // }
      // console.log(JSON.stringify(tps))
      this.selied();
    });
  }

  preProf() {
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.profs.length - 1; }

    this.selied();
  }

  nextProf() {
    this.seli += 1;
    if (this.seli === this.profs.length) { this.seli = 0; }

    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.profs[this.seli] != item.type) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/subpmap/smzsxxjdxx/' + this.seli + '/' + i);
  }

}
