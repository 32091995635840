import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-205_newshsjyxxhyybgpyxdt',
  templateUrl: './205_newshsjyxxhyybgpyxdt.component.html',
  styleUrls: ['./205_newshsjyxxhyybgpyxdt.component.styl']
})
export class NewShsjyxxhyybgpyxdtComponent implements OnInit {
  ttitle = '';

  data: any = [];
  rows: any = [];

  typei = 1;
  types: string[] = ['', ''];

  constructor(
    private util: UtilsService,
    private http: HttpClient,
    private router: Router  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshsjyxxhyybgpyxdt.title;
    this.http.get(MAPDIST.newshsjyxxhyybgpyxdt.api).toPromise().then((rows: any) => {
      this.data = rows;
      const types = [];
      for (const row of rows) {
        if (types.includes(row.type)) { continue; }

        types.push(row.type);
      }
      this.types = types;
      this.getRows();
    });
  }

  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }

    this.getRows();
  }

  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }

    this.getRows();
  }
  getRows() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      if (this.types[this.typei] === row.type) { rows.push(row); }
    }
    this.rows = rows;
  }
  toView(i: number = -1) {
    if (i != -1 && this.util.isMiniProgram()){
      for (let xi = 0; xi < this.rows.length; xi++) {
        if (this.rows[xi].id == i){
          const xurl = '/pages/index/search/view/view?id=' + this.rows[xi].org_id;
          window.parent.postMessage(`poi:${xurl}`, '*'); // 后面的*号就是处理跨域问题的
          window.wx.miniProgram.navigateTo({
            url: xurl,
          });
          return;
        }
      }
    }
    this.router.navigateByUrl('/new/pmap/newshsjyxxhyybgpyxdt/' + i);
  }
}
