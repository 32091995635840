import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventManager, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { XMapService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-203_newshsxsztyxsjlxdt_map',
  templateUrl: './203_newshsxsztyxsjlxdt_map.component.html',
  styleUrls: ['./203_newshsxsztyxsjlxdt_map.component.styl']
})
export class NewShsxsztyxsjlxdtMapGlComponent implements OnInit {

  constructor(
    private title: Title,
    private http: HttpClient,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager,
  ) { }

  rurl = '';
  model = '';

  deviceMode = null;

  rows = [];
  tmp  = {};

  selMarker = null;

  map       = null;
  infoWin   = {
    open: (fuc: any) => {},
    close: (fuc: any) => {},
    xsetPosition: (latlng: String) => {},
    setContent: (html: String) => {}
  };
  markers   = [];
  markerClusterer = null;

  selid = -1;

  markersObj = {};

  ngOnInit() {
    const model = 'newshsxsztyxsjlxdt';
    const selid = +this.route.snapshot.paramMap.get('selid');
    if (selid > -1) { this.selid = selid; }

    const pObjt = MAPDIST[model];
    this.model  = model;
    this.title.setTitle('地图模式 - ' + pObjt.ptitle);
    this.rurl = pObjt.url;

    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      this.rows = resp;

      this.xmapservice.loadTMapGlJs(() => {
        this.loadFullMap();
      });
    });

    // window['gotoPoint'] = (id: any) => {
    //   let markerObj = this.markersObj[id]
    //   if (markerObj) {
    //     this.map.panTo(markerObj.myLatLng)
    //     this.infoWin.xsetPosition(markerObj.myLatLng)
    //     this.infoWin.setContent(this.getInfoWinHtml(markerObj.obj))
    //     this.infoWin.open(() => {this.openMarker(markerObj.marker)})
    //   }
    // }
  }

  loadFullMap() {
    const sw = new   window.TMap.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new   window.TMap.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new   window.TMap.LatLng(31.240692, 121.501751);
    this.map = new   window.TMap.Map(document.getElementById('tmap-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 11,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new   window.TMap.LatLngBounds(ne, sw)
    }));

    //  window['TMap'].event.addListener(this.map,'click',() => {
    //   this.infoWin.close(() => {this.closeMarker()})
    // })
    //  window['TMap'].event.addListener(this.map,'zoom_changed',() => {
    //   this.infoWin.close(() => {this.closeMarker()})
    // })

    // const MyOverlay = this.xmapservice.initMyNewOverlay()
    // this.infoWin = new MyOverlay({
    //   map: this.map,
    //   position: center,
    //   complete: () => {
    //       this.loadFullMapMarkers()
    //   }
    // })

    this.loadFullMapMarkers();
  }
  loadFullMapMarkers() {
    this.infoWin.close(() => {this.closeMarker(); });

    const markerStyles = {
      marker: new window.TMap.MarkerStyle({
          width: 50,
          height: 57,
          // "anchor": { x: 16, y: 32 },
          src: '/assets/imgs/imed.png'
      }),
      markered: new window.TMap.MarkerStyle({
        width: 50,
        height: 57,
        // "anchor": { x: 16, y: 32 },
        src: '/assets/imgs/im.png'
    })
  };
    // const size = new  window['TMap'].Size(50, 57)
    // let bicon   = new  window['TMap'].MarkerImage('/assets/imgs/imed.png', null, null, null, size)
    // let biconed = new  window['TMap'].MarkerImage('/assets/imgs/im.png', null, null, null, size)

    const markers = [];
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }

    let objF; let myLatLngF; let markerF;
    for (let i = 0; i < this.rows.length; i++) {
      const objs = this.rows[i];
      if (objs.id != this.selid || !objs.location) { continue; }

      for (const obj of objs.points) {
        obj.pObj = JSON.parse(JSON.stringify(objs));

        const objLatLng = obj.location.split(',');
        if (objLatLng.length < 2) { continue; }

        const myLatLng = new   window.TMap.LatLng(objLatLng[0], objLatLng[1]);

         // 初始化marker
        const marker = new window.TMap.MultiMarker({
          id: 'marker-layer',
          map: this.map,
          styles: markerStyles,
          geometries: [{
              id: 'marker-' + obj.id,
              styleId: 'marker',
              position: myLatLng,
              properties: {
                  title: 'marker'
              }
          }]
      });

        this.markersObj[obj.id] = { myLatLng, obj, marker  };
        window.TMap.event.addListener(marker, 'click', () => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
          this.infoWin.setContent(this.getInfoWinHtml(obj));
          this.infoWin.open(() => {this.openMarker(marker); });
        });

        markers.push(marker);

        if (!objF) {
          objF      = obj;
          myLatLngF = myLatLng;
          markerF   = marker;
        }
      }
    }

    // 第一个站点
    this.map.setZoom(14);
    this.infoWin.setContent(this.getInfoWinHtml(objF));
    setTimeout(() => {
      this.map.panTo(myLatLngF);
      this.infoWin.xsetPosition(myLatLngF);
    }, 50);
    this.infoWin.open(() => {this.openMarker(markerF); });

    this.execRoute(objF.pObj.points);

    this.markers = markers;
  }

  execRoute(points) {
    return;
    const plen = points.length;
    for (let i = 0; i < plen; i++) {
      const from: any = points[i].location;
      let to: any   = '';
      if (i == plen - 1) {
        to = points[0].location;
      } else {
        to = points[i + 1].location;
      }

      this.http.get('http://jtest.jsoma.com.cn/hVnkYmLu3xEYqsJx?from=' + from + '&to=' + to).toPromise().then((resp: any) => {
        console.log(resp);
        if (resp.status == 0) {
          // 从结果中取出路线坐标串
          const coors = resp.result.routes[0].polyline, pl = [];
          // 坐标解压（返回的点串坐标，通过前向差分进行压缩，因此需要解压）
          const kr = 1000000;
          for (let i = 2; i < coors.length; i++) {
            coors[i] = Number(coors[i - 2]) + Number(coors[i]) / kr;
          }
          // 将解压后的坐标生成LatLng数组
          for (let i = 0; i < coors.length; i += 2) {
            pl.push(new   window.TMap.LatLng(coors[i], coors[i + 1]));
          }

          // 创建 MultiPolyline显示折线
          const polylineLayer = new   window.TMap.MultiPolyline({
            id: 'polyline-layer', // 图层唯一标识
            map: this.map, // 绘制到目标地图
            // 折线样式定义
            styles: {
                style_blue: new   window.TMap.PolylineStyle({
                    color: '#3777FF', // 线填充色
                    width: 6, // 折线宽度
                    borderWidth: 5, // 边线宽度
                    borderColor: '#FFF', // 边线颜色
                    lineCap: 'round' // 线端头方式
                })
            },
            // 折线数据定义
            geometries: [
                {
                    id: 'pl_1', // 折线唯一标识，删除时使用
                    styleId: 'style_blue', // 绑定样式名
                    paths: pl
                }
            ]
        });
        }
      });

      // this.http.get('https://apis.map.qq.com/ws/direction/vx1/walking/?output=jsonp&from='+startp+'&to='+endp+'&key=' + environment.mapKey).toPromise().then(resp => {
      //   console.log(resp)
      // })
    }

  }

  getInfoWinHtml(obj: any) {
    let html = '';
    html += '<div style="padding:10px;color: #ffffff;background-color: #010e3a; font-size: 14px">' + obj.point + '</div>';
    html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
    html += '<div style="font-size: 12px;"><span style="color: #010e3a">站点地址：</span>' + obj.address + '</div>';
    html += '<div style="font-size: 12px; word-break:break-all;"><span style="color: #010e3a">线路名称：</span>' + obj.pObj.name + '</div>';

    html += '<div style="font-size: 12px; word-break:break-all;"><span style="color: #010e3a">线路站点：</span>';
    for (let i = 0; i < obj.pObj.points.length; i++) {
      const itempoint = obj.pObj.points[i];
      if (i > 0) { html += ' => '; }

      if (obj.id == itempoint.id) {
        html += itempoint.point;
      } else {
        html += '<a href="javascript:void(0);" onclick="gotoPoint(' + itempoint.id + ')">' + itempoint.point + '</a>';
      }
    }
    html += '</div>';

    html += '<div style="font-size: 12px;"><span style="color: #010e3a">线路概况：</span>' + obj.pObj.overview + '</div>';
    html += '<div style="font-size: 12px; word-break:break-all;margin-bottom:10px"><span style="color: #010e3a">主题系列：</span>' + obj.pObj.topic + '</div>';
    html += '</div>';

    return html;
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

}
