// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1314923',
  baseUrl: '',
  businessUrl: '',
  U_ROOT: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com',
  S_ROOT_URL: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com',
  vvhost: 'https://edu-vrexpo.shedunews.com',
  OSS_BASE: 'https://u.expo.shnu.edu.cn',
  // vmap: 'https://edumap.linktour.vip',
  vmap: 'https://edumap.shnu.edu.cn',
  mapKey: 'XESBZ-TUN6U-FLKVO-4RRRZ-NNAMF-27B75',
  logHost: 'https://tracker99.cn-shanghai.log.aliyuncs.com/logstores/test/track_ua.gif',
  scanUrl: 'https://api.edumap.shnu.edu.cn/scan',
  markerClusterer: false, // 点聚合

  apihost: 'https://api.expo.shnu.edu.cn',
  // apihost: 'https://expo-api.ghz-tech.com:8188',
};
