export * from './index/index.component';
// export * from './3_kechuang/list.component';
// export * from './4_gaodeng/list.component';
// export * from './5_zhiyejiaoyu/list.component';
// export * from './6_kexuejia/list.component';
// export * from './7_kexueshijian/list.component';
// export * from './8_kepujiaoyu/list.component';
// export * from './9_zhongxiaoxue/list.component';
// export * from './10_shifan/list.component';
// export * from './11_rengongzhineng/list.component';


export * from './1_science_shsx/list.component';
export * from './2_science_shkp/list.component';
export * from './3_science_qgzx/list.component';
export * from './4_science_shsk/list.component';
export * from './5_science_gjzx/list.component';
export * from './6_science_gdjy/list.component';
export * from './7_science_zyjy/list.component';
export * from './8_science_kxjj/list.component';
export * from './9_science_shsq/list.component';
export * from './pmap/pmap.component';
export * from './detail/detail.component';
