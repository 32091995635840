import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { RunService } from '../../services/run.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.styl']
})
export class GuideComponent implements OnInit {

  @Input() pici = 0;

  deviceMode = null;
  show = true;

  owith   = 0;
  oheight = 0;
  isWidthMode = false;

  @ViewChild('ggref', {static: true}) ggrefl: ElementRef;

  constructor(
    private eventManager: EventManager,
    private runService: RunService
    ) { }

  ngOnInit() {
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation; // 0=竖屏 -+ 90横屏
      this.checkWidthMode();
    });
    this.checkGuides();
  }
  checkGuides() {
    let guides = this.runService.get('guides');
    if (!guides) { guides = {}; }
    if (guides[this.pici]) {
      this.show = false;
      return;
    }
    guides[this.pici] = true;
    this.runService.set('guides', guides);

    const edh1 = this.ggrefl.nativeElement.offsetWidth;
    const edh2 = this.ggrefl.nativeElement.offsetHeight;

    this.owith   = edh1 > edh2 ? edh2 : edh1;
    this.oheight = edh1 > edh2 ? edh1 : edh2;
    this.checkWidthMode();
  }
  close(ev: any) {
    this.show = false;
    ev.stopPropagation();
  }

  checkWidthMode() {
    this.isWidthMode = false;

    const dpr  = 750 / 1334;
    const dpr2 = 1334 / 750;

    if (this.deviceMode !== 0 && this.oheight / this.owith > dpr2) { this.isWidthMode = true; } // 横屏模式

    if (this.deviceMode === 0 && this.owith / this.oheight > dpr) {  this.isWidthMode = true; }  // 竖屏模式
  }
}
