import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { XMapService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-tsgz',
  templateUrl: './tsgz.component.html',
  styleUrls: ['./tsgz.component.styl'],
})
export class LifeTsgzComponent implements OnInit {
  showexpl = false;
  ttitle = '';

  rows: any = [];
  data: any = [];

  dcodes: number[] = [310115, 310106, 310104, 310105, 310107, 310110, 310112, 310113, 310114, 310116, 310151];

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRefV', {static: true}) mapSvgContainerRefVl: ElementRef;

  constructor(
    private xmapService: XMapService,
    private http: HttpClient,
    private rd2: Renderer2,
    private ngZone: NgZone,
    private router: Router) { }

  ngOnInit() {
    this.ttitle = MAPDIST.tsgz.title;
    this.http.get(MAPDIST.tsgz.api).toPromise().then((data: any) => {
      const rows = [];
      const tmp  = {};
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        if (!tmp[row.dcode]) { tmp[row.dcode] = {dcode: row.dcode, name: row.district, data: []}; }
        tmp[row.dcode].data.push(row);
      }
      // Object.keys(tmp).forEach(key => rows.push(tmp[key]))

      this.rows = [tmp[310115], tmp[310106], tmp[310104], tmp[310105], tmp[310107], tmp[310110], tmp[310112], tmp[310113], tmp[310114], tmp[310116], tmp[310151], ];

      this.data = this.xmapService.getDistrictSvg();
      this.renderHtml();
    });
    window.mapEvent = (dcode: any) => {
      if (this.dcodes.includes(dcode)) { this.ngZone.run(() => this.toDistrict(dcode)); }
    };
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'path':
            innerHTML += '<path onclick="mapEvent(' + id + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="rgba(0,0,0,0.3)" opacity="0"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon onclick="mapEvent(' + id + ')" points="' + ele.points + '" fill="rgba(0,0,0,0.3)" opacity="0"/>';
            break;
        }
      }
    }

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRefVl.nativeElement, 'innerHTML', innerHTML);
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/pmap/tsgz/' + i);
  }

  toDistrict(dcode: number) {
    this.router.navigateByUrl('/life/tsgz/' + dcode);
  }
}
