<app-rback rurl="/jyszhzx"></app-rback>
<app-xtheme [theme]="'new0'">
  <app-xtheme-hh>
    <div class="logoname jyszhzx-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt jyszhzx-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container" (click)="showFullMapEvent()">
        <app-hzoom>
          <img class="iled" src="/assets/imgs/gif/2022/206.gif?i=1213" />
        </app-hzoom>
      </div> 
    </div> 
 
    <div class="list-group">
      <app-liked-shared [mapkey]="'newshgdjydjrgznzdcylygxdt'"></app-liked-shared>

      <div class="title-group jyszhzx-theme-color">
        <div class="tg-school">高校</div>
        <div class="tg-subjects">人工智能实体单位</div>
      </div>
      <div class="lg-group">
        <div class="lggitem" *ngFor="let item of data;let i = index" (click)="showFullMapEvent(item.id)">
          <div class="school">{{item.name}}</div>
          <div class="subjects">
            <div class="subitem" >{{item.entity}}</div>
          </div>
        </div>
      </div>

    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt jyszhzx-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container" (click)="showFullMapEvent()">
          <app-zoomer>
            <img class="iled" src="/assets/imgs/gif/2022/206.gif?i=1213" />
          </app-zoomer>
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname jyszhzx-theme-color">{{ttitle}}</div>
 
      <div class="list-group">
        <!-- <div class="title-group jyszhzx-theme-color">人工智能</div>
        <div class="lg-group">
          <div class="lggitem" *ngFor="let item of data;let i = index" (click)="showFullMapEvent(item.id)">
            {{i+1}}、{{item.name}} - {{item.entity}}
          </div>
        </div> -->

        <div class="title-group jyszhzx-theme-color">
          <div class="tg-school">高校</div>
          <div class="tg-subjects">人工智能实体单位</div>
        </div>
        <div class="lg-group">
          <div class="lggitem" *ngFor="let item of data;let i = index" (click)="showFullMapEvent(item.id)">
            <div class="school">{{item.name}}</div>
            <div class="subjects">
              <div class="subitem" >{{item.entity}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   