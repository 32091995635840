import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-szjh',
  templateUrl: './szjh.component.html',
  styleUrls: ['./szjh.component.styl']
})
export class RedSzjhComponent implements OnInit {

  playState = false;
  playComplete = false;
  videoEle: HTMLVideoElement;

  vurl = 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/vod.linktour.com/8a797cc685f94e5ea91ef7b41cdb2645/cf91c0a34d84588c56eb7693510755d7-hd.mp4';

  constructor(private router: Router) { }

  btns = [
    {img: '/assets/imgs/new/00/btn1.png', url: MAPDIST.redzgxlszkc.url},
    {img: '/assets/imgs/new/00/btn2.png', url: MAPDIST.redshzxyzgxldt.url}
  ];

  settimeouter: any;
  ngOnInit() {
    setTimeout(() => {
      this.videoEle         = (document.getElementById('fullscreen-video') as HTMLVideoElement);
      this.videoEle.onplay  = () => { this.playComplete = false; this.playState = true; };
      this.videoEle.onpause = () => { this.playState = false; };
      this.videoEle.onended = () => {
        this.playComplete = true;

        clearTimeout(this.settimeouter);
        this.settimeouter = setTimeout(() => {
          this.play();
        }, 10000);
      };
    }, 300);
  }

  play() {
    this.videoEle.play();
  }

  pause() {
    this.videoEle.pause();
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }
}
