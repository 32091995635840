import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { RunService } from 'src/app/shared/services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl'],
})
export class RedMainComponent implements OnInit {

  subs: any = {
    vv: [
      {id: 1, left: 4.5},
      {id: 2, left: 15.5},
      {id: 3, left: 28},
      {id: 4, left: 41},
      {id: 5, left: 54},
      {id: 6, left: 66.5},
      {id: 7, left: 77.5},
    ],
    hh: [
      {id: 1, top: -0.4},
      {id: 2, top: 11.2},
      {id: 3, top: 22.8},
      {id: 4, top: 31.2},
      {id: 5, top: 39.5},
      {id: 6, top: 51},
      {id: 7, top: 62.5},
    ],
  };

  iShowScreen = false;

  constructor(
    private router: Router,
    private runService: RunService
  ) { }

  ngOnInit() {
    this.iShowScreen = this.runService.iShowScreen;
  }

  gotoThemeMap(id: any) {
    this.runService.reBackUrl = '/red';
    this.router.navigateByUrl('/red/' + id);
  }
}
