import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { mapOpenCloseAnimation, mapUpDownAnimation } from 'src/app/animations';
import { HttpClient } from '@angular/common/http';
import { EventManager } from '@angular/platform-browser';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-gjgjzlzdzy',
  templateUrl: './gjgjzlzdzy.component.html',
  styleUrls: ['./gjgjzlzdzy.component.styl'],
  animations: [ mapOpenCloseAnimation, mapUpDownAnimation ],
})
export class CityGjgjzlzdzyComponent implements OnInit {
  title = '';
  showexpl = false;

  kw = '';
  rlist: any = [];

  // 1=人工智能 2=集成电路 3=生物医药
  deviceMode = null;

  data: any[] = [];

  iconSeled = -1;
  topSeled = -1;
  icons = [
    {
      id: 1,
      txt: '人工智能',
      data: []
    },
    {
      id: 2,
      txt: '集成电路',
      data: []
    },
    {
      id: 3,
      txt: '生物医药',
      data: []
    },
    {
      id: 4,
      txt: '高校',
      data: []
    },
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.title = MAPDIST.gdjydjgjzdzlzdzy.title;
    this.eventManager.addEventListener(document.getElementById('gjgj-wrapper') as HTMLElement, 'click', () => this.topSeled = -1 );
    this.eventManager.addEventListener(document.getElementById('gjgj-wrapper2') as HTMLElement, 'click', () => this.topSeled = -1 );
    this.http.get(MAPDIST.gdjydjgjzdzlzdzy.api).toPromise().then((resp: any) => {
      this.data = resp;
    });
  }

  showFullMapEvent(iconseled: number = 3, ev: any, id: number = 0) {
    this.router.navigateByUrl('/city/gdjydjgjzdzlzdzy/map/' + iconseled + '/' + id);
    ev.stopPropagation();
  }

  iconSeledEvent(si: number) {
    if (this.iconSeled === si) {
      this.iconSeled = -1;
    } else {
      this.iconSeled = si;
    }
  }
  topSeledEvent(si: number, ev: any) {
    if (this.topSeled === si) {
      this.topSeled = -1;
      return;
    }
    this.topSeled = si;

    ev.stopPropagation();
  }

  closeList() {
    this.topSeled = -1;
  }


  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.rlist = [];
      return;
    }
    const itypes = ['人工智能', '集成电路', '生物医药'];
    const itypei = [];
    const p = new RegExp(this.kw, 'i');
    const rlist = [];
    const limit = 5;
    let ij = 0;
    for (let i = 0; i < 3; i++) {
      const type = itypes[i];
      if (p.test(type)) { itypei.push(i + 1); }
    }

    for (let i = 0; i < this.data.length; i++) {
      if (ij === limit) { break; }
      const obj = this.data[i];
      const intersection = itypei.filter(v => obj.industry.includes(v));
      if (p.test(obj.name) || intersection.length > 0) {
        const id   = obj.id;
        const name = obj.name.replace(this.kw, '<span>' + this.kw + '</span>');
        rlist.push({id, name});
        ij++;
      }
    }

    this.rlist = rlist;
  }

}
