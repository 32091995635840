<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname life-theme-color"> {{ttitle}} </div>
    <div class="map-group">
        <app-liked-shared [mapkey]="'jcjyzyfb'"></app-liked-shared>

        <app-hzoom> 
            <div class="map-svg-container" #mapSvgContainerRef ></div> 
        </app-hzoom>

        <div class="map-popup-list" *ngIf="popup.show" [ngStyle]="{'top': popup.hh.y, 'left': popup.hh.x}">
            <div class="mpl-bg">
                <div class="left-group">
                    <div class="lgitem">幼儿园</div>
                    <div class="lgitem">小学</div>
                    <div class="lgitem">中学</div> 
                    <!-- <div class="lgitem" *ngIf="popup.obj.mbxx">民办小学</div>
                    <div class="lgitem">民办中学</div> -->
                </div>
                <div class="right-group">
                    <div class="lgitem">{{popup.obj.gbyey}}</div>
                    <div class="lgitem">{{popup.obj.gbxx}}</div>
                    <div class="lgitem">{{popup.obj.gbzx}}</div>
                    <!-- <div class="lgitem" *ngIf="popup.obj.mbxx">{{popup.obj.mbxx}}</div>
                    <div class="lgitem">{{popup.obj.mbzx}}</div>  -->
                </div>
            </div>
        </div> 
    </div>
 
    <div class="table-group">
        <div class="th-group">
            <div class="th"><div>全市/区</div></div>
            <div class="th"><div>幼儿园</div></div>
            <div class="th"><div>小学</div></div>
            <div class="th"><div>中学</div></div> 
            <!-- <div class="th"><div>民办小学</div></div> 
            <div class="th"><div>民办中学</div></div> -->
        </div>
        <div class="td-group" *ngFor="let item of bgkvs">
            <div class="td"><div>{{item.name}}</div></div>
            <div class="td"><div>{{item.gbyey}}</div></div>
            <div class="td"><div>{{item.gbxx}}</div></div>
            <div class="td"><div>{{item.gbzx}}</div></div> 
            <!-- <div class="td"><div>{{item.mbxx}}</div></div>
            <div class="td"><div>{{item.mbzx}}</div></div> -->
        </div>
    </div>
 
    <div class="desc-group">
        <div class="p">数据更新时间：2021年12月</div> 
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv> 
    <div class="left-wrapper"> 
        <div class="map-svg-container">
            <app-zoomer>
                <div class="map-svg-dom" #mapSvgContainerRefV ></div> 
            </app-zoomer>
            <div class="map-popup-list" *ngIf="popup.show" [ngStyle]="{'top': popup.vv.y, 'left': popup.vv.x}">
                <div class="mpl-bg">
                    <div class="left-group">
                        <div class="lgitem">幼儿园</div>
                        <div class="lgitem">小学</div>
                        <div class="lgitem">中学</div> 
                        <!-- <div class="lgitem" *ngIf="popup.obj.mbxx">民办小学</div>
                        <div class="lgitem">民办中学</div> -->
                    </div>
                    <div class="right-group">
                        <div class="lgitem">{{popup.obj.gbyey}}</div>
                        <div class="lgitem">{{popup.obj.gbxx}}</div>
                        <div class="lgitem">{{popup.obj.gbzx}}</div>
                        <!-- <div class="lgitem" *ngIf="popup.obj.mbxx">{{popup.obj.mbxx}}</div>
                        <div class="lgitem">{{popup.obj.mbzx}}</div>  -->
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <div class="right-wrapper"> 
        <div class="logoname life-theme-color"> {{ttitle}} </div>
       
        <div class="table-group">
            <div class="th-group">
                <div class="th"><div>全市/区</div></div>
                <div class="th"><div>幼儿园</div></div>
                <div class="th"><div>小学</div></div>
                <div class="th"><div>中学</div></div> 
                <!-- <div class="th"><div>民办小学</div></div> 
                <div class="th"><div>民办中学</div></div> -->
            </div>
            <div class="td-group" *ngFor="let item of bgkvs">
                <div class="td"><div>{{item.name}}</div></div>
                <div class="td"><div>{{item.gbyey}}</div></div>
                <div class="td"><div>{{item.gbxx}}</div></div>
                <div class="td"><div>{{item.gbzx}}</div></div> 
                <!-- <div class="td"><div>{{item.mbxx}}</div></div>
                <div class="td"><div>{{item.mbzx}}</div></div> -->
            </div>
        </div>
        
        <div class="desc-group">
            <div class="p">数据更新时间：2021年12月</div> 
        </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
 