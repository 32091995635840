import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showgif',
  templateUrl: './showgif.component.html',
  styleUrls: ['./showgif.component.styl']
})
export class ShowgifComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
