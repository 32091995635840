<app-rback [rsback]='true'></app-rback>

<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name red-theme-color">{{ttitle}}</div>

        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/new/imap.png"/>
          <div class="txt red-theme-color">地图模式</div>
        </div>
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" (click)="toView()">
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/new/redgif/5-0.gif"/>
        </app-hzoom>
      </div>
    </div>

    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'redagzyjy'"></app-liked-shared>
      <div class="list-group red-list-bg">
        <div class="lwtitle red-theme-color red-list-border-bottom"> 
          <div class="title">社会实践基地</div> 
        </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>
          <div class="llitem" *ngIf="rows.length===0">无数据</div>
        </div>
        <div class="llitem " style="height: 0.1px;overflow: hidden;" *ngFor="let item of rows; ">{{item.name}}</div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw">

      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/new/imap.png" />
        <div class="txt red-theme-color">地图模式</div>
      </div>
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/new/redgif/5-0.gif"/>
          </app-zoomer>
        </div>
      </div>
    </div>
    <div class="right-lw">

      <div class="header-wrapper">

        <div class="search">
          <div class="logoname2 red-theme-color">{{ttitle}}</div>
        </div>
      </div>

      <div class="result-wrapper">
        <div class="list-group red-list-bg">
          <div class="lwtitle red-theme-color red-list-border-bottom"> 
            <div class="title">社会实践基地</div> 
          </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>
            <div class="llitem" *ngIf="rows.length===0">无数据</div>
          </div>
        </div>
      </div>

    </div>

  </app-xtheme-vv>
</app-xtheme>
