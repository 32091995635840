import { Component, OnInit, Renderer2, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { XMapService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-26_gqjyzyfb',
  templateUrl: './26_gqjyzyfb.component.html',
  styleUrls: ['./26_gqjyzyfb.component.styl']
})
export class LifeGqjyzyfbComponent implements OnInit {
  ttitle = '';
  data: any = [];
  popup = {
    id: 0,
    show: false,
    hh: {x: '', y: ''},
    vv: {x: '', y: ''},
    obj: {id: 0, name: '', zxval: '', jhval: '', prop: '', color: ''},
  };

  bgkvsi = {
    310115: 0,
    310101: 1,
    310106: 2,
    310104: 3,
    310105: 4,
    310107: 5,
    310109: 6,
    310110: 7,
    310113: 8,
    310112: 9,
    310114: 10,
    310116: 11,
    310117: 12,
    310118: 13,
    310120: 14,
    310151: 15
  };

  bgkvs  = [
    {id: 310115, name: '浦东新区', zxval: '359258', jhval: '459712', prop: '1.28', color: '#e2823c'},
    {id: 310101, name: '黄浦区', zxval: '46975', jhval: '50591', prop: '1.08', color: '#deb553'},
    {id: 310106, name: '静安区', zxval: '74048', jhval: '83721', prop: '1.13', color: '#deb553'},
    {id: 310104, name: '徐汇区', zxval: '84339', jhval: '96578', prop: '1.15', color: '#deb553'},
    {id: 310105, name: '长宁区', zxval: '23875', jhval: '28750', prop: '1.21', color: '#e2823c'},
    {id: 310107, name: '普陀区', zxval: '70753', jhval: '89040', prop: '1.26', color: '#e2823c'},
    {id: 310109, name: '虹口区', zxval: '44204', jhval: '56270', prop: '1.28', color: '#e2823c'},
    {id: 310110, name: '杨浦区', zxval: '73129', jhval: '95526', prop: '1.31', color: '#e2823c'},
    {id: 310113, name: '宝山区', zxval: '116277', jhval: '142770', prop: '1.23', color: '#e2823c'},
    {id: 310112, name: '闵行区', zxval: '162946', jhval: '234665', prop: '1.44', color: '#de6434'},
    {id: 310114, name: '嘉定区', zxval: '90913', jhval: '109023', prop: '1.20', color: '#e2823c'},
    {id: 310116, name: '金山区', zxval: '50441', jhval: '78136', prop: '1.55', color: '#de6434'},
    {id: 310117, name: '松江区', zxval: '103221', jhval: '133445', prop: '1.30', color: '#e2823c'},
    {id: 310118, name: '青浦区', zxval: '57267', jhval: '88100', prop: '1.54', color: '#de6434'},
    {id: 310120, name: '奉贤区', zxval: '60449', jhval: '79153', prop: '1.31', color: '#e2823c'},
    {id: 310151, name: '崇明区', zxval: '31115', jhval: '43955', prop: '1.42', color: '#de6434'},
  ];

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRefV', {static: true}) mapSvgContainerRefVl: ElementRef;

  constructor(
    private xmapService: XMapService,
    private cd: ChangeDetectorRef,
    private rd2: Renderer2,
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.gqjyzyfb.title;
    window.closeMask = () => {
      this.popup = {
        id: 0,
        show: false,
        hh: {x: '', y: ''},
        vv: {x: '', y: ''},
        obj: {id: 0, name: '', zxval: '', jhval: '', prop: '', color: ''},
      };
      this.delMoBan();
      this.cd.detectChanges();
    };
    window.mapEvent = (i: number, ii: number) => {
      this.selectedSvg(i);
    };
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();

    this.selectedSvg(2);
  }
  selectedSvg(i: number) {
    const dom = this.data[i];
    const popup = dom.popup;

    let hhx = popup.x / 610 * 100;
    let vvy = popup.y / 700 * 100;
    if (hhx > 36) { hhx = 36; }
    if (vvy > 78) { vvy = 78; }
    // if (vvy > 58) vvy = 58

    this.popup = {
      id: dom.id,
      show: true,
      hh: {x: hhx + 'vw', y: popup.y / 610 * 100 + 'vw'},
      vv: {x: popup.x / 700 * 100 + 'vh', y: vvy + 'vh'},
      obj: this.bgkvs[this.bgkvsi[dom.id]]
    };

    this.addMoBan(dom.data);
    this.cd.detectChanges();
  }
  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      const bgcolor = this.bgkvs[this.bgkvsi[id]].color;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + i + ',' + ii + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path onclick="mapEvent(' + i + ',' + ii + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon onclick="mapEvent(' + i + ',' + ii + ')" points="' + ele.points + '" fill="' + bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          default:

        }
      }
    }
    innerHTML += '<path class="mask-0 mask-dom" onclick="closeMask()" data-i="0" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-1 mask-dom" onclick="closeMask()" data-i="1" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-2 mask-dom" onclick="closeMask()" data-i="2" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-3 mask-dom" onclick="closeMask()" data-i="3" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRefVl.nativeElement, 'innerHTML', innerHTML);
  }
  addMoBan(data: any) {
    this.delMoBan();
    const paths = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].type != 'path') { break; }
      paths.push(data[i].val);
    }

    const doms = document.getElementsByClassName('mask-dom');
    for (let i = 0; i < doms.length; i++) {
      const dom = doms[i];
      const di  = parseInt(dom.getAttribute('data-i'));
      if (!paths[di]) { continue; }

      dom.setAttribute('d', paths[di]);
      dom.setAttribute('fill-opacity', '0.4');
    }
  }
  delMoBan() {
    const doms = document.getElementsByClassName('mask-dom');
    for (let i = 0; i < doms.length; i++) {
      const dom = doms[i];
      const di  = parseInt(dom.getAttribute('data-i'));
      dom.setAttribute('fill-opacity', '0');
      dom.setAttribute('d', 'M10000 10000');
    }
  }

}
