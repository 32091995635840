<app-rback rurl="/future"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname future-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt future-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container" (click)="showFullMapEvent()">
        <app-hzoom>
          <img class="iled" src="/assets/imgs/gif/22_qgjtjycxsjjd.gif?i=1213" />
        </app-hzoom>
      </div> 
    </div> 
 
    <div class="list-group">
      <app-liked-shared [mapkey]="'qgjtjycxsjjd'"></app-liked-shared>

      <div class="title-group future-theme-color">实践基地</div>
      <div class="lg-group">
        <div class="lggitem" *ngFor="let item of data;let i = index" (click)="showFullMapEvent(item.id)">
            {{i+1}}、{{item.name}}
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt future-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container" (click)="showFullMapEvent()">
          <app-zoomer>
            <img class="iled" src="/assets/imgs/gif/22_qgjtjycxsjjd.gif?i=1213" />
          </app-zoomer>
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname future-theme-color">{{ttitle}}</div>
 
      <div class="list-group">
        <div class="title-group future-theme-color">实践基地</div>
        <div class="lg-group">
          <div class="lggitem" *ngFor="let item of data;let i = index" (click)="showFullMapEvent(item.id)">
            {{i+1}}、{{item.name}}
          </div>
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   