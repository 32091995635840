import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class XResService {
  ress = {
    // '1上海民航职院'
    1: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/dae649c2.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/410a179b.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b683e1dc.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ca50b05a.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5599309b.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d436698f.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/10bbbef8.jpg'}]
    },
    // '2杨浦职院'
    2: {
      files: [
        {type: 'image', url: 'https:https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a5837d8a.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ded2da76.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d170aeb4.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b1dbea73.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e0822827.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/634bd331.JPG'}
      ]
    },
    // '3交通职院'
    3: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3e8286f1.jpg', title: '3 汽车技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8c849d51.jpg', title: '5 货运代理'}
      ]
    },
    // '4曹杨职院'
    4: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6e3dcd1b.jpg'}
      ]
    },
    // '6城建职院'
    5: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0279902e.jpg', title: '6 砌筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/01f2b7a3.jpg', title: '6 砌筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/825d0319.jpg', title: '9 混凝土建筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/add416f1.png', title: '9 混凝土建筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/99490f70.png', title: '9 混凝土建筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/16547c2e.png', title: '9 混凝土建筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/58d451a4.jpg', title: '9 混凝土建筑'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/240c2df3.jpg', title: '9 混凝土建筑'}
      ]
    },
    // '7城科校'
    6: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/77a946cd.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1650da3c.jpg'}
      ]
    },
    // '10公事校'
    7: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/bb419cdc.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3f2b2b31.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e66adb7f.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/04582ee1.jpg'}
      ]
    },
    // '12农职院'
    9: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b881aa94.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0f2c65f9.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3b317d43.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/83645445.jpg'}
      ]
    },
    // '12建设工校'
    8: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7d1abe73.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/202eb44a.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9eacdbeb.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f8d38b2d.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/eea5ff1d.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/19034027.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0014d009.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/74405a42.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/573a946a.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ec8c982c.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4c91e193.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/86c1a41e.jpg', title: '12 园艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6f05facb.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6ea5e1ea.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/91b9a41f.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/07d2f923.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f0619c91.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/73404c16.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c4103cac.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/37fe2bb0.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5c121e00.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/81157f86.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4b8a43b4.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/bf360ce6.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/abeb8681.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/dded47b8.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/fb3e8b97.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/51b01653.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1bb4480b.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a00968bd.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/dc7b87ff.jpg', title: '41 花艺'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0cdad8a1.jpg', title: '41 花艺'},
      ]
    },
    // '13房地产校'
    10: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1d284afd.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/40649dc5.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e8f9b41.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f064d020.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/405c0487.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/df1c4efe.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/fa37ab56.jpg', title: '45 3D数字游戏'},
      ]
    },
    // '13工美职院'
    11: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/609cdb6e.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8bb36eaa.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/cfbbbaad.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e0b623bd.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4b370488.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3ccf245c.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5415ffbc.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f4f51d27.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/93404aa0.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3cd8ef31.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3f4a4ea4.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/57548bce.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d5df5528.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/eb67ad32.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d631e913.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/95e0ce5c.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/711cbb51.jpg', title: '13 油漆与装饰'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/809a7377.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/18611dec.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c4fd6503.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6bc489bd.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0f93c384.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d16ae3de.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8e972a1b.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9172dd06.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a4371102.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d88f9193.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ac26a2aa.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/986ca8ac.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a538833a.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e34e719.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/21bda4ea.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b8f65bbd.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8b3eb253.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/43d9cec1.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b2d73ae7.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e4455f16.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5b16a32f.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b332168d.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b9b6da4e.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ecf2c61e.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/42e14476.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/82bc24ab.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/660482bf.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1ef8088b.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8e0130e5.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c9ec4813.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/2045c410.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b6649ce9.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5b63dee7.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d7ad2d74.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/fb4bc592.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8aec2cf9.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5a899c56.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/714bb7ff.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/12b0fdc0.jpg', title: '44 商品展示技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a357856a.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a2db22b6.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c7472dbc.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7999d37a.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1988ad60.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/95802144.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6e098cd2.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d3afe700.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/43f8b35c.jpg', title: '45 3D数字游戏'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/24475f6e.jpg', title: '45 3D数字游戏'},
      ]
    },
    // '14建筑工校'
    12: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/07a2d013.jpg', title: '14 抹灰与隔墙系统'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7953c915.JPG', title: '14 抹灰与隔墙系统'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ebaada70.JPG', title: '14 抹灰与隔墙系统'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0ce69a96.jpg', title: '14 抹灰与隔墙系统'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6d695b58.jpg', title: '15 管道与制暖'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/06eeeef9.jpg', title: '15 管道与制暖'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e67b05e.jpg', title: '15 管道与制暖'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/72d024ff.jpg', title: '17 瓷砖贴面'}
      ]
    },
    // '16科管校'
    13: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0902f021.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1ce2f52e.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7eb5417c.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b995434b.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b28208b6.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a2030a94.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/429fee8b.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9d7b166e.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f7041118.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5fccfab6.JPG'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4fda5780.jpg'}
      ]
    },
    // '18工技校'
    14: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/cde8ca0b.jpg', title: '18 数控铣'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6c932aab.jpg', title: '18 数控铣'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a04ee0f7.jpg', title: '18 数控铣'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e79fc6da.jpg', title: '19 数控车'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/2197fe91.jpg', title: '19 数控车'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3e1eeba4.jpg', title: '19 数控车'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c58252c0.jpg', title: '28 塑料模具工程'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4053fa56.jpg', title: '28 塑料模具工程'},
      ]
    },
    // '20江造集职校'
    15: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b0eabbe0.jpg', title: '20 建筑金属构造'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/db8035a9.jpg', title: '31 焊接'},
      ]
    },
    // '21电信职校'
    16: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/78799277.jpg', title: '21 电子技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9b88f208.jpg', title: '21 电子技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ec24b107.jpg', title: '21 电子技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ba68ef7d.png', title: '24 制造团队挑战赛'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/66cd3bc8.jpg', title: '33 信息网络布线'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e909bf7.jpg', title: '33 信息网络布线'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d06eec34.jpg', title: '33 信息网络布线'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e59041fc.jpg', title: '33 信息网络布线'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/03a63632.jpg', title: '33 信息网络布线'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f4662b22.jpg', title: '33 信息网络布线'},
      ]
    },
    // '22高技校'
    17: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1c305b76.jpg'},
      ]
    },
    // '23大工校'
    18: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/feb0e85e.jpg', title: '23 工业机械'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d87d353a.JPG', title: '23 工业机械'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d4b91b61.JPG', title: '29 原型制作'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1674c705.JPG', title: '29 原型制作'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/89a51423.JPG', title: '29 原型制作'},
      ]
    },
    // '25电机学院'
    19: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9a988e72.jpg', title: '25 CAD机械设计'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/231d7140.jpg', title: '25 CAD机械设计'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/81b8faa6.jpg', title: '25 CAD机械设计'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9a6f502c.jpg', title: '38 云计算'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4fe76c1f.jpg', title: '38 云计算'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ec2fe9a4.jpg', title: '38 云计算'},
      ]
    },
    // '26环境学校'
    20: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/59094873.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7daa44eb.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/2bc22f69.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ce1e7f35.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a1189771.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5b8a372a.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f986798c.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/78bd05e9.jpg', title: '26 机电一体化'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4ab1acc3.png', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/67cd00fe.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0cd1b295.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/36e35bc7.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a3881bb5.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3e0f907e.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3f408528.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/db275e57.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c7073e3f.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9cf8e53c.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d9411f77.jpg', title: '30 水处理技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/92c127e9.jpg', title: '30 水处理技术'}
      ]
    },
    // '27信息技术学校'
    21: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c641209a.jpg', title: '27 移动机器人'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7a8c2ed2.jpg', title: '32 化学实验室技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7959e872.jpg', title: '37 网站技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/26527a69.jpg', title: '43 珠宝加工'},
      ]
    },
    // '30同济大学'
    22: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/70609c90.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/bff0a234.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a7b1ef16.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9fe73152.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/624382bb.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9aa715e7.jpg'},
      ]
    },
    // '34信息管理学校'
    23: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8d9c707a.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/78b6ba06.jpg'},
      ]
    },
    // '35商学院'
    24: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/fe5e59d6.jpg', title: '53 酒店接待'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/74e8d3b0.jpg', title: '53 酒店接待'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/15b628c5.jpg', title: '53 酒店接待'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/fed44edb.jpg', title: '53 酒店接待'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/265c0adc.jpg', title: '53 酒店接待'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b130a7bd.jpg', title: '35 商务软件解决方案'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b231b32d.jpg', title: '35 商务软件解决方案'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a4ec7c76.jpg', title: '35 商务软件解决方案'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ad17083d.jpg', title: '35 商务软件解决方案'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5dc644f5.jpg', title: '35 商务软件解决方案'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0a6ef702.jpg', title: '35 商务软件解决方案'},
      ]
    },
    // '35经管校'
    25: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5ee076cf.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3bbe372a.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5c03d3f2.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1c183f31.jpg'},
      ]
    },
    // '36出版印刷高专校'
    26: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/87ba7228.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/79e47de7.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d492f3ea.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c315f3d9.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/2c1edfb0.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ab107320.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/11c968ec.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0a655fa0.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/2fc2a14c.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/bca68cfe.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/be85fc63.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/db9c2d97.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/88fc0384.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/aea8d819.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7b4738d8.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8e5bf4de.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f8537960.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d8887b63.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3c6f13c5.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/898c6c1f.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/b6045892.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a879f02c.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/35d193f0.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c0511992.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/1c971b52.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/813a517a.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8999bd67.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f5ca763f.JPG', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5fd7d63e.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/6dd8b68f.jpg', title: '36 印刷媒体技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/74391e8d.JPG', title: '42 平面设计技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ce474091.JPG', title: '42 平面设计技术'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0fcb8079.JPG', title: '42 平面设计技术'},
      ]
    },
    // '38第二工业大学'
    27: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/bab3934f.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c5fb1231.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/548330e0.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/16fa8684.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/219136fa.jpg'},
      ]
    },
    // '39上海交通大学'
    28: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/28f4facc.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c7458d37.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c70799a0.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e026ec0.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ce96e8cc.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/3dfd9590.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a2e9be27.jpg'}]
    },
    // '40上海工程技术大学'
    29: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f1dfe1ca.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/84938189.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/076b6fed.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/51f5fc13.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/0b08ee7b.jpg'},
      ]
    },
    // '46贸易学校'
    30: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e691dfed.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5523f43f.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/4d208e98.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/007816f2.jpg'},
      ]
    },
    // '47市北职业高级中学'
    31: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f3afc4f8.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ec723e50.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/42faf880.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9c7be5fa.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8ab1c76d.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7b1e779d.png'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a1a9c5e5.png'},
      ]
    },
    // '48蓝带厨艺'
    32: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/46a177e7.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/951a63fb.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/196afa6c.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a69ce213.jpg'},
      ]
    },
    // '50第二轻工业学校'
    33: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/63b972b4.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/43436880.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ff313251.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/5451d296.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/271926e6.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/c0bd3300.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/07f3f880.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/f3edd934.jpg'},
      ]
    },
    // '51健康学院'
    34: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/9058c235.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/eb95060b.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/a134ef81.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/8f4da5e2.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/e936f183.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ac89fd2a.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/11d4ed98.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/092c7a6c.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/964535c8.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/cd53a613.jpg'},
      ]
    },
    // '52南湖职业学校'
    35: {
      files: [
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/daffd65c.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/13f004d6.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/64295773.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/d240aa65.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/7e2b36f7.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/ef35c97f.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/09363663.jpg'},
        {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/umap/202008/70304b93.jpg'},
      ]
    },
  };


}
