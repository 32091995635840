import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-206_newshgdjydjrgznzdcylygxdt',
  templateUrl: './206_newshgdjydjrgznzdcylygxdt.component.html',
  styleUrls: ['./206_newshgdjydjrgznzdcylygxdt.component.styl']
})
export class NewShgdjydjrgznzdcylygxdtComponent implements OnInit {
  ttitle = '';

  data: any = [];

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshgdjydjrgznzdcylygxdt.title;
    this.http.get(MAPDIST.newshgdjydjrgznzdcylygxdt.api).toPromise().then((resp: any) => this.data = resp );
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/new/pmap/newshgdjydjrgznzdcylygxdt/' + i);
  }
}
