import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-full-screen-slider',
  templateUrl: './full-screen-slider.component.html',
  styleUrls: ['./full-screen-slider.component.styl']
})
export class FullScreenSliderComponent implements OnInit {

  @Input() files: string[] = [];
  @Input() fi = 0;

  UROOT = environment.U_ROOT;
  current = 0;

  constructor(
    private dialogServce: DialogService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.current = this.fi;
  }
  bindchange(ev: number) {
    this.fi = ev;
    this.cd.detectChanges();
  }
  close() {
    this.dialogServce.close();
  }
}
