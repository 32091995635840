import { Component, OnInit } from '@angular/core';
import { mapOpenCloseAnimation, mapUpDownAnimation } from 'src/app/animations';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-gfgyxk',
  templateUrl: './gfgyxk.component.html',
  styleUrls: ['./gfgyxk.component.styl'],
  animations: [ mapOpenCloseAnimation, mapUpDownAnimation ],
})
export class LifeGfgyxkComponent implements OnInit {
  ttitle = '';

  data: any[] = [];
  rows: any[] = [];
  kw = '';

  seli = 1;
  selii = 0;
  lwtitle: any[] = [
    {
      txt: '学校',
      childs: []
    },
    {
      txt: '高峰学科',
      childs: [ 'Ⅰ类高峰学科', 'Ⅱ类高峰学科', 'Ⅲ类高峰学科', 'Ⅳ类高峰学科' ]
    },
    // {
    //   txt: '高原学科',
    //   childs: [ 'Ⅰ类高原学科', 'Ⅱ类高原学科' ]
    // },
  ];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.gfgyxk.title;
    this.http.get(MAPDIST.gfgyxk.api).toPromise().then((data: any) => {
      this.data = data;
      this.lwlabel(0);
    });
  }

  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.selied();
      return;
    }
    const rows = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      let pjonoff = false;
      for (let ik = 0; ik < row.subject.length; ik++) {
        const subname = row.subject[ik];
        if (p.test(subname)) {
          pjonoff = true;
          continue;
        }
      }
      if (pjonoff || p.test(row.name) || p.test(row.area)) {
        rows.push(row);
      }
    }
    this.rows = rows;
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }
  toView(i: number = -1) {
    this.router.navigateByUrl('/subpmap/gfgyxk/' + this.seli + '/' + i);
  }

  lwlabel(seli: number) {
    this.seli  = seli;
    this.selii = 0;
    this.selied();
  }
  preProf() {
    this.selii -= 1;
    if (this.selii === -1) { this.selii = this.lwtitle[this.seli].childs.length - 1; }
    this.selied();
  }
  nextProf() {
    this.selii += 1;
    if (this.selii === this.lwtitle[this.seli].childs.length) { this.selii = 0; }

    this.selied();
  }
  selied() {
    if (this.seli === 0) {
      this.rows = this.data;
      return;
    }
    const dlei = this.lwtitle[this.seli].childs[this.selii];
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const sii  = item.type.indexOf(dlei);
      if (sii === -1) { continue; }
      item.xueke = item.subject[sii].split('、');
      rows.push(item);
    }
    this.rows = rows;
  }
}
