import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-209_newshsqsnkxsjgzzdt',
  templateUrl: './209_newshsqsnkxsjgzzdt.component.html',
  styleUrls: ['./209_newshsqsnkxsjgzzdt.component.styl']
})
export class NewShsqsnkxsjgzzdtComponent implements OnInit {
  ttitle = '';
  showexpl = false;
  data: any[] = [];

  profs: string[] = [];
  seli = 0;
  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshsqsnkxsjgzzdt.title;
    this.http.get(MAPDIST.newshsqsnkxsjgzzdt.api).toPromise().then((data: any) => {
      this.data = data;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (this.profs.includes(item.subject)) { continue; }

        this.profs.push(item.subject);
      }

      this.selied();
    });
  }

  preProf() {
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.profs.length - 1; }

    this.selied();
  }

  nextProf() {
    this.seli += 1;
    if (this.seli === this.profs.length) { this.seli = 0; }

    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.profs[this.seli] != item.subject) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/new/pmap/newshsqsnkxsjgzzdt/' + i);
  }
}
