import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/shared/config';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class LifeIndexComponent implements OnInit {
  deviceMode = null;

  data: {label: string, subs: any[]}[] = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {

    this.data = ROUTES[0].data[1].subs;
    // console.log('data', this.data)
  }

  toTuoYu(url: string) {
    this.router.navigateByUrl(url);
  }

}
