<app-guide [pici]="6"></app-guide>
<app-rback rurl="/city" *ngIf="!showexpl"></app-rback>

<app-xtheme>
  <app-xtheme-hh id="zyjydj-wrapper"> 
    <div class="logoname city-theme-color">
      {{title}}
      <app-expl [aei]='1' (bindExplEvent)="showexpl = $event"></app-expl>
    </div>
   
    <div class="map-model-group" (click)="showFullMapEvent($event)">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt city-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <app-hzoom> 
        <div class="map-svg-dom" #mapSvgContainerRef></div>
      </app-hzoom>
    </div>

    <div class="btns-group">
      <app-liked-shared [mapkey]="'zyjydjwdzx'"></app-liked-shared>

      <div class="btn">
        <div class="dlist-group" *ngIf="showArea">
          <div class="ditem-group" *ngFor="let items of lareas">
            <div class="ditem" *ngFor="let item of items" (click)="toArea(item.id, $event)">{{item.txt}}</div> 
          </div>
        </div> 
        <div class="btnlab" (click)="showAreaList($event)"> 
          <img src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}" />
          <div class="txt">区</div> 
        </div>
      </div> 
    </div>
    <div class="copydtime">数据更新时间：2023年7月 </div>
  </app-xtheme-hh>
  <app-xtheme-vv id="zyjydj-wrapper2">
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent($event)">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt city-theme-color">地图模式</div>
      </div>
      <div class="map-group">
        <app-zoomer> 
          <div class="map-svg-dom" #mapSvgContainerRefV></div>
        </app-zoomer>
      </div>
    </div>

    <div class="right-wrapper">
      <div class="logoname city-theme-color">
        {{title}}
        <app-expl [aei]='1' (bindExplEvent)="showexpl = $event"></app-expl>
      </div>
      <div class="btns-group">
        <div class="btn">
          <div class="dlist-group" *ngIf="showArea">
            <div class="ditem-group" *ngFor="let items of lareas">
              <div class="ditem" *ngFor="let item of items" (click)="toArea(item.id, $event)">{{item.txt}}</div> 
            </div>
          </div> 
          <div class="btnlab" (click)="showAreaList($event)"> 
            <img src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}" />
            <div class="txt">区</div> 
          </div>
        </div>
      </div>
      <div class="copydtime">数据更新时间：2023年7月 </div>
    </div>
    

  </app-xtheme-vv>
</app-xtheme>