<div class="modal-wrapper">
  <div class="mask" (click)="bgClickEvent()"></div>
  <div class='content'>
    <div class='title'>{{modalObj.title | appTf}}</div>
    <div class='text'>{{modalObj.content  | appTf }}</div>
    <div class='btn-group'>
      <div class='left  item' *ngIf="modalObj.showLeft" [ngStyle]="{'color': modalObj.leftColor}" (click)="leftClickEvent()">{{modalObj.leftText  | appTf}}</div>
      <div class='right item' [ngStyle]="{'color': modalObj.rightColor}" (click)="rightClickEvent()">{{modalObj.rightText  | appTf}}</div>
    </div>
  </div>
</div>