import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-shhswh',
  templateUrl: './shhswh.component.html',
  styleUrls: ['./shhswh.component.styl']
})
export class RedShhswhComponent implements OnInit {


  ttitle = '';

  data: any[] = [];

  profs: string[] = ['革命旧址', '纪念设施', '革命遗址'];
  areas: any[] = [['黄浦区', '徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '嘉定区', '浦东新区', '松江区', '青浦区', '奉贤区', '崇明区'], ['黄浦区', '徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '宝山区', '嘉定区', '浦东新区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'], ['黄浦区', '静安区', '虹口区']];

  seli = 0;
  seli2 = 0;

  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    const dname = {0: [], 1: [], 2: []};
    this.ttitle = MAPDIST.redcityculture.title;
    this.http.get(MAPDIST.redcityculture.api).toPromise().then((data: any) => {
      this.data = data;
      this.selied();
    });
  }

  changeIType(i: number) {
    this.seli  = i;
    this.seli2 = 0;
    this.selied();
  }

  preProf() {
    this.seli2 -= 1;
    if (this.seli2 === -1) { this.seli2 = this.areas[this.seli].length - 1; }

    this.selied();
  }
  nextProf() {
    this.seli2 += 1;
    if (this.seli2 === this.areas[this.seli].length) { this.seli2 = 0; }

    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (this.profs[this.seli] != item.type || this.areas[this.seli][this.seli2] != item.district) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/red/subpmap/redcityculture/' + this.seli + '/' + i);
  }

}
