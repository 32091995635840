import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'app-hvzoom',
  templateUrl: './hvzoom.component.html',
  styleUrls: ['./hvzoom.component.styl']
})
export class HvzoomComponent implements OnInit {

  constructor(
    private eventManager: EventManager,
    private rd2: Renderer2
  ) { }

  debug = false;
  // 缩放倍数
  scale = 1;

  @Input() startScale = 1;
  @Input() minScale   = 1;
  @Input() maxScale   = 3;

  // 视口宽高
  sgctrH = 0;
  sgctrW = 0;

  // 实际宽高
  sscrfH = 0;
  sscrfW = 0;

  hzbDom = null;
  @ViewChild('hvzoomBodyGroupContainerRef', {static: true}) hvzoomBodyGroupContainerRefl: ElementRef;

  @ViewChild('hvzoomGroupContainerRef', {static: true}) hvzoomGroupContainerRefl: ElementRef;
  ptype = '';
  // hammer.js
  cTop  = 0;
  cLeft = 0;
  pointerX = 0;
  pointerY = 0;

  ngOnInit() {
    this.eventManager.addEventListener(this.hvzoomGroupContainerRefl.nativeElement, 'touchmove', (e: any) => {
      if (this.scale != 1) { e.preventDefault(); }
    });
    const domEle = this.hvzoomGroupContainerRefl.nativeElement;
    this.sgctrH = domEle.offsetHeight;
    this.sgctrW = domEle.offsetWidth;
    this.sscrfH = domEle.offsetHeight;
    this.sscrfW = domEle.offsetWidth;

    if (this.startScale != this.scale) { this.scaleSvgContainer(this.startScale); }
  }

  scaleSvgContainer(scale: number = 3) {
    const oldsscrfH = this.sscrfH;
    const oldsscrfW = this.sscrfW;
    this.sscrfH = this.sgctrH * scale;
    this.sscrfW = this.sgctrW * scale;
    if (this.hzbDom === null) {
      this.rd2.setStyle(this.hvzoomBodyGroupContainerRefl.nativeElement, 'width', this.sscrfW + 'px');
      this.rd2.setStyle(this.hvzoomBodyGroupContainerRefl.nativeElement, 'height', this.sscrfH + 'px');
    } else {
      this.hzbDom.style.width  = this.sscrfW + 'px';
      this.hzbDom.style.height = this.sscrfH + 'px';
    }

    const cLeft = this.cLeft + (oldsscrfW - this.sscrfW) / 2;
    const cTop  = this.cTop +  (oldsscrfH - this.sscrfH) / 2;

    this.moveContainer(cLeft, cTop);
    this.scale = scale;
  }
  moveContainer(cLeft: number, cTop: number) {
    if (cTop > 0) { cTop = 0; }
    if (cLeft > 0) { cLeft = 0; }

    if ((this.sscrfW - this.sgctrW) < Math.abs(cLeft)) { cLeft = this.sgctrW - this.sscrfW; }
    if ((this.sscrfH - this.sgctrH) < Math.abs(cTop)) {   cTop = this.sgctrH - this.sscrfH; }

    if (this.hzbDom === null) {
      this.rd2.setStyle(this.hvzoomBodyGroupContainerRefl.nativeElement, 'left', cLeft + 'px');
      this.rd2.setStyle(this.hvzoomBodyGroupContainerRefl.nativeElement, 'top',  cTop + 'px');
     } else {
      this.hzbDom.style.left = cLeft + 'px';
      this.hzbDom.style.top  = cTop  + 'px';
    }

    this.cLeft = cLeft;
    this.cTop  = cTop;
  }
  panstartEvent(ev: any) {
    if (this.hzbDom === null) { this.hzbDom = document.getElementById('hvzoom-body'); }

    this.pointerX = ev.center.x;
    this.pointerY = ev.center.y;
  }
  panmoveEvent(ev: any) {
    const dy = ev.center.y - this.pointerY;
    const cTop  = this.cTop  + (ev.center.y - this.pointerY);
    const cLeft = this.cLeft + (ev.center.x - this.pointerX);
    this.pointerX = ev.center.x;
    this.pointerY = ev.center.y;

    this.moveContainer(cLeft, cTop);
  }
  pinchEvent(type: string, ev: any) {
    let scale = this.scale;
    this.ptype = type;
    if (type === 'in') {
      scale -= 0.02;
      if (this.sscrfW <= this.sgctrW) { return; }
    } else {
      scale += 0.02;
    }
    scale = scale < this.minScale ? this.minScale : scale;
    scale = scale > this.maxScale ? this.maxScale : scale;

    this.scaleSvgContainer(scale);
  }

}
