<app-rback rurl="/zlsjjy"></app-rback>
<app-xtheme theme="new0">
  <app-xtheme-hh>
    <div class="logoname zlsjjy-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt zlsjjy-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container" (click)="showFullMapEvent()">
        <app-hzoom>
          <img class="iled" src="/assets/imgs/gif/2022/213.gif?i=1213" />
        </app-hzoom>
      </div> 
    </div> 
  
      <div class="list-group"> 
        <app-liked-shared [mapkey]="'newcsjndjyjddt'"></app-liked-shared> 
        <div class="title-group zlsjjy-theme-color">上海市—基地名称</div>
        <div class="lg-group">
          <div class="lggitem" *ngFor="let item of shdata;let i = index" (click)="showFullMapEvent(item.id)">
              {{i+1}}、{{item.name}}
          </div>
        </div>
      </div> 
      <div class="list-group">
        <div class="lwtitle zlsjjy-theme-color">
          <div class="pre iicon" (click)="preProf()">
            <img class="ipre" src="/assets/imgs/new/2022/i-arrow-zlsjjy.png"/>
          </div>
          <div class="title">{{profs[seli]}}—基地名称</div>
          <div class="next iicon" (click)="nextProf()">
            <img class="inext" src="/assets/imgs/new/2022/i-arrow-zlsjjy.png"/>
          </div>
        </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="showFullMapEvent(item.id)">{{i + 1}}. {{item.name}}</div>
          <div class="llitem" *ngIf="rows.length===0">无数据</div>
        </div>
        <div class="llitem " style="height: 0.1px;overflow: hidden;" *ngFor="let item of rows; ">{{item.name}}</div>
      </div>
      
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt zlsjjy-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container" (click)="showFullMapEvent()">
          <app-zoomer>
            <img class="iled" src="/assets/imgs/gif/2022/213.gif?i=1213" />
          </app-zoomer>
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname zlsjjy-theme-color">{{ttitle}}</div>
 
        <div class="list-wrapper">
          
          <div class="list-group">  
            <div class="title-group zlsjjy-theme-color">上海市—基地名称</div>
            <div class="lg-group">
              <div class="lggitem" *ngFor="let item of shdata;let i = index" (click)="showFullMapEvent(item.id)">
                  {{i+1}}、{{item.name}}
              </div>
            </div>
          </div> 
          <div class="list-group">
            <div class="lwtitle zlsjjy-theme-color">
              <div class="pre iicon" (click)="preProf()">
                <img class="ipre" src="/assets/imgs/new/2022/i-arrow-zlsjjy.png"/>
              </div>
              <div class="title">{{profs[seli]}}—基地名称</div>
              <div class="next iicon" (click)="nextProf()">
                <img class="inext" src="/assets/imgs/new/2022/i-arrow-zlsjjy.png"/>
              </div>
            </div>
            <div class="lwtlist">
              <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="showFullMapEvent(item.id)">{{i + 1}}. {{item.name}}</div>
              <div class="llitem" *ngIf="rows.length===0">无数据</div>
            </div>
            <div class="llitem " style="height: 0.1px;overflow: hidden;" *ngFor="let item of rows; ">{{item.name}}</div>
          </div>
        </div>  
    </div>
  </app-xtheme-vv>
</app-xtheme>
   