import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventManager } from '@angular/platform-browser';
import { EchoService, XMapService } from 'src/app/shared/services';
import { HttpClient } from '@angular/common/http';
import { SwiperComponent } from 'src/app/shared/components';
import { Location } from '@angular/common';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-gj',
  templateUrl: './gj.component.html',
  styleUrls: ['./gj.component.styl']
})
export class CityGjGjDetailComponent implements OnInit {

  iid_f = {1: '人工智能', 2: '集成电路', 3: '生物医药'};

  deviceMode = null;

  row: any = {};

  si = 1;
  filei = [1, 1];

  // 0 视频 720p  1 照片  16:9
  files: any = [
    [
      {
        type: 'video',
        cover: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/899614aa.jpg',
        file: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/9013d831.mp4'
      },
      {
        type: 'video',
        cover: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/faa1ff6e.jpg',
        file: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/9013d831.mp4'
      },
      {
        type: 'video',
        cover: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/fe2651ac.jpg',
        file: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/us.linktour.com/app/era/202004/9013d831.mp4'
      }
    ],
    [
      // {
      //   type: 'pic',
      //   file: "/assets/tmp/detail/1849066e-b052-44ad-9f9d-7f8eafb38734.jpg",
      // },
      // {
      //   type: 'pic',
      //   file:  "/assets/tmp/detail/57a964cd-eb90-4676-8c5b-42d53a16b6a0.jpg",
      // },
      // {
      //   type: 'pic',
      //   file:  "/assets/tmp/detail/1a641da1-01c7-4950-a5a0-be08f5689c79.jpg",
      // },
      {
        type: 'pic',
        file:  '/assets/tmp/detail/edafc3e4-7c5b-426f-8137-0a0fb8b39df5.jpg',
      },
    ]
  ];

  @ViewChild('swipercomponent1', { static: false }) public swipercomponent1f: SwiperComponent;
  @ViewChild('swipercomponent2', { static: false }) public swipercomponent2f: SwiperComponent;

  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private echoService: EchoService,
    private cd: ChangeDetectorRef,
    private xmapService: XMapService,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('gj');

    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;

      this.swipercomponent1f.outRefresh();
      this.swipercomponent2f.outRefresh();
    });
    this.http.get(MAPDIST.gdjydjgjzdzlzdzy.api).toPromise().then((resp: any) => {
      for (let i = 0; i < resp.length; i++) {
        if (resp[i].id === id) {
          this.row = resp[i];
          break;
        }
      }

    });
  }

  bindchange(i: number, si: number) {
    this.filei[si] = i + 1;
    this.cd.detectChanges();

    if (si === 1) { return; }

    for (let ii = 0; ii < this.files[0].length; ii++) {
      if (this.files[0][ii].play) {
        this.files[0][ii].play = false;

        const vv = document.getElementById('vv-video' + ii) as HTMLVideoElement;
        const hh = document.getElementById('hh-video' + ii) as HTMLVideoElement;

        if (vv) { vv.pause(); }
        if (hh) { hh.pause(); }
      }
    }
  }

  toPlay(dom: string, i: number) {
    const video = document.getElementById(dom + i) as HTMLVideoElement;
    if (this.files[0][i].play) {
      this.swipercomponent1f.outPlay();
      this.swipercomponent2f.outPlay();

      this.files[0][i].play = false;
      video.pause();
    } else {
      this.swipercomponent1f.outStop();
      this.swipercomponent2f.outStop();

      this.files[0][i].play = true;
      video.play();
    }
  }
  swtichLunbo(i: number) {
    this.si = i;
  }

  goBack() {
    this.location.back();
  }
}
