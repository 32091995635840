<app-rback [type]="1" ></app-rback>
<app-xtheme [theme]="'new0'">
  <app-xtheme-hh>
    <img class="map" src="/assets/imgs/main/main-hh-map.png" /> 
    <div class="title">
      <img src="/assets/imgs/new/theme/i-zlsjjy.png" />
    </div>
    <div class="section-wrapper">
      <div class="sw-item" >
        <div class="intro">
          <p (click)="toTuoYu(item.url)" *ngFor="let item of data" >{{item.name}}</p>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <img src="/assets/imgs/main/main-vv-map.png"/>
    </div>
    <div class="right-wrapper">
      
      <div class="title">
        <img src="/assets/imgs/new/theme/i-zlsjjy.png" />
      </div>

      <div class="section-wrapper">
        <div class="sw-item"  >
          <div class="intro">
            <p (click)="toTuoYu(item.url)" *ngFor="let item of data">{{item.name}}</p>
          </div>
        </div>
      </div>
     
    </div>
  </app-xtheme-vv>
</app-xtheme>
  