import { Component, OnInit, Renderer2, ViewChild, ElementRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { XMapService } from 'src/app/shared/services';
import { EventManager } from '@angular/platform-browser';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-2_zyjydjwdzx',
  templateUrl: './2_zyjydjwdzx.component.html',
  styleUrls: ['./2_zyjydjwdzx.component.styl']
})
export class CityZyjydjwdzxComponent implements OnInit {
  title = '';
  showexpl = false;

  data: any[];

  showArea = true;

  lareas = [
    [
      {id: 310115, txt: '浦东新区'},
      {id: 310101, txt: '黄浦区'},
    ], [
      {id: 310106, txt: '静安区'},
      {id: 310104, txt: '徐汇区'},
    ], [
      {id: 310105, txt: '长宁区'},
      {id: 310107, txt: '普陀区'},
    ], [
      {id: 310109, txt: '虹口区'},
      {id: 310110, txt: '杨浦区'},
    ], [
      {id: 310113, txt: '宝山区'},
      {id: 310112, txt: '闵行区'},
    ], [
      {id: 310114, txt: '嘉定区'},
      {id: 310116, txt: '金山区'},
    ], [
      {id: 310117, txt: '松江区'},
      {id: 310118, txt: '青浦区'},
    ], [
      {id: 310120, txt: '奉贤区'},
      {id: 310151, txt: '崇明区'}
    ],
  ];

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRefV', {static: true}) mapSvgContainerRefVl: ElementRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    private rd2: Renderer2,
    private ngZone: NgZone,
    private xmapService: XMapService,
    private eventManager: EventManager
  ) { }

  isOkClickEvent = false;
  ngOnInit() {
    this.title = MAPDIST.zyjydjwdzx.title;
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();

    this.eventManager.addEventListener(document.getElementById('zyjydj-wrapper') as HTMLElement, 'click', () => this.closeFullPageEvent());
    this.eventManager.addEventListener(document.getElementById('zyjydj-wrapper2') as HTMLElement, 'click', () => this.closeFullPageEvent());
    window.mapEvent = (i: number, ii: number) => {
      const dist = this.data[i];

      this.ngZone.run(() => this.router.navigateByUrl('/city/zyjydjwdzx/' + dist.id) );
      this.isOkClickEvent = true;
    };
  }
  closeFullPageEvent() {
    if (!this.isOkClickEvent) { this.showArea = false; }

    this.isOkClickEvent = false;
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #314f6a;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + i + ',' + ii + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path onclick="mapEvent(' + i + ',' + ii + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + item.bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon onclick="mapEvent(' + i + ',' + ii + ')" points="' + ele.points + '" fill="' + item.bgcolor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          default:

        }
      }
    }
    innerHTML += '<path class="mask-0 mask-dom" onclick="closeMask()" data-i="0" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-1 mask-dom" onclick="closeMask()" data-i="1" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-2 mask-dom" onclick="closeMask()" data-i="2" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';
    innerHTML += '<path class="mask-3 mask-dom" onclick="closeMask()" data-i="3" d="" transform="translate(-159.97 -67.95)" fill="#000000" fill-opacity="0"/>';

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRefVl.nativeElement, 'innerHTML', innerHTML);
  }

  toArea(dcode: any, ev: any) {
    this.router.navigateByUrl('/city/zyjydjwdzx/' + dcode);
    ev.stopPropagation();
  }

  showFullMapEvent(ev: any) {
    this.router.navigateByUrl('/pmap/zyjydjwdzx/-1');
    ev.stopPropagation();
  }

  showAreaList(ev: any) {
    this.showArea = !this.showArea;
    ev.stopPropagation();
  }
}
