import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-11_shstsjyxx',
  templateUrl: './11_shstsjyxx.component.html',
  styleUrls: ['./11_shstsjyxx.component.styl']
})
export class LifeShstsjyxxComponent implements OnInit {
  ttitle = '';

  rows: any = [];

  constructor(
    private http: HttpClient,
    private router: Router ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.shstsjyxx.title;
    this.http.get(MAPDIST.shstsjyxx.api).toPromise().then((rows: any) => {
      this.rows = rows;
    });
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/pmap/shstsjyxx/' + i);
  }

}
