import { Injectable } from '@angular/core';
import { DialogService } from 'src/app/dialog';
import * as CryptoJS from 'crypto-js';

const KAEAYB = '0yKNmqdtGbiOT5bL';
const KAEAYA = CryptoJS.enc.Utf8.parse('1234123412ABCDEF');
const IAVA = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private dialogService: DialogService) { }
  isWxMiniprogram(): boolean { // 判断是否是微信
    const ua = window.navigator.userAgent;
    if (ua.indexOf('miniProgram') == -1) {
      // window.alert('请在小程序中打开网页')
    }
    return ua.indexOf('miniProgram') != -1;
  }
  isSmartDevice() {
    const userAgentInfo = navigator.userAgent;
    const mobileAgents  = [ 'Android', 'iPhone', 'iPad', 'iPod', 'Silk', 'BlackBerry', 'Opera Mini', 'IEMobile'];
    let flag = false;
    for (let v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        flag = true;
        break;
      }
    }
    return flag;
  }
  isWx() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
  }
  isMiniProgram() {
    return navigator.userAgent.toLowerCase().indexOf('miniprogram') !== -1;
  }
  isAndroid(){
    return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; // android终端
  }
  strSplice(str) {
    const str1 = str.substring(0, 4);
    const str2 = str.substring(4, 8);
    const str3 = str.substring(8, 12);
    return str1 + ' ' + str2 + ' ' + str3;
  }
  setShowPc() {
    localStorage.setItem('_is_show_pc_tag', 'is_show');
  }
  getShowPc() {
    const isShowPc = localStorage.getItem('_is_show_pc_tag');
    if (isShowPc === null) { return false; }
    return true;
  }
  /* * * * * * * * * * * * */
  encryptData(txt: string) {
    const str = CryptoJS.enc.Utf8.parse(txt);
    const result = CryptoJS.AES.encrypt( str, KAEAYB ).toString();
    return result;
  }
  daeacarayapata(txt: string) {
    const bytes  = CryptoJS.AES.decrypt(txt, KAEAYB);
    const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log('2 daeacarayapata', JSON.stringify(originalText));
    return originalText;
  }
  daeacarayapatav2(txt: string) {
    // console.log('daeacarayapatav2');
    const bytes  = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(KAEAYB), {
      iv: CryptoJS.enc.Latin1.parse(''),
      mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8);
    const originalText = JSON.parse(bytes);
    return originalText;
    // console.log(txt);
    // let encryptedHexStr = CryptoJS.enc.Hex.parse(txt)
    //   let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    //   let decrypt = CryptoJS.AES.decrypt(srcs, KAEAYA, { iv: '', mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    //   let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    //   console.log(decryptedStr.toString());
    //   return decryptedStr.toString()
  }
  eanacarayapata(txt: string) {
    // console.log('eanacarayapata');
    return CryptoJS.AES.encrypt(txt, KAEAYB).toString();
  }
  Decrypt(word: string) {
    // console.log('Decrypt');
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(srcs, KAEAYA, { iv: IAVA, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }
  Encrypt(word: string) {
    // console.log('Encrypt');
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, KAEAYA, { iv: IAVA, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString().toUpperCase();
  }
  iswechat(){
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('micromessenger') == -1) {
      console.log('不在微信或者小程序中');
      return false;
    } else {
      return true;
    }
  }
}
