<ng-container *ngIf="show">
  
<div class="rback-wrapper"  [ngClass]="{'i-circle':theme === 'i-circle'}"
  *ngIf="type === 1"
  (click)="goto($event)"
  [ngStyle]=" {
    'bottom': top, 
    'right': right + 'px', 
    'width': width + 'px'} ">
    <ng-container [ngSwitch]="theme">
      <ng-container *ngSwitchCase= "'i-circle'">
        <img src="/assets/imgs/new/i-back.png" />
      </ng-container>
      <ng-container *ngSwitchCase= "'i-rect-red'">
        <img src="/assets/imgs/new/icon/red-lb-back.png" />
      </ng-container>
      <ng-container *ngSwitchCase= "'i-rect'">
        <img src="/assets/imgs/icon/lb-back.png" />
      </ng-container>
      <ng-container *ngSwitchCase= "'i-new'">
        <img src="/assets/imgs/new/2022/new-lb-back.png" />
      </ng-container>
    </ng-container>
</div>

<div *ngIf="type === 2" class="rcloser-wrapper" (click)="goto($event)">
  <ng-container [ngSwitch]="theme">
    <ng-container *ngSwitchCase= "'i-red'">
      <img src="/assets/imgs/new/icon/red-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchCase= "'i-new'">
      <img src="/assets/imgs/new/2022/new-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img src="/assets/imgs/icon/map-back.png" />
    </ng-container>
  </ng-container>
</div> 
<div *ngIf="type === 21" class="rcloser-wrapper rightbottom21" (click)="goto($event)" ngStyle>
  <ng-container [ngSwitch]="theme">
    <ng-container *ngSwitchCase= "'i-red'">
      <img src="/assets/imgs/new/icon/red-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchCase= "'i-new'">
      <img src="/assets/imgs/new/2022/new-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img src="/assets/imgs/icon/map-back.png" />
    </ng-container>
  </ng-container>
</div> 
<div *ngIf="type === 22" class="rcloser-wrapper rightbottom22" (click)="goto($event)">
  <ng-container [ngSwitch]="theme">
    <ng-container *ngSwitchCase= "'i-red'">
      <img src="/assets/imgs/new/icon/red-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchCase= "'i-new'">
      <img src="/assets/imgs/new/2022/new-map-back.png" />
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img src="/assets/imgs/icon/map-back.png" />
    </ng-container>
  </ng-container>
</div> 

<div class="rback-home-wrapper" 
  *ngIf="!isShowPc && [1,3].includes(type)"
  (click)="gotoHome($event)">
  <img src="/assets/imgs/new/i-home.png" />
</div>

</ng-container>