<ng-container *ngIf="isicon"> 
  <app-rback [type]='2' [rurl]='rurl'></app-rback> 
</ng-container>
<ng-container *ngIf="!isicon">
  <app-rback [type]='22' [rurl]='rurl'></app-rback> 
</ng-container>

<div class="full-map-wrapper">

    <div class="qqmap-wrapper" id="qq-full-map-wrapper"></div>

    <ng-container *ngIf="!isicon">
      <div class="filters-group" [ngClass]="{'vv-group': deviceMode !== 0}" >
        <div class="img-btn imb-left" (click)="swLabPre()">
          <img src="/assets/imgs/city/top2.png"/>
        </div>
        <div class="txt life-theme-color">
          <div class="tt">{{swlabs[swlab].name}}</div>
        </div>
        <div class="img-btn imb-right" (click)="swLabNex()">
          <img src="/assets/imgs/city/top2.png"/>
        </div>
      </div>
    </ng-container>

    <div class="btns-group {{model}}" *ngIf="isicon" [ngClass]="{'vv-btns': deviceMode !== 0}">
  
      <div class="bbitem" *ngFor="let icon of swlabs; let i = index" (click)="swLab(i)">
        <div class="icon"> 
          <img src="{{icondir}}/i{{icon.id + (swlab===i ? 'ed' : '') }}.png" /> 
        </div> 
      </div>
    
    </div>
  </div>