import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-9_wdxsyslm',
  templateUrl: './9_wdxsyslm.component.html',
  styleUrls: ['./9_wdxsyslm.component.styl']
})
export class LifeWdxsyslmComponent implements OnInit {
  ttitle = '';

  types = [
    {
      id: 1,
      name: '合唱联盟',
      shortname: '合'
    },
    {
      id: 2,
      name: '戏剧联盟',
      shortname: '戏'
    },
    {
      id: 3,
      name: '交响乐联盟',
      shortname: '交'
    },
    {
      id: 4,
      name: '民乐联盟',
      shortname: '民'
    },
    {
      id: 5,
      name: '舞蹈联盟',
      shortname: '舞'
    }
  ];
  type2s = [
    {
      id: 1,
      name: '小学'
    },
    {
      id: 2,
      name: '初中'
    },
    {
      id: 3,
      name: '高中'
    },
    {
      id: 4,
      name: '大学'
    },
    {
      id: 5,
      name: '校外'
    }
  ];

  data: any[] = [];
  rows: any[] = [];
  kw = '';

  seli = 1;
  selii = 0;

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.wdxsyslm.title;
    this.http.get(MAPDIST.wdxsyslm.api).toPromise().then((data: any) => {
      this.data = data;
      this.lwlabel(0);
    });
  }

  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.selied();
      return;
    }
    const rows = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.name)) {
        rows.push(row);
      }
    }
    this.rows = rows;
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }
  toView(i: number = -1) {
    this.router.navigateByUrl('/subpmap/wdxsyslm/' + this.seli + '/' + i);
  }

  lwlabel(seli: number) {
    this.seli  = seli;
    this.selied();
  }
  preProf() {
    this.selii -= 1;
    if (this.selii === -1) { this.selii = this.type2s.length - 1; }
    this.selied();
  }
  nextProf() {
    this.selii += 1;
    if (this.selii === this.type2s.length) { this.selii = 0; }

    this.selied();
  }
  selied() {
    const dlei = this.type2s[this.selii].name;
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];

      if (this.seli === 0 && (item.type != this.types[this.seli].name || item.type2 != dlei)) { continue; }

      if (this.seli > 0 && item.type != this.types[this.seli].name) { continue; }

      rows.push(item);
    }
    // console.log(rows)
    this.rows = rows;
  }
}
