import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-15_gjjsylzy',
  templateUrl: './15_gjjsylzy.component.html',
  styleUrls: ['./15_gjjsylzy.component.styl']
})
export class LifeGjjsylzyComponent implements OnInit {
  ttitle = '';
  data = [[], []];

  iconSeled = 0;
  icons = [
    {
      id: 4,
      name: '一流大学'
    },
    {
      id: 11,
      name: '一流学科'
    },
  ];
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.gjjsylzy.title;
    this.http.get(MAPDIST.gjjsylzy.api).toPromise().then((resp: any) => {
      for (let i = 0; i < resp.length; i++) {
        resp[i].subjects = resp[i].subjects.split('、');
      }
      this.data = resp;
      // const data = {'一流大学': [], '一流学科': []}
      // for (let i = 0; i < resp.length; i++) {
      //   const item = resp[i]
      //   item.subjects = item.subjects.split('、')
      //   data[item.type].push(item)
      // }
      // this.data = [data['一流大学'], data['一流学科']]
    });
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/pmap/gjjsylzy/' + i);
    // this.router.navigateByUrl('/subpmap/gjjsylzy/'+this.iconSeled+'/' + i)
  }
  iconSeledEvent(i: number = 0) {
    this.iconSeled = i;
  }
}
