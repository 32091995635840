<div class="video-play-wrapper">
    <video 
        id="fullscreen-video" 
        name="media" 
        [poster]="vurl + '?x-oss-process=video/snapshot,t_1,f_jpg,w_1920,h_1080,m_fast'" 
        preload="auto"
        playsinline="true" webkit-playsinline="true" x5-video-player-type="h5-page" >
        <source type="video/mp4" [src]="vurl" >
        <p>你的浏览器不支持 <code>Video</code> </p> 
    </video>
    <div class="vplay-group" *ngIf="!playState" (click)="play()">
        <div class="play-btn" *ngIf="!playComplete">
            <img src="/assets/imgs/icon/vplay.png">
        </div>
    </div>

    <div class="goto-btn" *ngIf="playComplete">
        <div class="img" *ngFor="let btn of btns" (click)="goto(btn.url)">
            <img [src]="btn.img"/>
        </div>
    </div>
</div>