<app-rback rurl="/life"></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname life-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
      <div class="txt life-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container" (click)="showFullMapEvent()" >
        <app-hzoom>
          <img class="oimg" *ngIf="iconSeled===-1" src="/assets/imgs/main/main-map.png" />
          <img class="iled" *ngIf="iconSeled>-1" src="/assets/imgs/gif/15_gjjsylzy.gif?id=1324" />
        </app-hzoom>
      </div> 
    </div> 

    <div class="btns-group">
      <app-liked-shared [mapkey]="'gjjsylzy'"></app-liked-shared>
      
      <!-- <div class="bbitem" *ngFor="let item of icons; let i = index" (click)="iconSeledEvent(i)"> 
        <img class="icon" src="/assets/imgs/city/i{{item.id + (iconSeled === i ? 'ed' : '')}}.png" />
      </div> -->
    </div>
    <div class="list-group">
       
      <div class="title-group life-theme-color">“双一流”建设高校</div>
      <!-- <div class="title-group life-theme-color">{{icons[iconSeled].name}}</div> -->
      <div class="lg-group">
        <div class="lggitem" *ngFor="let item of data" (click)="showFullMapEvent(item.id)">
          <div class="school">{{item.name}}</div>
          <div class="subjects">
            <div class="subitem" *ngFor="let subject of item.subjects">{{subject}}</div>
          </div>
        </div>
      </div>
      <div class="copytime">数据更新时间：2022年12月</div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/> 
        <div class="txt life-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container" (click)="showFullMapEvent()">
          <app-zoomer>
            <img class="oimg" *ngIf="iconSeled===-1" src="/assets/imgs/main/main-map.png" />
            <img class="iled" *ngIf="iconSeled>-1" src="/assets/imgs/gif/15_gjjsylzy.gif" />
          </app-zoomer>
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname life-theme-color">{{ttitle}}</div>

      <!-- <div class="btns-wrapper">
        <div class="btns-group">
          <div class="bbitem" *ngFor="let item of icons; let i = index" (click)="iconSeledEvent(i)"> 
            <img class="icon" src="/assets/imgs/city/i{{item.id + (iconSeled === i ? 'ed' : '')}}.png" /> 
          </div> 
        </div>
      </div> -->
      <div class="list-group">
        <div class="title-group life-theme-color">“双一流”建设高校</div>
        <!-- <div class="title-group life-theme-color">{{icons[iconSeled].name}}</div> -->
        <div class="lg-group">
          <div class="lggitem" *ngFor="let item of data" (click)="showFullMapEvent(item.id)">
            <div class="school">{{item.name}}</div>
            <div class="subjects">
              <div class="subitem" *ngFor="let subject of item.subjects">{{subject}}</div>
            </div>
          </div>
        </div>
        <div class="copytime">数据更新时间：2022年12月</div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   