import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-212_newcsjagzyjyjddt',
  templateUrl: './212_newcsjagzyjyjddt.component.html',
  styleUrls: ['./212_newcsjagzyjyjddt.component.styl']
})
export class NewCsjagzyjyjddtComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }
  ttitle = '';

  shdata: any = [];
  otdata: any = [];


  profs: string[] = [];
  seli = 0;
  rows: any[]  = [];

  ngOnInit() {
    this.ttitle = MAPDIST.newcsjagzyjyjddt.title;
    this.http.get(MAPDIST.newcsjagzyjyjddt.api).toPromise().then((resp: any) => {
      const shdata = [];
      const otdata = [];
      const profs  = [];
      for (const rpitem of resp) {
        if (rpitem.province == '上海市') {
          shdata.push(rpitem);
        } else {
          otdata.push(rpitem);
          if (!profs.includes(rpitem.province)) {
            profs.push(rpitem.province);
          }
        }
      }

      this.shdata = shdata;
      this.otdata = otdata;
      this.profs  = profs;
      this.selied();
    });
  }
  preProf() {
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.profs.length - 1; }

    this.selied();
  }
  nextProf() {
    this.seli += 1;
    if (this.seli === this.profs.length) { this.seli = 0; }

    this.selied();
  }
  selied() {
    const rows = [];
    for (let i = 0; i < this.otdata.length; i++) {
      const item = this.otdata[i];
      if (this.profs[this.seli] != item.province) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/new/pmap/newcsjagzyjyjddt/' + i);
  }

}
