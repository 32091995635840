<app-rback [outputEvent]="true" (bindOutputEvent)="bindOutputEvent()" theme="i-rect-red"></app-rback>
<div class="hh-wrapper" >
  <div class="body">
    <div class="lunbo-group" *ngIf="files.length > 0">
      <div class="lgbg"></div>
      <div class="swiper" (click)="toFullView()">
        <app-swiper 
          #swipercomponent1
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          [isShowPager]='!isd'
          (bindchange)="bindchange($event)">
            <app-swiper-item *ngFor="let item of files; let i=index">    
              <img [src]="U_ROOT+item + '?x-oss-process=image/resize,m_fill,w_2880,h_1152,limit_0'"/>   
            </app-swiper-item>
        </app-swiper> 
      </div>

      <div class="docs-wrapper">
        <div class="docs-group"> 
          <div class="doc-item"   [ngClass]="{'active': true}" >图片 {{ci}}/{{files.length}}</div>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="title-tags-group">
        <ng-container [ngSwitch]="model">
          <ng-container *ngSwitchCase= "'redshdzxhsjz'">
            <div class="title">{{row.jzname}}</div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="title">{{row.name}}</div>
          </ng-container> 
        </ng-container>
      </div>
         
      <div class="map-phone-btn">
        <div class="left-mp">
          <div class="lmcontent"> 
            <ng-container [ngSwitch]="model">
              <ng-container *ngSwitchCase= "'redzxxhsxyzj'">
                <div class="infoitem" >
                  <img src="/assets/imgs/new/icon/red-idtel.png"/>
                  <div class="txt">{{ row.tel }}</div>
                </div> 
                <div class="infoitem" (click)="goBack()">
                  <img src="/assets/imgs/new/icon/red-idmap.png"/>
                  <div class="txt">{{row.district}} {{row.address ? ' - ' + row.address:''}}</div>
                </div> 
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="infoitem" (click)="goBack()">
                  <img src="/assets/imgs/new/icon/red-idmap.png"/>
                  <div class="txt">{{row.district}} {{row.address ? ' - ' + row.address:''}}</div>
                </div> 
              </ng-container> 
            </ng-container>
          </div>
        </div>
      </div>

      <div class="gapr"></div>
      <div class="intro-group">
        <ng-container *ngFor="let item of bodys">
          <div [innerHTML]='item'></div>
        </ng-container> 
      </div>

    </div>

  </div>
</div>
    