import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { RunService, UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-hsyrztdt',
  templateUrl: './hsyrztdt.component.html',
  styleUrls: ['./hsyrztdt.component.styl'],
})
export class HsyrztdtComponent implements OnInit {

  listRoutes = [
        {
          label: '内圈课程育人',
          data: [
            {
              name: MAPDIST.redzgxlszkc.title,
              url: MAPDIST.redzgxlszkc.url
            },
            {
              name: MAPDIST.redshzxyzgxldt.title,
              url: MAPDIST.redshzxyzgxldt.url
            },
            {
              name: MAPDIST.redshszxxzgxldsxxjyhdkc.title,
              url: MAPDIST.redshszxxzgxldsxxjyhdkc.url
            },
          ],
        },
        {
          label: '中圈文化育人',
          data: [
            {
              name: MAPDIST.redzxxhsxyzj.title,
              url: MAPDIST.redzxxhsxyzj.url
            },
            {
              name: MAPDIST.redshdzxhsjz.title,
              url: MAPDIST.redshdzxhsjz.url
            },
            {
              name: MAPDIST.redxysssjyr.title,
              url: MAPDIST.redxysssjyr.url
            },
          ],
        },
        {
          label: '外圈合力育人',
          data: [
            {
              name: MAPDIST.redagzyjy.title,
              url: MAPDIST.redagzyjy.url
            },
            {
              name: MAPDIST.redcityculture.title,
              url: MAPDIST.redcityculture.url
            },
          ],
        },
      ];

      constructor(
        private router: Router,
        private util: UtilsService,
        private runService: RunService,
      ) { }

      ngOnInit() {
      }

      goto(url: string) {
        this.runService.reBackUrl = '/hsyrztdt';
        this.router.navigateByUrl(url);
      }

}
