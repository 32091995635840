import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SwiperComponent } from 'src/app/shared/components';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EventManager, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { EchoService, RunService, UtilsService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-red-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class RedDetailComponent implements OnInit {

  constructor(
    private title: Title,
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private eventManager: EventManager,
    private runService: RunService,
    private utilsService: UtilsService,
    private echoService: EchoService
  ) { }

  row: any = {};

  url = '';
  model = '';
  selid = 0;

  U_ROOT = environment.U_ROOT;
  files: string[] = [];
  bodys: any[] = [];

  isd: boolean = this.utilsService.isSmartDevice();

  ci = 1;

  ngOnInit() {
    this.model  = this.route.snapshot.paramMap.get('model');
    this.selid = +this.route.snapshot.paramMap.get('selid');
    if (this.selid < 1) { this.location.back(); }

    const pObjt = MAPDIST[this.model];
    if (!pObjt) {
      this.location.back();
      return;
    }
    this.url = pObjt.url;
    this.title.setTitle('详情 - ' + pObjt.ptitle);

    this.http.get(pObjt.api + '?stid=456789765').toPromise().then((resp: any) => {
      let isrow = true;
      for (let i = 0; i < resp.length; i++) {
        if (parseInt(resp[i].id) === this.selid) {
          this.row = resp[i];
          this.title.setTitle(this.row.name + ' - ' + pObjt.ptitle);
          isrow = false;
          break;
        }
      }
      if (isrow) { this.location.back(); }

      let files = [];
      switch (this.model) {
        case 'redzgxlszkc':
        case 'redxysssjyr':
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].name != this.row.name) { continue; }

            files = files.concat(resp[i].file);
            this.bodys.push(resp[i].describe);
          }
          break;
        default:
          files = this.row.file;
          this.bodys = [this.row.describe];
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.length > 2) { this.files.push(file); }
      }

    });
  }
  bindchange(i: number) {
    this.ci = i + 1;
    this.cd.detectChanges();
  }
  // 后退问题
  // console.log(this.runService.isMapDetail)
  bindOutputEvent() {
    if (this.runService.isMapDetail) {
      this.runService.isMapDetail = false;
      this.location.back();
    } else {
      this.router.navigate([ this.url ], { replaceUrl: true });
    }
  }

  goBack() {
    this.location.back();
  }

  toFullView() {
    if (this.isd) {
      this.echoService.fullscreenslider(this.files, this.ci - 1);
    }
  }
}
