import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../services/utils.service';
import { RunService } from '../../services/run.service';
import { MAPDIST } from '../../config';

@Component({
  selector: 'app-rback',
  templateUrl: './rback.component.html',
  styleUrls: ['./rback.component.styl']
})
export class RbackComponent implements OnInit {

  @Input() theme = 'i-circle';

  @Input() type = 1;
  @Input() outputEvent = false;

  @Input() reback = false;
  @Input() show = true;
  @Input() rurl = '/';
  @Input() top = '10px';
  @Input() right = 10;
  @Input() width = 80;

  @Input() rsback = false;

  @Output()  bindOutputEvent = new EventEmitter<any>();

  isShowPc = false;
  constructor(
    private router: Router,
    private location: Location,
    private runService: RunService,
    private utilService: UtilsService
  ) { }

  topics: string[] = [];

  ngOnInit() {
    // console.log(this.router.url)
    Object.keys(MAPDIST).forEach(mapkey => {
      const cxurl: any = MAPDIST[mapkey].url;
      this.topics.push(cxurl);
    });

    this.isShowPc = this.utilService.getShowPc();
   }

  goto(ev: any) {
    // console.log(this.outputEvent, this.rsback, this.reback, this.rurl)
    if (this.outputEvent) {
      this.bindOutputEvent.emit(true);
      return;
    }
    if (this.topics.includes(this.router.url)) {
      if (['/hsyrztdt', '/qsmzqjydt'].includes(this.runService.reBackUrl)) {
        this.router.navigate([ this.runService.reBackUrl ], { replaceUrl: true });
        this.runService.reBackUrl = '/';
      } else {
        this.router.navigate([ '/' ], { replaceUrl: true });
      }
    } else if (this.rsback) {
      this.location.back();
      // this.router.navigate([ this.runService.reBackUrl ], { replaceUrl: true })
    } else if (this.reback) {
      this.location.back();
    } else {
      this.router.navigate([ this.rurl ], { replaceUrl: true });
    }
    ev.stopPropagation();
  }

  gotoHome(ev: any) {
    if (this.runService.isRedX) {
      this.router.navigate([ '/red/00' ], { replaceUrl: true });
    } else {
      this.router.navigate([ '/' ], { replaceUrl: true });
    }

    // ev.stopPropagation()
  }
}
