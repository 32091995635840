import { Component, OnInit, Renderer2, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { XMapService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-27_jcjyzyfb',
  templateUrl: './27_jcjyzyfb.component.html',
  styleUrls: ['./27_jcjyzyfb.component.styl']
})
export class LifeJcjyzyfbComponent implements OnInit {
  ttitle = '';
  data: any = [];
  popup = {
    id: 0,
    show: false,
    hh: {x: '', y: ''},
    vv: {x: '', y: ''},
    obj: {id: 0, name: '', gbzx: '', gbxx: '', gbyey: '', mbzx: '', mbxx: ''},
  };

  bgkvsi = {
    0: 0,
    310115: 1,
    310101: 2,
    310106: 3,
    310104: 4,
    310105: 5,
    310107: 6,
    310109: 7,
    310110: 8,
    310113: 9,
    310112: 10,
    310114: 11,
    310116: 12,
    310117: 13,
    310118: 14,
    310120: 15,
    310151: 16
  };

  bgkvs  = [
    {id: 0, name: '全市合计',
      gbyey: '1699', gbxx: '680', gbzx: '867',
      mbzx: '132', mbxx: '88'},
    {id: 310115, name: '浦东新区',
      gbyey: '325', gbxx: '134', gbzx: '172',
      mbzx: '27', mbxx: '20'},
    {id: 310101, name: '黄浦区',
      gbyey: '43', gbxx: '27', gbzx: '33',
      mbzx: '5', mbxx: ''},
    {id: 310106, name: '静安区',
      gbyey: '88', gbxx: '44', gbzx: '51',
      mbzx: '6', mbxx: '4'},
    {id: 310104, name: '徐汇区',
      gbyey: '100', gbxx: '44', gbzx: '42',
      mbzx: '7', mbxx: '4'},
    {id: 310105, name: '长宁区',
      gbyey: '40', gbxx: '23', gbzx: '26',
      mbzx: '3', mbxx: '2'},
    {id: 310107, name: '普陀区',
      gbyey: '83', gbxx: '25', gbzx: '51',
      mbzx: '7', mbxx: '1'},
    {id: 310109, name: '虹口区',
      gbyey: '53', gbxx: '34', gbzx: '35',
      mbzx: '6', mbxx: '4'},
    {id: 310110, name: '杨浦区',
      gbyey: '87', gbxx: '43', gbzx: '50',
      mbzx: '9', mbxx: '2'},
    {id: 310113, name: '宝山区',
      gbyey: '173', gbxx: '69', gbzx: '77',
      mbzx: '10', mbxx: '10'},
    {id: 310112, name: '闵行区',
      gbyey: '196', gbxx: '63', gbzx: '83',
      mbzx: '20', mbxx: '17'},
    {id: 310114, name: '嘉定区',
      gbyey: '105', gbxx: '41', gbzx: '48',
      mbzx: '8', mbxx: '8'},
    {id: 310116, name: '金山区',
      gbyey: '48', gbxx: '23', gbzx: '37',
      mbzx: '7', mbxx: ''},
    {id: 310117, name: '松江区',
      gbyey: '153', gbxx: '38', gbzx: '49',
      mbzx: '7', mbxx: '16'},
    {id: 310118, name: '青浦区',
      gbyey: '94', gbxx: '28', gbzx: '35',
      mbzx: '5', mbxx: ''},
    {id: 310120, name: '奉贤区',
      gbyey: '74', gbxx: '23', gbzx: '48',
      mbzx: '3', mbxx: ''},
    {id: 310151, name: '崇明区',
      gbyey: '37', gbxx: '21', gbzx: '30',
      mbzx: '2', mbxx: ''},
  ];

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRefV', {static: true}) mapSvgContainerRefVl: ElementRef;

  constructor(
    private xmapService: XMapService,
    private cd: ChangeDetectorRef,
    private rd2: Renderer2,
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.jcjyzyfb.title;

    window.mapEvent = (i: number) => {
      this.refreshMapPanel(i);
    };
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();

    this.refreshMapPanel();
  }
  refreshMapPanel(i: number = 2) {
    const dcode = this.data[i].id;
    this.removeSelectMapPanel();
    const doms = document.getElementsByClassName('dpath-' + dcode);
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];

      dom.setAttribute('fill', '#dd52e9');
      dom.classList.add('selected-map-panel');
    }

    this.selectedSvg(i);
  }
  removeSelectMapPanel() {
    const doms = document.getElementsByClassName('selected-map-panel');
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill', '#4958e4');
      dom.classList.remove('selected-map-panel');
    }
    const ndoms = document.getElementsByClassName('selected-map-panel');
    if (ndoms.length > 0) { this.removeSelectMapPanel(); }
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + i + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path class="dpath-' + id + '" onclick="mapEvent(' + i + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="#4958e4" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon class="dpath-' + id + '" onclick="mapEvent(' + i + ')" points="' + ele.points + '" fill="#4958e4" stroke="#ffffff" stroke-width="2"/>';
            break;
        }
      }
    }

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRefVl.nativeElement, 'innerHTML', innerHTML);
  }
  selectedSvg(i: number) {
    const dom = this.data[i];
    const popup = dom.popup;

    let hhx = popup.x / 610 * 100;
    let vvy = popup.y / 700 * 100;
    if (hhx > 36) { hhx = 36; }
    if (vvy > 78) { vvy = 78; }
    // if (vvy > 58) vvy = 58

    this.popup = {
      id: dom.id,
      show: true,
      hh: {x: hhx + 'vw', y: popup.y / 610 * 100 + 'vw'},
      vv: {x: popup.x / 700 * 100 + 'vh', y: vvy + 'vh'},
      obj: this.bgkvs[this.bgkvsi[dom.id]]
    };

    this.cd.detectChanges();
  }
}
