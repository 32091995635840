import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SharedHammerConfig } from './shared.hammer.config';

import { TfPipe } from './pipes';
import {
  LoaddingComponent,
  ToastComponent,
  ShowgifComponent,
  ModalComponent,
  ErrorComponent,
  TestComponent,
  SwiperComponent,
  SwiperItemComponent,
  RbackComponent,
  XthemeComponent,
  XthemeVvComponent,
  XthemeHhComponent,
  ZoomerComponent,
  HzoomComponent,
  ClmapComponent,
  GuideComponent,
  HvzoomComponent,
  VideoplayComponent,
  ExplComponent,
  TodoBuildingComponent,
  LikedSharedComponent,
  GuideShareComponent,
  FullScreenSliderComponent
} from './components';
import { VvclmapComponent } from './components/vvclmap';


@NgModule({
  declarations: [
    LoaddingComponent,
    ToastComponent,
    ShowgifComponent,
    ModalComponent,
    GuideShareComponent,
    FullScreenSliderComponent,

    ErrorComponent,
    TestComponent,
    RbackComponent,
    XthemeComponent,
    XthemeVvComponent,
    XthemeHhComponent,

    SwiperComponent,
    SwiperItemComponent,

    ZoomerComponent,
    HzoomComponent,
    HvzoomComponent,

    ClmapComponent,
    VvclmapComponent,
    GuideComponent,
    ExplComponent,
    TodoBuildingComponent,

    VideoplayComponent,

    LikedSharedComponent,

    TfPipe,
  ],
  imports: [CommonModule, FormsModule, HammerModule],
  exports: [
    CommonModule,
    FormsModule,

    LoaddingComponent,
    ToastComponent,
    ShowgifComponent,
    ModalComponent,
    GuideShareComponent,
    FullScreenSliderComponent,

    ErrorComponent,
    TestComponent,
    RbackComponent,
    XthemeComponent,
    XthemeVvComponent,
    XthemeHhComponent,

    SwiperComponent,
    SwiperItemComponent,

    ZoomerComponent,
    HzoomComponent,
    HvzoomComponent,

    ClmapComponent,
    VvclmapComponent,
    GuideComponent,
    ExplComponent,
    TodoBuildingComponent,

    VideoplayComponent,
    LikedSharedComponent,

    TfPipe,
  ],
  entryComponents: [
    LoaddingComponent,
    ToastComponent,
    ShowgifComponent,
    ModalComponent,
    GuideShareComponent,
    FullScreenSliderComponent
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: SharedHammerConfig}
  ]
})
export class SharedModule {}
