import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { XMapService, RunService } from 'src/app/shared/services';
import { EventManager, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-red-pmap',
  templateUrl: './pmap.component.html',
  styleUrls: ['./pmap.component.styl']
})
export class SciencePmapComponent implements OnInit {
  seldiv = 0;
  rurl = '';
  model = '';

  deviceMode = null;

  rows = [];
  tmp  = {};

  selMarker = null;

  map       = null;
  infoWin   = null;
  markers   = [];
  markerClusterer = null;

  selid = -1;

  dcode = 0;
  latlngs: any  = {310115: '31.258582,121.601201', 310106: '31.232084,121.442082', 310104: '31.192365,121.435903', 310105: '31.205880,121.428550', 310107: '31.244153,121.432104', 310110: '31.285590,121.538554', 310114: '31.295508,121.310888', 310116: '30.891012,121.023380'};

  constructor(
    private title: Title,
    private ngZone: NgZone,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager,
    private runService: RunService
  ) { }

  ngOnInit() {
    const model = this.route.snapshot.paramMap.get('model');
    const selid = +this.route.snapshot.paramMap.get('selid');
    if (selid > -1) { this.selid = selid; }

    const pObjt = MAPDIST[model];
    // const pObjt = {
    //   // _iid: 208,
    //   // rid: 47,
    //   // api: 'assets/tmp/json/new/208.json',
    //   // title: '上海职业教育虚拟仿真实训基地地图',
    //   // ptitle: '上海职业教育虚拟仿真实训基地地图',
    //   // url: '/jyszhzx/208',
    //
    //   _iid: 1,
    //   rid: 1,
    //   api: 'assets/tmp/json/science/shsx.json',
    //   title: '上海市学生（青少年）科创教育基地地图',
    //   ptitle: '上海市学生（青少年）科创教育基地地图',
    //   url: '/science/science_shsx'
    // };

    // console.log('new pmap ngOnInit:', {model, selid, pObjt: MAPDIST[model]});
    // console.log('2 new pmap ngOnInit:', JSON.stringify(MAPDIST[model]));

    if (!pObjt) {
      this.location.back();
      return;
    }
    this.model = model;
    this.title.setTitle('地图模式 - ' + pObjt.ptitle);
    this.rurl = pObjt.url;
    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });

    this.http.get(pObjt.api + '?stv=V09222113').toPromise().then((resp: any) => {
      // const test = [
      //   {
      //     id: '1',
      //     type: '高职',
      //     name: '上海城建职业学院',
      //     district: '奉贤区',
      //     address: '上海市奉贤区南亭公路2080号',
      //     location: '30.90904,121.45969',
      //     laboratory: '智慧健康养老虚拟仿真实训基地',
      //     intro: '1、基本情况上',
      //     files: [
      //       {
      //         type: 'image',
      //         url: 'https://u.linktour.com/app/edumap.shnu.edu/2022/208/1/基地场景.jpg'
      //       },
      //       {
      //         type: 'image',
      //         url: 'https://u.linktour.com/app/edumap.shnu.edu/2022/208/1/协助老人轮椅移动.png'
      //       },
      //       {
      //         type: 'image',
      //         url: 'https://u.linktour.com/app/edumap.shnu.edu/2022/208/1/vr沉浸式体验.png'
      //       }
      //     ]
      //   }
      // ];
      // const test1 = [
      //   {
      //     ...resp[0],
      //     location: '30.90904,121.45969',
      //     // files: test[0].files,
      //     // area: test[0].district
      //   }
      // ];
      // console.log('1 resp', JSON.stringify(resp[0]));
      // console.log('2 resp', resp[0]);
      // console.log('3 resp', test1);

      this.rows = resp;
      // console.log( 'resp', resp[0] )

      // this.rows = resp.map(v => ({...v, location: v.location ||  v.lng_lat}));

      this.xmapservice.loadQqMapJs(() => {
        this.loadFullMap();
      });
    });

    window.gotoView = (model: string, id: any) => {
      console.log('gotoView model', model, id );
      switch (model) {
        case 'newshsszjyyzkcdt': // science_shsx
          console.log('todo ', id);
          if (id == 6) {
            const xurl = '/pages/index/search/view/view?id=4966';
            window.parent.postMessage(`poi:${xurl}`, '*'); // 后面的*号就是处理跨域问题的
            window.wx.miniProgram.navigateTo({
              url: xurl,
            });
          }
          break;
        default:
          this.ngZone.run(() => {
            this.runService.isMapDetail = true;
            this.router.navigateByUrl('/science/detail/' + model + '/' + id);
          });
        }
    };
  }

  loadFullMap() {
    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);

    if (['newkxjjsjyjddt', 'newcsjagzyjyjddt', 'newcsjndjyjddt'].includes(this.model)) { // 科学家无限制
    // if (['science_shsx'].includes(this.model)) { // 科学家无限制
      this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
        zoom: 11,
        // minZoom: 8,
        maxZoom: 18,
        center,
        // boundary: new window['qq'].maps.LatLngBounds(ne, sw) // 范围锁定
      }));
    } else {
      this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
        zoom: 11,
        minZoom: 8,
        maxZoom: 18,
        center,
        boundary: new window.qq.maps.LatLngBounds(ne, sw) // 范围锁定
      }));
    }

    window.qq.maps.event.addListener(this.map, 'click', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });

    const MyOverlay = this.xmapservice.initMyNewOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => {
        this.loadFullMapMarkers();
      }
    });
  }

  loadFullMapMarkers() {
    this.infoWin.close(() => {this.closeMarker(); });

    const size = new window.qq.maps.Size(50, 57);
    const bicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, size);
    const biconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, size);

    let markers;
    if (environment.markerClusterer) {
      if (this.markerClusterer !== null) {
        this.markerClusterer.clearMarkers();
      }
      markers = new window.qq.maps.MVCArray();
    } else {
      markers = [];
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }

    for (let i = 0; i < this.rows.length; i++) {
      const obj = this.rows[i];

      let objLatLng;
      let myLatLng;
      const objlocation =  obj.location.split('；')[0];
      objLatLng = objlocation.split(',');
      if (objLatLng.length < 2) { objLatLng = ['31.240692', '121.501751']; }

      myLatLng = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);

      const marker = new window.qq.maps.Marker({
        position: myLatLng,
        icon: bicon,
        map: this.map,

        bicon, biconed
      });
      window.qq.maps.event.addListener(marker, 'click', () => {
        this.map.panTo(myLatLng);
        this.infoWin.xsetPosition(myLatLng);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        this.infoWin.open(() => {this.openMarker(marker); });
      });

      markers.push(marker);

      if (obj.id == this.selid) {
        this.map.setZoom(14);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        setTimeout(() => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
        }, 50);
        this.infoWin.open(() => {this.openMarker(marker); });
      }

    }

    if (this.dcode > 0) {
      const objLatLng = this.latlngs[this.dcode].split(',');
      const myLatLng  = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);
      // this.map.setZoom(14)
      this.map.panTo(myLatLng);
    }
    this.markers = markers;

    if (environment.markerClusterer) {
      this.markerClusterer = new window.qq.maps.MarkerCluster({
        maxZoom: 18,
        gridSize: 40,
        map: this.map,
        markers,
        zoomOnClick: true,
        minimumClusterSize: 3,
      });
    }
  }

  getInfoWinHtml(obj: any) {

    console.log('getInfoWinHtml', obj );
    let html = '';
    switch (this.model) {
      // case 'newshsjyxxhyybgpyxdt': // 上海市教育信息化应用标杆培育校地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">类型：</span>' + obj.type + '</div>';
      //   if (obj.superior) { html += '<div style="font-size: 12px"><span style="color: #237dc8">所属区/上级主管：</span>' + obj.superior + '</div>'; }
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //
      //   html += '</div>';
      //   break;
      // case 'newshgdjydjrgznzdcylygxdt': // 高等教育对接“人工智能”重大产业领域高校地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">区域：</span>' + obj.district + '</div>';
      //   if (obj.entity) { html += '<div style="font-size: 12px"><span style="color: #237dc8">实体单位：</span>' + obj.entity + '</div>'; }
      //   if (obj.construction) { html += '<div style="font-size: 12px"><span style="color: #237dc8">成立时间：</span>' + obj.construction + '</div>'; }
      //   if (obj.address) { html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>'; }
      //
      //   html += '</div>';
      //   break;
      // case 'newshsqsnkxsjgzzdt': // 上海市青少年科学实践工作站地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">学科：</span>' + obj.subject + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">区域：</span>' + obj.area + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">简介：</span>' + obj.Introduction + '</div>';
      //   if (obj.website) { html += '<div style="font-size: 12px;margin-bottom:10px; word-break:break-all;"><span style="color: #237dc8">官网：</span>' + obj.website + '</div>'; }
      //
      //   html += '</div>';
      //   break;
      // case 'newshgxrgznzyrcpydt': // 上海高校“人工智能”专业人才培养地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">层级：</span>' + obj.level + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">专业：</span>' + obj.subject + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '</div>';
      //   break;
      // case 'newkxjjsjyjddt': // 科学家精神教育基地地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">所在地：</span>' + obj.place + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">推荐单位：</span>' + obj.tjdw + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '</div>';
      //   break;
      // case 'newcsjagzyjyjddt': // 长三角爱国主义教育基地地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">所在省份：</span>' + obj.province + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">简介：</span>' + obj.intro + '</div>';
      //   html += '</div>';
      //   break;
      // case 'newcsjndjyjddt': // 长三角劳动教育基地地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">所在省份：</span>' + obj.province + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">简介：</span>' + obj.intro + '</div>';
      //   html += '</div>';
      //   break;
      // case 'newshsszjyyzkcdt': // 上海市素质教育优质课程资源地图
      //   html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      //   html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">区域：</span>' + obj.district + '</div>';
      //
      //   const courses = [];
      //   for (const course of obj.courses) {
      //     courses.push(course.coursename);
      //   }
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">课程：</span>' + courses.join('、') + '</div>';
      //   html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      //   html += '</div>';
      //
      //   if (obj.id == 6) { html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'newshsszjyyzkcdt\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/2022/detail.png"/> </div> '; }
      //   break;
      case 'science_shsx': // 上海教育虚拟仿真实训基地地图
        html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + (obj.laboratory || obj.name) + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px"><span style="color: #237dc8">区域：</span>' + (obj.area || '') + '</div>';
        html += '<div style="font-size: 12px"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
        // html += '<div style="font-size: 12px"><span style="color: #237dc8">实训基地：</span>' + obj.laboratory + '</div>';
        html += '</div>';
        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'science_shsx\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/2022/detail.png"/> </div> ';
        break;

      // case 'newshsxxtycgkfdt': // 上海市学校体育场馆开放地图
      // html += '<div style="padding:10px;color: #ffffff;background-color: #237dc8; font-size: 14px">' + obj.name + '</div>';
      // html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
      // html += '<div style="font-size: 12px;"><span style="color: #237dc8">区域：</span>' + obj.district + '</div>';
      // html += '<div style="font-size: 12px;"><span style="color: #237dc8">地址：</span>' + obj.address + '</div>';
      // html += '<div style="font-size: 12px;"><span style="color: #237dc8">开放场地：</span>' + obj.places.join('、') + '</div>';
      // html += '</div>';
      // break;
      // break;
      default:
    }

    return html;
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

}
