import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LifeTuoyuListComponent } from './life/4_tuoyu/list.component';
import { MainComponent } from './main/main.component';
import { CityIndexComponent } from './city/index/index.component';
import { FutureIndexComponent } from './future/index/index.component';
import { LifeIndexComponent } from './life/index/index.component';
import { ScienceIndexComponent } from './science/index/index.component'; // todo 2024
import {
  ScienceKeChuangListComponent,
  ScienceGaoDengListComponent,
  ScienceZhiYeJiaoYuListComponent,
  ScienceKeXueJiaListComponent,
  ScienceKeXueShiJianListComponent,
  ScienceKePuJiaoYuListComponent,
  ScienceZhongXiaoXueListComponent,
  ScienceShiFanListComponent, ScienceRenGongZhiNengListComponent, SciencePmapComponent, ScienceDetailComponent,
} from './science'; // todo 2024

// import { EduIndexComponent } from './edu/index/index.component'; // todo 2024
// import {
//   EduXinXiHuaListComponent,
//   EduGaoDengJiaoYuListComponent,
//   EduShangHaiGaoXiaoListComponent,
//   EduXuNiFangZhenListComponent,
// } from './edu'; // todo 2024

import { CityGjgjzlzdzyComponent } from './city/1_gjgjzlzdzy/gjgjzlzdzy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CityGjGjDetailComponent } from './city/1_gjgjzlzdzy/gj/gj.component';
import { AppStartComponent } from './app-start';
import {
  LifeXqhjthComponent, LifeXqhjthAreaComponent,
  LifeJmkhxxComponent, LifeJqczjsgcxxComponent,
  LifeTsgzComponent, LifeGfgyxkComponent,
  LifeGjjsylzyComponent, LifeLnjyxxComponent,
  LifeZjwpzyComponent,
  LifeShstsjyxxComponent, LifeSjjndsbzxxComponent,
  LifeWdxsyslmComponent, LifeTyytlComponent,
  LifeZgzgtzbgtComponent,
  LifeZbmapComponent,
  LifeSmzsxxjdxxComponent,
  LifeZyjykfsxzxComponent,
  LifeGqjyzyfbComponent,
  LifeJcjyzyfbComponent, LifeJmkyeyComponent, LifeTsgzDistrictComponent
} from './life';
import { FutureQsnkxsjgzzComponent, FutureQjzxxsxljksfzxComponent, FutureGjzdsssComponent, FutureQgjtjycxsjjdComponent, FutureShsxxhbgxComponent, FutureSedshsjjdComponent } from './future';
import { BuilderComponent } from './builder';
import { CityGjmapComponent, CityZyjydjwdzxComponent, CityZyjydjwdzxAreaComponent, CityJyyjtpgjdtComponent, CityJyyjtpgjdtMapComponent, CityShjysswjbjscgdtComponent, CityShjysswjbjscgdtDetailComponent } from './city';
import { PmapComponent } from './pmap';
import { TmpComponent } from './tmp';
import { SubpmapComponent } from './subpmap';
import { DetailComponent } from './detail';
import { RedCenterComponent, RedDetailComponent, RedInComponent, RedMainComponent, RedOutComponent, RedPmapComponent, RedShagzyxlshsjComponent, RedShhswhComponent, RedShzxyzgxldtComponent, RedSssjyrComponent, RedSubpmapComponent, RedSzjhComponent, RedXyhsjzComponent, RedXyhszjComponent, RedZgxlszkcComponent } from './red';
import { FutureSedshsjjdBigComponent } from './future/21_sedshsjjd_big';
import { NewDetailComponent, NewJyszhzxIndexComponent, NewKxjjsjyjddtComponent, NewPmapComponent, NewShgdjydjrgznzdcylygxdtComponent, NewShgxrgznzyrcpydtComponent, NewShjyxnfzsxjddtComponent, NewShsjyxxhyybgpyxdtComponent, NewShsqsnkxsjgzzdtComponent, NewShsszjyyzkcdtComponent, NewShsxsztyxsjlxdtComponent, NewShsxsztyxsjlxdtMapComponent, NewShsxsztyxsjlxdtMapGlComponent, NewShsxxtycgkfdtComponent, NewShszxxxrxcxdtComponent, NewSjswfzjysjqdtComponent, NewSubpmapComponent, NewZlsjjyIndexComponent } from './new';
import { NewCsjagzyjyjddtComponent } from './new/212_newcsjagzyjyjddt/212_newcsjagzyjyjddt.component';
import { NewCsjndjyjddtComponent } from './new/213_newcsjndjyjddt/213_newcsjndjyjddt.component';
import { RedShszxxzgxldsxxjyhdkcComponent } from './red/8_shszxxzgxldsxxjyhdkc';
import { NewShsxsztyxsjlxdtSubpmapComponent } from './new/203_newshsxsztyxsjlxdt/subpmap';
import { LifeTjzdzxListComponent } from './life/30_tjzdzx/list.component';
import { HsyrztdtComponent } from './2023/hsyrztdt/hsyrztdt.component';
import { QsmzqjydtComponent } from './2023/qsmzqjydt/qsmzqjydt.component';
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [
    TmpComponent,
    PmapComponent,
    SubpmapComponent,
    DetailComponent,
    BuilderComponent,
    AppStartComponent,
    HsyrztdtComponent,
    QsmzqjydtComponent,
    MainComponent,

    CityIndexComponent,
    CityGjgjzlzdzyComponent,
    CityGjGjDetailComponent,
    CityGjmapComponent,
    CityZyjydjwdzxComponent,
    CityZyjydjwdzxAreaComponent,
    CityJyyjtpgjdtComponent,
    CityJyyjtpgjdtMapComponent,
    CityShjysswjbjscgdtComponent,
    CityShjysswjbjscgdtDetailComponent,

    FutureIndexComponent,
    FutureQsnkxsjgzzComponent,
    FutureQjzxxsxljksfzxComponent,
    FutureGjzdsssComponent,
    FutureQgjtjycxsjjdComponent,
    FutureShsxxhbgxComponent,
    FutureSedshsjjdComponent,
    FutureSedshsjjdBigComponent,

    ScienceIndexComponent, // TODO 2024
    ScienceKeChuangListComponent, // TODO 2024 科创教育
    ScienceGaoDengListComponent, // TODO 2024 高等教育
    ScienceZhiYeJiaoYuListComponent, // TODO 2024 职业教育
    ScienceKeXueJiaListComponent, // TODO 2024 科学家
    ScienceKeXueShiJianListComponent, // TODO 2024 科学实践
    ScienceKePuJiaoYuListComponent, // TODO 2024 科普教育
    ScienceZhongXiaoXueListComponent, // TODO 2024 中小学
    ScienceShiFanListComponent, // TODO 2024 示范
    ScienceRenGongZhiNengListComponent, // TODO 2024 人工智能
    SciencePmapComponent, // TODO 2024 科创教育 详情
    ScienceDetailComponent, // TODO 2024 科创教育 详情

    // EduIndexComponent, // TODO 2024
    // EduXinXiHuaListComponent, // TODO 2024 edu 信息化
    // EduGaoDengJiaoYuListComponent, // TODO 2024 edu 高等教育
    // EduShangHaiGaoXiaoListComponent, // TODO 2024 edu 上海教育
    // EduXuNiFangZhenListComponent, // TODO 2024 edu 虚拟仿真

    LifeIndexComponent,
    LifeTuoyuListComponent,
    LifeJmkhxxComponent,
    LifeJqczjsgcxxComponent,
    LifeTsgzComponent,
    LifeTsgzDistrictComponent,
    LifeGfgyxkComponent,
    LifeXqhjthComponent,
    LifeXqhjthAreaComponent,
    LifeGjjsylzyComponent,
    LifeLnjyxxComponent,
    LifeZjwpzyComponent,
    LifeShstsjyxxComponent,
    LifeSjjndsbzxxComponent,
    LifeWdxsyslmComponent,
    LifeTyytlComponent,
    LifeZgzgtzbgtComponent,
    LifeZbmapComponent,
    LifeSmzsxxjdxxComponent,
    LifeZyjykfsxzxComponent,
    LifeGqjyzyfbComponent,
    LifeJcjyzyfbComponent,
    LifeJmkyeyComponent,
    LifeTjzdzxListComponent,

    RedSzjhComponent,
    RedMainComponent,
    RedZgxlszkcComponent,
    RedShzxyzgxldtComponent,
    RedXyhszjComponent,
    RedXyhsjzComponent,
    RedSssjyrComponent,
    RedShagzyxlshsjComponent,
    RedShhswhComponent,
    RedDetailComponent,
    RedPmapComponent,
    RedSubpmapComponent,
    RedShszxxzgxldsxxjyhdkcComponent,

    RedInComponent,
    RedCenterComponent,
    RedOutComponent,

    NewZlsjjyIndexComponent,
    NewJyszhzxIndexComponent,
    NewShsszjyyzkcdtComponent,
    NewShsxxtycgkfdtComponent,
    NewShsxsztyxsjlxdtComponent,
    NewShsxsztyxsjlxdtMapComponent,
    NewShsxsztyxsjlxdtMapGlComponent,
    NewKxjjsjyjddtComponent,
    NewShsjyxxhyybgpyxdtComponent,
    NewShgdjydjrgznzdcylygxdtComponent,
    NewShgxrgznzyrcpydtComponent,
    NewShjyxnfzsxjddtComponent,
    NewShsqsnkxsjgzzdtComponent,
    NewSjswfzjysjqdtComponent,
    NewShszxxxrxcxdtComponent,
    NewCsjagzyjyjddtComponent,
    NewCsjndjyjddtComponent,
    NewDetailComponent,
    NewPmapComponent,
    NewSubpmapComponent,
    NewShsxsztyxsjlxdtSubpmapComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    PerfectScrollbarModule,
  ],
  exports: [

  ]
})
export class RoutesModule { }
