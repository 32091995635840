<app-rback [outputEvent]="true" (bindOutputEvent)="bindOutputEvent()" theme="i-new"></app-rback>
<div class="hh-wrapper"  [ngStyle]="{'z-index': deviceMode === 0 ? 22: -1}">
  <div class="body"> 
    <div class="lunbo-group" *ngIf="files[0].length>0 || files[1].length>0">
      <div class="lgbg"></div>
      <div class="swiper">
        <app-swiper 
          #swipercomponent1
          height="100%" 
          [showPosition]="false" 
          [interval]="10000"
          [duration]="1000"
          [isShowPager]='files[si].length>1 && !isd'
          (bindchange)="bindchange($event, si)">
            <app-swiper-item *ngFor="let item of files[si]; let i=index"> 
              <div class="video-group" *ngIf="si === 0" (click)="toPlay(item.url)">
                <img [src]="item.url + '?x-oss-process=video/snapshot,t_1,f_jpg,w_2880,h_1152,m_fast'" class="vposter">
                <div class="vplay" *ngIf="!item.play">
                  <img src="/assets/imgs/icon/vplay.png"/>
                </div>  
              </div>  
              <img *ngIf="si === 1" [src]="item.url + '?x-oss-process=image/resize,m_fill,w_2880,h_1152,limit_0'"/>   
            </app-swiper-item>
        </app-swiper> 
      </div>

      <div class="docs-wrapper">
        <div class="docs-group">
          <div class="doc-item" *ngIf="files[0].length>1 || (files[0].length===1&&files[1].length>0)" [ngClass]="{'active': si === 0}" (click)="swtichLunbo(0)">视频 {{filei[0]}}/{{files[0].length}}</div>
          <div class="doc-item" *ngIf="files[1].length>1 || (files[1].length===1&&files[0].length>0)" [ngClass]="{'active': si === 1}" (click)="swtichLunbo(1)">图片 {{filei[1]}}/{{files[1].length}}</div>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="title-tags-group">
        <div class="title">{{row.name}}</div> 
        <div class="tags-group" *ngIf="row.industry">
          <div class="tag tt{{iid}}" *ngFor="let iid of row.industry">{{iid_f[iid]}}</div>
        </div>
      </div>
         
      <div class="map-phone-btn">
        <div class="left-mp">
          <div class="lmcontent">
            <div class="infoitem" (click)="goBack()">
              <img src="/assets/imgs/icon/2022/idmap.png"/>
              <div class="txt">{{row.district}} {{row.address ? ' - ' + row.address:''}}</div>
            </div>
          </div>
        </div>
        <div class="right-btn" (click)="gotoVv(row.vcode)" *ngIf="row.vcode">
          <img src="/assets/imgs/idzt.png"/>
        </div>
      </div>

      <div class="gapr"></div>
      <div class="intro-group">
        <ng-container *ngIf="model==='newshjyxnfzsxjddt'">
          <div class="subject-group">
            <div class="label"></div>
            <div class="txt"> {{row.intro}} </div>
          </div>
        </ng-container>
        
      </div>

    </div>

  </div>
</div>
   
<app-videoplay 
  [src]="vsrc" 
  [show]="vplay"
  (onclose)="pause()"
  (onpause)="pause()"
  [poster]="vsrc+'?x-oss-process=video/snapshot,t_1,f_jpg,w_1334,h_750,m_fast'"></app-videoplay>