import { Injectable } from '@angular/core';
import { DialogService } from 'src/app/dialog';
import { LoaddingComponent, ToastObj, ToastComponent, ModalComponent, ModalObj, FullScreenSliderComponent, ShowgifComponent } from '../components';

@Injectable({ providedIn: 'root' })
export class EchoService {

    constructor(private dialogService: DialogService,
                ) { }

    showLoading() {
        this.dialogService.open(LoaddingComponent, {
            inputs:  {},
            outputs: {}
        });
    }

    hideLoading() {
        this.dialogService.close();
    }

    showToast(toastObj: ToastObj) {
        this.dialogService.open(ToastComponent, {
            inputs:  {toastObj},
            outputs: {}
        });


        const duration = toastObj.duration > 0 ? toastObj.duration : 1500;
        setTimeout(() => {
            this.dialogService.close();
        }, duration);
    }

    showModal(modalObj: ModalObj) {
        const  defaultObj = {
            title:      '',
            content:    '',
            showLeft:   true,
            leftText:   '取消',
            leftColor:  '#000000',
            rightText:  '确定',
            rightColor: '#576B95',
            bgcolor:     'rgba(0,0,0, 0.6)',
            leftFunc:     () => { },
            rightFunc:    () => { },
            completeFunc: () => {}
        };

        for (const key in defaultObj) {
            if (modalObj.hasOwnProperty(key)) {
                defaultObj[key] = modalObj[key];
            }
        }

        this.dialogService.open(ModalComponent, {
            inputs: { modalObj: defaultObj },
            outputs: { },
        });
    }

    fullscreenslider(files: string[], fi: number = 0) {
        this.dialogService.open(FullScreenSliderComponent, {
            inputs:  { files, fi },
            outputs: {}
        });
    }

    showExperience(msg: any) {
        this.dialogService.open(ShowgifComponent, {
            inputs:  {gifmodel: 't'},
            outputs: {}
        });

        setTimeout(() => {
            this.dialogService.close();
            // this.showToast({title: '已得 ' + msg + ' 积分', duration: 2000})
            alert('已得 ' + msg + ' 积分');
        }, 4000);
    }
}
