import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { XMapService } from 'src/app/shared/services';

@Component({
  selector: 'app-shzxyzgxldt',
  templateUrl: './shzxyzgxldt.component.html',
  styleUrls: ['./shzxyzgxldt.component.styl']
})
export class RedShzxyzgxldtComponent implements OnInit {

  ttitle = '';

  kvs = [310115, 310101, 310106, 310104, 310105, 310107, 310109, 310110, 310113, 310112, 310114, 310116, 310117, 310118, 310120, 310151];
  bgColor    = '#ad4439'; // bb3b32'
  selBgColor = '#da9144'; // e68e2e'

  selii = 0;
  titleii = ['浦东新区', '黄浦区', '静安区', '徐汇区', '长宁区', '普陀区', '虹口区', '杨浦区', '宝山区', '闵行区', '嘉定区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'];

  data: any[] = [];
  rows: any[] = [];

  districtPanel = [];

  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRef1', {static: true}) mapSvgContainerRef1l: ElementRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    private rd2: Renderer2,
    private cd: ChangeDetectorRef,
    private mapService: XMapService,
  ) { }

  ngOnInit() {
    this.districtPanel = this.mapService.getDistrictSvg();
    this.renderHtml();

    this.ttitle = MAPDIST.redshzxyzgxldt.title;
    this.http.get(MAPDIST.redshzxyzgxldt.api).toPromise().then((resp: any) => {
      this.data = resp;
      this.refreshMapPanel(310115);
    });

    window.mapEvent = (dcode: number) => {
      this.refreshMapPanel(dcode);
      this.cd.detectChanges();
    };
  }

  preProf() {
    this.selii = this.selii - 1;
    if (this.selii === -1) { this.selii = this.titleii.length - 1; }
    this.calcRows();
    this.refreshMapPanel(this.kvs[this.selii]);
  }
  nextProf() {
    this.selii = this.selii + 1;
    if (this.selii === this.titleii.length) { this.selii = 0; }
    this.calcRows();
    this.refreshMapPanel(this.kvs[this.selii]);
  }

  calcRows() {
    const rows = [];
    const tmp  = {};
    this.data.forEach((ele: any) => {
      if (ele.district === this.titleii[this.selii]) {
        if (!tmp[ele.name]) { tmp[ele.name] = []; }

        tmp[ele.name].push(ele);
      }
    });
    Object.keys(tmp).forEach(sname => {
      rows.push({
        name: sname,
        majors: tmp[sname]
      });
    });

    this.rows = rows;
  }

  refreshMapPanel(dcode: number = 310115) {
    this.removeSelectMapPanel();

    const doms = document.getElementsByClassName('dpath-' + dcode);
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];

      dom.setAttribute('fill', this.selBgColor);
      dom.classList.add('selected-map-panel');
    }

    this.selii = this.kvs.indexOf(dcode);
    this.calcRows();
  }
  removeSelectMapPanel() {
    const doms = document.getElementsByClassName('selected-map-panel');
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill', this.bgColor);
      dom.classList.remove('selected-map-panel');
    }
    const ndoms = document.getElementsByClassName('selected-map-panel');
    if (ndoms.length > 0) { this.removeSelectMapPanel(); }
  }
  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.districtPanel.length; i++) {
      const item = this.districtPanel[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + id + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path class="dpath-' + id + '" onclick="mapEvent(' + id + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon class="dpath-' + id + '" onclick="mapEvent(' + id + ')" points="' + ele.points + '" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
        }
      }
    }

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRef1l.nativeElement, 'innerHTML', innerHTML);
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/red/pmap/redshzxyzgxldt/' + i);
  }
}
