import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ClmapComponent } from 'src/app/shared/components';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-25_zyjykfsxzx',
  templateUrl: './25_zyjykfsxzx.component.html',
  styleUrls: ['./25_zyjykfsxzx.component.styl']
})
export class LifeZyjykfsxzxComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  resrows: any[] = [];
  typei = 0;
  types = [{id: 1, name: '中职'}, {id: 2, name: '高职'}];
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private cd: ChangeDetectorRef) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;

  ngOnInit() {
    this.ttitle = MAPDIST.zyjykfsxzx.title;
    this.http.get(MAPDIST.zyjykfsxzx.api).toPromise().then((rows: any) => {
      // console.log('上海市职业教育开放实训中心地图：', JSON.stringify(rows[0]));
      // console.log('上海市职业教育开放实训中心地图：', JSON.stringify(rows));
      // console.log('上海市职业教育开放实训中心地图：', rows);
      this.rows = rows;
      this.clmpref.refreshMapPanel();
    });
  }

  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    this.typei = 0;
    this.getRows();
    this.cd.detectChanges();
  }

  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }
    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      let pjonoff = false;
      for (let ik = 0; ik < row.majors.length; ik++) {
        const pjname = row.majors[ik];
        if (p.test(pjname)) {
          pjonoff = true;
          continue;
        }
      }
      if (pjonoff || p.test(row.address) || p.test(row.name) || p.test(row.district)) {
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = '检索';
  }
  preProf() {
    this.typei -= 1;
    if (this.typei === -1) { this.typei = this.types.length - 1; }
    this.getRows();
  }
  nextProf() {
    this.typei += 1;
    if (this.typei === this.types.length) { this.typei = 0; }
    this.getRows();
  }
  getRows() {
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode && row.type == this.types[this.typei].name) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/zyjykfsxzx/' + i));
  }
}
