import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CacheService } from '../services/cache.service';
import { RunService } from '../services/run.service';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: CacheService,
    private runService: RunService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // const newReq = req.clone({
    //   url: environment.businessUrl + req.url + '?v=20200925',
    //   headers: req.headers
    //     .set("Content-Type", "application/json")
    //     .set("Accept-Language", 'zh')
    // });
    let domin = req.url.indexOf('frontend') > -1 ? environment.apihost : environment.businessUrl;
    if (req.url.indexOf('http') > -1) { domin = ''; }

    const urlP = req.url.split('?');
    const url  = urlP[0] + '?version=' + environment.version + (urlP[1] ? '&' + urlP[1] : '');

    let newReq: any;
    if (this.runService.uid) {
      newReq = req.clone({
        url: domin + url,
        headers: req.headers
          .set('Content-Type', 'application/json')
          .set('Accept-Language', 'zh')
          .set('X-UID', this.runService.uid)
      });
    } else {
      newReq = req.clone({
        url: domin + url,
        headers: req.headers
          .set('Content-Type', 'application/json')
          .set('Accept-Language', 'zh')
      });
    }


    // alert(JSON.stringify(newReq));
    return next.handle(newReq);
  }
}
