import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService, RunService, UtilsService } from 'src/app/shared/services';
import { mapOpenCloseAnimation } from 'src/app/animations';
import { MAPDIST, ROUTES } from 'src/app/shared/config';
import { environment } from 'src/environments/environment';


import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-app-start',
  templateUrl: './app-start.component.html',
  styleUrls: ['./app-start.component.styl'],
  animations: [ mapOpenCloseAnimation ]
})
export class AppStartComponent implements OnInit {
  public type = 'component';
  public disabled = false;
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  @ViewChild('kw') kw: ElementRef;

  imgloaded = false;
  search_data = '';
  shownav = true;
  navs = [
    {
      index: 2,
      icon: 'i-sjjy.png',
      url: ''
    },
    {
      index: 3,
      icon: 'i-szzx.png',
      url: ''
    },
    {
      index: 0,
      icon: 'i-jyzt.png',
      url: ''
    },
    {
      index: 1,
      icon: 'i-hsyr.png',
      url: ''
    },
  ];
  clientHeight = 0;
  // mobile
  listRoutes: any[] = [
    { // theme
      id: 'bt6',
      lbgcolor: '#1D71BC',
      sbgcolor: '#FBFDFE',
      label: '科学育人地图',
      data: [ // topic
        {
          label: '',
          data: [ // sub
            {
              name: MAPDIST.science_shsx.title,
              url: MAPDIST.science_shsx.url // goto
            },
            {
              name: MAPDIST.science_shkp.title,
              url: MAPDIST.science_shkp.url // goto
            },
            {
              name: MAPDIST.science_qgzx.title,
              url: MAPDIST.science_qgzx.url
            },
            {
              name: MAPDIST.science_shsk.title,
              url: MAPDIST.science_shsk.url
            },
            {
              name: MAPDIST.science_gjzx.title,
              url: MAPDIST.science_gjzx.url
            },
            {
              name: MAPDIST.science_gdjy.title,
              url: MAPDIST.science_gdjy.url // goto
            },
            {
              name: MAPDIST.science_zyjy.title,
              url: MAPDIST.science_zyjy.url
            },
            {
              name: MAPDIST.science_kxjj.title,
              url: MAPDIST.science_kxjj.url
            },
            {
              name: MAPDIST.science_shsq.title,
              url: MAPDIST.science_shsq.url
            },
          ],
        }
      ]
    },
    {
      id: 'bt5',
      lbgcolor: '#726DF2',
      sbgcolor: '#F9F9FF',
      label: '教育数字化转型地图',
      data: [
        {
          label: '',
          data: [
            {
              name: MAPDIST.newshsjyxxhyybgpyxdt.title,
              url: MAPDIST.newshsjyxxhyybgpyxdt.url
            },
            {
              name: MAPDIST.newshgdjydjrgznzdcylygxdt.title,
              url: MAPDIST.newshgdjydjrgznzdcylygxdt.url
            },
            {
              name: MAPDIST.newshgxrgznzyrcpydt.title,
              url: MAPDIST.newshgxrgznzyrcpydt.url
            },
            {
              name: MAPDIST.newshjyxnfzsxjddt.title,
              url: MAPDIST.newshjyxnfzsxjddt.url
            },
          ],
        },
      ]
    },
    // {
    //   id: 'bt7',
    //   lbgcolor: '#726DF2',
    //   sbgcolor: '#F9F9FF',
    //   label: '教育数字化转型地图',
    //   data: [ // topic
    //     {
    //       label: '',
    //       data: [ // sub
    //         {
    //           name: MAPDIST.edu_shsj.title,
    //           url: MAPDIST.edu_shsj.url // goto
    //         },
    //         {
    //           name: MAPDIST.edu_gdjy.title,
    //           url: MAPDIST.edu_gdjy.url
    //         },
    //         {
    //           name: MAPDIST.edu_shgx.title,
    //           url: MAPDIST.edu_shgx.url
    //         },
    //         {
    //           name: MAPDIST.edu_shzy.title,
    //           url: MAPDIST.edu_shzy.url
    //         }
    //       ],
    //     }
    //   ]
    // },

    // 历史数据
    {
      id: 'bt1',
      lbgcolor: '#10BAD7',
      sbgcolor: 'rgba(16, 186, 215, 0.05)',
      label: '全生命周期教育地图',
      data: [
        {
          label: '幼有善育',
          data: [
            {
              name: MAPDIST.ty.title,
              url: MAPDIST.ty.url
            },
            {
              name: MAPDIST.jmkyey.title,
              url: MAPDIST.jmkyey.url
            },
          ],
        },
        {
          label: '优质均衡',
          data: [
            {
              name: MAPDIST.jmkhxx.title,
              url: MAPDIST.jmkhxx.url
            },
            {
              name: MAPDIST.jqczjsgcxx.title,
              url: MAPDIST.jqczjsgcxx.url
            },
            {
              name: MAPDIST.xqhjth.title,
              url: MAPDIST.xqhjth.url
            },
            {
              name: MAPDIST.tsgz.title,
              url: MAPDIST.tsgz.url
            },
            {
              name: MAPDIST.wdxsyslm.title,
              url: MAPDIST.wdxsyslm.url
            },
            {
              name: MAPDIST.tjzdzx.title,
              url: MAPDIST.tjzdzx.url
            },
            {
              name: MAPDIST.shstsjyxx.title,
              url: MAPDIST.shstsjyxx.url
            },
            {
              name: MAPDIST.jcjyzyfb.title,
              url: MAPDIST.jcjyzyfb.url
            },
          ],
        },
        {
          label: '贯通融合',
          data: [
            {
              name: MAPDIST.zgzgtzbgt.title,
              url: MAPDIST.zgzgtzbgt.url
            },
            {
              name: MAPDIST.sjjndsbzxx.title,
              url: MAPDIST.sjjndsbzxx.url
            },
            {
              name: MAPDIST.zyjykfsxzx.title,
              url: MAPDIST.zyjykfsxzx.url
            },
          ],
        },
        {
          label: '特色一流',
          data: [
            {
              name: MAPDIST.gjjsylzy.title,
              url: MAPDIST.gjjsylzy.url
            },
            {
              name: MAPDIST.gfgyxk.title,
              url: MAPDIST.gfgyxk.url
            },
          ],
        },
        {
          label: '泛在可选',
          data: [
            {
              name: MAPDIST.lnjyxx.title,
              url: MAPDIST.lnjyxx.url
            },
            {
              name: MAPDIST.smzsxxjdxx.title,
              url: MAPDIST.smzsxxjdxx.url
            },
          ],
        },
      ]
    },
    {
      id: 'bt2',
      lbgcolor: '#FF6B73',
      sbgcolor: 'rgba(255, 107, 115, 0.05)',
      label: '红色育人地图',
      data: [
        {
          label: '内圈课程育人',
          data: [
            {
              name: MAPDIST.redzgxlszkc.title,
              url: MAPDIST.redzgxlszkc.url
            },
            {
              name: MAPDIST.redshzxyzgxldt.title,
              url: MAPDIST.redshzxyzgxldt.url
            },
            {
              name: MAPDIST.redshszxxzgxldsxxjyhdkc.title,
              url: MAPDIST.redshszxxzgxldsxxjyhdkc.url
            },
          ],
        },
        {
          label: '中圈文化育人',
          data: [
            {
              name: MAPDIST.redzxxhsxyzj.title,
              url: MAPDIST.redzxxhsxyzj.url
            },
            {
              name: MAPDIST.redshdzxhsjz.title,
              url: MAPDIST.redshdzxhsjz.url
            },
            {
              name: MAPDIST.redxysssjyr.title,
              url: MAPDIST.redxysssjyr.url
            },
          ],
        },
        {
          label: '外圈合力育人',
          data: [
            {
              name: MAPDIST.redagzyjy.title,
              url: MAPDIST.redagzyjy.url
            },
            {
              name: MAPDIST.redcityculture.title,
              url: MAPDIST.redcityculture.url
            },
          ],
        },
      ]
    },
    {
      id: 'bt3',
      lbgcolor: '#1A8AF7',
      sbgcolor: 'rgba(24, 138, 247, 0.06)',
      label: '服务国家和城市发展教育地图',
      data: [
        {
          label: '创新引领发展',
          data: [
            {
              name: MAPDIST.gdjydjgjzdzlzdzy.title,
              url: MAPDIST.gdjydjgjzdzlzdzy.url
            },
            {
              name: MAPDIST.zyjydjwdzx.title,
              url: MAPDIST.zyjydjwdzx.url
            },
          ],
        },
        {
          label: '教育服务国家',
          data: [
            {
              name: MAPDIST.jyyjtpgjdt.title,
              url: MAPDIST.jyyjtpgjdt.url
            },
          ],
        },
        {
          label: '基建筑基逐梦',
          data: [
            {
              name: MAPDIST.shjysswjbjscgdt.title,
              url: MAPDIST.shjysswjbjscgdt.url
            },
          ],
        },
      ]
    },
    {
      id: 'bt4',
      lbgcolor: '#41C181',
      sbgcolor: '#F5FFFB',
      label: '助力“双减”教育地图',
      data: [
        {
          label: '',
          data: [
            {
              name: MAPDIST.newsjswfzjysjqdt.title,
              url: MAPDIST.newsjswfzjysjqdt.url
            },
            {
              name: MAPDIST.newshsszjyyzkcdt.title,
              url: MAPDIST.newshsszjyyzkcdt.url
            },
            {
              name: MAPDIST.newshszxxxrxcxdt.title,
              url: MAPDIST.newshszxxxrxcxdt.url
            },
            {
              name: MAPDIST.sedshsjjd.title,
              url: MAPDIST.sedshsjjd.url
            },
            {
              name: MAPDIST.newshsxxtycgkfdt.title,
              url: MAPDIST.newshsxxtycgkfdt.url
            },
            {
              name: MAPDIST.newshsxsztyxsjlxdt.title,
              url: MAPDIST.newshsxsztyxsjlxdt.url
            },
            {
              name: MAPDIST.newkxjjsjyjddt.title,
              url: MAPDIST.newkxjjsjyjddt.url
            },
            {
              name: MAPDIST.newcsjagzyjyjddt.title,
              url: MAPDIST.newcsjagzyjyjddt.url
            },
            {
              name: MAPDIST.newcsjndjyjddt.title,
              url: MAPDIST.newcsjndjyjddt.url
            },
            {
              name: MAPDIST.qsnkxsjgzz.title,
              url: MAPDIST.qsnkxsjgzz.url
            },
            {
              name: MAPDIST.qgjtjycxsjjd.title,
              url: MAPDIST.qgjtjycxsjjd.url
            },
            {
              name: MAPDIST.qjzxxsxljksfzx.title,
              url: MAPDIST.qjzxxsxljksfzx.url
            },
          ],
        },
      ]
    },

  ];
// pc
  vvListRoutes: any[] = [
    [
      {// theme
        id: 'bt6',
        lbgcolor: '#1D71BC',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '科学育人地图',
        data: [
          {
            label: '',
            data: [
              {
                name: MAPDIST.science_shsx.title,
                url: MAPDIST.science_shsx.url // goto
              },
              {
                name: MAPDIST.science_shkp.title,
                url: MAPDIST.science_shkp.url // goto
              },
              {
                name: MAPDIST.science_qgzx.title,
                url: MAPDIST.science_qgzx.url
              },
              {
                name: MAPDIST.science_shsk.title,
                url: MAPDIST.science_shsk.url
              },
              {
                name: MAPDIST.science_gjzx.title,
                url: MAPDIST.science_gjzx.url
              },
              {
                name: MAPDIST.science_gdjy.title,
                url: MAPDIST.science_gdjy.url // goto
              },
              {
                name: MAPDIST.science_zyjy.title,
                url: MAPDIST.science_zyjy.url
              },
              {
                name: MAPDIST.science_kxjj.title,
                url: MAPDIST.science_kxjj.url
              },
              {
                name: MAPDIST.science_shsq.title,
                url: MAPDIST.science_shsq.url
              },
            ],
          },
        ]
      },
      // {
      //   id: 'bt7',
      //   lbgcolor: '#726DF2',
      //   sbgcolor: 'rgba(255, 255, 255, 0.05)',
      //   label: '教育数字化转型地图',
      //   data: [
      //     {
      //       label: '2',
      //       data: [
      //         {
      //           name: MAPDIST.edu_shsj.title,
      //           url: MAPDIST.edu_shsj.url // goto
      //         },
      //         {
      //           name: MAPDIST.edu_gdjy.title,
      //           url: MAPDIST.edu_gdjy.url
      //         },
      //         {
      //           name: MAPDIST.edu_shgx.title,
      //           url: MAPDIST.edu_shgx.url
      //         },
      //         {
      //           name: MAPDIST.edu_shzy.title,
      //           url: MAPDIST.edu_shzy.url
      //         }
      //       ],
      //     },
      //   ]
      // },

      {
        id: 'bt5',
        lbgcolor: '#726DF2',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '教育数字化转型地图',
        data: [
          {
            label: '',
            data: [
              {
                name: MAPDIST.newshsjyxxhyybgpyxdt.title,
                url: MAPDIST.newshsjyxxhyybgpyxdt.url
              },
              {
                name: MAPDIST.newshgdjydjrgznzdcylygxdt.title,
                url: MAPDIST.newshgdjydjrgznzdcylygxdt.url
              },
              {
                name: MAPDIST.newshgxrgznzyrcpydt.title,
                url: MAPDIST.newshgxrgznzyrcpydt.url
              },
              {
                name: MAPDIST.newshjyxnfzsxjddt.title,
                url: MAPDIST.newshjyxnfzsxjddt.url
              },
            ],
          },
        ]
      },
    ],
    [
      {
        id: 'bt1',
        lbgcolor: '#10BAD7',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '全生命周期教育地图',
        data: [
          {
            label: '幼有善育',
            data: [
              {
                name: MAPDIST.ty.title,
                url: MAPDIST.ty.url
              },
              {
                name: MAPDIST.jmkyey.title,
                url: MAPDIST.jmkyey.url
              },
            ],
          },
          {
            label: '优质均衡',
            data: [
              {
                name: MAPDIST.jmkhxx.title,
                url: MAPDIST.jmkhxx.url
              },
              {
                name: MAPDIST.jqczjsgcxx.title,
                url: MAPDIST.jqczjsgcxx.url
              },
              {
                name: MAPDIST.xqhjth.title,
                url: MAPDIST.xqhjth.url
              },
              {
                name: MAPDIST.tsgz.title,
                url: MAPDIST.tsgz.url
              },
              {
                name: MAPDIST.wdxsyslm.title,
                url: MAPDIST.wdxsyslm.url
              },
              {
                name: MAPDIST.tjzdzx.title,
                url: MAPDIST.tjzdzx.url
              },
              {
                name: MAPDIST.shstsjyxx.title,
                url: MAPDIST.shstsjyxx.url
              },
              {
                name: MAPDIST.jcjyzyfb.title,
                url: MAPDIST.jcjyzyfb.url
              },
            ],
          },
          {
            label: '贯通融合',
            data: [
              {
                name: MAPDIST.zgzgtzbgt.title,
                url: MAPDIST.zgzgtzbgt.url
              },
              {
                name: MAPDIST.sjjndsbzxx.title,
                url: MAPDIST.sjjndsbzxx.url
              },
              {
                name: MAPDIST.zyjykfsxzx.title,
                url: MAPDIST.zyjykfsxzx.url
              },
            ],
          },
          {
            label: '特色一流',
            data: [
              {
                name: MAPDIST.gjjsylzy.title,
                url: MAPDIST.gjjsylzy.url
              },
              {
                name: MAPDIST.gfgyxk.title,
                url: MAPDIST.gfgyxk.url
              },
            ],
          },
          {
            label: '泛在可选',
            data: [
              {
                name: MAPDIST.lnjyxx.title,
                url: MAPDIST.lnjyxx.url
              },
              {
                name: MAPDIST.smzsxxjdxx.title,
                url: MAPDIST.smzsxxjdxx.url
              },
            ],
          },
        ]
      },
      {
        id: 'bt2',
        lbgcolor: '#FF6B73',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '红色育人地图',
        data: [
          {
            label: '内圈课程育人',
            data: [
              {
                name: MAPDIST.redzgxlszkc.title,
                url: MAPDIST.redzgxlszkc.url
              },
              {
                name: MAPDIST.redshzxyzgxldt.title,
                url: MAPDIST.redshzxyzgxldt.url
              },
              {
                name: MAPDIST.redshszxxzgxldsxxjyhdkc.title,
                url: MAPDIST.redshszxxzgxldsxxjyhdkc.url
              },
            ],
          },
          {
            label: '中圈文化育人',
            data: [
              {
                name: MAPDIST.redzxxhsxyzj.title,
                url: MAPDIST.redzxxhsxyzj.url
              },
              {
                name: MAPDIST.redshdzxhsjz.title,
                url: MAPDIST.redshdzxhsjz.url
              },
              {
                name: MAPDIST.redxysssjyr.title,
                url: MAPDIST.redxysssjyr.url
              },
            ],
          },
          {
            label: '外圈合力育人',
            data: [
              {
                name: MAPDIST.redagzyjy.title,
                url: MAPDIST.redagzyjy.url
              },
              {
                name: MAPDIST.redcityculture.title,
                url: MAPDIST.redcityculture.url
              },
            ],
          },
        ]
      },
    ],
    [
      {
        id: 'bt3',
        lbgcolor: '#1A8AF7',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '服务国家和城市发展教育地图',
        data: [
          {
            label: '创新引领发展',
            data: [
              {
                name: MAPDIST.gdjydjgjzdzlzdzy.title,
                url: MAPDIST.gdjydjgjzdzlzdzy.url
              },
              {
                name: MAPDIST.zyjydjwdzx.title,
                url: MAPDIST.zyjydjwdzx.url
              },
            ],
          },
          {
            label: '教育服务国家',
            data: [
              {
                name: MAPDIST.jyyjtpgjdt.title,
                url: MAPDIST.jyyjtpgjdt.url
              },
            ],
          },
          {
            label: '基建筑基逐梦',
            data: [
              {
                name: MAPDIST.shjysswjbjscgdt.title,
                url: MAPDIST.shjysswjbjscgdt.url
              },
            ],
          },
        ]
      },
      {
        id: 'bt4',
        lbgcolor: '#41C181',
        sbgcolor: 'rgba(255, 255, 255, 0.05)',
        label: '助力“双减”教育地图',
        data: [
          {
            label: '',
            data: [
              {
                name: MAPDIST.newsjswfzjysjqdt.title,
                url: MAPDIST.newsjswfzjysjqdt.url
              },
              {
                name: MAPDIST.newshsszjyyzkcdt.title,
                url: MAPDIST.newshsszjyyzkcdt.url
              },
              {
                name: MAPDIST.newshszxxxrxcxdt.title,
                url: MAPDIST.newshszxxxrxcxdt.url
              },
              {
                name: MAPDIST.sedshsjjd.title,
                url: MAPDIST.sedshsjjd.url
              },
              {
                name: MAPDIST.newshsxxtycgkfdt.title,
                url: MAPDIST.newshsxxtycgkfdt.url
              },
              {
                name: MAPDIST.newshsxsztyxsjlxdt.title,
                url: MAPDIST.newshsxsztyxsjlxdt.url
              },
              {
                name: MAPDIST.newkxjjsjyjddt.title,
                url: MAPDIST.newkxjjsjyjddt.url
              },
              {
                name: MAPDIST.newcsjagzyjyjddt.title,
                url: MAPDIST.newcsjagzyjyjddt.url
              },
              {
                name: MAPDIST.newcsjndjyjddt.title,
                url: MAPDIST.newcsjndjyjddt.url
              },
              {
                name: MAPDIST.qsnkxsjgzz.title,
                url: MAPDIST.qsnkxsjgzz.url
              },
              {
                name: MAPDIST.qgjtjycxsjjd.title,
                url: MAPDIST.qgjtjycxsjjd.url
              },
              {
                name: MAPDIST.qjzxxsxljksfzx.title,
                url: MAPDIST.qjzxxsxljksfzx.url
              },
            ],
          },
        ]
      },
      // {
      //   id: 'bt5',
      //   lbgcolor: '#726DF2',
      //   sbgcolor: 'rgba(255, 255, 255, 0.05)',
      //   label: '教育数字化转型地图',
      //   data: [
      //     {
      //       label: '',
      //       data: [
      //         {
      //           name: MAPDIST.newshsjyxxhyybgpyxdt.title,
      //           url: MAPDIST.newshsjyxxhyybgpyxdt.url
      //         },
      //         {
      //           name: MAPDIST.newshgdjydjrgznzdcylygxdt.title,
      //           url: MAPDIST.newshgdjydjrgznzdcylygxdt.url
      //         },
      //         {
      //           name: MAPDIST.newshgxrgznzyrcpydt.title,
      //           url: MAPDIST.newshgxrgznzyrcpydt.url
      //         },
      //         {
      //           name: MAPDIST.newshjyxnfzsxjddt.title,
      //           url: MAPDIST.newshjyxnfzsxjddt.url
      //         },
      //       ],
      //     },
      //   ]
      // },
    ]
  ];
  constructor(
    private router: Router,
    private util: UtilsService,
    private cacheService: CacheService,
    private runService: RunService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
  ) { }
  ngOnInit() {
    const that = this;
    window.mapImgLoad = function(){
      that.imgloaded = true;
      that.cd.detectChanges();
    };
    this.clientHeight = document.documentElement.clientHeight;

    window.onresize = function(){
      if (that.clientHeight != document.documentElement.clientHeight) {
        that.shownav = false;
      }else{
        that.shownav = true;
      }
    };
  }
  toNext(ev, url: string) {
    this.runService.reBackUrl = '/';
    let rUrl = this.cacheService.get('rUrl');
    if (rUrl) {
      rUrl = decodeURIComponent(rUrl);
      this.cacheService.remove('rUrl');
    } else {
      rUrl = '/';
    }
    this.router.navigateByUrl(url);
    // this.router.navigate([rUrl], { replaceUrl: true })
    ev.stopPropagation();
  }
  goto(url: string) {
    // console.log('goto', url);
    // return
    if (url == '/zlsjjy/210' && this.util.isWxMiniprogram()){
      const xurl = '/pages/home/sjzymap/sjzymap?type=1';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      return;
    }
    if (url == '/zlsjjy/211' && this.util.isWxMiniprogram()){
      const xurl = '/pages/home/map/map?type=5';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      return;
    }
    this.runService.reBackUrl = '/';
    this.router.navigateByUrl(url);
  }
  goSearch(){
    if (this.search_data) { this.kw.nativeElement.blur(); }
    setTimeout(() => {
      window.parent.postMessage('kw:' + this.search_data, '*'); // 后面的*号就是处理跨域问题的
      window.wx.miniProgram.navigateTo({
        url: this.search_data == '' ? `/pages/index/search/search?q=${this.search_data}` : `/pages/index/search/result/result?kw=${this.search_data}`,
      });
    }, 100);

    // if (this.util.isWxMiniprogram()){
    //   window['wx'].miniProgram.navigateTo({
    //     url: `/pages/index/search/search?q=${this.search_data}`,
    //   })
    // }
  }
  goMap(ev, i: number){
    this.zone.run(() => {
      this.toNext(ev, this.listRoutes[i].url);
    });
  }
  openListMapPoup(ev) {
    ev.stopPropagation();

    this.cd.detectChanges();
  }
}
