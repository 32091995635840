import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-17_gjzdsss',
  templateUrl: './17_gjzdsss.component.html',
  styleUrls: ['./17_gjzdsss.component.styl']
})
export class FutureGjzdsssComponent implements OnInit {
  ttitle = '';

  data: any = [];

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.navigate(['/error'], { replaceUrl: true });
    return;
  }

  showFullMapEvent(i: number = -1) {
  }

}
