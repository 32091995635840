import { Component, OnInit, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { XMapService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-18_lnjyxx',
  templateUrl: './18_lnjyxx.component.html',
  styleUrls: ['./18_lnjyxx.component.styl']
})
export class LifeLnjyxxComponent implements OnInit {
  ttitle = '';

  kvs = {310115: 0, 310101: 1, 310106: 2, 310104: 3, 310105: 4, 310107: 5, 310109: 6, 310110: 7, 310113: 8, 310112: 9, 310114: 10, 310116: 11, 310117: 12, 310118: 13, 310120: 14, 310151: 15};
  bgColor    = '#4958e4';
  selBgColor = '#dd52e9';

  seli = 0;
  selii = 0;

  data = [{id: 1, name: '市级老年大学', rows: []}, {id: 2, name: '高校老年大学', rows: []}, {id: 3, name: '区级老年大学', rows: []}, {id: 4, name: '街镇老年大学', rows: []}];

  subtitle = '';
  rows = [];

  selectedI = 2;
  districtPanel = [];
  @ViewChild('mapSvgContainerRef', {static: true}) mapSvgContainerRefl: ElementRef;
  @ViewChild('mapSvgContainerRef1', {static: true}) mapSvgContainerRef1l: ElementRef;

  isListRrefreshMap = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private rd2: Renderer2,
    private cd: ChangeDetectorRef,
    private mapService: XMapService,
  ) { }

  transformResp(data: any): any {
    const ndata = [{id: 1, name: '市级老年大学', rows: []}, {id: 2, name: '高校老年大学', rows: []}, {id: 3, name: '区级老年大学', rows: []}, {id: 4, name: '街镇老年大学', rows: []}];
    const ctfs = {};
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.type < 4) { ndata[item.type - 1].rows.push(item); }

      if (item.type === 4) {
        if (!ctfs[item.dcode]) { ctfs[item.dcode] = {dcode: item.dcode, district: item.district, items: []}; }
        ctfs[item.dcode].items.push(item);
      }
    }
    Object.keys(ctfs).forEach(key => ndata[3].rows[this.kvs[key]] = ctfs[key]);
    // Object.keys(ctfs).forEach(key => ndata[3].rows.push(ctfs[key]))

    return ndata;
  }
  ngOnInit() {
    this.ttitle = MAPDIST.lnjyxx.title;
    this.http.get(MAPDIST.lnjyxx.api).toPromise().then((resp: any) => {
      this.data = this.transformResp(resp);
      this.selIndex();
    });

    window.mapEvent = (dcode: number) => {
      this.refreshMapPanel(dcode);
    };

    this.districtPanel = this.mapService.getDistrictSvg();
    this.renderHtml();
    this.refreshMapPanel();
  }
  refreshMapPanel(dcode: number = 310115) {
    this.removeSelectMapPanel();

    const doms = document.getElementsByClassName('dpath-' + dcode);
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];

      dom.setAttribute('fill', this.selBgColor);
      dom.classList.add('selected-map-panel');
    }

    if (this.isListRrefreshMap) {
      this.isListRrefreshMap = false;
    } else {
      // 地图刷新 列表
      for (let i = 0; i < this.data[3].rows.length; i++) {
        const distr = this.data[3].rows[i];
        if (distr.dcode === dcode) {
          this.selii = i;
          this.seliidex(true);
          this.cd.detectChanges();
          break;
        }
      }
    }
  }
  removeSelectMapPanel() {
    const doms = document.getElementsByClassName('selected-map-panel');
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill', this.bgColor);
      dom.classList.remove('selected-map-panel');
    }
    const ndoms = document.getElementsByClassName('selected-map-panel');
    if (ndoms.length > 0) { this.removeSelectMapPanel(); }
  }

  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.districtPanel.length; i++) {
      const item = this.districtPanel[i];
      const id = item.id;
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="mapEvent(' + id + ')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path class="dpath-' + id + '" onclick="mapEvent(' + id + ')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon class="dpath-' + id + '" onclick="mapEvent(' + id + ')" points="' + ele.points + '" fill="' + this.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
        }
      }
    }

    innerHTML += '</svg>';

    this.rd2.setProperty(this.mapSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.mapSvgContainerRef1l.nativeElement, 'innerHTML', innerHTML);
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/subpmap/lnjyxx/' + this.seli + '/' + i);
  }

  preProf(isSub: boolean = false) {
    if (isSub) {
      this.selii -= 1;
      if (this.selii === -1) { this.selii = this.data[this.seli].rows.length - 1; }
      this.seliidex();
      return;
    }
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.data.length - 1; }
    this.selIndex();
  }
  nextProf(isSub: boolean = false) {
    if (isSub) {
      this.selii += 1;
      if (this.selii === this.data[this.seli].rows.length) { this.selii = 0; }
      this.seliidex();
      return;
    }
    this.seli += 1;
    if (this.seli === this.data.length) { this.seli = 0; }
    this.selIndex();
  }
  selIndex() {
    if (this.seli === 3) {
      this.seliidex();
      return;
    }
    this.rows = this.data[this.seli].rows;
  }
  seliidex(isList: boolean = false) {
    this.subtitle = this.data[3].rows[this.selii].district;
    this.rows = this.data[this.seli].rows[this.selii].items;
    if (isList) { return; }

    this.isListRrefreshMap = true;
    for (let i = 0; i < this.districtPanel.length; i++) {
      const distid = this.districtPanel[i].id;
      if (distid === this.data[3].rows[this.selii].dcode) {
        this.selectedI = i;
        this.refreshMapPanel(distid);
        break;
      }
    }
  }
}
