<div class="hvzommer-wrapper" #hvzoomGroupContainerRef>
  <div class="hvzoomer-content"
      (pinchin)="pinchEvent('in', $event)"
      (pinchout)="pinchEvent('out', $event)"
      (panstart)="panstartEvent($event)"
      (panmove)="panmoveEvent($event)" #hvzoomBodyGroupContainerRef id="hvzoom-body" [ngStyle]="{'touch-action': 'pan-y'}">
    <ng-content></ng-content>
  </div> 
  <div class="mask" *ngIf="debug">
    <p>ptype：{{ptype}}</p>
    <p>scale：{{scale}}</p>
    <p>cLeft：{{cLeft}}</p>
    <p>cTop：{{cTop}}</p>
    <p>pointerX：{{pointerX}}</p>
    <p>pointerY：{{pointerY}}</p>
    <p>sgctrH: {{sgctrH}}</p>
    <p>sgctrW: {{sgctrW}}</p>
    <p>sscrfH: {{sscrfH}}</p>
    <p>sscrfW: {{sscrfW}}</p>
  </div>
</div>