import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/shared/config';
import { UtilsService } from 'src/app/shared/services';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class NewZlsjjyIndexComponent implements OnInit {

  deviceMode = null;

  data: any[] = [];

  constructor(
    private router: Router,
    private util: UtilsService
  ) { }

  ngOnInit() {
    this.data = ROUTES[2].data[0].subs[0].subs;
  }

  toTuoYu(url: string) {
    if (url == '/zlsjjy/210' && this.util.isWxMiniprogram()){
      const xurl = '/pages/home/sjzymap/sjzymap?type=1';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      return;
    }
    if (url == '/zlsjjy/211' && this.util.isWxMiniprogram()){
      const xurl = '/pages/home/map/map?type=5';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      return;
    }

    this.router.navigateByUrl(url);
  }

}
