import { Component, NgZone, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RunService, XMapService } from 'src/app/shared/services';
import { EventManager, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-red-subpmap',
  templateUrl: './subpmap.component.html',
  styleUrls: ['./subpmap.component.styl']
})
export class RedSubpmapComponent implements OnInit {

  isicon = false;
  swlab  = 0;
  swlabs = [];
  icondir = '';

  rurl = '';
  model = '';

  deviceMode = null;

  rows = [];
  tmp  = {};

  selMarker = null;

  map       = null;
  infoWin   = null;
  markers   = [];
  markerClusterer = null;

  selid = -1;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private title: Title,
    private runService: RunService,
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager,
  ) { }

  ngOnInit() {
    const model = this.route.snapshot.paramMap.get('model');
    this.swlab  = +this.route.snapshot.paramMap.get('swlab');
    const selid = +this.route.snapshot.paramMap.get('selid');
    if (selid > -1) { this.selid = selid; }

    const pObjt = MAPDIST[model];
    if (!pObjt) {
      this.location.back();
      return;
    }
    this.model  = model;

    this.swlabs = pObjt.labs;
    this.isicon = pObjt.icon;
    this.icondir = pObjt.icondir;

    this.title.setTitle('地图模式 - ' + pObjt.ptitle);
    this.rurl = pObjt.url;

    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });

    this.http.get(pObjt.api).toPromise().then((resp: any) => {
      switch (model) {
        case 'redxysssjyr':
          for (let i = 0; i < resp.length; i++) {
            const item = resp[i];
            item.pjnames = [];
            for (let j = 0; j < resp.length; j++) {
              const jtem = resp[j];
              if (jtem.name === item.name) { item.pjnames.push(jtem.pjname); }
            }
            resp[i] = item;
          }

          break;
        default:

      }
      this.rows = resp;
      this.xmapservice.loadQqMapJs(() => {
        this.loadFullMap();
      });
    });

    window.gotoView = (model: string, id: any) => {
      this.ngZone.run(() => {
        this.runService.isMapDetail = true;
        this.router.navigateByUrl('/red/d/' + model + '/' + id);
      });
    };
  }

  loadFullMap() {
    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 11,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new window.qq.maps.LatLngBounds(ne, sw)
    }));

    window.qq.maps.event.addListener(this.map, 'click', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });

    const MyOverlay = this.xmapservice.initMyRedOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => {
          this.swLab(this.swlab);
          // this.loadFullMapMarkers()
      }
    });
  }

  loadFullMapMarkers(kw: string = '') {
    this.infoWin.close(() => {this.closeMarker(); });

    const size = new window.qq.maps.Size(50, 57);
    let bicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, size);
    let biconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, size);

    if (this.isicon) {
      const id = this.swlabs[this.swlab].id;
      bicon   = new window.qq.maps.MarkerImage(this.icondir + '/ii' + id + '.png', null, null, null, size);
      biconed = new window.qq.maps.MarkerImage(this.icondir + '/ii' + id + 'ed.png', null, null, null, size);
    }

    let markers;
    if (environment.markerClusterer) {
      if (this.markerClusterer !== null) {
        this.markerClusterer.clearMarkers();
      }
      markers = new window.qq.maps.MVCArray();
    } else {
      markers = [];
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }

    const p = new RegExp(kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const obj = this.rows[i];

      let isCheck = false;
      // filter
      if (kw.length > 0) {
        switch (this.model) {
          case 'redcityculture':
          case 'redxysssjyr':
          case 'redzxxhsxyzj':
              isCheck = true;
              if (kw === obj.type) {
                isCheck = false;
              }
              break;
          case 'redshdzxhsjz':
            isCheck = true;
            if (kw === obj.type2) {
                isCheck = false;
              }
            break;
          default:
        }
      }

      if (isCheck || !obj.location) { continue; }

      let objLatLng;
      let myLatLng;

      const objlocation =  obj.location.split('；')[0];
      objLatLng = objlocation.split(',');
      if (objLatLng.length < 2) { objLatLng = ['31.240692', '121.501751']; }

      myLatLng = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);


      const marker = new window.qq.maps.Marker({
        position: myLatLng,
        icon: bicon,
        map: this.map,

        bicon, biconed
      });
      window.qq.maps.event.addListener(marker, 'click', () => {
        this.map.panTo(myLatLng);
        this.infoWin.xsetPosition(myLatLng);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        this.infoWin.open(() => {this.openMarker(marker); });
      });

      markers.push(marker);

      if (obj.id == this.selid) {
        this.map.setZoom(14);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        setTimeout(() => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
        }, 50);
        this.infoWin.open(() => {this.openMarker(marker); });
      }

    }
    this.markers = markers;

    if (environment.markerClusterer) {
      this.markerClusterer = new window.qq.maps.MarkerCluster({
        maxZoom: 18,
        gridSize: 40,
        map: this.map,
        markers,
        zoomOnClick: true,
        minimumClusterSize: 3,
      });
    }
  }

  getInfoWinHtml(obj: any) {
    let html = '';
    switch (this.model) {
      case 'redcityculture':
        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">微信公众号：</span>' + obj.wechat + '</div>';
        // html += '<div style="font-size: 12px;margin-bottom:10px"><span style="color: #c95b51">简介：</span>' + obj.intro + '</div>'
        html += '</div>';
        break;
      case 'redxysssjyr':
        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">项目：</span>' + obj.pjnames.join('、') + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'redxysssjyr\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/new/icon/red-detail.png"/> </div> ';
        html += '</div>';
        break;
      case 'redzxxhsxyzj':
        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.name + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'redzxxhsxyzj\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/new/icon/red-detail.png"/> </div> ';
        html += '</div>';
        break;
      case 'redshdzxhsjz':
        html += '<div style="padding:10px;color: #ffffff;background-color: #c95b51; font-size: 14px">' + obj.jzname + '</div>';
        html += '<div style="margin:10px;max-height:calc(50vh - 150px);overflow:scroll;color:#606060;-webkit-overflow-scrolling: touch">';
        if (obj.name ) { html += '<div style="font-size: 12px;"><span style="color: #c95b51">学校（单位）：</span>' + obj.name + '</div>'; }
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">区域：</span>' + obj.district + '</div>';
        html += '<div style="font-size: 12px;"><span style="color: #c95b51">地址：</span>' + obj.address + '</div>';
        html += '</div>';

        html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(\'redshdzxhsjz\',' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/new/icon/red-detail.png"/> </div> ';

        break;
      default:

    }

    return html;
  }

  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

  swLab(i: number) {
    this.swlab = i;
    this.loadFullMapMarkers(this.swlabs[i].val);
  }

  swLabPre() {
    this.swlab -= 1;
    if (this.swlab === -1) { this.swlab = this.swlabs.length - 1; }

    this.loadFullMapMarkers(this.swlabs[this.swlab].val);
  }
  swLabNex() {
    this.swlab += 1;
    if (this.swlab === this.swlabs.length) { this.swlab = 0; }

    this.loadFullMapMarkers(this.swlabs[this.swlab].val);
  }
}
