<app-rback rurl="/jyszhzx" *ngIf="!showexpl"></app-rback>

<app-xtheme theme="new0">
  <app-xtheme-hh>
    <div class="logoname jyszhzx-theme-color"> 
      {{title}} 
      <app-expl [aei]='10' (bindExplEvent)="showexpl = $event"></app-expl>
    </div>
    <div class="input-group">
      <div class="input" [ngClass]="{'active': kw.length > 0}">
        <input id="xinput-1" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
        <div class="img-clear" (click)="changeKw()">
          <img class="clear" src="/assets/imgs/search.png"/>
        </div>

        <div class="rlist-wrapper">
          <ng-container *ngFor="let item of rlist">
            <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="showFullMapEvent(item.id)"> </div>
          </ng-container>
          <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
        </div>

      </div>

      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt jyszhzx-theme-color">地图模式</div>
      </div>
 
    </div>
 
    <div class="map-group" >
      <div class="map-zoom">
        <app-hzoom> 
          <img src="/assets/imgs/gif/2022/207.gif"/>
        </app-hzoom>
      </div>
    </div>

    <div class="list-group">
      <app-liked-shared [mapkey]="'newshgxrgznzyrcpydt'"></app-liked-shared>

      <div class="lwtitle"> 
          <ng-container *ngFor="let tag of tags;let ti=index">
            <div class="title" [ngClass]="{'jyszhzx-theme-color': ti === tagi}" (click)="selied(ti)"> {{tag}} </div> 
          </ng-container> 
      </div> 

      <div class="lg-group" [ngStyle]="{'height':'272px'}">
        <div class="lggitem pjname" *ngFor="let item of rows;let i = index" >  
          <div class="lgleft">
            <div class="txt">
              {{i+1}}、{{item.name}}
            </div>
          </div>
          <div class="lgright">
            <div class="lgritem" *ngFor="let sub of item.subs" (click)="showFullMapEvent(sub.id)">
              <div class="txt">
                {{sub.subject}}
              </div>
            </div> 
          </div>
        </div>  
        <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
      </div>
    </div>
     
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-lw"> 
 
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt jyszhzx-theme-color">地图模式</div>
      </div>
 
      <div class="map-group" > 
          <img src="/assets/imgs/gif/2022/207.gif"/>  
      </div>
  
    </div>

    <div class="right-lw">
      <div class="logoname jyszhzx-theme-color"> 
         {{title}}  
         <app-expl [aei]='10' (bindExplEvent)="showexpl = $event"></app-expl>
      </div>
      <div class="input-group">
        <div class="input" [ngClass]="{'active': kw.length > 0}">
          <input id="xinput-1" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
          <div class="img-clear" (click)="changeKw()">
            <img class="clear" src="/assets/imgs/search.png"/>
          </div>
  
          <div class="rlist-wrapper">
            <ng-container *ngFor="let item of rlist">
              <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="showFullMapEvent(item.id)"> </div>
            </ng-container>
            <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
          </div>
  
        </div> 
      </div>
    
      <div class="list-group">
        <div class="lwtitle"> 
            <ng-container *ngFor="let tag of tags;let ti=index">
              <div class="title" [ngClass]="{'jyszhzx-theme-color': ti === tagi}" (click)="selied(ti)"> {{tag}} </div> 
            </ng-container> 
        </div> 
 
        <div class="lg-group" [ngStyle]="{'height':'calc(100vh - 144px)'}">
          <div class="lggitem pjname" *ngFor="let item of rows;let i = index" >  
            <div class="lgleft">
              <div class="txt">
                {{i+1}}、{{item.name}}
              </div>
            </div>
            <div class="lgright">
              <div class="lgritem" *ngFor="let sub of item.subs" (click)="showFullMapEvent(sub.id)">
                <div class="txt">
                  {{sub.subject}}
                </div>
              </div> 
            </div>
          </div>
        
          <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container> 
        </div>
      </div>
       
     
    </div>
  </app-xtheme-vv>
</app-xtheme>
