import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-3_jyyjtpgjdt',
  templateUrl: './3_jyyjtpgjdt.component.html',
  styleUrls: ['./3_jyyjtpgjdt.component.styl']
})
export class CityJyyjtpgjdtComponent implements OnInit {
  ttitle = '';
  showexpl = false;
  swlabs = [
    {
      hhtop: '20vw',
      hhheight: '22vw',
      vvtop: '12vh',
      vvheight: '15vh',
      name: '青海果洛'
    },
    {
      hhtop: '50vw',
      hhheight: '22vw',
      vvtop: '32vh',
      vvheight: '13vh',
      name: '新疆喀什'
    },
    {
      hhtop: '80vw',
      hhheight: '22vw',
      vvtop: '50vh',
      vvheight: '15vh',
      name: '西藏日喀则'
    },
    {
      hhtop: '108vw',
      hhheight: '30vw',
      vvtop: '68vh',
      vvheight: '22vh',
      name: '云南昆明'
    }
  ];

  constructor(
    private router: Router) { }

  ngOnInit() {
    this.ttitle = MAPDIST.jyyjtpgjdt.title;
  }

  toView(ev: any, i: number = -1) {
    this.router.navigateByUrl('/city/jyyjtpgjdt/' + i);
    ev.stopPropagation();
  }
}
