<app-rback rurl="/life/tsgz" ></app-rback>
<app-xtheme>
  <app-xtheme-hh> 
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color"> {{ttitle}} <br/>—— {{_ttitle}}</div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group">
      <div class="map-zoom"> 
        <img src="/assets/imgs/gif/tsgz-d-{{dcode}}.gif"/> 
        <div class="ph-list">
          <div class="plitem"  (click)="toView(item.id)" *ngFor="let item of rows" [ngStyle]="{'top': pss[item.id].hy + 'vw','left': pss[item.id].hx + 'vw'}"></div>
        </div> 
      </div> 
    </div>
    <div class="result-wrapper">
      <div class="list-group life-theme-color">
        <div class="lwtitle"> 学校 </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let row of rows" (click)="toView(row.id)">{{row.name}}</div>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt life-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group">
        <div class="map-zoom"> 
          <img src="/assets/imgs/gif/tsgz-d-{{dcode}}.gif"/>
          <div class="ph-list">
            <div class="plitem"  (click)="toView(item.id)" *ngFor="let item of rows" [ngStyle]="{'top': pss[item.id].vy + 'vh','left': pss[item.id].vx + 'vh'}"></div>
          </div> 
        </div>

      </div>
    </div>
    <div class="right-lw"> 
      <div class="header-wrapper">
        <div class="search"> 
          <div class="logo2name life-theme-color"> {{ttitle}} <br/>—— {{_ttitle}} </div> 
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle life-theme-color"> 学校 </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let row of rows" (click)="toView(row.id)">{{row.name}}</div>
          </div>
        </div>
      </div>
       
    </div>
   
  </app-xtheme-vv>
</app-xtheme>
  