<app-rback rurl="/life" ></app-rback>
<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">{{ttitle}}</div>
        <div class="input" *ngIf="false">
          <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-1" (keyup)="changeKw()"/>
          <div class="clear-img" (click)="closeKeyboard(1)">
            <img class="clear" src="/assets/imgs/search.png"/>
          </div>
        </div>
        
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" (click)="toView()">
      <div class="map-zoom">
        <app-hzoom> 
          <img src="/assets/imgs/gif/9_wdxsyslm_{{seli}}.gif"/>
        </app-hzoom>
      </div>
    </div>
 
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'wdxsyslm'"></app-liked-shared>

      <div class="icon-group">
          <div class="icon-item" *ngFor="let item of types; let i = index" (click)="lwlabel(i)">
            <img src="/assets/imgs/wdlm/i{{item.id + (seli === i ? 'ed':'')}}.png" />
          </div>
      </div>
      <div class="list-group">
        <ng-container *ngIf="kw.length === 0">
          <div class="lwlabel">
            <div class="lwitem life-theme-color"> {{types[seli].name}} </div>
            <!-- <div class="lwitem" *ngFor="let lw of types;let i = index" [ngClass]="{'life-theme-color': seli === i}" (click)="lwlabel(i)">{{lw.shortname}}</div> -->
          </div>
          <ng-container *ngIf="seli === 0">
            <div class="lwtitle life-theme-color">
              <div class="pre iicon" (click)="preProf()">
                <img class="ipre" src="/assets/imgs/city/top2.png"  />
              </div> 
              <div class="title">{{type2s[selii].name}}</div>
              <div class="next iicon" (click)="nextProf()">
                <img class="inext" src="/assets/imgs/city/top2.png" />
              </div> 
            </div>
          </ng-container>
          <ng-container *ngIf="seli===0">
            <div class="llitem" style="height: 0.1px;overflow: hidden;" *ngFor="let item of rows;">{{item.name}}</div>
          </ng-container> 
          <div class="lwtlist" [ngClass]="{'chorus': seli === 0}">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="kw.length > 0">
          <div class="lwlabel">
            <div class="lwitem life-theme-color">检索</div>
          </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>

            <div class="llitem " *ngIf="rows.length === 0">无数据</div>
          </div>
        </ng-container> 
      </div>
    </div>
    <div class="copytime">数据更新时间：2023年8月</div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-lw"> 
 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
        <div class="txt life-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()"> 
        <div class="map-zoom">
          <app-zoomer> 
            <img src="/assets/imgs/gif/9_wdxsyslm_{{seli}}.gif"/>
          </app-zoomer>
        </div>
      </div>
    </div>
    <div class="right-lw">
  
      <div class="header-wrapper"> 
       
        <div class="search"> 
          <div class="logoname2 life-theme-color">{{ttitle}}</div>
          <div class="input-group">
            <div class="input" *ngIf="false">
              <input [(ngModel)]="kw" (ngModelChange)="changeKw()" id="xinput-2"  (keyup)="changeKw()"/>
              <div class="clear-img" (click)="closeKeyboard(2)">
                <img class="clear" src="/assets/imgs/search.png"/>
              </div>
            </div> 
          </div>
          
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="icon-group">
          <div class="icon-item" *ngFor="let item of types; let i = index" (click)="lwlabel(i)">
            <img src="/assets/imgs/wdlm/i{{item.id + (seli === i ? 'ed':'')}}.png" />
          </div>
        </div>
        <div class="list-group"> 
          <ng-container *ngIf="kw.length === 0">
            <div class="lwlabel">
              <div class="lwitem life-theme-color"> {{types[seli].name}} </div>
              <!-- <div class="lwitem" *ngFor="let lw of types;let i = index" [ngClass]="{'life-theme-color': seli === i}" (click)="lwlabel(i)">{{lw.shortname}}</div> -->
            </div>
            <ng-container *ngIf="seli === 0">
              <div class="lwtitle life-theme-color">
                <div class="pre iicon" (click)="preProf()">
                  <img class="ipre" src="/assets/imgs/city/top2.png"  />
                </div> 
                <div class="title">{{type2s[selii].name}}</div>
                <div class="next iicon" (click)="nextProf()">
                  <img class="inext" src="/assets/imgs/city/top2.png" />
                </div> 
              </div>
            </ng-container>
            <div class="lwtlist" [ngClass]="{'chorus': seli === 0}" >
              <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="kw.length > 0">
            <div class="lwlabel">
              <div class="lwitem life-theme-color">检索</div>
            </div>
            <div class="lwtlist">
              <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.name}}</div>
              <div class="llitem " *ngIf="rows.length === 0">无数据</div>
            </div>
          </ng-container> 

        </div>
      </div>
       
      <div class="copytime">数据更新时间：2023年8月</div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
  