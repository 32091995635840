import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EventManager } from '@angular/platform-browser';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.styl']
})
export class LifeXqhjthAreaComponent implements OnInit {
  ttitle = '';

  showArea = false;
  showSA   = true;
  pathEvent = false;
  whObj = {
    baoshan: {w: 504, h: 614 },
    changning: {w: 586, h: 420 },
    chongming: {w: 650, h: 534 },
    fengxian: {w: 638, h: 412 },
    hongkou: {w: 380, h: 564 },
    huangpu: {w: 480, h: 582 },
    jiading: {w: 548, h: 618 },
    jingan: {w: 418, h: 606 },
    jinshan: {w: 632, h: 424 },
    minhang: {w: 602, h: 598 },
    pudong: {w: 576, h: 616 },
    putuo: {w: 624, h: 496 },
    qingpu: {w: 662, h: 588 },
    songjiang: {w: 622, h: 562 },
    xuhui: {w: 368, h: 596 },
    yangpu: {w: 436, h: 598 },
  };

  area = '';
  areas = {
    pudong: '浦东新区',
		huangpu: '黄浦区',
		jingan: '静安区',
		xuhui: '徐汇区',
		changning: '长宁区',
		putuo: '普陀区',
		hongkou: '虹口区',
		yangpu: '杨浦区',
		baoshan: '宝山区',
		minhang: '闵行区',
		jiading: '嘉定区',
		jinshan: '金山区',
		songjiang: '松江区',
		qingpu: '青浦区',
		fengxian: '奉贤区',
		chongming: '崇明区'
  };
  areasw = {
    pudong: 100,
		huangpu:  80,
		jingan:  80,
		xuhui:  80,
		changning: 100,
		putuo:  100,
		hongkou:  80,
		yangpu:  80,
		baoshan:  100,
		minhang:  100,
		jiading:  100,
		jinshan:  100,
		songjiang: 100,
		qingpu:  100,
		fengxian:  100,
		chongming:  100,
  };

  data: any;
  xqdist: any = []; // 全部学区
  rdata: any = { id: '', name: '', area: []};

  @ViewChild('areaSvgContainerRef', {static: true})  areaSvgContainerRefl: ElementRef;
  @ViewChild('areaSvgContainerRef2', {static: true}) areaSvgContainerRefl2: ElementRef;

  constructor(
    private rd2: Renderer2,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.xqhjth.title;
    this.pathEvent = true;

    this.eventManager.addEventListener(document.getElementById('xqhjth-area-wrapper') as HTMLElement, 'click', () => this.closeEvent() );
    this.eventManager.addEventListener(document.getElementById('xqhjth-area-wrapper2') as HTMLElement, 'click', () => this.closeEvent() );
    this.area = this.route.snapshot.paramMap.get('area');
    const xqhjth = this.route.snapshot.queryParamMap.get('xqhjth');

    this.http.get(MAPDIST.xqhjth.subapi + this.area + '.json?v=12345').toPromise().then((resp: any) => {
      this.data = resp;

      this.renderHtml();

      let isFoneValue = true;
      if (xqhjth) {
        this.rdata.id = xqhjth;
        this.switchArea();
        isFoneValue = false;
        this.showSA = false;
        this.showArea = true;
      }
      const xqdist = [];
      Object.keys(resp.data).forEach(key => {
        const item = resp.data[key];

        xqdist.push({id: key, name: item.name});

        if (isFoneValue) {
          this.rdata.id = key;
          this.switchArea();
          isFoneValue = false;
        }
      });
      this.xqdist = xqdist;
    });

    window.selectedArea = (ev: any, tt: any) => {
      this.rdata.id = ev;
      this.switchArea();
      this.showArea  = true;
      this.pathEvent = true;
      this.showSA = false;
    };
  }
  closeEvent() {
    if (this.pathEvent) {
      this.pathEvent = false;
      return;
    }
    this.showArea = false;
    this.showSA   = false;
  }

  renderHtml() {
    let innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" ';
    innerHTML += 'version="1.1" ';
    innerHTML += 'x="0" ';
    innerHTML += 'y="0"  ';
    innerHTML += 'viewBox="0 0 ' + this.whObj[this.area].w + ' ' + this.whObj[this.area].h + '" ';
    innerHTML += 'width="100%" ';
    innerHTML += 'style="" >';

    const shapes = this.data.shapes;
    Object.keys(shapes).forEach(key => {
      const path = shapes[key].trim();
      innerHTML += '    <path class="path-' + key + '" onclick="selectedArea(\'' + key + '\')" d="' + path + '" stroke="none" stroke-width="1" stroke-linejoin="round" fill="#ff0000" fill-opacity="0" />';
    });

    innerHTML += '</svg>';

    this.rd2.setProperty(this.areaSvgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.areaSvgContainerRefl2.nativeElement, 'innerHTML', innerHTML);
  }

  refreshMapPanel(area: string) {
    this.removeSelectMapPanel();

    const doms = document.getElementsByClassName('path-' + area);
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill-opacity', '1');
      dom.classList.add('path-selected');
    }
  }
  removeSelectMapPanel() {
    const doms = document.getElementsByClassName('path-selected');
    for (let i = 0; i < doms.length; i++)  {
      const dom = doms[i];
      dom.setAttribute('fill-opacity', '0');
      dom.classList.remove('path-selected');
    }
    const ndoms = document.getElementsByClassName('path-selected');
    if (ndoms.length > 0) { this.removeSelectMapPanel(); }
  }

  switchArea() {
    const area  = this.rdata.id;
    const rdata = this.data.data[area];
    this.refreshMapPanel(area);

    this.rdata.name = rdata.name;
    this.rdata.area = rdata.area;

    this.cd.detectChanges();
  }
  schooldist(ev: any, id: string) {
    this.rdata.id = id;
    this.switchArea();
    this.showArea = true;
    this.showSA = false;
    ev.stopPropagation();
  }

  openCloseList(ev: any) {
    if (this.showSA) {
      this.showSA   = false;
      this.showArea = true;
    } else {
      this.showSA   = true;
      this.showArea = false;
    }

    ev.stopPropagation();
  }
  schoolpEvent(ev: any) {
    this.showArea = !this.showArea;
    ev.stopPropagation();
  }

  emptyEvent(ev: any) {
    ev.stopPropagation();
  }
}
