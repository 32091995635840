import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClmapComponent } from 'src/app/shared/components';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { MAPDIST } from 'src/app/shared/config';

declare var wx: any;
@Component({
  selector: 'app-kexuejia-list', // app-tuoyu-list
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl'],
})
export class ScienceKeXueJiaListComponent implements OnInit {
  ttitle = '';
  rows: any = [];
  dcode = 0;
  dcodename = '';
  kw = '';
  title = '';
  resrows: any[] = [];
  constructor(
    private router: Router,
    private http: HttpClient,
    private ngZone: NgZone,
    private utilsService: UtilsService,
    private cd: ChangeDetectorRef ) { }

  @ViewChild('clmpref', { static: false }) public clmpref: ClmapComponent;
  ngOnInit() {
    this.ttitle = MAPDIST.science_kxjj.title;
    this.http.get(MAPDIST.science_kxjj.api).toPromise().then((rows: any) => {
      // console.log('科学家精神教育基地地图：', rows);

      // this.rows = rows;
      this.rows = [];
      this.clmpref.refreshMapPanel();
    });
  }
  switchDist(obj: {id: number, name: string}) {
    this.dcode = obj.id;
    this.dcodename = obj.name;
    const totalRow = [];
    let title = '';
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.dcode === this.dcode) {
        title = row.district;
        totalRow.push(row);
      }
    }
    this.resrows = totalRow;
    this.title = this.dcodename;
    if (this.kw.length > 0) { this.kw = ''; }
    this.cd.detectChanges();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.clmpref.refreshMapPanel(this.dcode);
      return;
    } else {
      this.clmpref.removeSelectMapPanel();
    }

    const totalRow = [];
    const p = new RegExp(this.kw, 'i');
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (p.test(row.company) || p.test(row.district) || p.test(row.address)) {
        totalRow.push(row);
      }
    }

    this.resrows = totalRow;
    this.title = '检索';
  }

  closeKeyboard(i: number) {
    document.getElementById('xinput-' + i).blur();
    this.changeKw();
  }

  toView(i: number = -1) {
    // return
    this.ngZone.run(() => this.router.navigateByUrl('/pmap/science_kxjj/' + i));
  }

}
