import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { PAGESUBTITLE } from '../../config';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.styl']
})
export class ErrorComponent implements OnInit {

  code = 0;

  constructor(
    private utilService: UtilsService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private title: Title) { }

  ngOnInit() {
    this.code = +this.route.snapshot.queryParamMap.get('code');
    if (this.code === 4040) { this.title.setTitle('抱歉出错了' + PAGESUBTITLE); }

    if (this.code === 4040 && this.utilService.isWx()) { this.router.navigate(['start'], { queryParams: {code: 4040}, replaceUrl: true }); }
  }

  goBack() {
    this.location.back();
  }
}
