<app-rback rurl="/life" *ngIf="!showexpl"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name life-theme-color">
          {{ttitle}}
          <app-expl [aei]='5' (bindExplEvent)="showexpl = $event"></app-expl>
        </div>
        <div class="input">
            <input [(ngModel)]="kw" (ngModelChange)="grows()" id="xinput-1" (keyup)="grows()"/>
            <div class="clear-img" (click)="closeKeyboard(1)">
              <img class="clear" src="/assets/imgs/search.png"/>
            </div>
          </div>
        <ng-container *ngIf="true">
          <div class="full-map-group" (click)="toView()" >
            <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
            <div class="txt life-theme-color">地图模式</div>
          </div> 
          <img class="compass" src="/assets/imgs/icon/compass.png" />
        </ng-container>
      </div>
    </div>
    <div class="map-group">
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/gif/12_zbgt-{{slabi}}.gif?id=1332322"/>
        </app-hzoom>
      </div> 
    </div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'zgzgtzbgt'"></app-liked-shared>

      <div class="list-group">
        <div class="title-lab">
          <div class="tl" *ngFor="let item of slabs;let i=index" [ngClass]="{'life-theme-color': slabi===i}" (click)="changeIType(i)">{{item.name}}</div>
        </div>
        <div class="th-group"> 
          <div class="major">专业</div>
          <div class="prename">中职</div>
          <div class="prearea">区</div> 
          <div class="nexname">{{slabi===0?'高职':'本科'}}</div>
        </div>
        <div class="lwlist">
          <ng-container *ngFor="let item of rows;let i = index" > 
            <div class="lwlitem"> 
              <div class="major lwltit" (click)="toView(1, item.id)"><div>{{item.major}}</div></div>
              <div class="preschool lwltit" (click)="toView(2, item.id)"><div>{{item.prename}}</div></div>
              <div class="prearea lwltit" (click)="toView(4, item.id)"><div>{{item.prearea}}</div></div> 
              <div class="nexschool lwltit" (click)="toView(3, item.id)"><div>{{item.nexname}}</div></div>
            </div> 
          </ng-container>
          <ng-container *ngIf="rows.length===0">
            <div class="lwlitem" style="text-align: center;display: block;"> 
              无数据
            </div> 
          </ng-container>
        </div>
      </div>
    </div>
    <div class="copytime">数据更新时间：2023年5月 </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
      <ng-container *ngIf="true">
        <div class="full-map-group"  (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt life-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </ng-container>
      <div class="map-group">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/12_zbgt-{{slabi}}.gif?id=1332322"/>
          </app-zoomer>
        </div>

      </div>
    </div>
    <div class="right-lw">
  
      <div class="header-wrapper"> 
       
        <div class="search"> 
          <div class="logoname2 life-theme-color">
            {{ttitle}}
            <app-expl [aei]='5' (bindExplEvent)="showexpl = $event"></app-expl>
          </div> 
          <div class="input-group">
            <div class="input">
              <input [(ngModel)]="kw" (ngModelChange)="grows()" id="xinput-2"  (keyup)="grows()"/>
              <div class="clear-img" (click)="closeKeyboard(2)">
                <img class="clear" src="/assets/imgs/search.png"/>
              </div>
            </div> 
          </div>
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
            <div class="title-lab">
              <div class="tl" *ngFor="let item of slabs;let i=index" [ngClass]="{'life-theme-color': slabi===i}" (click)="changeIType(i)">{{item.name}}</div>
            </div>
            <div class="th-group"> 
              <div class="major">专业</div>
              <div class="prename">中职</div>
              <div class="prearea">区</div> 
              <div class="nexname">{{slabi===0?'高职':'本科'}}</div>
            </div>
            <div class="lwlist">
              <ng-container *ngFor="let item of rows;let i = index" > 
                <div class="lwlitem"> 
                  <div class="major lwltit" (click)="toView(1, item.id)"><div>{{item.major}}</div></div>
                  <div class="preschool lwltit" (click)="toView(2, item.id)"><div>{{item.prename}}</div></div>
                  <div class="prearea lwltit" (click)="toView(4, item.id)"><div>{{item.prearea}}</div></div> 
                  <div class="nexschool lwltit" (click)="toView(3, item.id)"><div>{{item.nexname}}</div></div>
                </div> 
              </ng-container>
              <ng-container *ngIf="rows.length===0">
                <div class="lwlitem" style="text-align: center;display: block;"> 
                  无数据
                </div> 
              </ng-container>
            </div>
        </div>
      </div>
      
      <div class="copytime">数据更新时间：2023年5月</div>
    </div>
   
  </app-xtheme-vv>
</app-xtheme>
  