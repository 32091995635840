<div class="swiper-wrapper" [ngStyle]="{
    'width': width, 
    'height': height
}">
    <div class="swiper-group" #slider>  
        <div class="slide-swiper-wrapper" [ngStyle]="{'height': height}" #slideSwiperWrapper> 
            <ng-content select="app-swiper-item"></ng-content> 
        </div>
    </div>
    <div class="docs-wrapper" *ngIf="showPosition">
        <div class="doc"><span #currentPageIndexRef>1</span> / <span #totalPageRef>1</span> </div>
    </div>

    <div class="prenexpage-wrapper" *ngIf="isShowPager">
        <div class="pre totobtn" (click)="prePage()"><img src="/assets/imgs/icon/totoleft.png"/></div>
        <div class="nex totobtn" (click)="nextPage()"><img src="/assets/imgs/icon/totoright.png"/></div>
    </div>
</div>

