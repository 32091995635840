import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, NgZone, Renderer2, ViewChildren, AfterViewChecked, SimpleChanges } from '@angular/core';
import BScroll from '@better-scroll/core';
import Slide from '@better-scroll/slide';
import ObserveDOM from '@better-scroll/observe-dom';

// BScroll.use(ObserveDOM)
BScroll.use(Slide);

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwiperComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() isRefresh = false; // 是否可刷新
  @Input() isShowPager = true; // 是否显示 翻页器
  @Input() width  = '100%';
  @Input() height  = '50%';
  @Input() showPosition = true;  // 是否显示当前位置nav
  @Input() autoplay = true;  // 是否自动切换
  @Input() current  = 0;     // 当前所在滑块的 index
  @Input() interval  = 5000;  // 自动切换时间间隔：毫秒
  @Input() duration  = 500;   // 滑动动画时长
  @Input() circular = true;  // 是否采用衔接滑动
  @Input() vertical = false; // 滑动方向是否为纵向

  @Output() bindchange = new EventEmitter<number>(); // 当前变化的位置 index

  @ViewChild('slider', {static: true})                sliderEle: ElementRef;
  @ViewChild('slideSwiperWrapper', {static: true})    slideWrapperEle: ElementRef;
  @ViewChild('totalPageRef', {static: false})        totalSpanEle: ElementRef;
  @ViewChild('currentPageIndexRef', {static: false}) cpiSpanEle: ElementRef;

  slide: any     = null;
  playTimer: any     = null;
  sliderTimer: any     = null;

  constructor(private ngZone: NgZone, private rd2: Renderer2) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.sliderTimer);
      this.sliderTimer = setTimeout(() => {
        this.initStyleEr();
        this.loadSlider();
      }, 200);
    });
  }

  ngAfterViewChecked(): void {
    // console.log('ngAfterViewChecked ... ')
  }

  initStyleEr() {
    if (this.slide) { return; }
    this.slideWrapperEle.nativeElement.childNodes.forEach((sub: Node) => {
      if (sub.nodeType === 1) {
        this.rd2.setStyle(sub, 'width',  this.width);
        this.rd2.setStyle(sub, 'height', this.height);
        this.rd2.setStyle(sub, 'float',  'left');
      }
    });
    if (this.showPosition) {
      const total = this.slideWrapperEle.nativeElement.childElementCount;
      this.rd2.setProperty(this.totalSpanEle.nativeElement, 'innerText', total);
    }
  }

  loadSlider() {
    if (this.slide) {
      if (this.isRefresh) { this.slide.refresh(); }
      return;
    }
    // 参考文档： https://better-scroll.github.io/docs/zh-CN/plugins/slide.html#%E9%85%8D%E7%BD%AE
    const bscrollConfig = {
      scrollX: true,
      scrollY: false,
      slide: {
        loop: true,
        threshold: 100
      },
      momentum: false,
      bounce: false,
      stopPropagation: true,
      probeType: 2,
      click: true,
      // observeDOM: true // 开启对 scroll 区域 DOM 改变的探测
    };
    if (this.vertical) {
      bscrollConfig.scrollX = false;
      bscrollConfig.scrollY = true;
    }
    if (!this.circular) {
      bscrollConfig.slide.loop = false;
    }

    clearTimeout(this.playTimer);
    this.slide = new BScroll(this.sliderEle.nativeElement, bscrollConfig);

    this.slide.on('scrollEnd', () => {
      this.autoGoNext();
    });

    this.slide.on('beforeScrollStart', () => {
      clearTimeout(this.playTimer);
    });

    this.slide.on('slideWillChange', (page: {pageX: number}) => {
      this.bindchange.emit(page.pageX);

      if (this.showPosition) {
        this.rd2.setProperty(this.cpiSpanEle.nativeElement, 'innerText', page.pageX + 1);
      }
    });

    this.slide.goToPage(this.current);
    if (this.autoplay){
      setTimeout(() => {
        this.slide.goToPage(this.current + 1);
      }, this.interval);
    }
  }

  autoGoNext() {
    if (this.autoplay){
      clearTimeout(this.playTimer);
      this.playTimer = setTimeout(() => {
        this.nextPage();
      }, this.interval);
    }
  }

  nextPage() {
    if (this.slide) { this.slide.next(this.duration); }
  }

  prePage() {
    if (this.slide) { this.slide.prev(this.duration); }
  }

  ngOnDestroy(): void {
    clearTimeout(this.playTimer);
    if (this.slide) { this.slide.destroy(); }
  }

  outRefresh() {
    if (this.slide) { this.slide.refresh(); }
  }
  outPlay() {
    this.autoplay = true;
    this.autoGoNext();
  }
  outStop() {
    this.autoplay = false;
    clearTimeout(this.playTimer);
  }

}
