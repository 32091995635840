<app-rback [rsback]='true'></app-rback>

<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name red-theme-color">{{ttitle}}</div>

        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/new/imap.png"/>
          <div class="txt red-theme-color">地图模式</div>
        </div>
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" (click)="toView()">  
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/new/redgif/4-{{seli}}.gif?id=1234"/>
        </app-hzoom>
      </div> 
    </div>
 
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'redshdzxhsjz'"></app-liked-shared>
      <div class="list-group red-list-bg">
        
        <div class="lwtitle red-list-border-bottom ">
          <div class="tl red-list-border-left" *ngFor="let item of profs;let i=index" [ngClass]="{'red-theme-color': seli===i}" (click)="changeIType(i)">
            <div class="txt ">{{item.name}}</div>
          </div>
        </div> 
        <div class="lwtlist">
          <div class="llitem red-list-border-bottom"> 
            <div class="rightxt red-list-border-left red-list-border-right">
              <div class="rowtxt red-list-border-bottom">
                <div class="txt">建筑名称</div>
              </div>
            </div> 
            <div class="leftxt red-list-border-right center">
              <div class="txt">{{titles2[seli]}}</div>
            </div>  
        </div>
          <div class="llitem red-list-border-bottom" *ngFor="let item of rows; let i = index;" (click)="toView(item.id)"> 
              <div class="rightxt red-list-border-left red-list-border-right">
                <div class="rowtxt red-list-border-bottom" *ngFor="let jzname of item.jznames">
                  <div class="txt">{{jzname}}</div>
                </div>
              </div> 
              <div class="leftxt red-list-border-right">
                <div class="txt">{{item.name}}</div>
              </div> 
          </div>
        </div> 

      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/new/imap.png" />
        <div class="txt red-theme-color">地图模式</div>
      </div>
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()">  
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/new/redgif/4-{{seli}}.gif?id=1234"/>
          </app-zoomer>
        </div> 
      </div>
    </div>
    <div class="right-lw">
  
      <div class="header-wrapper"> 
       
        <div class="search"> 
          <div class="logoname2 red-theme-color">{{ttitle}}</div>
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group red-list-bg">

          <div class="lwtitle red-list-border-bottom ">
            <div class="tl red-list-border-left" *ngFor="let item of profs;let i=index" [ngClass]="{'red-theme-color': seli===i}" (click)="changeIType(i)">
              <div class="txt ">{{item.name}}</div>
            </div>
          </div> 
          <div class="lwtlist">
            <div class="llitem red-list-border-bottom"> 
              <div class="rightxt red-list-border-left red-list-border-right">
                <div class="rowtxt red-list-border-bottom">
                  <div class="txt">建筑名称</div>
                </div>
              </div> 
              <div class="leftxt red-list-border-right center">
                <div class="txt">{{titles2[seli]}}</div>
              </div>  
          </div>
            <div class="llitem red-list-border-bottom" *ngFor="let item of rows; let i = index;" (click)="toView(item.id)"> 
                <div class="rightxt red-list-border-left red-list-border-right">
                  <div class="rowtxt red-list-border-bottom" *ngFor="let jzname of item.jznames">
                    <div class="txt">{{jzname}}</div>
                  </div>
                </div> 
                <div class="leftxt red-list-border-right">
                  <div class="txt">{{item.name}}</div>
                </div> 
            </div>
          </div> 
          
        </div>
      </div>
       
    </div>
  
  </app-xtheme-vv>
</app-xtheme>
  