import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-shagzyxlshsj',
  templateUrl: './shagzyxlshsj.component.html',
  styleUrls: ['./shagzyxlshsj.component.styl']
})
export class RedShagzyxlshsjComponent implements OnInit {

  ttitle = '';

  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.redagzyjy.title;
    this.http.get(MAPDIST.redagzyjy.api).toPromise().then((data: any) => this.rows = data );
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/red/pmap/redagzyjy/' + i);
  }

}
