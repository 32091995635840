import { Injectable } from '@angular/core';
import { XHttpService } from './xhttp.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private xhttp: XHttpService;
  constructor(private http: HttpClient) {
    this.xhttp = new XHttpService(http);
  }

}
