import { Component, OnInit, NgZone } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { XMapService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-gjmap',
  templateUrl: './gjmap.component.html',
  styleUrls: ['./gjmap.component.styl']
})
export class CityGjmapComponent implements OnInit {
  deviceMode = null;

  data = [];

  map = null;
  infoWin = null;
  markers = [];
  markerids = [];
  markerClusterer = null;

  id = 0;
  icon = {1: '', 2: '', 3: '', 4: ''};
  iconed = {1: '', 2: '', 3: '', 4: ''};
  iconSeled = 3;
  icons = [
    {
      id: 1,
      txt: '人工智能',
      data: []
    },
    {
      id: 2,
      txt: '集成电路',
      data: []
    },
    {
      id: 3,
      txt: '生物医药',
      data: []
    },
    {
      id: 4,
      txt: '高校',
      data: []
    },
  ];

  selMarker = null;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    // const iconseled = +this.route.snapshot.queryParamMap.get('iconseled')
    // const id = +this.route.snapshot.queryParamMap.get('id')

    const iconseled = +this.route.snapshot.paramMap.get('iconseled');
    const id = +this.route.snapshot.paramMap.get('id');

    if (iconseled > -1) { this.iconSeled = iconseled; }
    if (id > 0) { this.id = id; }

    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });
    window.gotoView = (id: any) => {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/city/gdjydjgjzdzlzdzy/map/' + id);
      });
    };

    this.http.get(MAPDIST.gdjydjgjzdzlzdzy.api).toPromise().then((resp: any) => {
      this.data = resp;

      this.xmapservice.loadQqMapJs(() => {
        this.loadFullMap();
      });
    });
  }

  loadFullMap() {
    const size = new window.qq.maps.Size(50, 57);
    this.icon[1] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii1.png', null, null, null, size);
    this.icon[2] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii2.png', null, null, null, size);
    this.icon[3] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii3.png', null, null, null, size);
    this.icon[4] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii4.png', null, null, null, size);
    this.iconed[1] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii1ed.png', null, null, null, size);
    this.iconed[2] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii2ed.png', null, null, null, size);
    this.iconed[3] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii3ed.png', null, null, null, size);
    this.iconed[4] = new window.qq.maps.MarkerImage('/assets/imgs/city/ii4ed.png', null, null, null, size);
    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 11,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new window.qq.maps.LatLngBounds(ne, sw)
    }));

    window.qq.maps.event.addListener(this.map, 'click', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.infoWin.close(() => {this.closeMarker(); });
    });

    const MyOverlay = this.xmapservice.initMyOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => {
        this.loadFullMapMarkers(this.iconSeled);
      }
    });
  }

  loadFullMapMarkers(iconSeled: number = 3) {
    this.infoWin.close(() => {this.closeMarker(); });

    this.iconSeled = iconSeled;
    const iconId = this.icons[iconSeled].id;

    let markers;
    if (environment.markerClusterer) {
      if (this.markerClusterer !== null) {
        this.markerClusterer.clearMarkers();
      }
      markers = new window.qq.maps.MVCArray();
    } else {
      markers = [];
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }

    const markerids = [];
    for (let i = 0; i < this.data.length; i++) {
      const obj = this.data[i];

      if (iconSeled !== 3 && !obj.industry.includes(iconId) || markerids.includes(obj.id)) { continue; }

      const objLatLng = obj.coordinate.split(',');
      const myLatLng = new window.qq.maps.LatLng(objLatLng[0], objLatLng[1]);
      const marker = new window.qq.maps.Marker({
        position: myLatLng,
        icon: this.icon[iconId],
        map: this.map,
        bicon: this.icon[iconId],
        biconed: this.iconed[iconId]
      });

      window.qq.maps.event.addListener(marker, 'click', () => {
        this.map.panTo(myLatLng);
        this.infoWin.xsetPosition(myLatLng);
        this.infoWin.setContent(this.getInfoWinHtml(obj));
        this.infoWin.open(() => {this.openMarker(marker); });
      });

      markers.push(marker);
      markerids.push(obj.id);

      if (obj.id == this.id) {
        this.map.setZoom(14);

        this.infoWin.setContent(this.getInfoWinHtml(obj));
        setTimeout(() => {
          this.map.panTo(myLatLng);
          this.infoWin.xsetPosition(myLatLng);
        }, 50);
        this.infoWin.open(() => {this.openMarker(marker); });
      }
    }

    this.markers = markers;
    this.markerids = markerids;

    if (environment.markerClusterer) {
      this.markerClusterer = new window.qq.maps.MarkerCluster({
        maxZoom: 18,
        gridSize: 40,
        map: this.map,
        markers,
        zoomOnClick: true,
        minimumClusterSize: 3,
      });
    }
  }

  getInfoWinHtml(obj: any) {
    let html = '<div><div style="padding:10px;color: #ffffff;background-color: #60828e; font-size: 14px">' + obj.name + '</div>';
    html += '<div style="margin:10px;margin-bottom:0;color:#606060;">';

    html += '<div style="max-height:calc(50vh - 150px);overflow:scroll;-webkit-overflow-scrolling: touch;margin-bottom:10px">';
    html += '<div style="font-size: 12px"><span style="color: #9b7b7c">区域：</span>' + obj.district + '</div>';

    if (obj.entity) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">实体单位：</span>' + obj.entity + '</div>'; }
    if (obj.construction) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">成立时间：</span>' + obj.construction + '</div>'; }
    if (obj.address) { html += '<div style="font-size: 12px"><span style="color: #9b7b7c">地址：</span>' + obj.address + '</div>'; }

    // if (obj.introduction) html += '<div style="font-size: 12px"><span style="color: #9b7b7c">人工智能：</span>' + obj.introduction + '</div>'
    // if (obj.subject2) html += '<div style="font-size: 12px"><span style="color: #9b7b7c">集成电路：</span>' + obj.subject2 + '</div>'
    // if (obj.subject3) html += '<div style="font-size: 12px"><span style="color: #9b7b7c">生物医药：</span>' + obj.subject3 + '</div>'
    html += '</div>';

    // html += '<div style="font-size:0;line-height:0;display:flex;justify-content: center;" onclick="gotoView(' + obj.id + ')"> <img style="width:128px;height: 47.64px" src="/assets/imgs/icon/detail.png"/> </div> '
    html += '</div> </div>';
    return html;
  }


  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

}
