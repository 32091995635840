<app-rback [rsback]='true'></app-rback>
<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="logoname red-theme-color">{{ttitle}}</div>
   
    <div class="map-model-group" (click)="showFullMapEvent()">
      <img class="mmg-icon" src="/assets/imgs/new/imap.png"/> 
      <div class="txt red-theme-color">地图模式</div>
    </div>

    <div class="map-group">
      <div class="mg-container">
        <app-hzoom> 
          <div class="map-svg-dom" #mapSvgContainerRef ></div> 
        </app-hzoom>
      </div> 
    </div> 
 
    <div class="list-group red-list-bg">
      <app-liked-shared [mapkey]="'redshzxyzgxldt'"></app-liked-shared>
      <div class="lwtitle subfilter red-theme-color red-list-border-bottom">
        <div class="pre icon" (click)="preProf()">
          <img src="/assets/imgs/new/i-arrow-y.png" />
        </div>
        <div class="title"> {{titleii[selii]}} </div>
        <div class="next icon" (click)="nextProf()">
          <img src="/assets/imgs/new/i-arrow-y.png" />
        </div>
      </div>
      <div class="lg-group" [ngStyle]="{'height':true?'264px':''}">
        <div class="lggitem red-list-border-bottom">
          <div class="scname red-list-border-right red-list-border-left cccenter" > <div>中小学</div> </div>
          <div class="scmajors"> <div class="scpitem red-list-border-right" > <div>课程</div> </div> </div> 
        </div>
        <div class="lggitem red-list-border-bottom" *ngFor="let item of rows;let i = index" >   
          <div class="scname red-list-border-right red-list-border-left" (click)="showFullMapEvent(item.majors[0].id)">
            <div>{{i+1}}. {{item.name}}</div>
          </div>
          <div class="scmajors red-list-border-right">
            <div class="scpitem red-list-border-bottom " *ngFor="let pitem of item.majors" (click)="showFullMapEvent(pitem.id)">
              <div>{{pitem.subject}}</div>
            </div>
          </div> 
        </div>
        <ng-container *ngIf="rows.length===0">
          <div class="lggitem red-list-border-bottom">
            <div class="scname red-list-border-right red-list-border-left cccenter" > <div>无数据</div> </div> 
          </div>
        </ng-container> 
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/new/imap.png"/> 
        <div class="txt red-theme-color">地图模式</div>
      </div>
 
      <div class="map-group">
        <div class="mg-container">
          <!-- <app-hzoom> -->
            <div class="zoomer-wrapper"> 
              <div class="map-svg-dom" #mapSvgContainerRef1 ></div> 
            </div>
          <!-- </app-hzoom> -->
        </div> 
      </div>
    </div>
    <div class="right-wrapper">
      <div class="logoname red-theme-color">{{ttitle}}</div>
 
      <div class="list-group red-list-bg">
       
        <div class="lwtitle subfilter red-theme-color red-list-border-bottom">
          <div class="pre icon" (click)="preProf()">
            <img src="/assets/imgs/new/i-arrow-y.png" />
          </div>
          <div class="title"> {{titleii[selii]}} </div>
          <div class="next icon" (click)="nextProf()">
            <img src="/assets/imgs/new/i-arrow-y.png" />
          </div>
        </div>
        <div class="lg-group" [ngStyle]="{'height':true?'calc(100vh - 127px)':''}">
          <div class="lggitem red-list-border-bottom">
            <div class="scname red-list-border-right red-list-border-left cccenter" > <div>中小学</div> </div>
            <div class="scmajors"> <div class="scpitem red-list-border-right" > <div>课程</div> </div> </div> 
          </div>
          <div class="lggitem red-list-border-bottom" *ngFor="let item of rows;let i = index" >   
            <div class="scname red-list-border-right red-list-border-left" (click)="showFullMapEvent(item.majors[0].id)">
              <div>{{i+1}}. {{item.name}}</div>
            </div>
            <div class="scmajors red-list-border-right">
              <div class="scpitem red-list-border-bottom " *ngFor="let pitem of item.majors" (click)="showFullMapEvent(pitem.id)">
                <div>{{pitem.subject}}</div>
              </div>
            </div> 
          </div>
          <ng-container *ngIf="rows.length===0">
            <div class="lggitem red-list-border-bottom">
              <div class="scname red-list-border-right red-list-border-left cccenter" > <div>无数据</div> </div> 
            </div>
          </ng-container> 
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
   