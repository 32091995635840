import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';
import { RunService } from 'src/app/shared/services';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.styl']
})
export class LifeTsgzDistrictComponent implements OnInit {
  ttitle = '';
  _ttitle = '';
  dcode   = 0;
  dcodes: any     = {310115: '浦东新区', 310106: '静安区', 310104: '徐汇区', 310105: '长宁区', 310107: '普陀区', 310110: '杨浦区', 310112: '闵行区', 310113: '宝山区', 310114: '嘉定区', 310116: '金山区', 310151: '崇明区'};

  rows: any = [];

  pss: any = {
    // 310107 普陀区
    1:  {hx: 24, hy: 39, vx: 22, vy: 35}, // 上海市曹杨中学
    2:  {hx: 64, hy: 37, vx: 58, vy: 34}, // 上海市甘泉外国语中学
    3:  {hx: 68, hy: 47, vx: 62.5, vy: 43}, // 同济大学第二附属中学
    4:  {hx: 65, hy: 54, vx: 60, vy: 49}, // 上海音乐学院附属安师实验中学
    // 310115 浦东新区
    5:  {hx: 12, hy: 28, vx: 11, vy: 26}, // 上海海事大学附属北蔡高级中学
    6:  {hx: 18, hy: 17, vx: 16, vy: 16}, // 华东师范大学附属东昌中学
    // 310104 徐汇区
    7:  {hx: 38, hy: 13.5, vx: 34.5, vy: 13}, // 上海市徐汇中学
    // 310106 静安区
    8:  {hx: 25, hy: 21, vx: 23, vy: 19}, // 上海戏剧学院附属高级中学
    // 310105 长宁区
    9:  {hx: 66, hy: 30, vx: 60, vy: 28}, // 华东政法大学附属中学
    // 310110 杨浦区
    10: {hx: 48.5, hy: 36, vx: 45.5, vy: 32.5}, // 上海理工大学附属中学
    // 310114 嘉定区
    11: {hx: 56.5, hy: 56.5, vx: 52, vy: 52}, // 上海市嘉定二中
    // 310116 金山区
    12: {hx: 4, hy: 25, vx: 3.5, vy: 22.5},  // 华东师范大学附属枫泾中学
    16: {hx: 62, hy: 55.5, vx: 56, vy: 50.5},  // 上海师范大学第二附属中学
    // 310112 闵行区
    13: {hx: 40.5, hy: 69, vx: 36.5, vy: 62.5},  // 上海市闵行第三中学
    // 310115 浦东新区
    14: {hx: 13.5, hy: 21.5, vx: 11.5, vy: 19.5},  // 上海市香山中学
    // 310151 崇明区
    15: {hx: 19.5, hy: 35, vx: 17.5, vy: 32},  // 上海市崇明区城桥中学

     // 310113 宝山区
     17: {hx: 24.5, hy: 16, vx: 22.5, vy: 14.5}  // 上海师范大学附属罗店中学
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private runService: RunService
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.tsgz.title;
    const dcode = +this.route.snapshot.paramMap.get('dcode');
    const _ttitle = this.dcodes[dcode];
    if (_ttitle === undefined) {
      this.location.back();
      return;
    }
    this._ttitle = _ttitle;
    this.dcode = dcode;

    this.http.get(MAPDIST.tsgz.api).toPromise().then((data: any) => {
      const rows = [];
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        if (row.dcode === this.dcode) { rows.push(row); }
      }
      this.rows = rows;
    });
  }

  toView(id: number = -1) {
    this.runService.set('tsgz-district', this.dcode);
    this.router.navigateByUrl('/pmap/tsgz/' + id);
  }
}
