<app-rback rurl="/city" *ngIf="!showexpl"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="logoname city-theme-color">
      {{title}}
      <app-expl [aei]='8' (bindExplEvent)="showexpl = $event"></app-expl>
    </div>
    <div class="input-group">
      <div class="input" [ngClass]="{'active': kw.length > 0}">
        <input id="xinput-1" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
        <div class="img-clear" (click)="changeKw()">
          <img class="clear" src="/assets/imgs/search.png"/>
        </div>

        <div class="rlist-wrapper">
          <ng-container *ngFor="let item of rlist">
            <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="gotoView(item.id)"> </div>
          </ng-container>
          <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
        </div>

      </div>

      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt">地图模式</div>
      </div>

      <div class="btns-wrapper"  style="display: none;">
        <div class="btn" (click)="topi = 1"><img src="/assets/imgs/city/29/top1.png" /></div>
        <div class="btn" (click)="topi = 2"><img src="/assets/imgs/city/29/top2.png" /></div>
      </div>

    </div>

    <div class="map-group" >
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/gif/29_shjysswjbjscgdt-{{tagi}}.gif"/>
        </app-hzoom>
      </div>
    </div>

    <div class="list-group">
      <app-liked-shared [mapkey]="'shjysswjbjscgdt'"></app-liked-shared>

      <div class="lwtitle">
          <ng-container *ngFor="let tag of tags;let ti=index">
            <div class="title" [ngClass]="{'city-theme-color': ti === tagi}" (click)="selied(ti)"> {{tag.name}} </div>
          </ng-container>
      </div>
      <ng-container *ngIf="tagi === 0">
        <div class="lwtitle city-theme-color">
          <div class="ipre iicon" (click)="preProf()">
            <img src="/assets/imgs/city/top1.png"  />
          </div>
          <div class="ititle">{{this.districts[this.districti]}}</div>
          <div class="inext iicon" (click)="nextProf()">
            <img src="/assets/imgs/city/top1.png" />
          </div>
        </div>
      </ng-container>
      <div class="lg-group" [ngStyle]="{'height':tagi === 0?'231px':'272px'}">

        <ng-container *ngIf="tagi === 0 && districti > 0">
          <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="gotoView(item.subs[0].id)">
            {{i+1}}、{{item.name}}
          </div>
        </ng-container>
        <ng-container *ngIf="tagi === 0 && districti == 0">
          <div class="lggitem pjname" *ngFor="let item of rows;let i = index">
            <div class="lgleft">
              <div class="txt">
                {{i+1}}、{{item.name}}
              </div>
            </div>
            <div class="lgright">
              <div class="lgritem" *ngFor="let sub of item.subs" (click)="gotoView(sub.id)">
                <div class="txt">
                  {{sub.pjname}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="tagi === 1">
          <div class="lggitem pjname" *ngFor="let item of rows;let i = index" >
            <div class="lgleft">
              <div class="txt">
                {{i+1}}、{{item.name}}
              </div>
            </div>
            <div class="lgright">
              <div class="lgritem" *ngFor="let sub of item.subs" (click)="showFullMapEvent(sub.id)">
                <div class="txt">
                  {{sub.pjname}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container>
      </div>
    </div>

  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-lw">

      <div class="map-model-group" (click)="showFullMapEvent()">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt city-theme-color">地图模式</div>
      </div>

      <div class="map-group" >
          <img src="/assets/imgs/gif/29_shjysswjbjscgdt-{{tagi}}.gif"/>
      </div>

      <div class="btns-wrapper" style="display: none;">
        <div class="btn" (click)="topi = 1"><img src="/assets/imgs/city/29/top1.png" /></div>
        <div class="btn" (click)="topi = 2"><img src="/assets/imgs/city/29/top2.png" /></div>
      </div>

    </div>

    <div class="right-lw">
      <div class="logoname city-theme-color">
        {{title}}
        <app-expl [aei]='8' (bindExplEvent)="showexpl = $event"></app-expl>
      </div>
      <div class="input-group">
        <div class="input" [ngClass]="{'active': kw.length > 0}">
          <input id="xinput-1" placeholder="请输入关键字" [(ngModel)]="kw" (ngModelChange)="changeKw()" (keyup)="changeKw()"/>
          <div class="img-clear" (click)="changeKw()">
            <img class="clear" src="/assets/imgs/search.png"/>
          </div>

          <div class="rlist-wrapper">
            <ng-container *ngFor="let item of rlist">
              <div class="rlwitem spanfoo" [innerHTML]="item.name" (click)="gotoView(item.id)"> </div>
            </ng-container>
            <div class="rlwitem" *ngIf="rlist.length === 0 && kw.length > 0"> 无数据 </div>
          </div>

        </div>
      </div>

      <div class="list-group">
        <div class="lwtitle">
            <ng-container *ngFor="let tag of tags;let ti=index">
              <div class="title" [ngClass]="{'city-theme-color': ti === tagi}" (click)="selied(ti)"> {{tag.name}} </div>
            </ng-container>
        </div>
        <ng-container *ngIf="tagi === 0">
          <div class="lwtitle city-theme-color">
            <div class="ipre iicon" (click)="preProf()">
              <img src="/assets/imgs/city/top1.png"  />
            </div>
            <div class="ititle">{{this.districts[this.districti]}}</div>
            <div class="inext iicon" (click)="nextProf()">
              <img src="/assets/imgs/city/top1.png" />
            </div>
          </div>
        </ng-container>
        <div class="lg-group" [ngStyle]="{'height':tagi === 0?'calc(100vh - 185px)':'calc(100vh - 144px)'}">

          <ng-container *ngIf="tagi === 0 && districti > 0">
            <div class="lggitem" *ngFor="let item of rows;let i = index" (click)="gotoView(item.subs[0].id)">
              {{i+1}}、{{item.name}}
            </div>
          </ng-container>
          <ng-container *ngIf="tagi === 0 && districti == 0">
            <div class="lggitem pjname" *ngFor="let item of rows;let i = index" >
              <div class="lgleft">
                <div class="txt">
                  {{i+1}}、{{item.name}}
                </div>
              </div>
              <div class="lgright">
                <div class="lgritem" *ngFor="let sub of item.subs" (click)="gotoView(sub.id)">
                  <div class="txt">
                    {{sub.pjname}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tagi === 1">
            <div class="lggitem pjname" *ngFor="let item of rows;let i = index" >
              <div class="lgleft">
                <div class="txt">
                  {{i+1}}、{{item.name}}
                </div>
              </div>
              <div class="lgright">
                <div class="lgritem" *ngFor="let sub of item.subs" (click)="showFullMapEvent(sub.id)">
                  <div class="txt">
                    {{sub.pjname}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="rows.length===0"><div class="lggitem" style="text-align: center;">无数据</div>  </ng-container>
        </div>
      </div>


    </div>
  </app-xtheme-vv>
</app-xtheme>

<div class="top10-popup-wrapper" [@openClose]="topi > 0 ? 'open' : 'closed'">
  <div class="tpw-content">
    <div class="tpw-close-group" (click)="topi = 0">
      <img src="/assets/imgs/main/close.png"/>
    </div>
    <div class="tpw-body">
      <div class="ptitle city-theme-color">{{tops[topi].name}}</div>
      <div class="memo-wrapper">{{tops[topi].memo}}</div>
      <div class="tops-group">

        <div class="top-group">
          <div class="pptitle city-theme-color">基教TOP10</div>
          <div class="prows">
            <div class="prow" *ngFor="let sub of tops[topi].top10.basicschool">
              <div class="prleft"><div class="txt">{{sub.district}}</div></div>
              <div class="prright"><div class="txt">{{sub.count}}</div></div>
            </div>
          </div>
        </div>
        <div class="top-group">
          <div class="pptitle city-theme-color">高教TOP10</div>
          <div class="prows">
            <div class="prow" *ngFor="let sub of tops[topi].top10.highschool">
              <div class="prleft"><div class="txt">{{sub.school}}</div></div>
              <div class="prright"><div class="txt">{{sub.count}}</div></div>
            </div>
          </div>
        </div>

        <!-- <div class="top-group" *ngFor="let top of tops[topi].data">
          <div class="pptitle city-theme-color">{{top.name}}</div>
          <div class="prows">
            <div class="prow" *ngFor="let sub of top.rows">
              <div class="prleft"><div class="txt">{{sub.name}}</div></div>
              <div class="prright"><div class="txt">{{sub.qty}}</div></div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</div>
