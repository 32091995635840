<app-rback rurl="/red" [rsback]='true'></app-rback>
<app-xtheme theme='red'>
  <app-xtheme-hh>
    <div class="logo-wrapper"><img src="/assets/imgs/new/red2/i-red-theme-logo.png?i=0928"/></div>
    <div class="map-wrapper"><img src="/assets/imgs/new/i-map.png"/></div> 
    <div class="title">
      <img src="/assets/imgs/new/red2/i-red-theme-in.png" />
    </div>
    <div class="section-wrapper">
      <div class="sw-item" *ngFor="let item of data" >
        <div class="intro">
          <p *ngFor="let sub of item.subs" (click)="toTuoYu(sub.url)">{{sub.name}}</p>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>
    <div class="left-wrapper">
      <div class="logo-wrapper"><img src="/assets/imgs/new/red2/i-red-theme-logo.png?i=0928"/></div>
        
      <div class="map-wrapper"><img src="/assets/imgs/new/i-map.png"/></div> 
    </div>
    <div class="right-wrapper">
      <div>
        
        <div class="title">
          <img src="/assets/imgs/new/red2/i-red-theme-in.png" />
        </div>

        <div class="section-wrapper">
          <div class="sw-item" *ngFor="let item of data" >
            <div class="intro">
              <p *ngFor="let sub of item.subs" (click)="toTuoYu(sub.url)">{{sub.name}}</p>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </app-xtheme-vv>
</app-xtheme>
  