import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/services';
import { Location } from '@angular/common';
import { MAPDIST } from 'src/app/shared/config';
@Component({
  selector: 'app-210_newsjswfzjysjqdt',
  templateUrl: './210_newsjswfzjysjqdt.component.html',
  styleUrls: ['./210_newsjswfzjysjqdt.component.styl']
})
export class NewSjswfzjysjqdtComponent implements OnInit {

  ttitle = '';
  constructor(
    private util: UtilsService,
    private location: Location
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newsjswfzjysjqdt.title;
    if (this.util.isWxMiniprogram()){
      // window.parent.postMessage('navigate:/pages/index/search/map/map?category=8', '*');//后面的*号就是处理跨域问题的
      const xurl = '/pages/home/sjzymap/sjzymap?type=1';
      window.parent.postMessage(`navigate:${xurl}`, '*');
      window.wx.miniProgram.navigateTo({
        url: xurl,
      });
      setTimeout(() => {
        this.location.back();
      }, 800);
      // window['wx'].miniProgram.navigateTo({
      //   url: `/pages/index/search/map/map?category=8`,
      // })
    }
  }

}
