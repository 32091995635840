<app-guide [pici]="5"></app-guide>
<app-rback rurl="/life/xqhjth"></app-rback>
<app-xtheme>
    <app-xtheme-hh id='xqhjth-area-wrapper'>
      <div class="container">
         
        <div class="logoname life-theme-color">{{ttitle}} </div>
 
        <div class="svg-wrapper" [ngStyle]="{'height': (whObj[area].h / whObj[area].w * 100)+ 'vw'}">
          <div class="svg-group" [ngStyle]="{'width': areasw[area] + '%', 'height': areasw[area] + '%'}" >
            <app-hzoom> 
                <div class="svg-container" #areaSvgContainerRef [ngStyle]="{'background-image': 'url(/assets/tmp/json/life/xqhjth/bg/' + area + '.png?id=133)'}"></div> 
            </app-hzoom>
          </div>
        </div>
 
        <div class="area-group"> 
          <div class="bottom-btn" (click)="openCloseList($event)" >

            <div class="listdist" *ngIf="showArea">
              <div class="list-group" >
                <div class="item" *ngFor="let item of rdata.area" (click)="emptyEvent($event)">{{item.name}}</div>
              </div> 
              <div class="list-title" (click)="schoolpEvent($event)">{{rdata.name}}</div>
            </div>

            <div class="listdist" *ngIf="showSA">
              <div class="list-group">
                <div class="item" 
                  [ngClass]="{ 'active': item.id === rdata.id }" 
                  *ngFor="let item of xqdist"
                  (click)="schooldist($event, item.id)" >{{item.name}}</div>
              </div> 
            </div>

            <div class="title-toto">
              <img class="icon" src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showSA}"/>
              <div class="label-txt">{{areas[area]}}分布图</div> 
            </div>
            
            <img class="label" src="/assets/imgs/icon/xqhjth-sub-area-label.png"/>
          </div>
        </div>
        
      </div> 
    </app-xtheme-hh>
    <app-xtheme-vv id='xqhjth-area-wrapper2'>
      <div class="left-lw">
        <div class="svg-wrapper" [ngStyle]="{'width': (whObj[area].w / whObj[area].h * 100)+ 'vh'}"> 
            <app-zoomer> 
              <div class="svg-container" #areaSvgContainerRef2 [ngStyle]="{'background-image': 'url(/assets/tmp/json/life/xqhjth/bg/' + area + '.png?id=133)'}"></div> 
            </app-zoomer>
        </div>
        
      </div>
      <div class="right-lw">

        <div class="logoname life-theme-color">{{ttitle}} </div>
  
        <div class="area-group"> 
          <div class="bottom-btn" (click)="openCloseList($event)" >
            <div class="listdist" *ngIf="showArea">
              <div class="list-group" >
                <div class="item" *ngFor="let item of rdata.area" (click)="emptyEvent($event)">{{item.name}}</div>
              </div> 
              <div class="list-title" (click)="schoolpEvent($event)">{{rdata.name}}</div>
            </div>

            <div class="listdist" *ngIf="showSA">
              <div class="list-group">
                <div class="item" 
                  [ngClass]="{ 'active': item.id === rdata.id }" 
                  *ngFor="let item of xqdist"
                  (click)="schooldist($event, item.id)" >{{item.name}}</div>
              </div> 
            </div>

            <div class="title-toto">
              <img class="icon" src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showSA}"/>
              <div class="label-txt">{{areas[area]}}分布图</div> 
            </div>
            
            <img class="label" src="/assets/imgs/icon/xqhjth-sub-area-label.png"/>
          </div>
        </div>
        
      </div>
    </app-xtheme-vv>
  </app-xtheme>