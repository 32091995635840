<app-rback rurl="/future"></app-rback>

<app-xtheme>
  <app-xtheme-hh>
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name future-theme-color">{{ttitle}}</div>

        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
          <div class="txt future-theme-color">地图模式</div>
        </div>
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group" (click)="toView()">
      <div class="map-zoom">
        <app-hzoom>
          <img src="/assets/imgs/gif/21_sedshsjjd-{{seli}}.gif"/>
        </app-hzoom>
      </div>
    </div>

    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'sedshsjjd'"></app-liked-shared>

      <div class="list-group">
        <div class="lwtitle future-theme-color">
          <div class="pre iicon" (click)="preProf()">
            <img class="ipre" src="/assets/imgs/city/top3.png"/>
          </div>
          <div class="title">{{profs[seli]}}</div>
          <div class="next iicon" (click)="nextProf()">
            <img class="inext" src="/assets/imgs/city/top3.png"/>
          </div>
        </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.place}}</div>
          <div class="llitem" *ngIf="rows.length===0">无数据</div>
        </div>
        <div class="llitem " style="height: 0.1px;overflow: hidden;" *ngFor="let item of rows; ">{{item.place}}</div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw">

      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
        <div class="txt future-theme-color">地图模式</div>
      </div>
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group" (click)="toView()">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/21_sedshsjjd-{{seli}}.gif"/>
          </app-zoomer>
        </div>
      </div>
    </div>
    <div class="right-lw">

      <div class="header-wrapper">

        <div class="search">
          <div class="logoname2 future-theme-color">{{ttitle}}</div>
        </div>
      </div>

      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle future-theme-color">
            <div class="pre iicon" (click)="preProf()">
              <img class="ipre" src="/assets/imgs/city/top3.png"/>
            </div>
            <div class="title">{{profs[seli]}}</div>
            <div class="next iicon" (click)="nextProf()">
              <img class="inext" src="/assets/imgs/city/top3.png"/>
            </div>
          </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let item of rows; let i = index;" (click)="toView(item.id)">{{i + 1}}. {{item.place}}</div>
            <div class="llitem" *ngIf="rows.length===0">无数据</div>
          </div>
        </div>
      </div>

    </div>

  </app-xtheme-vv>
</app-xtheme>
