import { Component, OnInit, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EventManager } from '@angular/platform-browser';
import { XMapService } from 'src/app/shared/services';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-xqhjth',
  templateUrl: './xqhjth.component.html',
  styleUrls: ['./xqhjth.component.styl']
})
export class LifeXqhjthComponent implements OnInit {

  constructor(
    private rd2: Renderer2,
    private http: HttpClient,
    private router: Router,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private xmapService: XMapService,
    private eventManager: EventManager
  ) { }
  ttitle = '';
  showexpl = false;
  mode = 1;
  showArea = true;

  kw = '';

  area = '';
  rows: any;
  data: any;

  lareas = [
    [
      {id: 'pudong', txt: '浦东新区'},
      {id: 'huangpu', txt: '黄浦区'},
    ], [
      {id: 'jingan', txt: '静安区'},
      {id: 'xuhui', txt: '徐汇区'},
    ], [
      {id: 'changning', txt: '长宁区'},
      {id: 'putuo', txt: '普陀区'},
    ], [
      {id: 'hongkou', txt: '虹口区'},
      {id: 'yangpu', txt: '杨浦区'},
    ], [
      {id: 'baoshan', txt: '宝山区'},
      {id: 'minhang', txt: '闵行区'},
    ], [
      {id: 'jiading', txt: '嘉定区'},
      {id: 'jinshan', txt: '金山区'},
    ], [
      {id: 'songjiang', txt: '松江区'},
      {id: 'qingpu', txt: '青浦区'},
    ], [
      {id: 'fengxian', txt: '奉贤区'},
      {id: 'chongming', txt: '崇明区'}
    ],
  ];

  areas = [
    {id: 'pudong', txt: '浦东新区'},
		{id: 'huangpu', txt: '黄浦区'},
		{id: 'jingan', txt: '静安区'},
		{id: 'xuhui', txt: '徐汇区'},
		{id: 'changning', txt: '长宁区'},
		{id: 'putuo', txt: '普陀区'},
		{id: 'hongkou', txt: '虹口区'},
		{id: 'yangpu', txt: '杨浦区'},
		{id: 'baoshan', txt: '宝山区'},
		{id: 'minhang', txt: '闵行区'},
		{id: 'jiading', txt: '嘉定区'},
		{id: 'jinshan', txt: '金山区'},
		{id: 'songjiang', txt: '松江区'},
		{id: 'qingpu', txt: '青浦区'},
		{id: 'fengxian', txt: '奉贤区'},
		{id: 'chongming', txt: '崇明区'}
  ];

  kvs = {
    310101: {name: '黄浦区', val: 'huangpu', bgColor: '#e02815'},
    310104: {name: '徐汇区', val: 'xuhui', bgColor: '#e17133'},
    310105: {name: '长宁区', val: 'changning', bgColor: '#e17133'},
    310106: {name: '静安区', val: 'jingan', bgColor: '#e02815'},
    310107: {name: '普陀区', val: 'putuo', bgColor: '#e17133'},
    310109: {name: '虹口区', val: 'hongkou', bgColor: '#e02815'},
    310110: {name: '杨浦区', val: 'yangpu', bgColor: '#e02815'},
    310112: {name: '闵行区', val: 'minhang', bgColor: '#e2963c'},
    310113: {name: '宝山区', val: 'baoshan', bgColor: '#e17133'},
    310114: {name: '嘉定区', val: 'jiading', bgColor: '#e2963c'},
    310115: {name: '浦东新区', val: 'pudong', bgColor: '#e17133'},
    310116: {name: '金山区', val: 'jinshan', bgColor: '#deb553'},
    310117: {name: '松江区', val: 'songjiang', bgColor: '#deb553'},
    310118: {name: '青浦区', val: 'qingpu', bgColor: '#deb553'},
    310120: {name: '奉贤区', val: 'fengxian', bgColor: '#e2963c'},
    310151: {name: '崇明区', val: 'chongming', bgColor: '#deb553'},
  };

  @ViewChild('svgContainerRef', {static: true})  svgContainerRefl: ElementRef;
  @ViewChild('svgContainerRef2', {static: true}) svgContainerRefl2: ElementRef;

  @ViewChild('svgContainerRef21', {static: true}) svgContainerRefl21: ElementRef;
  @ViewChild('svgContainerRef22', {static: true}) svgContainerRefl22: ElementRef;

  kwdist = {};
  rlist  = [];
  showAreaOnOff = false;

  ngOnInit() {
    this.ttitle = MAPDIST.xqhjth.title;

    this.eventManager.addEventListener(document.getElementById('xqhjth-wrapper') as HTMLElement, 'click', () => this.closeAreaEvent() );
    this.eventManager.addEventListener(document.getElementById('xqhjth-wrapper2') as HTMLElement, 'click', () => this.closeAreaEvent() );

    window.toarea = (ev: string) => {
      this.ngZone.run(() => this.router.navigateByUrl('/life/xqhjth/' + ev));
      this.showAreaOnOff = true;
    };

    this.render();
    this.render2();
  }
  closeAreaEvent() {
    if (this.showAreaOnOff) {
      this.showAreaOnOff = false;
      return;
    }
    this.showArea = false;
  }

  render() {
    this.data = this.xmapService.getDistrictSvg();
    this.renderHtml();
  }
  renderHtml() {
    let innerHTML = '<svg class="ele-svg-dom" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 700"';
    innerHTML += 'style="width: 100%; height: 100%" >';
    innerHTML += '<defs> <style>';
    innerHTML += '  .cls-1 { font-size: 16px; fill: #ffffff;font-family: "PingFang SC"; font-weight: 500; }';
    innerHTML += '</style> </defs>';

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const id = item.id;
      const kvitem = this.kvs[id];
      for (let ii = 0; ii < item.data.length; ii ++) {
        const ele = item.data[ii];
        ele.x += 34;
        ele.y -= 60;
        switch (ele.type) {
          case 'text':
            let style = '';
            if (ele.fs) {
              style = 'font-size: ' + ele.fs + 'px';
            }
            innerHTML += '<text onclick="toarea(\'' + kvitem.val + '\')" data-name="' + ele.name + '" style="' + style + '" class="cls-1" x="' + ele.x + '" y="' + ele.y + '">' + ele.name;
            if (ele.tspan) {
              innerHTML += '<tspan dx="' + ele.tspan.dx + '" dy="' + ele.tspan.dy + '">' + ele.tspan.txt + '</tspan>';
            }
            innerHTML += '</text>';
            break;
          case 'path':
            innerHTML += '<path onclick="toarea(\'' + kvitem.val + '\')" d="' + ele.val + '" transform="translate(-159.97 -67.95)" fill="' + kvitem.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          case 'polygon':
            innerHTML += '<polygon onclick="toarea(\'' + kvitem.val + '\')" points="' + ele.points + '" fill="' + kvitem.bgColor + '" stroke="#ffffff" stroke-width="2"/>';
            break;
          default:
        }
      }
    }

    innerHTML += '</svg>';

    this.rd2.setProperty(this.svgContainerRefl.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.svgContainerRefl2.nativeElement, 'innerHTML', innerHTML);
  }

  render2() {
    this.http.get(MAPDIST.xqhjth.api + '?v=0821').toPromise().then((rows: any) => {

      // let areas = Object.keys(rows.shapes);
      // let cxdata = {};
      // for (const area of areas) {
      //   this.http.get(MAPDIST.xqhjth.subapi + area + '.json').toPromise().then((resp: any) => {
      //     Object.keys(resp.data).forEach(jta => {
      //       for (const jtaitem of resp.data[jta].area) {
      //         if(!cxdata[area]) cxdata[area] = {};
      //         if(!cxdata[area][jta]) cxdata[area][jta] = [];

      //         cxdata[area][jta].push(jtaitem.name);
      //       }
      //     })
      //     console.log(JSON.stringify(cxdata))
      //   })
      // }

      this.rows = rows.shapes;
      this.kwdist = rows.data;
      this.render2Html();
    });
  }
  render2Html() {
    let innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" ';
    innerHTML += 'version="1.1" ';
    innerHTML += 'x="0" ';
    innerHTML += 'y="0"  ';
    innerHTML += 'viewBox="0 0 597 620" ';
    innerHTML += 'width="100%" ';
    innerHTML += 'style="" >';

    const shapes = this.rows;
    Object.keys(shapes).forEach(key => {
      const path = shapes[key].trim();
      innerHTML += '    <path class="path-' + key + '" onclick="toarea(\'' + key + '\')" data-id="' + key + '" d="' + path + '" stroke="none" stroke-width="1" stroke-linejoin="round" fill="#ff0000" fill-opacity="0" />';
    });

    innerHTML += '</svg>';

    this.rd2.setProperty(this.svgContainerRefl21.nativeElement, 'innerHTML', innerHTML);
    this.rd2.setProperty(this.svgContainerRefl22.nativeElement, 'innerHTML', innerHTML);
  }

  toArea(area: string, ev: any) {
    this.router.navigateByUrl('/life/xqhjth/' + area);
    ev.stopPropagation();
  }

  showList(ev: any) {
    this.showArea = !this.showArea;
    ev.stopPropagation();
  }

  changeKw() {
    if (this.kw.length === 0) {
      this.rlist = [];
      return;
    }
    const limit = 4;
    let ij = 0;
    const rlist = {};
    const p = new RegExp(this.kw, 'i');
    Object.keys(this.kwdist).forEach(area => {
      if (ij > limit) { return; }

      const areas = this.kwdist[area];
      Object.keys(areas).forEach(xqhjth => {
        if (ij > limit) { return; }

        const xqhjths = areas[xqhjth];
        for (let i = 0; i < xqhjths.length; i++) {
          let name = xqhjths[i];
          if (p.test(name)) {
            if (ij > limit) { break; }

            if (!rlist[area]) { rlist[area] = {}; }
            if (!rlist[area][xqhjth]) { rlist[area][xqhjth] = []; }

            name = name.replace(this.kw, '<span>' + this.kw + '</span>');
            rlist[area][xqhjth].push(name);
            ij ++;
          }
        }
      });
    });
    const nrlist = [];
    Object.keys(rlist).forEach(area => {
      const areas = rlist[area];
      Object.keys(areas).forEach(xqhjth => {
        const xqhjths = areas[xqhjth];
        for (let i = 0; i < xqhjths.length; i++) {
          const name = xqhjths[i];
          nrlist.push({area, xqhjth, name});
        }
      });
    });
    this.rlist = nrlist;
  }

  gotoArea(area: string, xqhjth: string, ev: any) {
    this.router.navigateByUrl('/life/xqhjth/' + area + '?xqhjth=' + xqhjth);
    ev.stopPropagation();
  }
}
