<div class="wrapper-group">
  <div class="wg-bg">
    <div class="header-group">
      <div class="logol logo"><img src="/assets/image/230830/hsyrztdt/logo1.png"/></div>
      <div class="logoc logo"><img src="/assets/image/230830/hsyrztdt/page-title.png"/></div>
      <div class="logor logo">
        <!-- <img src="/assets/image/230830/hsyrztdt/logo2.png"/> -->
      </div>
    </div>
    <div class="content-wrapper">
      <div class="cw-content">
        <div class="item-group" *ngFor="let theme of listRoutes">
          <div class="ig-label-group">
            <div class="iglgbg">{{theme.label}}</div>
          </div>
          <div class="ig-content-group">
            <div class="icg-row"  *ngFor="let tditem of theme.data" (click)="goto(tditem.url)">
              <div class="icgr-gap"></div>
              <div class="icgr-txt">{{tditem.name}}</div>
              <div class="icgr-icon">
                <img src="/assets/image/230830/hsyrztdt/icon-arrow-r.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div>