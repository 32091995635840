<app-guide [pici]="7"></app-guide>
<app-rback rurl="/city/zyjydjwdzx"></app-rback>
<app-xtheme>
  <app-xtheme-hh id="zyjydj-area-wrapper">
    <div class="logoname city-theme-color">{{ttitle}}</div>

    <div class="map-model-group" (click)="showFullMapEvent($event)">
      <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
      <div class="txt city-theme-color">地图模式</div>
    </div>

    <div class="map-group" >
      <div class="map-content" [ngStyle]="{'width': areaConf.scale + 'vw', 'height': (areaConf.height / areaConf.width * areaConf.scale) +'vw'}">
        <img class="img-area"  src="/assets/imgs/area/{{dcode}}.png" />
        <div class="infolist">
          <div class="iflitem"
            (click)="swlist($event, i)"
            *ngFor="let item of idata;let i=index"
            [ngStyle]="{
              'top': areaConf.xys[i][0] + 'vw',
              'left': areaConf.xys[i][1] + 'vw',
              'color': i === dindex ? areaConf.colored : areaConf.color
            }">{{item.label}}</div>
        </div>
      </div>
    </div>

    <div class="btns-group">
      <div class="btn">
        <div class="dlist-group" *ngIf="showArea">
          <div class="dgcontent">
            <div class="ditem-group" *ngFor="let item of idata[dindex].items" (click)="showFullMapEvent($event, item.id)">
              <div class="ditem">
                <div class="title">
                  {{item.name}}
                </div>
              </div>
              <div class="ditem">
                <div class="major" *ngFor="let major of item.major">{{major}}</div>
              </div>
            </div>
          </div>
          <div class="ditem-group switchlab" (click)="stopClose($event)">
            <div class="toleft toiimg" (click)="toLeftLab($event)" *ngIf="idata.length>1">
              <img class="iimg"  src="/assets/imgs/icon/xqhjth-totop.png" />
            </div>
            <div class="txt" [ngStyle]="{'color': areaConf.colored}">{{idata[dindex].label}}</div>
            <div class="toright toiimg" (click)="toRightLab($event)" *ngIf="idata.length>1">
              <img class="iimg" src="/assets/imgs/icon/xqhjth-totop.png" />
            </div>
          </div>
        </div>
        <div class="btnlab" (click)="showAreaList($event)">
          <img src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}" />
          <div class="txt">{{areaConf.name}}</div>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <app-xtheme-vv id="zyjydj-area-wrapper2">
    <div class="left-wrapper">
      <div class="map-model-group" (click)="showFullMapEvent($event)">
        <img class="mmg-icon" src="/assets/imgs/icon/imap.png"/>
        <div class="txt city-theme-color">地图模式</div>
      </div>
      <div class="map-group">
        <div class="map-content" [ngStyle]="{'width': areaConf.scale/2 + 'vw', 'height': (areaConf.height / areaConf.width * areaConf.scale/2) +'vw'}">
          <img class="img-area" src="/assets/imgs/area/{{dcode}}.png" />
          <div class="infolist">
            <div class="iflitem"
              (click)="swlist($event, i)"
              *ngFor="let item of idata;let i=index"
              [ngStyle]="{
                'top': areaConf.xys[i][0]/2 + 'vw',
                'left': areaConf.xys[i][1]/2 + 'vw',
                'color': i === dindex ? areaConf.colored : areaConf.color
              }">{{item.label}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-wrapper">
      <div class="logoname city-theme-color">{{ttitle}}</div>
      <div class="btns-group">
        <div class="btn">
          <div class="dlist-group" *ngIf="showArea">
            <div class="dgcontent">
              <div class="ditem-group" *ngFor="let item of idata[dindex].items" (click)="showFullMapEvent($event, item.id)" >
                <div class="ditem">
                  <div class="title">
                    {{item.name}}
                  </div>
                </div>
                <div class="ditem">
                  <div class="major" *ngFor="let major of item.major">{{major}}</div>
                </div>
              </div>
            </div>
            <div class="ditem-group switchlab" (click)="stopClose($event)">
              <div class="toleft toiimg" (click)="toLeftLab($event)" *ngIf="idata.length>1">
                <img class="iimg"  src="/assets/imgs/icon/xqhjth-totop.png"  />
              </div>
              <div class="txt" [ngStyle]="{'color': areaConf.colored}">{{idata[dindex].label}}</div>
              <div class="toright toiimg" (click)="toRightLab($event)" *ngIf="idata.length>1">
                <img class="iimg" src="/assets/imgs/icon/xqhjth-totop.png" />
              </div>
            </div>
          </div>
          <div class="btnlab" (click)="showAreaList($event)">
            <img src="/assets/imgs/icon/xqhjth-totop.png" [ngClass]="{'tobottom': showArea}" />
            <div class="txt">{{areaConf.name}}</div>
          </div>
        </div>
      </div>
    </div>

  </app-xtheme-vv>
</app-xtheme>
