<app-rback rurl="/jyszhzx"></app-rback>
<app-xtheme theme="new0">
  <app-xtheme-hh> 
    <div class="header-wrapper">
      <div class="search">
        <div class="logo2name jyszhzx-theme-color">
          {{ttitle}}
        </div>
        <div class="full-map-group" (click)="toView()">
          <img class="fullmap-btn" src="/assets/imgs/icon/imap.png" />
          <div class="txt jyszhzx-theme-color">地图模式</div>
        </div> 
        <img class="compass" src="/assets/imgs/icon/compass.png" />
      </div>
    </div>
    <div class="map-group">
      <div class="map-zoom">
        <app-hzoom>
          <div>
            <img src="/assets/imgs/gif/2022/208.gif"/>
          </div>
        </app-hzoom>
      </div> 
    </div>
    <div class="result-wrapper">
      <app-liked-shared [mapkey]="'newshjyxnfzsxjddt'"></app-liked-shared>
      <div class="list-group jyszhzx-theme-color">
        <div class="lwtitle"> 
          <div class="lwt-lab" (click)="tiggleTypes(i)" [ngClass]="{'unselected':typei!=i}" *ngFor="let type of types;let i=index">{{type}}</div>
        </div>
        <div class="lwtlist">
          <div class="llitem " *ngFor="let row of rows" (click)="toView(row.id)">
            <div class="leftll" ><div class="txt">{{row.name}}</div></div>
            <div class="rightll">
              <div class="rllitem" >{{row.laboratory}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="copytime">数据更新时间：2022年8月</div> -->
  </app-xtheme-hh>
  <app-xtheme-vv>

    <div class="left-lw"> 
      <div class="full-map-group" (click)="toView()">
        <img class="fullmap-btn" src="/assets/imgs/icon/imap.png"/>
        <div class="txt jyszhzx-theme-color">地图模式</div>
      </div> 
      <img class="compass" src="/assets/imgs/icon/compass.png" />

      <div class="map-group">
        <div class="map-zoom">
          <app-zoomer>
            <img src="/assets/imgs/gif/2022/208.gif"/>
          </app-zoomer>
        </div>

      </div>
    </div>
    <div class="right-lw"> 
      <div class="header-wrapper">
        <div class="search"> 
          <div class="logoname2 jyszhzx-theme-color">
            {{ttitle}}
          </div> 
        </div>
      </div>
     
      <div class="result-wrapper">
        <div class="list-group">
          <div class="lwtitle jyszhzx-theme-color"> 
            <div class="lwt-lab" (click)="tiggleTypes(i)" [ngClass]="{'unselected':typei!=i}" *ngFor="let type of types;let i=index">{{type}}</div>
          </div>
          <div class="lwtlist">
            <div class="llitem " *ngFor="let row of rows" (click)="toView(row.id)">
                <div class="leftll" ><div class="txt">{{row.name}}</div></div>
                <div class="rightll">
                  <div class="rllitem" >{{row.laboratory}}</div>
                </div>
              </div>
          </div>
        </div>
      </div>
       
      <!-- <div class="copytime">数据更新时间：2022年8月</div> -->
    </div>
   
  </app-xtheme-vv>
</app-xtheme>
  