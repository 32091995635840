import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { XMapService } from 'src/app/shared/services';
import { EventManager } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-zbmap',
  templateUrl: './zbmap.component.html',
  styleUrls: ['./zbmap.component.styl']
})
export class LifeZbmapComponent implements OnInit {

  deviceMode = null;

  row: any;
  rows  = [];

  map = null;
  infoWin = null;
  migration = null;
  selMarker = null;
  markers   = [];

  prebicon: any;
  prebiconed: any;
  nexbicon: any;
  nexbiconed: any;

  iact  = 0; // 0=全部 1=专业导向 2=中职导向 3=高职本科导向
  iid   = 0; // 记录id
  slabi = 0; // 0=中高 1=中本
  slabs = [
    {
      id: 1,
      name: '中高职贯通'
    },
    {
      id: 2,
      name: '中本贯通'
    },
    {
      id: 3,
      name: '五年一贯制'
    }
  ];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private xmapservice: XMapService,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.slabi = +this.route.snapshot.paramMap.get('type');
    this.iact  = +this.route.snapshot.paramMap.get('act');
    this.iid   = +this.route.snapshot.paramMap.get('id');

    this.deviceMode = window.orientation;
    this.eventManager.addGlobalEventListener('window', 'orientationchange', (ev: any) => {
      this.deviceMode = window.orientation;
    });
    this.http.get(MAPDIST.zgzgtzbgt.api).toPromise().then((rows: any) => {
      this.rows = rows;
      this.getRow();

      this.xmapservice.loadQqMapJs(() => {
        setTimeout(() => {
          this.loadFullMap();
        }, 300);
      });
    });

    window.vview = (act: number, id: number) => {
      this.iact = act;
      this.iid  = id;
      this.getRow();
      this.migrationData(true);
    };
  }

  getRow() {
    if (this.iid > 0) {
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        if (row.id == this.iid) {
          this.row = row;
          break;
        }
      }
    }
  }

  loadFullMap() {
    const presize  = new window.qq.maps.Size(25, 28.5);
    this.prebicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, presize);
    this.prebiconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, presize);

    const nexsize  = new window.qq.maps.Size(50, 57);
    this.nexbicon   = new window.qq.maps.MarkerImage('/assets/imgs/imed.png', null, null, null, nexsize);
    this.nexbiconed = new window.qq.maps.MarkerImage('/assets/imgs/im.png', null, null, null, nexsize);

    const sw = new window.qq.maps.LatLng(32.597734, 122.667847); // 东北角坐标
    const ne = new window.qq.maps.LatLng(30.078601, 120.618896); // 西南角坐标
    const center = new window.qq.maps.LatLng(31.240692, 121.501751);
    this.map = new window.qq.maps.Map(document.getElementById('qq-full-map-wrapper'), this.xmapservice.getMapOptions({
      zoom: 9,
      minZoom: 8,
      maxZoom: 18,
      center,
      boundary: new window.qq.maps.LatLngBounds(ne, sw)
    }));

    window.qq.maps.event.addListener(this.map, 'click', () => this.closeInfoWin());
    window.qq.maps.event.addListener(this.map, 'zoom_changed', () => this.closeInfoWin() );

    this.migration = new window.qq.maps.visualization.Migration({
      map: this.map,
      stylePicker: (data) => {
        return {
          color: '#01a4e8', // #ff5a00 // "rgba(0, 0, 255, .3)",
          width: 2
        };
      }
    });
    this.migrationData(true);

    const MyOverlay = this.xmapservice.initMyOverlay();
    this.infoWin = new MyOverlay({
      map: this.map,
      position: center,
      complete: () => this.infoWin.close()
    });
  }

  migrationData(isdepth: boolean = false) {
    this.closeInfoWin();

    if (this.markers.length > 0) {
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        marker.setMap(null);
      }
      this.markers = [];
    }

    // this.map.setZoom(isdepth && this.iact > 0 ? 13 : 9)

    const regExps = {
      1: [
        new RegExp('南湖职业', 'i'), new RegExp('经济管理', 'i'),
        new RegExp('商贸旅游', 'i'), new RegExp('大众工业', 'i'),
        new RegExp('城市科技', 'i'), new RegExp('浦东外事服务', 'i'),
        new RegExp('现代流通', 'i'),  new RegExp('临港科技', 'i'),
      ],
      2: [
        new RegExp('南湖职业', 'i'), new RegExp('经济管理', 'i'),
        new RegExp('商贸旅游', 'i'), new RegExp('大众工业', 'i'),
        new RegExp('城市科技', 'i'), new RegExp('浦东外事服务', 'i'),
        new RegExp('现代流通', 'i'),  new RegExp('临港科技', 'i'),
        new RegExp('食品科技', 'i'), new RegExp('石化', 'i'),
        new RegExp('工程技术', 'i'), new RegExp('工商信息', 'i'),
        new RegExp('电子工业', 'i'), new RegExp('群益', 'i'),
        new RegExp('工商外国语', 'i')
      ]
    };

    let objLatLng;
    let imap = 0;
    const nData = [];
    const markers = [];
    const rows = this.rows;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.type != this.slabs[this.slabi].name) { continue; } // 非本类型

      // TODO
      // let isBad = true
      // for (let j=0;j<regExps[row.type].length;j++) {
      //   const regExp = regExps[row.type][j]
      //   if (regExp.test(row.prename)) {
      //     isBad = false
      //     break
      //   }
      // }
      // if (isBad) continue
      // console.log(row)

      if (isdepth && this.iact > 0 && this.iid > 0) {
        switch (this.iact) {
          case 1: // 专业一条
            // if (row.id !== this.iid) continue
            if (row.major !== this.row.major) { continue; }
            if (imap === 0) { objLatLng = row.prelocation.split(','); }
            break;
          case 2: // 中职=>
            if (row.prename !== this.row.prename) { continue; }
            if (imap === 0) { objLatLng = row.prelocation.split(','); }
            break;
          case 4: // 按区检索
            if (row.prearea !== this.row.prearea) { continue; }
            if (imap === 0) { objLatLng = row.prelocation.split(','); }
            break;
          case 3: // =>高职或本科
            if (row.nexname !== this.row.nexname) { continue; }
            if (imap === 0) { objLatLng = row.nexlocation.split(','); }
            break;
          default:
            continue;
        }
        imap ++;
      }
      if (imap === 1) { this.map.panTo(new window.qq.maps.LatLng(objLatLng[0], objLatLng[1])); } // move map

      const prelocation = row.prelocation.split(',');
      const nexlocation = row.nexlocation.split(',');
      nData.push({
        from: {lat: prelocation[0], lng: prelocation[1], }, // label: row.prename
        to:   {lat: nexlocation[0], lng: nexlocation[1], }  // label: row.nexname
      });

      // 起点
      const preLatlng = new window.qq.maps.LatLng(prelocation[0], prelocation[1]);
      const premarker = new window.qq.maps.Marker({
        position: preLatlng,
        icon: this.prebicon,
        map: this.map,

        bicon: this.prebicon,
        biconed: this.prebiconed
      });
      window.qq.maps.event.addListener(premarker, 'click', () => {
        this.map.panTo(preLatlng);
        this.infoWin.xsetPosition(preLatlng);
        this.infoWin.setContent(this.getInfoWinHtml(row, 1));
        this.infoWin.open(() => {this.openMarker(premarker); });
      });
      markers.push(premarker);
      // 终点
      const nexLatlng = new window.qq.maps.LatLng(nexlocation[0], nexlocation[1]);
      const nexmarker = new window.qq.maps.Marker({
        position: nexLatlng,
        icon: this.nexbicon,
        map: this.map,

        bicon: this.nexbicon,
        biconed: this.nexbiconed
      });
      window.qq.maps.event.addListener(nexmarker, 'click', () => {
        this.map.panTo(nexLatlng);
        this.infoWin.xsetPosition(nexLatlng);
        this.infoWin.setContent(this.getInfoWinHtml(row, 2));
        this.infoWin.open(() => {this.openMarker(nexmarker); });
      });
      markers.push(nexmarker);
    }

    this.markers = markers;

    this.migration.setData(nData);
  }

  getInfoWinHtml(row: any, type: number) {
    const ititle   = type === 1 ? row.prename : row.nexname;
    const itemname = type === 1 ? (this.slabi === 0 ? '高职院校' : '本科院校') : '中职学校';

    let html = '<style>';
    html += '.winfo-wrapper{padding:6px}';
    html += '.winfo-title{border-radius:10px;padding:10px;color: #ffffff;background-color: #60828e; font-size: 18px;text-align:center}';
    html += '.winfo-list-wrapper{margin:6px 0;color:#606060;border:1px solid #60828e;border-radius:10px;overflow:hidden;text-align:center;}';
    html += '.winfo-list-wrapper .winfo-list-title{display:flex;color:#60828e;font-size:14px;line-height:20px;}';
    html += '.winfo-list-wrapper .winfo-list-title .wlt1{border-right:1px solid #60828e;}';
    html += '.winfo-list-wrapper .winfo-list-title .wlt{flex:1;border-bottom:1px solid #60828e;padding:4px;display: flex;justify-content: center;align-items: center}';
    html += '.winfo-body{max-height:calc(50vh - 150px);overflow:scroll;color:#606060;}';
    html += '.winfo-body .wbitem{display:flex;border-bottom:1px solid #60828e;font-size: 12px;}';
    html += '.winfo-body .wbitem:last-child{border-bottom:none;}';
    html += '.winfo-body .wbitem .wbii{flex:1;line-height:20px;padding:4px;display: flex;justify-content: center;align-items: center}';
    html += '</style>';

    html += '<div class="winfo-wrapper">';
    html += '<div class="winfo-title" style="">' + ititle + '</div>';

    html += '<div class="winfo-list-wrapper">';
    html += '<div class="winfo-list-title">';
    html += '<div class="wlt1 wlt"><div>专业</div></div>';
    html += '<div class="wlt2 wlt"><div>' + itemname + '</div></div>';
    html += '</div>';
    html += '<div class="winfo-body" style="">';
    for (let i = 0; i < this.rows.length; i++) {
          const item = this.rows[i];
          if ((type == 1 && item.prename !== row.prename) || (type == 2 && item.nexname !== row.nexname) || item.type != this.slabs[this.slabi].name) { continue; }

          html += '<div class="wbitem" onclick="vview(' + (type === 1 ? 3 : 2) + ',' + item.id + ')">';
          html += '<div class="wbii" style="border-right:1px solid #60828e;"><div>' + item.major + '</div></div>';
          html += '<div class="wbii" style=""><div>' + (type == 1 ? item.nexname : item.prename) + '</div></div>';
          html += '</div>';
        }
    html += '</div>';
    html += '</div>';

    html += '</div>';

    return html;
  }
  openMarker(marker: any) {
    if (this.selMarker !== null) { this.closeMarker(); }

    this.selMarker = marker;
    this.selMarker.setIcon(this.selMarker.biconed);
  }
  closeMarker() {
    if (this.selMarker) {
      this.selMarker.setIcon(this.selMarker.bicon);
      this.selMarker = null;
    }
  }

  swLabPre() {
    this.slabi -= 1;
    if (this.slabi === -1) { this.slabi = this.slabs.length - 1; }

    this.migrationData();
  }
  swLabNex() {
    this.slabi += 1;
    if (this.slabi === this.slabs.length) { this.slabi = 0; }

    this.migrationData();
  }

  closeInfoWin() {
    if (this.infoWin !== null) { this.infoWin.close(() => {this.closeMarker(); }); }
  }
}
