<app-xtheme [theme]="'start'">
  <app-xtheme-hh>
    <div class="list-map-popup">
      <div class="lmp-body">
        <div class="theme-group" *ngFor="let theme of listRoutes" [ngStyle]="{'border-color': theme.lbgcolor}">
          <div class="theme-title">
            <div class="images">
              <img src="/assets/image/230830/start/{{theme.id}}.png"/>
            </div>
          </div>
          <div class="topic-group" [ngStyle]="{'margin': theme.data.length>1?'10px':'0'}">
            <div class="topic-item" *ngFor="let topic of theme.data">
              <div class="topic-title" *ngIf="theme.data.length>1" [ngStyle]="{'background-color': theme.lbgcolor}">
                <div class="tgbg">
                  <div class="tgname">{{topic.label}}</div>
                </div>
              </div>
              <div class="routes-group" [ngStyle]="{'background-color': theme.sbgcolor}">
                <ng-container *ngFor="let sub of topic.data">
                  <div class="rgitem" (click)="goto(sub.url)">
                    <div class="txt"> {{sub.name}} </div>
                    <div class="img"><img src="/assets/image/230830/start/icon-arrow-r.png"/></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-xtheme-hh>
  <!--PC-->
  <app-xtheme-vv>
    <div class="wrapper-group">
      <div class="wg-bg">
        <div class="header-group">
          <div class="logol logo"><img src="/assets/image/230830/start/vv/logo1.png"/></div>
          <div class="logoc logo page-title"><img src="/assets/image/240515/start/vv/page-title.png"/></div>
          <div class="logor logo">
            <!--<img src="/assets/image/230830/start/vv/logo2.png"/>-->
          </div>
          <!--<div class="logorb logo rb">
            <img src="/assets/image/240515/start/vv/rb.png"/>
          </div>-->
        </div>
        <div class="content-wrapper">
          <div class="cw-content right-content">
            <ng-container *ngFor="let theme of vvListRoutes[0];let i=index">
              <div class="theme-gap" *ngIf="i>0"></div>
              <div class="theme-group" [ngStyle]="{'border-color': theme.lbgcolor}">
                <div class="theme-title">
                  <div class="images">
                    <img src="/assets/image/230830/start/vv/{{theme.id}}.png"/>
                  </div>
                </div>
                <div class="topic-group" >
                  <perfect-scrollbar
                    style="height: 100%;"
                    *ngIf="type === 'component'"
                    class="scroll-container test"
                    fxFlex="auto"
                    [config]="config"
                    [scrollIndicators]="true"
                  >
                    <div class="scrollable-content">
                      <div class="topic-item" *ngFor="let topic of theme.data">
                        <div class="topic-title" *ngIf="theme.data.length>1" [ngStyle]="{'background-color': theme.lbgcolor}">
                          <div class="tgbg">
                            <div class="tgname">{{topic.label}}</div>
                          </div>
                        </div>
                        <div class="routes-group" [ngStyle]="{'background-color': theme.sbgcolor}">
                          <ng-container *ngFor="let sub of topic.data">
                            <div class="rgitem" (click)="goto(sub.url)">
                              <div class="txt"> {{sub.name}} </div>
                              <div class="img"><img src="/assets/image/230830/start/vv/icon-arrow-r.png"/></div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="cw-gap"></div>
          <div class="cw-content center-content">
            <ng-container *ngFor="let theme of vvListRoutes[1];let i=index">
              <div class="theme-gap" *ngIf="i>0"></div>
              <div class="theme-group" [ngStyle]="{'border-color': theme.lbgcolor}">
                <div class="theme-title">
                  <div class="images">
                    <img src="/assets/image/230830/start/vv/{{theme.id}}.png"/>
                  </div>
                </div>
                <div class="topic-group" >
                  <perfect-scrollbar
                    style="height: 100%;"
                    *ngIf="type === 'component'"
                    class="scroll-container"
                    fxFlex="auto"
                    [config]="config"
                    [scrollIndicators]="true"
                  >
                    <div class="scrollable-content">
                      <div class="topic-item" *ngFor="let topic of theme.data">
                        <div class="topic-title" *ngIf="theme.data.length>1" [ngStyle]="{'background-color': theme.lbgcolor}">
                          <div class="tgbg">
                            <div class="tgname">{{topic.label}}</div>
                          </div>
                        </div>
                        <div class="routes-group" [ngStyle]="{'background-color': theme.sbgcolor}">
                          <ng-container *ngFor="let sub of topic.data">
                            <div class="rgitem" (click)="goto(sub.url)">
                              <div class="txt"> {{sub.name}} </div>
                              <div class="img"><img src="/assets/image/230830/start/vv/icon-arrow-r.png"/></div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="cw-gap"></div>
          <div class="cw-content center-content">
            <ng-container *ngFor="let theme of vvListRoutes[2];let i=index">
              <div class="theme-gap" *ngIf="i>0"></div>
              <div class="theme-group" [ngStyle]="{'border-color': theme.lbgcolor}">
                <div class="theme-title">
                  <div class="images">
                    <img src="/assets/image/230830/start/vv/{{theme.id}}.png"/>
                  </div>
                </div>
                <div class="topic-group">
                  <perfect-scrollbar
                    style="height: 100%;"
                    *ngIf="type === 'component'"
                    class="scroll-container"
                    fxFlex="auto"
                    [config]="config"
                    [scrollIndicators]="true"
                  >
                    <div class="scrollable-content">
                      <div class="topic-item" *ngFor="let topic of theme.data">
                        <div class="topic-title" *ngIf="theme.data.length>1" [ngStyle]="{'background-color': theme.lbgcolor}">
                          <div class="tgbg">
                            <div class="tgname">{{topic.label}}</div>
                          </div>
                        </div>
                        <div class="routes-group" [ngStyle]="{'background-color': theme.sbgcolor}">
                          <ng-container *ngFor="let sub of topic.data">
                            <div class="rgitem" (click)="goto(sub.url)">
                              <div class="txt"> {{sub.name}} </div>
                              <div class="img"><img src="/assets/image/230830/start/vv/icon-arrow-r.png"/></div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="bottom-group">
          <div class="logorb logo">
            <img src="/assets/image/240515/start/vv/rb1.png"/>
          </div>
        </div>
      </div>
    </div>
  </app-xtheme-vv>
</app-xtheme>
