import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MAPDIST } from '../../config';
import { DialogService } from 'src/app/dialog';
import { GuideShareComponent } from '../guide-share';
import { UtilsService } from '../../services/utils.service';
import { RunService } from '../../services/run.service';

@Component({
  selector: 'app-liked-shared',
  templateUrl: './liked-shared.component.html',
  styleUrls: ['./liked-shared.component.styl']
})
export class LikedSharedComponent implements OnInit {

  @Input() fdcol = false;
  @Input() mapkey = '';

  isApplets = false;
  isLiked = false;

  rid: any;
  uid: any;

  constructor(
    private http: HttpClient,
    private utilService: UtilsService,
    private dialogService: DialogService,
    private runService: RunService
  ) { }

  ngOnInit() {
    this.rid = MAPDIST[this.mapkey].rid;
    this.uid  = this.runService.uid;
    if (this.utilService.isMiniProgram() || this.uid) { this.isApplets = true; }

    if (this.rid && this.uid) {
      this.http.get('/frontend/activity/praise2023?scene=map&scene_ids=' + this.rid).toPromise().then((resp: any) => {
        if (resp[0].checked){
          this.isLiked = true;
        } else {
          this.isLiked = false;
        }
      });
    }
  }

  liked() {
    if (this.rid && this.uid) {
      this.http.post('/frontend/activity/praise2023', {scene: 'map', scene_id: this.rid}).toPromise().then((resp: any) => {
        if (resp.checked){
          this.isLiked = true;
        } else {
          this.isLiked = false;
        }
      });
    }
  }
  shared() {
    this.dialogService.open(GuideShareComponent, { inputs: { }, outputs: { } });
  }
}
