<div class="wrapper-group">
  <div class="wg-bg">
    <div class="header-group">
      <div class="logol logo"><img src="/assets/image/230830/qsmzqjydt/logo1.png"/></div>
      <div class="logoc logo"><img src="/assets/image/230830/qsmzqjydt/page-title.png"/></div>
      <div class="logor logo"><img src="/assets/image/230830/qsmzqjydt/logo2.png"/></div>
    </div>
    <div class="content-wrapper">
      <div class="left-group lr-content">
        <div class="item-group" *ngFor="let theme of listRoutes[0]">
          <div class="ig-label-group">
            <div class="iglgbg">{{theme.label}}</div>
          </div>
          <div class="ig-content-group">
            <div class="icg-row"  *ngFor="let tditem of theme.data" (click)="goto(tditem.url)">
              <div class="icgr-gap"></div>
              <div class="icgr-txt">{{tditem.name}}</div>
              <div class="icgr-icon">
                <img src="/assets/image/230830/qsmzqjydt/icon-arrow-r-b.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-gap"></div>
      <div class="right-group lr-content">
        <div class="item-group" *ngFor="let theme of listRoutes[1]">
          <div class="ig-label-group">
            <div class="iglgbg">{{theme.label}}</div>
          </div>
          <div class="ig-content-group">
            <div class="icg-row"  *ngFor="let tditem of theme.data" (click)="goto(tditem.url)">
              <div class="icgr-gap"></div>
              <div class="icgr-txt">{{tditem.name}}</div>
              <div class="icgr-icon">
                <img src="/assets/image/230830/qsmzqjydt/icon-arrow-r-b.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
