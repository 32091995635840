import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

export class XHttpService {

    constructor(private http: HttpClient) { }

    _get<T>(api: string,  params?: HttpParams | {
        [param: string]: string | string[];
    }) {
        return this.http.get<T>(api, {
            params
        });
    }

    _post(api: string, body: any | null, params?: HttpParams | {
        [param: string]: string | string[];
    }) {
        return this.http.post(api, body, {
            params
        });
    }

    _put(api: string, body: any | null, params?: HttpParams | {
        [param: string]: string | string[];
    }) {
        return this.http.put(api, body, {
          params
        });
    }

    _patch(api: string, body: any | null, params?: HttpParams | {
        [param: string]: string | string[];
    }) {
        return this.http.patch(api, body, {
          params
        });
    }
}
