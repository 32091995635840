import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swiper-item',
  templateUrl: './swiper-item.component.html',
  styleUrls: ['./swiper-item.component.styl']
})
export class SwiperItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
