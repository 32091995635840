<app-rback [show]="false"></app-rback>
<!-- 竖屏 -->
<div class="hh-wrapper container-wrapper"  [ngStyle]="{'z-index': deviceMode === 0 ? 22: -1}">
  <div class="body">
    <ng-content select="app-xtheme-hh"></ng-content>
  </div>
  <ng-container [ngSwitch]="theme">
   
    <div class="bg-wrapper" *ngSwitchCase= "'edu'">
        <img class="top" src="/assets/imgs/main/h-bg-top.jpg" />
        <img class="bottom" src="/assets/imgs/main/h-bg-bottom.jpg" />
    </div>
  
    <div class="bg-wrapper redbg" *ngSwitchCase= "'start'">
      <img class="top" src="/assets/imgs/new/theme/red-bg-top.png" />
      <img class="bottom" src="/assets/imgs/new/theme/red-bg-bottom.png" />
    </div>
  
    <div class="bg-wrapper redbg" *ngSwitchCase= "'red'" >
      <img class="top" src="/assets/imgs/new/theme/red-bg-top2.png" />
      <img class="bottom" src="/assets/imgs/new/theme/red-bg-bottom2.png" />
    </div>

    <div class="bg-wrapper new0" *ngSwitchCase= "'new0'" >
      <img class="top" src="/assets/imgs/new/theme/new-bg-top.png" />
    </div>

    <div class="bg-wrapper redbg0" *ngSwitchCase= "'red0'" >
      <img class="top" src="/assets/imgs/new/theme/main-red-bg-top.png" />
      <img class="bottom" src="/assets/imgs/new/theme/main-red-bg-bottom.png" />
    </div>

    <div class="bg-wrapper" *ngSwitchDefault>
      <img class="top" src="/assets/imgs/main/h-bg-top1.jpg" />
      <img class="bottom" src="/assets/imgs/main/h-bg-bottom1.jpg" />
    </div>
  
  </ng-container>
  
</div>
<!-- 横屏 -->
<div class="vv-wrapper container-wrapper" [ngStyle]="{'z-index': deviceMode !== 0 ? 22: -1}">
  <div class="body">
    <ng-content select="app-xtheme-vv"></ng-content>
  </div>
  <ng-container [ngSwitch]="theme">
    <div class="red-bg-wrapper vvleftright" *ngSwitchCase="'start'">
      <img class="rbw-left" src="/assets/imgs/new/theme/red-bg-left.png"/>
      <img class="rbw-right" src="/assets/imgs/new/theme/red-bg-right.png"/>
    </div>
    <div class="red-bg-wrapper vvleftright" *ngSwitchCase="'red'">
      <img class="rbw-left" src="/assets/imgs/new/theme/red-bg-left.png"/>
      <img class="rbw-right" src="/assets/imgs/new/theme/red-bg-right.png"/>
    </div>
    <div class="new0-bg-wrapper vvleftright" *ngSwitchCase="'new0'">
      <img class="rbw-left" src="/assets/imgs/new/theme/new-bg-left.png"/>
    </div>
    <div class="red-bg-wrapper vvleftright redo" *ngSwitchCase="'red0'">
      <img class="rbw-left" src="/assets/imgs/new/theme/main-h-red-bg-left.png"/>
      <img class="rbw-right" src="/assets/imgs/new/theme/main-h-red-bg-right.png"/>
    </div>
    <div class="full-red-bg-wrapper" *ngSwitchCase= "'red00'" >
      <img class="full" src="/assets/imgs/new/theme/main-h-red-bg.png" /> 
    </div>
    <div class="edu-bg-wrapper vvleftright" *ngSwitchCase="'edu'">
      <img class="rbw-left" src="/assets/imgs/new/theme/edu-bg-left.png"/>
      <img class="rbw-right" src="/assets/imgs/new/theme/edu-bg-right.png"/>
    </div>
    <div class="bg-wrapper" *ngSwitchDefault>
      <img class="top" src="/assets/imgs/main/v-bg-top.jpg" />
      <img class="bottom" src="/assets/imgs/main/v-bg-bottom.jpg" />
    </div>
  </ng-container> 
</div>
