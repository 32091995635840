import { Component, OnInit, ViewChild, ElementRef, Renderer2, NgZone, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { RunService, XMapService } from 'src/app/shared/services';
import { Location } from '@angular/common';
import { EventManager } from '@angular/platform-browser';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.styl']
})
export class CityZyjydjwdzxAreaComponent implements OnInit {
  cyname = '';
  ttitle = '';

  showArea = true;

  dcode = 0;
  areaConf: any;
  pdareas = {
    310101: {
      name: '黄浦区',
      width: 435,
      height: 549,
      scale: 70,
      color: '#ffffff',
      colored: '#85afb7',
      xys: [[34, 30], [44, 16], [50, 40]]
    },
    310104: {
      name: '徐汇区',
      width: 450,
      height: 549,
      scale: 60,
      color: '#ffffff',
      colored: '#a88ba7',
      xys: [[20, 25], [30, 16], [40, 22]]
    },
    310105: {
      name: '长宁区',
      width: 645,
      height: 479,
      scale: 60,
      color: '#ffffff',
      colored: '#a88ba7',
      xys: [[16, 14], [22, 28], [28, 14]]
    },
    310106: {
      name: '静安区',
      width:  262,
      height: 549,
      scale: 30, // 60,
      color: '#d49c5a',
      colored: '#9d5602',
      xys: [[22, 6], [28, 10], [34, 14]]

    },
    310107: {
      name: '普陀区',
      width: 645,
      height: 549,
      scale: 70,
      color: '#ffffff',
      colored: '#85afb7',
      xys: [[22, 30], [32, 40], [42, 32]]
    },
    310109: {
      name: '虹口区',
      width:  376,
      height: 549,
      scale: 35, // 70,
      color: '#ffffff',
      colored: '#bb7d7d',
      xys: [[24, 10], [28, 12]]
    },
    310110: {
      name: '杨浦区',
      width: 403,
      height: 549,
      scale: 60,
      color: '#ffffff',
      colored: '#85afb7',
      xys: [[26, 12], [36, 28], [46, 12]]
    },
    310112: {
      name: '闵行区',
      width: 576,
      height: 549,
      scale: 70,
      color: '#ffffff',
      colored: '#85afb7',
      xys: [[22, 18], [36, 30], [50, 30]]
    },
    310113: {
      name: '宝山区',
      width: 450,
      height: 549,
      scale: 70,
      color: '#ffffff',
      colored: '#a88ba7',
      xys: [[34, 22], [46, 18]]
    },
    310114: {
      name: '嘉定区',
      width:  517,
      height: 549,
      scale: 70,
      color: '#d49c59',
      colored: '#9d5602',
      xys: [[26, 38], [34, 16], [40, 40]]
    },
    310115: {
      name: '浦东新区',
      width: 517,
      height: 549,
      scale: 80,
      color: '#d49c59',
      colored: '#9d5602',
      xys: [[16, 16], [26, 10], [36, 30], [46, 20], [56, 40], [66, 50], [76, 45]]
    },
    310116: {
      name: '金山区',
      width: 645,
      height: 420,
      scale: 80,
      color: '#ffffff',
      colored: '#85afb7',
      xys: [[12, 40], [24, 28], [32, 46], [42, 46]]
    },
    310117: {
      name: '松江区',
      width: 645,
      height: 549,
      scale: 80,
      color: '#ffffff',
      colored: '#bb7d7d',
      xys: [[20, 24], [30, 40], [46, 40]]
    },
    310118: {
      name: '青浦区',
      width: 645,
      height: 575,
      scale: 80,
      color: '#ffffff',
      colored: '#a88ba7',
      xys: [[12, 40], [24, 48], [36, 16], [48, 22]]
    },
    310120: {
      name: '奉贤区',
      width: 645,
      height: 431,
      scale: 80,
      color: '#ffffff',
      colored: '#314f6a',
      xys: [[22, 30]]
    },
    310151: {
      name: '崇明区',
      width:  645,
      height: 549,
      scale: 80,
      color: '#ffffff',
      colored: '#b37878',
      xys: [[28, 32], [16, 14], [46, 32]]
    },
  };

  dindex = 0;
  idata: any[]  = [[]];

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private runService: RunService,
    private eventManager: EventManager
  ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.zyjydjwdzx.title;

    this.eventManager.addEventListener(document.getElementById('zyjydj-area-wrapper') as HTMLElement, 'click', () => this.showArea = false);
    this.eventManager.addEventListener(document.getElementById('zyjydj-area-wrapper2') as HTMLElement, 'click', () => this.showArea = false);
    this.dcode = +this.route.snapshot.paramMap.get('dcode');
    this.areaConf = this.pdareas[this.dcode];
    if (!this.areaConf) { this.location.back(); }

    this.http.get(MAPDIST.zyjydjwdzx.api).toPromise().then((resp: any) => {

      // 检查类别坐标个数是否够用
      // const dcodes = {}
      // for (const rpitem of resp) {
      //   if (!dcodes[rpitem.dcode]) dcodes[rpitem.dcode] = [];
      //   if (dcodes[rpitem.dcode].includes(rpitem.type)) continue;

      //   dcodes[rpitem.dcode].push(rpitem.type);
      // }
      // Object.keys(this.pdareas).forEach(dcode => {
      //   if (dcodes[dcode].length != this.pdareas[dcode].xys.length) {
      //     console.log(dcode, dcodes[dcode], this.pdareas[dcode].xys)
      //   }
      // })


      const data = {};
      for (let i = 0; i < resp.length; i++) {
        resp[i].dcode = resp[i].dcode;
        const obj = resp[i];

        if (this.dcode !== obj.dcode) { continue; }

        if (!data[obj.type]) { data[obj.type] = {label: obj.type, items: []}; }

        obj.major = obj.major.split('；');
        data[obj.type].items.push(obj);
      }

      const newData = [];
      Object.keys(data).forEach(key => {
        newData.push(data[key]);
      });
      this.idata = newData;
    });

    window.indomEvent = (i: number) => {
      this.dindex = i;
      this.showArea = true;
    };
  }

  showFullMapEvent(ev: any, i: number = -1) {
    ev.stopPropagation();
    this.runService.set('zyjydjwdzx-district', this.dcode);
    this.router.navigateByUrl('/pmap/zyjydjwdzx/' + i);
  }

  toLeftLab(ev: any) {
    this.dindex -= 1;

    if (this.dindex === -1) { this.dindex = this.idata.length - 1; }
    ev.stopPropagation();
  }
  toRightLab(ev: any) {
    this.dindex += 1;

    if (this.dindex === this.idata.length) { this.dindex = 0; }
    ev.stopPropagation();
  }

  showAreaList(ev: any) {
    this.showArea = !this.showArea;
    ev.stopPropagation();
  }
  swlist(ev: any, i: number) {
    this.dindex = i;
    this.showArea = true;
    ev.stopPropagation();
  }
  stopClose(ev: any) {
    ev.stopPropagation();
  }
}
