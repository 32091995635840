import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-208_newshjyxnfzsxjddt',
  templateUrl: './208_newshjyxnfzsxjddt.component.html',
  styleUrls: ['./208_newshjyxnfzsxjddt.component.styl']
})
export class NewShjyxnfzsxjddtComponent implements OnInit {

  typei  = 0;
  types: string[] = [''];
  ttitle = '';
  data: any = [];
  rows: any = [];

  files: any = {
    1: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/1/基地场景.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/1/协助老人轮椅移动.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/1/vr沉浸式体验.png'}, ],
    2: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/2/1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/2/2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/2/3.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/2/4.png'}, ],
    4: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/4/【南湖】5G+XR智能汽车虚拟仿真应用场景1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/4/【南湖】5G+XR智能汽车虚拟仿真应用场景2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/4/【南湖】5G+XR智能汽车虚拟仿真应用场景3.jpg'}, ],
    5: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/5/济光-助产虚拟仿真实训基地  图1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/5/济光-助产虚拟仿真实训基地  图2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/5/济光-助产虚拟仿真实训基地  图3.png'}, ],
    6: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/6/新能源驱动电机系统MR教学虚拟仿真实训系统.png'}, ],
    7: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/7/1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/7/2.png'}, ],
    9: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/9/1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/9/2.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/9/3.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/9/4.png'}, ],
    10: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/1.1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/3.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/4.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/8.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/9.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/10.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/10/11.jpg'}, ],
    11: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/11/2025装配式建筑示范实训基地.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/11/虚拟仿真实训软件1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/11/虚拟仿真实训软件2.png'}, ],
    12: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/12/教育部原副部长鲁昕体验印刷媒体虚拟仿真系统.JPG'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/12/世界技能大赛首席执行官David Hoey体验印刷媒体技术虚拟仿真实训系统'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/12/虚拟仿真实训教学开展情况.JPG'}, ],
    13: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/13/VR虚拟仿真实训1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/13/VR虚拟仿真实训2.png'}, ],
    14: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/14/01 红色文化与旅游虚拟仿真基地教学区.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/14/02红色文化与旅游虚拟仿真基地体验区.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/14/03房车使用与安全管理虚拟仿真实验.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/14/04 旅游目的地管理虚拟仿真.png'}, ],
    15: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/15/虚拟偶像工作室动作捕捉现场1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/15/虚拟偶像工作室动作捕捉现场2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/15/直播货品仓库.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/15/直播间场景.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/15/直播优选、帮扶助农活动.jpg'}, ],
    16: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/16/5G无线网络测试与优化仿真实训平台（第五届一带一路金砖国家技能发展与技术创新大赛5G网络建设与运用赛项应用平台）/第五届一带一路金砖国家技能发展与技术创新大赛5G网络建设与运用赛项现场1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/16/5G无线网络测试与优化仿真实训平台（第五届一带一路金砖国家技能发展与技术创新大赛5G网络建设与运用赛项应用平台）/第五届一带一路金砖国家技能发展与技术创新大赛5G网络建设与运用赛项现场2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/16/物联网智能家居虚拟仿真实训平台/1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/16/物联网智能家居虚拟仿真实训平台/2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/16/物联网智能家居虚拟仿真实训平台/3.jpg'}, ],
    17: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/17/图片1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/17/图片2.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/17/图片3.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/17/图片4.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/17/图片5.png'}, ],
    18: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/18/仿真婴儿.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/18/实训3.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/18/实训室.jpg'}, ],
    19: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/19/2d66d188ab9db4e8ecd0949ae187e39.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/19/e4675e2ff96cfec7282cf56c2f340da.jpg'}, ],
    20: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/管理平台-技能综合分析.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/管理平台-课程实训情况概览.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/实训场景-操作实训.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/实训场景-柴油发电机房.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/实训场景-低压开关柜.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/实训场景-水泵房（喷淋泵）.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/未来大厦-主界面.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/20/未来大厦-VR效果.jpg'}, ],
    21: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/21/照片1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/21/照片2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/21/照片3.jpg'}, ],
    22: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/22/01.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/22/02.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/22/03.png'}, ],
    23: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/23/物联网智慧农业虚拟仿真1.PNG'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/23/物联网智慧农业虚拟仿真2.PNG'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/23/虚拟仿真实训室效果图.jpg'}, ],
    24: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/24/图1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/24/图2.png'}, ],
    25: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/25/虚仿--水仙花的雕刻之净化.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/25/虚仿--水仙花的造型.png'}, ],
    26: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/26/大众工业1.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/26/大众工业2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/26/大众工业3.jpg'}, ],
    27: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/27/趣味教学资源.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/27/危化安全环境建设.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/27/虚实结合的典型化工生产装置.png'}, ],
    28: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/28/微信图片_20220124142650.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/28/微信图片_20220124142734.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/28/新能源驱动电机系统MR教学虚拟仿真实训系统.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/28/虚拟仿真喷漆.png'}, ],
    29: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/29/口腔常见疾病辅助认知.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/29/口腔局部解剖AR认知.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/29/口腔种植护理VR实训.jpg'}, ],
    30: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/30/3D量体设备.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/30/陈列虚拟实训空间.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/30/服装虚拟缝制.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/30/服装制作资源.png'}, ],
    31: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/31/1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/31/2.jpg'}, ],
    32: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/32/微信图片_20220123101700.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/32/微信图片_20220123101748.png'}, ],
    33: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/33/船舶内装虚仿区.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/33/基地总布局图.png'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/33/CAVE展示区.png'}, ],
    34: [{type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/34/盾构施工虚拟仿真软件.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/34/盾构实体模型1.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/34/盾构实体模型2.jpg'}, {type: 'image', url: 'https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/u.linktour.com/app/edumap.shnu.edu/2022/208/34/管片拼装虚拟仿真软件.jpg'}, ],
  };

  constructor(
    private http: HttpClient,
    private router: Router) { }

  ngOnInit() {
    this.ttitle = MAPDIST.newshjyxnfzsxjddt.title;
    this.http.get(MAPDIST.newshjyxnfzsxjddt.api).toPromise().then((data: any) => {
      const types = [];
      for (const row of data) {
        if (types.includes(row.type)) { continue; }

        types.push(row.type);
      }

      this.types = types;
      this.data  = data;
      this.tiggleTypes(0);
    });
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/new/pmap/newshjyxnfzsxjddt/' + i);
  }

  tiggleTypes(i: number) {
    const rows = [];
    this.typei = i;
    for (const row of this.data) {
      if (this.types[this.typei] != row.type) { continue; }

      rows.push(row);
    }

    this.rows = rows;
  }
}
