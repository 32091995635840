import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xtheme-hh',
  templateUrl: './xtheme-hh.component.html',
  styleUrls: ['./xtheme-hh.component.styl']
})
export class XthemeHhComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
