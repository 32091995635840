import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-21_sedshsjjd',
  templateUrl: './21_sedshsjjd.component.html',
  styleUrls: ['./21_sedshsjjd.component.styl']
})
export class FutureSedshsjjdComponent implements OnInit {

  ttitle = '';

  data: any[] = [];

  profs: string[] = ['爱国主义教育系列', '民族、民俗、民间文化系列', '公民教育系列', '健康与生存教育系列', '科普教育系列', '行业企业体验系列', '工业和信息化系列', '职业教育考察系列', '农业生态考察系列', '绿化环境系列', '艺术教育系列', '爱心服务系列', '未成年人校外实践场所', '上海市学生社区实践指导站'];
  seli = 0;
  rows: any[]  = [];

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  ngOnInit() {
    this.ttitle = MAPDIST.sedshsjjd.title;
    this.http.get(MAPDIST.sedshsjjd.api).toPromise().then((data: any) => {
      this.data = data;

      this.selied();
    });
  }

  preProf() {
    this.seli -= 1;
    if (this.seli === -1) { this.seli = this.profs.length - 1; }

    this.selied();
  }

  nextProf() {
    this.seli += 1;
    if (this.seli === this.profs.length) { this.seli = 0; }

    this.selied();
  }

  selied() {
    const rows = [];
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if ((this.seli + 1) != item.type) { continue; }

      rows.push(item);
    }
    this.rows = rows;
  }

  toView(i: number = -1) {
    this.router.navigateByUrl('/subpmap/sedshsjjd/' + this.seli + '/' + i);
  }

}
