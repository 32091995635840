import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAPDIST } from 'src/app/shared/config';

@Component({
  selector: 'app-207_newshgxrgznzyrcpydt',
  templateUrl: './207_newshgxrgznzyrcpydt.component.html',
  styleUrls: ['./207_newshgxrgznzyrcpydt.component.styl']
})
export class NewShgxrgznzyrcpydtComponent implements OnInit {
  showexpl = false;

  title = '';

  kw = '';
  rlist: any = [];

  tagi = 0;
  tags = [];

  data: any[] = [];
  rows: any[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit() {
    this.title = MAPDIST.newshgxrgznzyrcpydt.title;
    this.http.get(MAPDIST.newshgxrgznzyrcpydt.api).toPromise().then((resp: any) => {
      const tags = [];
      for (const ditem of resp) {
        if (!tags.includes(ditem.level)) {
          tags.push(ditem.level);
        }
      }
      this.tags = tags;
      this.data = resp;

      this.calc();
    });
  }

  selied(i: number) {
    this.tagi = i;
    this.calc();
  }

  changeKw() {
    this.kw = this.kw.trim();
    if (this.kw.length === 0) {
      this.rlist = [];
      return;
    }

    const p = new RegExp(this.kw, 'i');
    const rlist = [];
    const limit = 5;
    let ij = 0;
    for (let i = 0; i < this.data.length; i++) {
      if (ij === limit) { break; }
      const obj = this.data[i];
      if (p.test(obj.name)) {
        const id   = obj.id;
        const name = obj.name.replace(this.kw, '<span>' + this.kw + '</span>');

        rlist.push({id, name: name + ' - ' + obj.subject});
        ij++;
      }
    }

    this.rlist = rlist;
  }

  calc() {

    const rows = [];
    const tmps = {};
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (item.level.includes(this.tags[this.tagi])) {
        if (!tmps[item.name]) { tmps[item.name] = {name: item.name, subs: []}; }
        tmps[item.name].subs.push(item);
      }
    }
    Object.keys(tmps).forEach(kname => {
      rows.push(tmps[kname]);
    });

    this.rows = rows;
  }

  showFullMapEvent(i: number = -1) {
    this.router.navigateByUrl('/new/pmap/newshgxrgznzyrcpydt/' + i);
  }
}
